import { LatLngExpression } from "leaflet";

export const NORWAY_BOUNDS: LatLngExpression[] = [
  {
    lat: 67.31445151125824,
    lng: 24.873046875000004,
  },
  {
    lat: 58.263287052486035,
    lng: -5.141601562500001,
  },
];

export const THIRTY_SECONDS_IN_MS = 30 * 1000;

export const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

export const YESTERDAY = new Date(Date.now() - ONE_DAY_IN_MS);

export const MAP_MAX_ZOOM = 19;

export const vehicleTypes = [
  "69",
  "70",
  "72",
  "73",
  "74",
  "75",
  "Vogn",
] as const;

export type VehicleType = (typeof vehicleTypes)[number];
