import { useQueries } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import {
  StationEventResponse,
  stationEventResponse,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/stationEventResponseSchema";

export const useStationEvents = (uuids: string[]) =>
  useQueries({
    queries: uuids.map((uuid) => ({
      queryKey: ["stationEventByUuid", uuid],
      queryFn: () =>
        axiosClient
          .get<StationEventResponse>(
            `${getBackendUrl()}/station-information/information/${uuid}`,
            // signal?
          )
          .then((res) => {
            // Opinfo has different types for STATION_GENERAL_INFO and _WARNING, but for UX reasons
            // the modal form handles them as the same event with severity as a different field.
            // Therefore we have to consolidate the type here to be able to parse those events.
            const stationInformationType =
              res.data.stationInformationType.startsWith("STATION_GENERAL")
                ? "STATION_GENERAL"
                : res.data.stationInformationType;
            return stationEventResponse.parse({
              ...res.data,
              stationInformationType,
            });
          }),
      staleTime: 0,
    })),
  });
