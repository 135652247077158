import type { AwsRumConfig } from "aws-rum-web";

/**
 * Errors that do not affect user experience, but we have no sound way of entirely
 * getting rid of, so they end of bloating our logs (and AWS bill) more than necessary.
 */
const IGNORED_ERRORS: string[] = [];

export function ignoreError(
  error: string | ErrorEvent | PromiseRejectionEvent,
) {
  let message = "unknown error";
  if (typeof error === "string") {
    message = error;
  } else if ("message" in error) {
    message = error.message;
  }

  return IGNORED_ERRORS.some((it) =>
    message.toLowerCase().includes(it.toLowerCase()),
  );
}

export function awsRumConfig(options: {
  identityPoolId: string;
  endpoint: string;
  sessionAttributes?: Record<string, string>;
  sessionEventLimit: number;
}): AwsRumConfig {
  return {
    sessionSampleRate: 1,
    identityPoolId: options.identityPoolId,
    endpoint: options.endpoint,
    allowCookies: true,
    enableXRay: true,
    sessionEventLimit: options.sessionEventLimit,
    sessionLengthSeconds: 3600,
    dispatchInterval: 5000,
    telemetries: [
      [
        "errors",
        {
          ignore: ignoreError,
        },
      ],
      [
        "performance",
        {
          eventLimit: 10,
        },
      ],
      [
        "http",
        {
          addXRayTraceIdHeader: true,
          // Don't log errors for requests to AWS (which in our case are RUM requests).
          // Also exclude requests to Puzzel because they do not allow the xray trace id header.
          urlsToExclude: [
            /\bhttps?:\/\/(?:\S+\.)?amazonaws\.com\S*\b/,
            /\bhttps?:\/\/(?:\S+\.)?puzzel\.com\S*\b/,
          ],
        },
      ],
    ],
    sessionAttributes: {
      clientVersion: import.meta.env.APP_VERSION,
      ...options.sessionAttributes,
    },
  };
}
