import { useEffect } from "react";
import constate from "constate";
import { useApiAction } from "../../../../../api/drops/useApiAction";
import { usePuzzelAuthenticationContext } from "./PuzzelAuthenticationContext";
import { useUserValidationState } from "./UserValidationContext";
import {
  userGetTelephoneApi,
  userLogOnOffTelephoneApi,
} from "../../../../../api/puzzel/puzzel";

function useCallStatus() {
  const { userId } = useUserValidationState();

  const { isAuthenticated } = usePuzzelAuthenticationContext();

  const [userGetRequest, performUserGet]: any = useApiAction(
    userGetTelephoneApi,
    false,
  );

  const [userLogOnOffRequest, performUserLogOnOff] = useApiAction(
    userLogOnOffTelephoneApi,
    false,
  );

  useEffect(() => {
    if (isAuthenticated && userId) {
      performUserGet({ userId });
    }
  }, [isAuthenticated, userLogOnOffRequest.data, userId]);

  const profileId = userGetRequest.data?.result?.currentProfileId;
  const currentProfile = profileId
    ? userGetRequest.data?.result?.profiles.find(
        (profile: { id: number }) => profile.id === profileId,
      )
    : null;
  const profiles = userGetRequest.data?.result?.profiles;

  const statusLogonFromLogOnOffRequest = userLogOnOffRequest.data?.result
    ? userLogOnOffRequest.data?.result !== "LoggedOff"
    : undefined;
  const statusLoginFromUserDetailsRequest = userGetRequest.data?.result
    ?.contactCentreStatus
    ? userGetRequest.data?.result?.contactCentreStatus !== "LoggedOff"
    : undefined;

  let isLoggedOn = null;

  if (statusLogonFromLogOnOffRequest !== undefined) {
    isLoggedOn = statusLogonFromLogOnOffRequest;
  } else if (statusLoginFromUserDetailsRequest !== undefined) {
    isLoggedOn = statusLoginFromUserDetailsRequest;
  }

  return {
    userDetails: {
      currentProfile,
      profiles,
      isLoggedOn,
    },
    performUserLogOnOff,
  };
}

const [
  UserDetailsAndLogOnOffProvider,
  useUserDetailsState,
  usePerformUserLogOnOff,
] = constate(
  useCallStatus,
  (value) => value.userDetails,
  (value) => value.performUserLogOnOff,
);
export {
  UserDetailsAndLogOnOffProvider,
  useUserDetailsState,
  usePerformUserLogOnOff,
};
