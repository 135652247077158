import React from "react";
import styled from "styled-components";
import { Severity, SeverityIcon } from "./severity";
import { Text } from "../../typography/TitleStyles";
import { COLUMN_GAP, InfoMessageBaseStyling } from "./style";

interface InfoMessageProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  severity: Severity;
  summary: string;
  inlineButton?: React.JSX.Element;
}

const StaticMessage = styled.div<{
  severity: Severity;
}>`
  ${({ severity }) => InfoMessageBaseStyling(severity)};
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: ${COLUMN_GAP}px;

  & :nth-child(1) {
    grid-row: 1;
  }

  & :nth-child(2) {
    grid-row: 1 / span 2;
  }

  & :nth-child(3) {
    grid-row: 2;
    grid-column: 3;
  }
`;

export const StaticInfoMessage = ({
  summary,
  severity,
  inlineButton,

  ...rest
}: InfoMessageProps) => (
  <StaticMessage severity={severity} {...rest}>
    <Grid>
      <SeverityIcon severity={severity} />
      <Text>{summary}</Text>
      {inlineButton ? inlineButton : null}
    </Grid>
  </StaticMessage>
);
