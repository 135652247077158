import { useEffect, useState } from "react";

// Return the current date every minute
export const useMinute = () => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  const updateCurrentTime = () => {
    const timeUntilNextMinute = 60 - new Date().getSeconds();
    const timeout = timeUntilNextMinute === 0 ? 60 : timeUntilNextMinute;
    return window.setTimeout(() => {
      setCurrentTime(new Date());
      updateCurrentTime();
    }, timeout * 1000);
  };

  useEffect(() => {
    const timeout = updateCurrentTime();
    return () => clearTimeout(timeout);
  }, []);

  return currentTime;
};
