import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { PartialTrainIdentifier } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";

export type TrainRouteResponse = {
  uuid: string;
  firstStop: Stop;
  lastStop: Stop;
};

type Stop = {
  ids: Array<StopId>;
};

type StopId = {
  id: string;
  name?: string;
};

export const routeChangedEventsQueryKey = "routeChangedEvents";
export const useRouteChangedEvents = ({
  identifier,
  nominalDate,
  countryCode,
}: PartialTrainIdentifier) => {
  const { status, data, isSuccess } = useQuery({
    queryKey: [routeChangedEventsQueryKey, identifier, nominalDate],
    queryFn: ({ signal }) =>
      queryFnGET<TrainRouteResponse[]>({
        signal,
        url: `${getBackendUrl()}/trainInformation/train/existing-changes/${countryCode ?? "NO"}/${identifier}/${nominalDate}`,
      }),
  });
  return { status, data, isSuccess };
};
