import { useState } from "react";

type FormStatus = "validationNotStarted" | "invalid" | "valid";

type FormValidationErrors<T> = {
  [P in keyof T]?: string;
};

const hasErrors = <T extends object>(errors: FormValidationErrors<T>) =>
  Object.values(errors).filter((v) => v !== undefined).length > 0;

const getNextStatus = <T>(
  previousStatus: FormStatus,
  errors: FormValidationErrors<T>,
): FormStatus => {
  if (previousStatus === "validationNotStarted") {
    return "validationNotStarted";
  }

  return hasErrors(errors) ? "invalid" : "valid";
};

interface FormData<T> {
  status: FormStatus;
  values: T;
  errors: FormValidationErrors<T>;
}

export const useTypedForm = <T>(
  initialValues: T,
  validate: (values: T) => FormValidationErrors<T>,
) => {
  const [formData, setValues] = useState<FormData<T>>({
    status: "validationNotStarted",
    values: initialValues,
    errors: {},
  });

  const reset = (newInitialState = initialValues) =>
    setValues(() => ({
      values: newInitialState,
      status: "validationNotStarted",
      errors: {},
    }));

  const startValidation = (callback?: (formStatus: FormStatus) => void) =>
    setValues((previousFormData) => {
      const nextErrors = validate(previousFormData.values);
      const nextStatus = hasErrors(nextErrors) ? "invalid" : "valid";

      if (callback !== undefined) {
        callback(nextStatus);
      }

      return {
        ...previousFormData,
        errors: nextErrors,
        status: nextStatus,
      };
    });

  const handleChange = (update: Partial<T>) =>
    setValues((previousFormData) => {
      const nextValues = {
        ...previousFormData.values,
        ...update,
      };

      const nextErrors =
        previousFormData.status !== "validationNotStarted"
          ? validate(nextValues)
          : previousFormData.errors;

      const nextStatus = getNextStatus(previousFormData.status, nextErrors);

      return {
        ...previousFormData,
        errors: nextErrors,
        values: nextValues,
        status: nextStatus,
      };
    });

  return {
    values: formData.values,
    status: formData.status,
    errors: formData.errors,
    handleChange,
    reset,
    startValidation,
  };
};
