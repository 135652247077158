import { EditOutline18Icon } from "@vygruppen/spor-icon-react";
import { HStack, IconButton, Stack, VStack } from "@vygruppen/spor-react";
import { format } from "date-fns";
import { GlobalEvent } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { Badges } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/Badges";
import {
  getDisplayDurationFromVisibility,
  sortByVisibility,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/utils";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { Text } from "shared/components/typography/TitleStyles";
import { dropsRole } from "stores/dropsRole";
import { useTheme } from "styled-components";

type GlobalEventsProps = {
  data: GlobalEvent[];
  onEditClick: (event: GlobalEvent) => void;
};

export const GlobalEventsList = ({ data, onEditClick }: GlobalEventsProps) => {
  const theme = useTheme();
  const { isVaktleder } = dropsRole();
  if (data.length === 0)
    return <NoDataMessage reason="Det er ikke registrert noen hendelser" />;
  return (
    <Stack gap={3}>
      {data.sort(sortByVisibility).map((event) => {
        const text =
          event.customTexts.length > 0
            ? event.customTexts[0].texts.NOB
            : { title: "", description: "" };
        return (
          <HStack
            key={event.uuid}
            border={`1px solid ${theme.colorOutline}`}
            borderRadius="sm"
            p={2}
            justifyItems="space-between"
            alignItems="flex-start"
            gap={4}
          >
            <VStack alignItems="flex-start" flex="1">
              <Text bold>{text.title}</Text>
              <Badges
                eventCategory="global"
                displayDuration={getDisplayDurationFromVisibility(
                  event.visibility,
                )}
              />
              <Text>{text.description}</Text>
              <Text secondary>
                Sist oppdatert {format(event.updatedAt.toDate(), "dd.MM.yyyy")}{" "}
                kl. {format(event.updatedAt.toDate(), "HH:mm")}
              </Text>
            </VStack>
            {isVaktleder() && (
              <IconButton
                onClick={() => onEditClick(event)}
                size="sm"
                aria-label="Rediger"
                variant="ghost"
                icon={<EditOutline18Icon />}
              />
            )}
          </HStack>
        );
      })}
    </Stack>
  );
};
