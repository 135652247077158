import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Text, Flex, TextLink, Input } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { VisibilityInput } from "features/CenterContent/RoleContent/GlobalEvents/VisibilityInput";
import { formToGlobalInformationRequest } from "features/CenterContent/RoleContent/GlobalEvents/formToGlobalInformationRequest";
import { getDefaultValues } from "features/CenterContent/RoleContent/GlobalEvents/globalEventDefaultValues";
import {
  GlobalEvent,
  GlobalEventRequest,
  GlobalFormSchema,
  globalFormSchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { InfrastructureEventCreatedResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { TextAreaWithCharacterCount } from "shared/components/forms/TextAreaWithCharacterCount";
import { format } from "date-fns";
import { GLOBAL_EVENTS_QUERY_KEY } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewButtonAndModal";
import chainLink from "shared/icons/chain-link.svg?react";
import styled from "styled-components";

export const ChainLinkIcon = styled(chainLink)`
  width: 18px;
  path {
    fill: ${({ theme }) => theme.colorTextMain};
  }
`;

type GlobalEventModalProps = {
  onClose: () => void;
  event?: GlobalEvent;
  relatedInfrastructureEvent?: InfrastructureEventCreatedResponse;
};

export const GlobalEventModal: FC<GlobalEventModalProps> = ({
  onClose,
  event,
  relatedInfrastructureEvent,
}) => {
  const isEditMode = !!event;
  const isRelatedToIncident =
    !!relatedInfrastructureEvent ||
    (isEditMode && event?.referencedIncidentIds.length > 0);
  const incidentId =
    isEditMode && event?.referencedIncidentIds.length > 0
      ? event.referencedIncidentIds[0]
      : relatedInfrastructureEvent?.incidentId;

  const formMethods = useForm<GlobalFormSchema>({
    resolver: zodResolver(globalFormSchema),
    defaultValues: getDefaultValues(relatedInfrastructureEvent, event),
    mode: "onBlur",
  });

  const textFieldErrors =
    formMethods.formState.errors.globalForm?.customTexts?.texts;

  const queryClient = useQueryClient();
  const { mutate: postGlobalInfo, status: postGlobalInfoStatus } = useMutation({
    mutationFn: (data: GlobalEventRequest) =>
      mutationFnPOST<undefined, GlobalEventRequest>(
        `${getBackendUrl()}/global-information${isEditMode ? `/${event.uuid}/change` : "/create"}`,
        data,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GLOBAL_EVENTS_QUERY_KEY] });
    },
  });
  return (
    <FormProvider {...formMethods}>
      <ActionModal
        title={`${isEditMode ? "Endre" : "Opprett"} global driftsmelding`}
        actionTitle={`${isEditMode ? "Endre" : "Opprett"} hendelse`}
        titleBadge={isRelatedToIncident ? "Infrastrukturavhengig" : "Uavhengig"}
        onClose={onClose}
        onSubmit={formMethods.handleSubmit((data) =>
          postGlobalInfo(
            formToGlobalInformationRequest(data.globalForm, incidentId),
          ),
        )}
        isLoading={postGlobalInfoStatus === "pending"}
        isSuccess={postGlobalInfoStatus === "success"}
        isError={postGlobalInfoStatus === "error"}
        successMessage={isEditMode ? "Hendelse endret" : "Hendelse opprettet"}
        failureMessage={`Kunne ikke ${isEditMode ? "endre" : "opprette"} hendelsen. Prøv på nytt, eller kontakt IT hvis feilen vedvarer.`}
        internalMessage={isEditMode ? "edit" : "create"}
      >
        <ErrorBoundary>
          <Flex gap={3} flexDirection="column" width="100%">
            {isEditMode && (
              <Text variant="xs">
                <span style={{ fontWeight: "bold" }}>Sist oppdatert:</span>{" "}
                {format(event.updatedAt.toDate(), "dd.MM.yyyy")} kl.{" "}
                {format(event.updatedAt.toDate(), "HH:mm")}
              </Text>
            )}
            <Text variant="xs">Skriv egendefinert melding</Text>
            <TextAreaWithCharacterCount
              label="Norsk overskrift *"
              maxLength={50}
              formField="NOB.title"
              isInvalid={!!textFieldErrors?.NOB?.title}
              errorMessage={textFieldErrors?.NOB?.title?.message}
            />
            <TextAreaWithCharacterCount
              label="Norsk tekst *"
              maxLength={250}
              minHeight="150px"
              formField="NOB.description"
              isInvalid={!!textFieldErrors?.NOB?.description}
              errorMessage={textFieldErrors?.NOB?.description?.message}
            />
            <TextAreaWithCharacterCount
              label="English title"
              maxLength={50}
              formField="ENG.title"
              isInvalid={!!textFieldErrors?.ENG?.title}
              errorMessage={textFieldErrors?.ENG?.title?.message}
            />
            <TextAreaWithCharacterCount
              label="English text"
              maxLength={250}
              minHeight="150px"
              formField="ENG.description"
              isInvalid={!!textFieldErrors?.ENG?.description}
              errorMessage={textFieldErrors?.ENG?.description?.message}
            />
            <Text variant="xs">
              Hjelpetekster til ulike situasjoner finnes{" "}
              <TextLink href="https://vygruppen.atlassian.net/wiki/spaces/KUNDEINFO/pages/7180877920/Ekstra+kundeinformasjon+ved+store+oppst+tte+avvik+og+hendelser">
                her
              </TextLink>
            </Text>

            {/* <Input leftIcon={<ChainLinkIcon />} label="Legg til lenke" /> */}

            <VisibilityInput isRelatedToIncident={isRelatedToIncident} />
            <Text variant="xs">
              <span style={{ fontWeight: "bold" }}>OBS!</span> Globale
              driftsmeldinger blir synlig for alle kunder i alle kundekanaler
            </Text>
          </Flex>
        </ErrorBoundary>
      </ActionModal>
    </FormProvider>
  );
};
