import { useState } from "react";
import constate from "constate";

export const initStates = {
  showIncoming: true,
  showOutgoing: false,
  inConversation: true,
  inService: false,
  requestId: undefined,
  iqSessionId: undefined,
  callerNumber: undefined,
};

function useCallStatus() {
  const [callStatusState, setCallStatusState] = useState(initStates);
  const [profileId, setProfileId] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");

  return {
    callStatusState,
    setCallStatusState,
    profileId,
    setProfileId,
    phoneNumber,
    setPhoneNumber,
  };
}

const [
  CallStatusProvider,
  useCallStatusState,
  useSetCallStatus,
  useProfileId,
  useSetProfileId,
  usePhoneNumber,
  useSetPhoneNumber,
] = constate(
  useCallStatus,
  (value) => value.callStatusState,
  (value) => value.setCallStatusState,
  (value) => value.profileId,
  (value) => value.setProfileId,
  (value) => value.phoneNumber,
  (value) => value.setPhoneNumber,
);
export {
  CallStatusProvider,
  useCallStatusState,
  useSetCallStatus,
  useProfileId,
  useSetProfileId,
  usePhoneNumber,
  useSetPhoneNumber,
};
