import { format } from "date-fns/format";
import { nb } from "date-fns/locale/nb";
import { FC } from "react";
import { HStack } from "@vygruppen/spor-react";
import { ReserveAlternativeTransport } from "shared/types/alternativeTransport";
import styled from "styled-components";
import { VehiclePhoneNumber } from "../shared/VehiclePhoneNumber";
import { VehicleSummary } from "../shared/VehicleSummary";
import { vehicleTypeToIcon } from "../shared/utils";
import { VehicleBooking } from "./VehicleBooking";

type Props = {
  reserveAlternativeTransport: ReserveAlternativeTransport;
};

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 2;
`;

export const ReserveVehicleInfo: FC<Props> = ({
  reserveAlternativeTransport,
}) => {
  const {
    id,
    baseStopName,
    workShiftId,
    shiftStartTime,
    operatorName,
    shiftEndTime,
    type,
    driverPhoneNumber,
  } = reserveAlternativeTransport;
  const [Icon, iconTitle] = vehicleTypeToIcon.get(type)!;

  const date = shiftStartTime
    ? format(shiftStartTime, "(dd.MM.yy)", { locale: nb })
    : undefined;

  const formattedShiftStartTime = shiftStartTime
    ? format(shiftStartTime, "HH:mm", { locale: nb })
    : null;
  const formattedShiftEndTime = shiftEndTime
    ? format(shiftEndTime, "HH:mm", { locale: nb })
    : null;

  const timeInterval = [formattedShiftStartTime, "-", formattedShiftEndTime]
    .filter((element) => element !== null)
    .join(" ");

  return (
    <InfoWrapper>
      <VehicleSummary
        vehicleIcon={<Icon title={iconTitle} />}
        vehicleInfo={operatorName}
        title="Reserve"
        subTitle={workShiftId}
        route={baseStopName}
        interval={timeInterval}
        date={date}
      />

      <HStack role="group" mt={2}>
        <VehiclePhoneNumber phoneNumber={driverPhoneNumber} id={id} />
        <VehicleBooking vehicle={reserveAlternativeTransport} />
      </HStack>
    </InfoWrapper>
  );
};
