import {
  CalendarDateTime,
  getLocalTimeZone,
  toZoned,
} from "@internationalized/date";
import {
  FormSchemaDuration,
  infrastructureParametersSchema,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { InfrastructureProviderEnum } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureProvider";
import { ruleToPeriod } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/periodicDurationUtils";
import {
  OpenInfrastructureEventBody,
  RequestDuration,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { isCustomInputType } from "features/CenterContent/shared/operationalInformation/utils";
import { z } from "zod";

export const formatTime = (time: CalendarDateTime) => {
  const localTimeZone = getLocalTimeZone();
  return toZoned(time, localTimeZone).toString();
};

export const createRequestDurations = (
  durations: FormSchemaDuration,
): RequestDuration => {
  switch (durations.type) {
    case "TIMED":
      return {
        fromTime: formatTime(durations.from_time),
        toTime: formatTime(durations.to_time),
      };
    case "PERIODIC": {
      return { periods: durations.rules.flatMap((rule) => ruleToPeriod(rule)) };
    }
    case "OPEN_ENDED":
    default:
      return {
        fromTime: formatTime(durations.from_time),
        timeHorizonInMinutes: Number(durations.extension_time_in_minutes),
      };
  }
};

export const getInfrastructureEventBody = (
  formState: z.infer<typeof infrastructureParametersSchema>,
): OpenInfrastructureEventBody => ({
  type: formState.event,
  reason: {
    type: formState.reason.type,
    description:
      isCustomInputType(formState.reason.type) && formState.reason.description
        ? formState.reason.description
        : null,
  },
  action:
    formState.action.type.length > 0
      ? {
          type: formState.action.type,
          description:
            isCustomInputType(formState.action.type) &&
            formState.action.description
              ? formState.action.description
              : null,
        }
      : null,
  actionCard:
    formState.actionCard === "" ? null : { type: formState.actionCard },
  consequence:
    formState.consequence.type.length > 0
      ? {
          type: formState.consequence.type,
          description:
            isCustomInputType(formState.consequence.type) &&
            formState.consequence.description
              ? formState.consequence.description
              : null,
        }
      : null,
  prognosis:
    formState.prognosisType.type.length > 0
      ? {
          type: formState.prognosisType.type,
          description:
            isCustomInputType(formState.prognosisType.type) &&
            formState.prognosisType.description
              ? formState.prognosisType.description
              : null,
          estimatedInfoUpdate:
            formState.prognosisType.type === "ESTIMATED_INFO_UPDATE"
              ? formatTime(formState.prognosisTime)
              : null,
          estimatedResolved:
            formState.prognosisType.type === "ESTIMATED_OPENING"
              ? formatTime(formState.prognosisTime)
              : null,
        }
      : null,
  stretchName: formState.stretchName,
  affectedLegs: formState.affectedLegs,
  redirectStretches: formState.redirectStretches,
  duration: createRequestDurations(formState.durations),
  countryCode: "NO",
  isirkId: formState.isirkId === "" ? null : formState.isirkId,
  infrastructureProvider:
    formState.infrastructureProvider === InfrastructureProviderEnum.NONE
      ? null
      : formState.infrastructureProvider,
});
