import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { BusGpsResponseSchema } from "features/CenterContent/shared/types";
import { busGpsDataToBaseGpsData } from "features/CenterContent/shared/utils";
import { useParams } from "react-router-dom";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { AlternativeTransportVehicle } from "shared/types/alternativeTransport";
import { GPSContent } from "../../shared/GPSContent";

type BusLocations = {
  locations: {
    latitude: string;
    longitude: string;
    timestamp: string;
  }[];
};

export const BusGps = ({
  vehicle,
}: {
  vehicle: AlternativeTransportVehicle;
}) => {
  const { vehicleUuid: uuid } = useParams();
  const { status, data } = useQuery({
    queryKey: ["bus", uuid],
    refetchInterval: 60000,
    queryFn: ({ signal }) =>
      queryFnGET<BusLocations>({
        signal,
        url: `${getBackendUrl()}/bus/locations?trip_id=${uuid}`,
      }),
  });

  const locations = data?.locations ?? [];
  const [currentLocation, ...path] = locations.toReversed();

  const busGps = BusGpsResponseSchema.parse({
    data: currentLocation
      ? [
          {
            workshiftId: vehicle?.workShiftPlan?.shiftId ?? "",
            trainNumber: vehicle?.trainIds.join(" - ") ?? "",
            phoneNumber: vehicle?.driverPhoneNumber ?? "",
            timestamp: new Date(currentLocation.timestamp).toISOString(),
            gps: {
              longitude: currentLocation.longitude,
              latitude: currentLocation.latitude,
            },
            closestNeighbor: null,
            path: path.map((location) => ({
              timestamp: new Date(location.timestamp).toISOString(),
              gps: {
                longitude: location.longitude,
                latitude: location.latitude,
              },
            })),
          },
        ]
      : [],
  });

  const getGPS = () => {
    switch (status) {
      case "pending":
        return <SkeletonLoader skeletonType="trainmap" />;
      case "success":
        if (busGps.data.length > 0) {
          return <GPSContent gpsData={busGpsDataToBaseGpsData(busGps.data)} />;
        }
        return (
          <NoDataMessage reason="Det finnes ikke GPS-data for denne bussen" />
        );
      case "error":
      default:
        return (
          <FailureMessage customMessage="Kunne ikke hente GPS-data for denne bussen" />
        );
    }
  };

  return getGPS();
};
