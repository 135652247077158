import { DefaultError, UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";

// Yes you could make a mock handler instead, but it requires more file
// navigation both while making the mock and cleaning away the mock.
// This also makes it more clear whether the component is finished
// or is only mocked for now. It includes a slight delay to mock the status.

export function useQueryMock<T>(data: T): UseQueryResult<T, DefaultError> {
  const [isPending, setIsPending] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsPending(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [data]);

  return {
    data: isPending ? undefined : data,
    error: null,
    isError: false,
    isPending,
    isLoadingError: false,
    isRefetchError: false,
    isSuccess: !isPending,
    status: isPending ? "pending" : "success",
  } as UseQueryResult<T, DefaultError>;
}
