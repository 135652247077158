import { useColorMode, VyLogo } from "@vygruppen/spor-react";
import { Link } from "react-router-dom";

export const Logo = () => {
  const { colorMode } = useColorMode();
  return (
    <Link to="/" aria-label="Lenke til startsiden">
      <VyLogo display="block" colorScheme={colorMode} width="80px" />
    </Link>
  );
};
