import { FC } from "react";
import { CommonSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { TrainContext } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";
import { TrainDelayTypeFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainDelayed";

export const TrainLateToTrack: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps & TrainContext
> = (props) => (
  <TrainDelayTypeFields
    label="Utgangstid (min)"
    delayFieldKey="minutesLate"
    {...props}
  />
);
