import { Tooltip } from "@chakra-ui/react";
import { InformationOutline24Icon } from "@vygruppen/spor-icon-react";
import {
  Box,
  HStack,
  SkeletonText,
  StaticAlert,
  Text,
} from "@vygruppen/spor-react";
import { FC } from "react";
import { PreviewStatus } from "./Preview";

type PreviewProps = {
  previewStatus: PreviewStatus;
  previewTexts?: string[];
  numberOfPreviews?: number;
  isFormValid: boolean;
};

const PreviewContent: FC<PreviewProps> = ({
  previewStatus,
  previewTexts,
  numberOfPreviews,
  isFormValid,
}) => {
  if (!isFormValid) {
    return <Text variant="xs">En oppsummering av hendelsen.</Text>;
  }

  switch (previewStatus) {
    case "idle":
      return <Text variant="xs">En oppsummering av hendelsen.</Text>;
    case "pending":
      return <SkeletonText noOfLines={numberOfPreviews} width="100%" />;
    case "success":
      return (
        previewTexts &&
        previewTexts.map((text, i) => (
          <Text key={i} mt={2} variant="xs" data-testid="previewMessage">
            {text}
          </Text>
        ))
      );
    case "error":
    default:
      return (
        <StaticAlert variant="error">Kunne ikke hente oppsummering</StaticAlert>
      );
  }
};

export const PreviewList: FC<PreviewProps> = (props) => (
  <Box>
    <HStack>
      <Text variant="xs" fontWeight="bold">
        Oppsummering
      </Text>
      <Tooltip
        hasArrow
        label="Dette er en oppsummering av informasjon som sendes ut om denne hendelsen. Ordlyden tilpasses etter mottaker."
        placement="right"
      >
        <InformationOutline24Icon />
      </Tooltip>
    </HStack>
    <PreviewContent {...props} />
  </Box>
);
