import { css } from "styled-components";

export const ObtrusiveScrollCss = css`
  --scrollbar-size: 0.375rem;
  --scrollbar-minlength: 1.5rem; /* Minimum length of scrollbar thumb (width of horizontal, height of vertical) */
  --scrollbar-ff-width: thin; /* FF-only accepts auto, thin, none */
  --scrollbar-track-color: transparent;
  --scrollbar-color: rgba(174, 175, 176, 0.44);
  --scrollbar-color-hover: rgba(141, 142, 143, 0.75);
  --scrollbar-color-active: rgba(141, 142, 143, 0.55);
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    height: var(--scrollbar-size);
    width: var(--scrollbar-size);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color-hover);
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: var(--scrollbar-color-active);
  }
  &::-webkit-scrollbar-thumb:vertical {
    min-height: var(--scrollbar-minlength);
  }
  &::-webkit-scrollbar-thumb:horizontal {
    min-width: var(--scrollbar-minlength);
  }
`;
