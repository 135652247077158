import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import styled from "styled-components";
import { OperationalTrainInfo } from "./OperationalTrainInfo/OperationalTrainInfo";
import TrainReasonCodes from "./TrainReasonCodes/TrainReasonCodes";
import VehicleAlerts from "./VehicleAlerts";

const TrainConditionContainer = styled.div`
  ${ObtrusiveScrollCss};
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow-y: scroll;
`;

const TrainCondition = () => (
  <TrainConditionContainer>
    <OperationalTrainInfo />
    <VehicleAlerts />
    <TrainReasonCodes />
  </TrainConditionContainer>
);

export default withErrorBoundary(TrainCondition);
