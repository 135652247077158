import { useCheckboxGroup } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { FilterOutline30Icon } from "@vygruppen/spor-icon-react";
import {
  CardSelect,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  SearchInput,
  SimpleGrid,
  Switch,
  Text,
} from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import {
  TrainGpsResponse,
  TrainGpsResponseSchema,
} from "features/CenterContent/shared/types";
import { trainGpsDataToBaseGpsData } from "features/CenterContent/shared/utils";
import { useState } from "react";
import useDebouncedValue from "shared/hooks/useDebouncedValue";
import styled, { useTheme } from "styled-components";
import MapAlert from "./MapAlert";
import { Wrapper } from "./VehicleMap";
import VehicleMapLayers from "./VehicleMapLayers";
import { THIRTY_SECONDS_IN_MS, VehicleType, vehicleTypes } from "./constants";
import { getFilteredVehicles } from "./utils";

const FilterSelect = styled(CardSelect)`
  &&& button {
    border-radius: 40px;
    background: ${({ theme }) => theme.colorBackgroundSecondary};
  }
`;

const VehicleMapTrain = () => {
  const [showOldVehicleTypes, setShowOldVehicleTypes] = useState(false);
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebouncedValue(query, 500);
  const { value: currentFilters, getCheckboxProps } = useCheckboxGroup();

  const { data: trainGps, isError } = useQuery({
    queryKey: ["trainGps"],
    refetchInterval: THIRTY_SECONDS_IN_MS,
    queryFn: ({ signal }) =>
      axiosClient
        .get<TrainGpsResponse>(
          `${getBackendUrl()}/trainGps/gpsDataForVehicleSets/NO`,
          {
            signal,
          },
        )
        .then((res) => TrainGpsResponseSchema.parse(res.data)),
  });

  const filterdVehicles =
    getFilteredVehicles(trainGps, query, currentFilters, showOldVehicleTypes) ??
    [];

  const hasNoResults: boolean =
    !filterdVehicles?.some((vehicle) =>
      vehicle.resourceId.startsWith(debouncedQuery),
    ) && debouncedQuery.length !== 0;

  const numberOfResults = (type: VehicleType) => {
    if (type !== "Vogn") {
      return (
        filterdVehicles?.filter((vehicle) =>
          vehicle.resourceId.startsWith(type),
        ).length || 0
      );
    }
    return (
      filterdVehicles?.filter(
        (vehicle) =>
          vehicle.resourceId.startsWith("2") ||
          vehicle.resourceId.startsWith("5-") ||
          vehicle.resourceId.startsWith("7-"),
      ).length || 0
    );
  };

  const theme = useTheme();

  return (
    <Wrapper
      position="relative"
      height="100%"
      width="100%"
      align="center"
      justify="center"
    >
      <Grid
        position="absolute"
        alignItems="center"
        column={2}
        top={8}
        left={0}
        p={2}
        gap={2}
        zIndex={1000}
      >
        <SearchInput
          label="Søk på sett-ID"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onReset={() => setQuery("")}
        />
        <FilterSelect
          variant="base"
          size="md"
          label={`Filtrér (${currentFilters.length})`}
          crossOffset={90}
          withChevron={false}
          icon={<FilterOutline30Icon />}
        >
          <SimpleGrid minW={280} columns={2} role="group">
            {vehicleTypes.map((type) => (
              <Checkbox
                key={type}
                value={type}
                padding={2}
                {...getCheckboxProps({ value: type })}
              >
                {type}{" "}
                <Text textColor={theme.colorTextSecondary} display="inline">
                  ({numberOfResults(type)})
                </Text>
              </Checkbox>
            ))}
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              gridColumn="span 2"
            >
              <Divider my={2} />
              <FormControl display="flex" justifyContent="center">
                <FormLabel>Vis eldre enn 1 døgn</FormLabel>
                <Switch
                  size="sm"
                  isChecked={showOldVehicleTypes}
                  onChange={() => setShowOldVehicleTypes(!showOldVehicleTypes)}
                />
              </FormControl>
            </Flex>
          </SimpleGrid>
        </FilterSelect>
        <GridItem colSpan={2}>
          <MapAlert
            isError={isError}
            hasNoResults={hasNoResults}
            query={debouncedQuery}
          />
        </GridItem>
      </Grid>

      <VehicleMapLayers
        gpsData={trainGpsDataToBaseGpsData(filterdVehicles)}
        query={debouncedQuery}
        searchMarginTop={8}
      />
    </Wrapper>
  );
};

export default withErrorBoundary(VehicleMapTrain);
