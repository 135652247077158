import z from "zod";

export const staffSchema = z.object({
  name: z.string().nullable(),
  employeeNumber: z.string(),
  dayPlanDate: z.string(),
  gsmrPhoneNumber: z.string().nullable(),
  role: z.string(),
});
export const trainStaffSchema = z.object({
  staff: z.array(staffSchema),
});
