import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "api/http/config";
import {
  AffectedTrainsResponse,
  AffectedTrainsResponseSchema,
} from "features/CenterContent/RoleContent/Vaktleder/types";
import { getBackendUrl } from "api/common";
import { useEffect } from "react";
import {
  TRAINMAP_WS_PROPERTIES,
  TrainMapWebsocketMessage,
  isOTRMessage,
} from "websocket/trainmap/trainMapWebsocketHelper";
import { useWebsocketClient } from "websocket/useWebsocketClient";

export const useAffectedTrains = () => {
  const affectedTrainsResult = useQuery({
    queryKey: ["affectedTrainsList"],
    queryFn: ({ signal }) =>
      axiosClient
        .get<AffectedTrainsResponse>(`${getBackendUrl()}/affected-trains`, {
          signal,
        })
        .then((res) => AffectedTrainsResponseSchema.parse(res.data)),
    refetchInterval: 5 * 60 * 1000, // Every 5 minutes
  });

  const {
    message: infraEventMessage,
    register,
    deregister,
  } = useWebsocketClient<TrainMapWebsocketMessage>({
    ...TRAINMAP_WS_PROPERTIES,
  });

  useEffect(() => {
    register("useAffectedTrains", ["INFRASTRUCTURE_EVENT"]);
    return () => deregister("useAffectedTrains", ["INFRASTRUCTURE_EVENT"]);
  }, []);

  /**
   * refetch affected trains whenever we receive a new infrastructure event
   */
  useEffect(() => {
    if (
      infraEventMessage.status === "received" &&
      !isOTRMessage(infraEventMessage.data) &&
      infraEventMessage.data.topic === "INFRASTRUCTURE_EVENT" &&
      affectedTrainsResult.isSuccess
    ) {
      // It takes a while to calculate affected trains, so we delay the refetch a bit.
      // This is a best-effort and there is no guarantee that affected trains is ready by the time
      // we refetch.
      window.setTimeout(() => {
        affectedTrainsResult.refetch();
      }, 7_000);
    }
  }, [infraEventMessage]);

  return affectedTrainsResult;
};
