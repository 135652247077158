import { Button } from "@vygruppen/spor-react";
import React, { FC, ReactElement } from "react";
import { DefaultTheme, useTheme } from "styled-components";

type Severity = "info" | "alarm" | "secondaryAlarm" | "warning";

type ButtonColors = {
  severityTextMain: string;
  severity: string;
  severityFill: string;
  severityFillPulse: string;
};

const SeverityColors = (
  severity: Severity,
  theme: DefaultTheme,
): ButtonColors => {
  switch (severity) {
    case "alarm":
      return {
        severityTextMain: theme.colorAlarmTextMain,
        severity: theme.colorAlarm,
        severityFill: theme.colorAlarmFill,
        severityFillPulse: theme.colorAlarmFillPulse,
      };
    case "secondaryAlarm":
      return {
        severityTextMain: theme.colorSecondaryAlarmTextMain,
        severity: theme.colorAlarmSecondary,
        severityFill: theme.colorSecondaryAlarmFill,
        severityFillPulse: theme.colorSecondaryAlarmFillPulse,
      };
    case "warning":
      return {
        severityTextMain: theme.colorWarningTextMain,
        severity: theme.colorWarning,
        severityFill: theme.colorWarningFill,
        severityFillPulse: theme.colorWarningFillPulse,
      };
    case "info":
    default:
      return {
        severityTextMain: theme.colorInfoTextMain,
        severity: theme.colorInfo,
        severityFill: theme.colorInfoFill,
        severityFillPulse: theme.colorInfoFillPulse,
      };
  }
};

const getButtonStyle = (severity: Severity, theme: DefaultTheme) => {
  const colors = SeverityColors(severity, theme);

  return {
    color: colors.severityTextMain,
    backgroundColor: colors.severityFill,
    outlineColor: colors.severity,
    "&:hover": {
      color: colors.severityTextMain,
      backgroundColor: colors.severityFill,
      outlineColor: colors.severity,
    },
    "&:active": {
      color: colors.severityTextMain,
      backgroundColor: colors.severityFillPulse,
      outlineColor: colors.severity,
      outlineWidth: "2px",
    },
    "&:disabled": {
      color: colors.severityTextMain,
      backgroundColor: colors.severityFillPulse,
      outline: "none",
      opacity: 0.5,
    },
  };
};

interface SemanticButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  severity?: Severity;
  size: "xs" | "sm" | "md";
  isDisabled?: boolean;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
}

export const SemanticButton: FC<SemanticButtonProps> = ({
  title,
  severity,
  size,
  leftIcon,
  rightIcon,
  isDisabled,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Button
      size={size}
      variant="tertiary"
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      isDisabled={isDisabled}
      {...props}
      sx={
        severity
          ? getButtonStyle(severity, theme)
          : { backgroundColor: theme.colorBackgroundSecondary }
      }
    >
      {title}
    </Button>
  );
};
