import { useToast } from "@vygruppen/spor-react";
import {
  GeographicTrainMapTopology,
  Train,
  TrainMap,
} from "@vygruppen/vy-train-map";
import { LogLevel, log } from "api/cloudWatch";
import { useAffectedTrains } from "features/CenterContent/RoleContent/TrainMap/useAffectedTrains";
import { useInfrastructureInformation } from "features/CenterContent/RoleContent/TrainMap/useInfrasctructureInformation";
import { useRealtimeTrains } from "features/CenterContent/RoleContent/TrainMap/useRealtimeTrains";
import { useStationInformation } from "features/CenterContent/RoleContent/TrainMap/useStationInformation";
import { FC, useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import * as ROUTES from "shared/utils/routes";
import { dropsRole } from "stores/dropsRole";
import { useStretchBuilder } from "stores/useStretchBuilder";
import { useTheme } from "styled-components";
import { useDropsWebsocket } from "websocket/drops/DropsWebsocketContext";
import {
  TRAINMAP_WS_PROPERTIES,
  TrainMapWebsocketMessage,
  isOTRMessage,
} from "websocket/trainmap/trainMapWebsocketHelper";
import { useWebsocketClient } from "websocket/useWebsocketClient";

type TrainMapProps = {
  mapHeight: number;
  mapWidth: number;
  topology: GeographicTrainMapTopology;
  stretchBuilderMode: boolean;
  onEventClick: (ids: { infrastructure: string[]; station: string[] }) => void;
};

export const TrainMapRenderer: FC<TrainMapProps> = ({
  mapHeight,
  mapWidth,
  topology,
  stretchBuilderMode,
  onEventClick,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const toast = useToast();

  const [
    clickedStops,
    setClickedStops,
    selectedLegs,
    allStopsSelected,
    setSelectedLegs,
    setSelectedStops,
  ] = useStretchBuilder((state) => [
    state.clickedStops,
    state.setClickedStops,
    state.selectedLegs,
    state.allStopsSelected,
    state.setSelectedLegs,
    state.setSelectedStops,
  ]);

  const {
    message: websocketMessage,
    register,
    deregister,
  } = useWebsocketClient<TrainMapWebsocketMessage>({
    ...TRAINMAP_WS_PROPERTIES,
  });

  const { message: dropsWebsocketMessage } = useDropsWebsocket(
    "stationInformation",
    ["STATION_EVENT"],
  );

  const { infraInfo, isInfraInfoSuccess, handleInfraWebsocketMessage } =
    useInfrastructureInformation();

  const { stationInfo, isStationInfoSuccess, handleStationWebsocketMessage } =
    useStationInformation();

  const infraEventIds = infraInfo?.infrastructure_information.map(
    (info) => info.event.incident_id,
  );

  const { affectedTrains, isAffectedTrainsSuccess } = useAffectedTrains(
    infraEventIds,
    isInfraInfoSuccess,
  );

  const { isVaktleder } = dropsRole();
  const stretchBuilderEnabled = isVaktleder();

  useEffect(() => {
    register("TrainMap", ["INFRASTRUCTURE_EVENT"]);
    return () => deregister("TrainMap", ["INFRASTRUCTURE_EVENT"]);
  }, []);

  useEffect(() => {
    if (
      websocketMessage.status !== "received" ||
      isOTRMessage(websocketMessage.data)
    ) {
      return;
    }
    if (websocketMessage.data.topic !== "INFRASTRUCTURE_EVENT") {
      log(LogLevel.error, TrainMap.name, "Received message on unknown topic.");
      return;
    }
    handleInfraWebsocketMessage(websocketMessage.data);
  }, [websocketMessage]);

  useEffect(() => {
    if (
      dropsWebsocketMessage.status === "received" &&
      dropsWebsocketMessage.data.topic === "STATION_EVENT"
    ) {
      handleStationWebsocketMessage(dropsWebsocketMessage.data);
    }
  }, [dropsWebsocketMessage]);

  return (
    <TrainMap
      height={mapHeight}
      width={mapWidth}
      topology={topology}
      useTrains={useRealtimeTrains}
      infrastructureInformation={
        isInfraInfoSuccess && infraInfo
          ? infraInfo
          : { infrastructure_information: [] }
      }
      stationInformation={
        isStationInfoSuccess && stationInfo
          ? stationInfo
          : { station_information: [] }
      }
      affectedTrains={
        isAffectedTrainsSuccess && affectedTrains
          ? affectedTrains
          : { trains_for_event: [] }
      }
      theme={theme.themeName as "light" | "dark"}
      stretchBuilder={
        stretchBuilderEnabled
          ? {
              enabled: stretchBuilderMode,
              shouldClearSelectedStretches:
                clickedStops.length === 0 && selectedLegs.length === 0,
              setClickedStops,
              setSelectedLegs,
              setSelectedStops,
              eventOnClick: onEventClick,
              allStopsSelected,
            }
          : undefined
      }
      trainOnClick={(train: Train) => {
        if (
          (train.train_id &&
            train.train_date &&
            ["VY", "VYT", "VYG"].includes(train.train_company ?? "")) ||
          !train.train_company
        ) {
          navigate(
            generatePath(ROUTES.TRAIN, {
              countryCode: train.country_code ?? null,
              trainNumber: train.train_id,
              trainDate: train.train_date,
            }),
          );
        } else {
          toast({
            variant: "info",
            text: `Har ikke data på tog ${train.train_id} fra ${train.train_company}`,
            isClosable: true,
          });
        }
      }}
    />
  );
};
