export enum SessionCommands {
  ClientConnected = "ClientConnected",
  ConferenceAdd = "ConferenceAdd",
  ConferenceRemove = "ConferenceRemove",
  ConsultOff = "ConsultOff",
  ConsultWithAgent = "ConsultWithAgent",
  ConsultWithPhone = "ConsultWithPhone",
  Hangup = "Hangup",
  MuteOff = "MuteOff",
  MuteOn = "MuteOn",
  TransferToAgent = "TransferToAgent",
  TransferToConsultant = "TransferToConsultant",
  TransferToPersonalQueue = "TransferToPersonalQueue",
  TransferToPhone = "TransferToPhone",
  TransferToQueue = "TransferToQueue",
  TransferToService = "TransferToService",
}

export enum Status {
  Alerting = "Alerting",
  Allocated = "Allocated",
  Busy = "Busy",
  CallbackInQueue = "CallbackInQueue",
  Connected = "Connected",
  Connecting = "Connecting",
  Disconnected = "Disconnected",
  Failure = "Failure",
  InQueue = "InQueue",
  InService = "InService",
  NoAnswer = "NoAnswer",
  OnHold = "OnHold",
  Searching = "Searching",
  Setup = "Setup",
}

export enum UserStatus {
  Connected = "Connected",
  Available = "Available",
  Busy = "Busy",
  Alerting = "Alerting",
  Allocated = "Allocated",
  ConsultCall = "ConsultCall",
  Failure = "Failure",
  NoAnswer = "NoAnswer",
  Searching = "Searching",
  Setup = "Setup",
  System = "System",
  WrapUp = "WrapUp",
}

export enum IqSessionStateTypes {
  ConsultationCallTransfereeOnHold = "ConsultationCallTransfereeOnHold",
  ConsultationCall = "ConsultationCall",
  UserNotInSession = "UserNotInSession",
  UserNotConnected = "UserNotConnected",
  UserNotOnPhone = "UserNotOnPhone",
  UserNotCallee = "UserNotCallee",
  CallerOnHold = "CallerOnHold",
  JoinedToCaller = "JoinedToCaller",
  CallingCaller = "CallingCaller",
  NoRequestsInSession = "NoRequestsInSession",
  JoinedToAgent = "JoinedToAgent",
  CallingAgent = "CallingAgent",
}

export type RealTimeStateInformationRequest = {
  currentUserId: number;
  destination: string;
  extendedInformations: [];
  id: number;
  iqSessionId: string;
  isOutbound: boolean;
  mediaSubTypeId: number;
  mediaType: string;
  queueEntryTime: string;
  queueId: number;
  queuePosition: number;
  remoteAddress: string;
  requestStart: string;
  reservedUserExpiry: string;
  reservedUserId: number;
  reservedUserName: string;
  serviceId: number;
  status: string;
};

export type UserStateInformation = {
  userStatus: UserStatus;
  phoneNumber: string;
  contactCentreStatus: string;
  profileName: string;
  statusRemainingSeconds: number;
  statusSeconds: number;
  userId: number;
};

export enum ContactCentreStatus {
  LoggedOff = "LoggedOff",
  LoggedOn = "LoggedOn",
}

export type PuzzelUser = {
  id: string;
  firstName: string;
  lastName: string;
  contactCentreStatus: ContactCentreStatus;
  userGroupName: string;
};

export type IqSessionRequestType = {
  status: Status;
  remoteAddress: string;
  id: string;
};

interface CommunicationPoint {
  readonly communicationType: string;
  readonly remoteAddress: string;
}
export interface CatalogContactType {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly communicationPoints: CommunicationPoint[];
}
