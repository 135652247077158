export type DayPlanTrackingResponseType = {
  dayPlanTrackings: DayPlanTrackingType[];
};

export type DayPlanTrackingType = {
  dayPlanDate: string;
  employeeId: string;
  companyCode: string;
  requiresAcknowledgement: boolean;
  deletion: boolean;
  createdAt: string | null;
  lastUpdatedAt: string;
  fetchedAt: string | null;
  acknowledgedAt: string | null;
  acknowledgedManuallyAt: string | null;
  acknowledgedManuallyBy: string | null;
  dutyId: string;
  dutyStart: string | null;
  dutyEnd: string | null;
  isDriver: boolean;
  isConductor: boolean;
  status: DayPlanTrackingStatus;
  fullName: string | null;
  phoneNumber: string | null;
};

export enum DayPlanTrackingStatus {
  SENT = "SENT",
  RECEIVED = "RECEIVED",
  RECEIVED_BEFORE_START = "RECEIVED_BEFORE_START",
  READ = "READ",
  MANUAL = "MANUAL",
  OUTDATED = "OUTDATED",
}
