import { Divider } from "@chakra-ui/react";
import { Box, Flex, Text } from "@vygruppen/spor-react";
import { ReactNode, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { CombineButton } from "./CombineButton";
import { SplitButton } from "./SplitButton";

export type Tab = {
  id: string;
  icon: ReactNode;
  text: string;
};

const TabBox = styled(Box)<{ cursor: string }>`
  cursor: ${({ cursor }) => cursor};
  &:hover {
    * {
      opacity: 1;
    }
  }
`;

const DividerAbs = styled(Divider)`
  position: absolute;
  top: 0px;
  left: 50%;
  height: 100%;
  z-index: 100;
`;

export const TabsWindows = ({
  tabs,
  selectedTabIds,
  setSelectedTabIds,
  justifyContent,
  margin,
  allowSplit,
  notifications,
  setNotifications,
}: {
  tabs: Tab[];
  selectedTabIds: string[];
  setSelectedTabIds: (tabIds: string[]) => void;
  justifyContent: "start" | "end";
  margin?: string;
  allowSplit?: boolean;
  notifications?: number[];
  setNotifications?: (value: number[]) => void;
}) => {
  const theme = useTheme();
  const multipleTabs = selectedTabIds.length === 2;
  const showNotification = (tabId: string, index: number) => {
    const isSelected = selectedTabIds.includes(tabId);
    const hasNotifications = notifications && notifications[index] > 0;
    return !isSelected && hasNotifications;
  };

  useEffect(() => {
    if (setNotifications) setNotifications([0, 0]);
  }, [selectedTabIds]);
  return (
    <>
      <Flex
        justifyContent={multipleTabs ? "space-between" : justifyContent}
        gap="30px"
        m={margin}
        mt="24px"
        width="100%"
        pb={0}
        alignItems="center"
      >
        {tabs.map((it, index) => (
          <TabBox
            key={it.id}
            p="0px"
            pb="8px"
            ml={multipleTabs ? "40px" : "0px"}
            borderBottom={
              selectedTabIds.includes(it.id)
                ? `2px solid ${theme.colorTextMain}`
                : ""
            }
            cursor={multipleTabs ? "auto" : "pointer"}
            onClick={
              multipleTabs ? undefined : () => setSelectedTabIds([it.id])
            }
          >
            <Flex gap="10px">
              <Flex
                margin="auto"
                opacity={selectedTabIds.includes(it.id) ? "1" : "0.5"}
              >
                {it.icon}
              </Flex>
              <Text
                fontWeight="600"
                variant="xs"
                opacity={selectedTabIds.includes(it.id) ? "1" : "0.5"}
              >
                {it.text}
                {showNotification(it.id, index)
                  ? ` (${notifications![index]})`
                  : ``}
              </Text>
            </Flex>
          </TabBox>
        ))}
        {allowSplit &&
          (multipleTabs ? (
            <CombineButton tabs={tabs} setSelectedTabIds={setSelectedTabIds} />
          ) : (
            <SplitButton tabs={tabs} setSelectedTabIds={setSelectedTabIds} />
          ))}
      </Flex>
      {multipleTabs && <DividerAbs orientation="vertical" />}
    </>
  );
};
