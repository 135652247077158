import { addHours, endOfDay, formatISO, startOfDay } from "date-fns";

export const getISODrivingFromToTimes = (inputDate: Date | null) => {
  const date = inputDate ?? new Date();
  const from = formatISO(addHours(startOfDay(date), 3));
  const to = formatISO(addHours(endOfDay(date), 3));

  return { from, to };
};
export const getDate = (inputDate: Date | null) => {
  const date = inputDate ?? new Date();
  return formatISO(date, { representation: "date" });
};
