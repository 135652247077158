import {
  ChoiceChip,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Stack,
  Text,
} from "@vygruppen/spor-react";
import {
  FormErrorWithDiscriminatedField,
  FormSchema,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/formSchema";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useStretchBuilder } from "stores/useStretchBuilder";

export const StationSingleStop: FC = () => {
  const { watch, setValue, formState, clearErrors } =
    useFormContext<FormSchema>();
  const selectedTracks = watch("stationForm.affectedTracks");
  const selectedStop = watch("stationForm.stop");
  const selectedStops = useStretchBuilder((state) => state.selectedStops);

  // Nested errors within discriminated types aren't great to work with in
  // react-hook-form – add error types for each layer below discriminator
  const fieldErrors: FormErrorWithDiscriminatedField<
    "stop" | "affectedTracks"
  > = formState.errors.stationForm;

  useEffect(() => {
    if (selectedStops.length === 1) {
      setValue("stationForm.stop", selectedStops[0].id);
    } else {
      setValue("stationForm.stop", "");
    }
  }, []);

  const toggleCheckedTracks = (value: number) => {
    if (selectedTracks === undefined) {
      setValue("stationForm.affectedTracks", [value]);
    } else if (selectedTracks.includes(value)) {
      setValue("stationForm.affectedTracks", [
        ...selectedTracks.filter((track) => track !== value),
      ]);
    } else {
      setValue("stationForm.affectedTracks", [...selectedTracks, value]);
    }
  };

  return (
    <Stack>
      <FormControl isInvalid={!!fieldErrors?.stop}>
        <FormLabel as="legend">Stasjon</FormLabel>
        <HStack flexWrap="wrap">
          {selectedStops.map((stop) => (
            <ChoiceChip
              key={stop.id}
              isChecked={stop.id === selectedStop}
              onChange={() => {
                setValue("stationForm.stop", stop.id);
                clearErrors("stationForm.stop");
              }}
            >
              {stop.name}
            </ChoiceChip>
          ))}
        </HStack>
        <FormErrorMessage marginTop={2}>
          {fieldErrors?.stop?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!fieldErrors?.affectedTracks}>
        <FormLabel as="legend">Spor</FormLabel>
        <HStack flexWrap="wrap">
          {Array.from({ length: 19 }, (_, index) => index + 1).map((track) => (
            <ChoiceChip
              key={track}
              isChecked={selectedTracks?.includes(track)}
              onChange={() => {
                toggleCheckedTracks(track);
                clearErrors("stationForm.affectedTracks");
              }}
            >
              <Text w="10px" display="flex" justifyContent="center">
                {track}
              </Text>
            </ChoiceChip>
          ))}
        </HStack>
        <FormErrorMessage marginTop={2}>
          {fieldErrors?.affectedTracks?.message}
        </FormErrorMessage>
      </FormControl>
    </Stack>
  );
};
