import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
} from "@vygruppen/spor-react";
import {
  FormErrorWithDiscriminatedField,
  FormSchema,
  StationMeansOfPaymentEnum,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/formSchema";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type StationSalesChannelReducedProps = {};

export const StationSalesChannelReduced: FC<
  StationSalesChannelReducedProps
> = () => {
  const formMethods = useFormContext<FormSchema>();
  const meansOfPayment = formMethods.watch("stationForm.meansOfPayment");
  const fieldErrors: FormErrorWithDiscriminatedField<"meansOfPayment"> =
    formMethods.formState.errors.stationForm;

  return (
    <FormControl as="fieldset" isInvalid={!!fieldErrors?.meansOfPayment}>
      <FormLabel as="legend">Manglende betalingsvalg</FormLabel>

      <RadioGroup
        name="meansOfPayment"
        value={meansOfPayment}
        onChange={(value: StationMeansOfPaymentEnum) => {
          formMethods.setValue("stationForm.meansOfPayment", value);
          formMethods.clearErrors("stationForm.meansOfPayment");
        }}
      >
        <Radio value="COINS">Mynt</Radio>
        <Radio value="BANKNOTE">Seddel</Radio>
        <Radio value="CARD">Kort</Radio>
      </RadioGroup>

      <FormErrorMessage marginTop={2}>
        {fieldErrors?.meansOfPayment?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
