import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";
import { compareDateStringAsc } from "shared/utils/datetime";

// 1: [..., b, a, ...]
// -1: [..., a, b, ...]
export const sortByIncidentArrivalTime = (
  a: AffectedTrain,
  b: AffectedTrain,
) => {
  if (a.incidentArrivalTime === null && b.incidentArrivalTime === null) {
    return 0;
  }
  if (a.incidentArrivalTime === null) {
    return 1;
  }
  if (b.incidentArrivalTime === null) {
    return -1;
  }

  const currentTime = new Date().getTime();

  const aHasPassed =
    a.incidentExitTime && currentTime > a.incidentExitTime.getTime();
  const bHasPassed =
    b.incidentExitTime && currentTime > b.incidentExitTime.getTime();

  if (aHasPassed && bHasPassed) {
    return a.incidentExitTime!.getTime() - b.incidentExitTime!.getTime();
  }

  if (aHasPassed && !bHasPassed) {
    return -1;
  }
  if (!aHasPassed && bHasPassed) {
    return 1;
  }
  return a.incidentArrivalTime.getTime() - b.incidentArrivalTime.getTime();
};

export const sortByInfoUpdatedAt = (a: AffectedTrain, b: AffectedTrain) => {
  const aUpdatedAt = a.referencedOperationalTrainInformation
    ?.at(0)
    ?.versions.at(0)?.updatedAt;
  const bUpdatedAt = b.referencedOperationalTrainInformation
    ?.at(0)
    ?.versions.at(0)?.updatedAt;
  if (!aUpdatedAt && !bUpdatedAt) {
    return 0;
  }
  if (!aUpdatedAt) {
    return 1;
  }
  if (!bUpdatedAt) {
    return -1;
  }
  return compareDateStringAsc(aUpdatedAt, bUpdatedAt);
};
