import styled from "styled-components";
import {
  SelectListLITag,
  SelectListULTag,
} from "../../../../../shared/components/SelectList/SelectListStyle";

export const SearchResultList = styled(SelectListULTag)`
  max-height: 45rem;
  width: 300px;
`;

export const SearchResultRow = styled(SelectListLITag)`
  display: grid;
  align-items: center;
  grid-template-columns: 9.7rem 6rem;
`;

export const NameColumn = styled.span`
  grid-column: 1;
`;

export const PhoneNumberColumn = styled.span`
  grid-column: 2;
  justify-self: end;
`;
