import styled from "styled-components";
import { InputField } from "../../../../../shared/components/forms/Inputfield";

interface CatalogSearchInputType {
  hasDropdown: boolean;
  onKeyDown: (event: any) => void;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
  value: string;
  placeholder: string;
}

const StyledInputField = styled(InputField)`
  width: 300px;
  height: 40px;
  margin: 0;
`;

const CatalogSearchInput = ({
  hasDropdown,
  onKeyDown,
  onChange,
  onBlur,
  value,
  placeholder,
}: CatalogSearchInputType) => (
  <StyledInputField
    type="search"
    $hasDropdown={hasDropdown}
    onKeyDown={onKeyDown}
    onChange={onChange}
    onBlur={onBlur}
    value={value}
    placeholder={placeholder}
    autoFocus
  />
);

export default CatalogSearchInput;
