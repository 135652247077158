import { Button, Stack } from "@vygruppen/spor-react";
import { FC } from "react";
import { StaticInfoMessage } from "./InfoMessage/StaticInfoMessage";

type InfoMessageWithRetryButtonProps = {
  message: string;
  retryFn: () => void;
};
export const InfoMessageWithRetryButton: FC<
  InfoMessageWithRetryButtonProps
> = ({ message, retryFn }) => (
  <Stack flexDirection="row">
    <StaticInfoMessage
      severity="info"
      summary={message}
      style={{ gridColumn: "span 2" }}
      inlineButton={
        <Button
          onClick={(ev) => {
            ev.preventDefault();
            retryFn();
          }}
          size="xs"
          variant="tertiary"
          width="100%"
        >
          Prøv på nytt
        </Button>
      }
    />
  </Stack>
);
