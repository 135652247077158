import { AddOutline24Icon } from "@vygruppen/spor-icon-react";
import { Button } from "@vygruppen/spor-react";
import { FC } from "react";

type ButtonProps = {
  onClick: () => void;
};

export const AddRuleButton: FC<ButtonProps> = ({ onClick }) => (
  <Button
    variant="tertiary"
    leftIcon={<AddOutline24Icon />}
    size="sm"
    maxWidth="max-content"
    onClick={onClick}
  >
    Legg til regel
  </Button>
);
