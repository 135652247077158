import { ChoiceChip } from "@vygruppen/spor-react";
import styled, { css } from "styled-components";

export const ChoiceChipForInfoBackground = styled(ChoiceChip)`
  text-wrap: nowrap;

  > div:first-of-type {
    ${({ isChecked }) =>
      !isChecked
        ? css`
        outline-color: ${({ theme }) => theme.colorInfo};
    > span {
      color: ${({ theme }) => theme.colorInfoTextMain};
   `
        : ""}
  }
`;
