import { ZonedDateTime } from "@internationalized/date";
import { format } from "date-fns";
import {
  GlobalEvent,
  Visibility,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import {
  Duration,
  InfraStructureInformation,
} from "shared/types/infrastructureResponse";

export type DisplayDuration = {
  fromTime: ZonedDateTime;
  toTime: ZonedDateTime | null;
};

export const getDisplayDurationFromVisibility = (
  visibility: Visibility,
): DisplayDuration | null => {
  switch (visibility.type) {
    case "TIMED":
      return {
        fromTime: visibility.fromTime,
        toTime: visibility.toTime,
      };
    case "OPEN_ENDED":
      return {
        fromTime: visibility.fromTime,
        toTime: null,
      };
    case "CONSTANT":
    default:
      return null;
  }
};

export const getDisplayDuration = (
  duration: Duration,
): DisplayDuration | null => {
  switch (duration.type) {
    case "TIMED":
      return {
        fromTime: duration.from_time,
        toTime: duration.to_time,
      };
    case "OPEN_ENDED":
      return {
        fromTime: duration.from_time,
        toTime: null,
      };
    case "PERIODIC": {
      if (duration.periods.length === 0) return null;

      const from = duration.periods.sort(
        (a, b) => a.fromTime.toDate().valueOf() - b.fromTime.toDate().valueOf(),
      )[0];
      const to = duration.periods.sort(
        (a, b) => b.toTime.toDate().valueOf() - a.toTime.toDate().valueOf(),
      )[0];

      return {
        fromTime: from.fromTime,
        toTime: to.toTime,
      };
    }
    default:
      return null;
  }
};

export const sortByDisplayDuration = (
  a: InfraStructureInformation,
  b: InfraStructureInformation,
) => {
  const durationA = getDisplayDuration(a.durations);
  const durationB = getDisplayDuration(b.durations);

  const fromTimeA = durationA?.fromTime.toDate().valueOf();
  const fromTimeB = durationB?.fromTime.toDate().valueOf();

  if (fromTimeA === undefined || fromTimeB === undefined) return 0;

  return fromTimeA - fromTimeB;
};

export const getDisplayDurationString = (
  displayDuration: DisplayDuration | null,
) => {
  if (displayDuration === null) return "Ukjent tidsrom.";

  if (!displayDuration.toTime)
    return `Fra kl. ${format(displayDuration.fromTime.toDate(), "HH:mm, dd.MM.yyyy")}`;

  return `Fra ${format(displayDuration.fromTime.toDate(), "dd.MM.yyyy")} - ${format(
    displayDuration.toTime.toDate(),
    "dd.MM.yyyy",
  )}`;
};

export const sortByVisibility = (a: GlobalEvent, b: GlobalEvent): number => {
  if (a.visibility.type === "CONSTANT" || b.visibility.type === "CONSTANT") {
    return 0;
  }
  const fromTimeA = a.visibility.fromTime.toDate().valueOf();
  const fromTimeB = b.visibility.fromTime.toDate().valueOf();

  return fromTimeB - fromTimeA;
};
