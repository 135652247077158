import { TabPanel, TabPanels, Tabs } from "@vygruppen/spor-react";
import { InternalMessageMockType } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewButtonAndModal";
import { InternalMessageEventsList } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/InternalMessageEventsList";

type InternalMessageEventsProps = {
  data: InternalMessageMockType[];
};

export const InternalMessageEvents = ({ data }: InternalMessageEventsProps) => (
  <Tabs
    variant="base"
    colorScheme="base"
    isFitted
    gap={3}
    size="sm"
    marginTop={3}
  >
    <TabPanels>
      <TabPanel>
        <InternalMessageEventsList data={data} onEditClick={() => {}} />
      </TabPanel>
    </TabPanels>
  </Tabs>
);
