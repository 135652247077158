import React from "react";
import { formatRelative, isPast, parseISO } from "date-fns";
import { nb } from "date-fns/locale";
import {
  NotificationReceivedOutline24Icon,
  StopwatchOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Text } from "shared/components/typography/TitleStyles";
import { UnderlineButton } from "shared/components/buttons/UnderlineButton";
import { Todo } from "./TodoElement";

interface ITodoBanner {
  todo: Todo;
  onClick: () => void;
}

const AlarmTime = ({ alarmTime }: { alarmTime: string | null }) => {
  if (!alarmTime) return null;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Text style={{ margin: "0 0.4rem" }}>
        {formatRelative(parseISO(alarmTime), new Date(), { locale: nb })}
      </Text>
      {isPast(parseISO(alarmTime)) ? (
        <NotificationReceivedOutline24Icon />
      ) : (
        <StopwatchOutline24Icon />
      )}
    </div>
  );
};

const TodoFooter = (props: ITodoBanner) => {
  const { todo, onClick } = props;

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {todo.status === "active" ? (
        <UnderlineButton slim onClick={onClick}>
          Rediger
        </UnderlineButton>
      ) : null}
      <AlarmTime alarmTime={todo.alarmTime} />
    </div>
  );
};

export default TodoFooter;
