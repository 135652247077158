import { UserRole } from "shared/types/roles";
import { dropsHttpClient } from "../../../../api/drops/dropsHttpClient";
import { TodoStatus } from "../TodoComponent";

export type UpdateTodo = {
  todoId: string;
  username: string | null;
  role: UserRole;
  title: string | null;
  createdAt: string | null;
  lastChangedAt?: string;
  priority: boolean | null;
  vehicleSetTypes: string | null;
  markedAsRead: boolean | null;
  alarmTime: string | null;
  status: TodoStatus | null;
  description: string | null;
  transferOnShiftEnd: string | null;
  trainNumber: string | null;
};
const updateExistingTodo = (todo: UpdateTodo) =>
  dropsHttpClient.put(`todo/${todo.todoId}`, { ...todo });

export { updateExistingTodo };
