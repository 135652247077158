import {
  ArrowLeftOutline24Icon,
  ArrowRightOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { IconButton } from "@vygruppen/spor-react";
import { format, intlFormat, isToday, isTomorrow, isYesterday } from "date-fns";
import { Text } from "shared/components/typography/TitleStyles";
import styled from "styled-components";
import { VehicleDate } from "./useVehicleDate";

const ChangeDayContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colorTextSecondary};
  color: ${({ theme }) => theme.colorTextMain};
  height: max-content;
  min-width: max-content;
  gap: 3px;
  padding: 1px;
`;

const getFormattedText = (date: Date): string => {
  const formattedDateString = format(date, "dd.MM");
  if (isYesterday(date)) return `i går, ${formattedDateString}`;
  if (isToday(date)) return `i dag, ${formattedDateString}`;
  if (isTomorrow(date)) return `i morgen, ${formattedDateString}`;

  return `${intlFormat(
    date,
    { weekday: "long" },
    { locale: "nb-NO" },
  )}, ${formattedDateString}`;
};

type Props = {
  showChangeDayOptions: boolean;
  vehicleDate: VehicleDate;
};

export const DayPicker = ({ showChangeDayOptions, vehicleDate }: Props) =>
  showChangeDayOptions ? (
    <ChangeDayContainer>
      <IconButton
        aria-label="Gå en dag bak"
        variant="ghost"
        icon={<ArrowLeftOutline24Icon />}
        onClick={vehicleDate.goToPreviousDay}
        isDisabled={!vehicleDate.canGoToPreviousDay}
      />
      <Text>{getFormattedText(vehicleDate.selectedDate)}</Text>
      <IconButton
        aria-label="Gå en dag frem"
        variant="ghost"
        icon={<ArrowRightOutline24Icon />}
        onClick={vehicleDate.goToNextDay}
        isDisabled={!vehicleDate.canGoToNextDay}
      />
    </ChangeDayContainer>
  ) : null;
