import { StationInformationType } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/formSchema";
import { StationCommunicationSystemsDown } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/subTypeInputs/StationCommunicationSystemsDown";
import { StationCustom } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/subTypeInputs/StationCustom";
import { StationPerson } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/subTypeInputs/StationPerson";
import { StationSalesChannelReduced } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/subTypeInputs/StationSalesChannelReduced";
import { StationSingleStop } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/subTypeInputs/StationSingleStop";
import { StationThreatLevel } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/subTypeInputs/StationThreatLevel";
import { FC } from "react";

type FormProps = {
  type: StationInformationType;
};

export const SubTypeInputs: FC<FormProps> = ({ type }: FormProps) => {
  switch (type) {
    case "STATION_THREAT_LEVEL":
      // StationThreatLevelRequest
      return <StationThreatLevel />;

    case "STATION_SALES_CHANNEL_REDUCED":
      // StationSalesChannelReducedRequest
      return <StationSalesChannelReduced />;

    case "STATION_DIFFICULT_CUSTOMER":
    case "STATION_WANTED_PERSON":
      // StationPersonRequest
      return <StationPerson />;

    case "STATION_ARRIVAL_AND_DEPARTURE_MONITOR_FAULT":
    case "STATION_ELEVATOR_FAULT":
    case "STATION_SPEAKER_FAULT":
    case "STATION_TRACK_MONITOR_FAULT":
    case "STATION_WHEELCHAIR_RAMP_FAULT":
      // StationSingleStopRequest
      return <StationSingleStop />;

    case "STATION_COMMUNICATION_SYSTEMS_DOWN":
      // StationCommunicationSystemsDownRequest
      return <StationCommunicationSystemsDown />;

    case "STATION_GENERAL":
      // StationCustomRequest
      return <StationCustom />;

    default:
      // StationGeneralRequest has no extra fields
      return null;
  }
};
