import { useState } from "react";
import constate from "constate";

function useTransferProvider() {
  const [isTransfered, setTransfered] = useState(false);

  return {
    isTransfered,
    setTransfered,
  };
}

const [TransferProvider, useIsTransfered, useSetTransfered] = constate(
  useTransferProvider,
  (value) => value.isTransfered,
  (value) => value.setTransfered,
);
export { TransferProvider, useIsTransfered, useSetTransfered };
