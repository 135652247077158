import {
  ErrorFill24Icon,
  InformationFill24Icon,
  SuccessFill24Icon,
  WarningFill24Icon,
} from "@vygruppen/spor-icon-react";
import { DefaultTheme, useTheme } from "styled-components";

export type Severity = "info" | "success" | "error" | "warning";

type SeverityIconProps = {
  severity: Severity;
  fill?: string;
};

export const SeverityIcon = ({ severity, fill }: SeverityIconProps) => {
  const theme = useTheme();

  switch (severity) {
    case "info":
      return (
        <InformationFill24Icon
          color={fill ?? theme.colorInfo}
          title="Informasjon"
        />
      );
    case "warning":
      return (
        <WarningFill24Icon color={fill ?? theme.colorWarning} title="Varsel" />
      );
    case "success":
      return (
        <SuccessFill24Icon color={fill ?? theme.colorSuccess} title="Suksess" />
      );
    case "error":
      return (
        <ErrorFill24Icon color={fill ?? theme.colorAlarm} title="Feilmelding" />
      );
    default:
      return null;
  }
};

export const severityBorderColor = (
  severity: Severity,
  theme: DefaultTheme,
) => {
  switch (severity) {
    case "info":
      return theme.colorInfoOutline;
    case "warning":
      return theme.colorWarningOutline;
    case "success":
      return theme.colorSuccessOutline;
    case "error":
      return theme.colorAlarmOutline;
    default:
      return theme.colorNeutralOutline;
  }
};

export const severityBackgroundColor = (
  severity: Severity,
  theme: DefaultTheme,
) => {
  switch (severity) {
    case "info":
      return theme.colorInfoFill;
    case "warning":
      return theme.colorWarningFill;
    case "success":
      return theme.colorSuccessFill;
    case "error":
      return theme.colorAlarmFill;
    default:
      return theme.colorNeutralFill;
  }
};

export const severityTitleColor = (severity: Severity, theme: DefaultTheme) => {
  switch (severity) {
    case "info":
      return theme.colorInfoTextMain;
    case "warning":
      return theme.colorWarningTextMain;
    case "success":
      return theme.colorSuccessTextMain;
    case "error":
      return theme.colorAlarmTextMain;
    default:
      return theme.colorNeutralTextMain;
  }
};

export const severitySubtitleColor = (
  severity: Severity,
  theme: DefaultTheme,
) => {
  switch (severity) {
    case "info":
      return theme.colorInfoTextSecondary;
    case "warning":
      return theme.colorWarningTextSecondary;
    case "success":
      return theme.colorSuccessTextSecondary;
    case "error":
      return theme.colorAlarmTextSecondary;
    default:
      return theme.colorNeutralTextSecondary;
  }
};
