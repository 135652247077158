import { useState } from "react";
import axios, { AxiosResponse } from "axios";
import { log, LogLevel } from "../cloudWatch";

type AxioAction = (...body: any) => Promise<AxiosResponse>;

export type ReturnState = {
  loading: boolean;
  error: boolean;
  data: any;
};

export type ReturnFunc = (body?: any) => void;

export type ResetFunc = () => void;

export type useApiActionReturnType = [ReturnState, ReturnFunc, ResetFunc];

export function useApiAction(
  action: AxioAction,
  source?: any,
  initStateLoading: boolean = true,
): useApiActionReturnType {
  const [state, setState] = useState({
    data: null,
    loading: initStateLoading,
    error: false,
  });

  const performAction = async (body = null) => {
    try {
      setState((prevState: any) => ({
        ...prevState,
        loading: true,
        error: false,
      }));
      const response = await action(body, source);
      setState((prevState) => ({
        ...prevState,
        data: response.data,
        loading: false,
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          log(
            LogLevel.error,
            `useApiAction`,
            `Axios response error - status: ${error.response.status}, url: ${error.response.config.url}, data: ${error.response.data}, headers: ${error.response.headers}`,
          );
        } else if (error.request) {
          log(
            LogLevel.error,
            `useApiAction`,
            `Axios request error - ${error.request}`,
          );
        } else {
          log(LogLevel.error, `useApiAction`, `Axios error - ${error}`);
        }
      } else {
        log(
          LogLevel.error,
          `useApiAction`,
          `Action failed with an error: ${error}`,
        );
      }

      if (!axios.isCancel(error)) {
        setState((prevState) => ({ ...prevState, error: true }));
      }
    }
  };

  const resetState = () => {
    setState({
      data: null,
      loading: initStateLoading,
      error: false,
    });
  };

  return [state, performAction, resetState];
}
