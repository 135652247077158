import { Divider, Flex, Textarea } from "@vygruppen/spor-react";
import { DropsExpandable } from "features/CenterContent/VehicleDetails/shared/DropsExpandable";
import { MessageListElement } from "shared/components/InternalMessage/components/MessageListElement";
import { useQueryMock } from "shared/hooks/useQueryMock";

const mockData = [
  {
    text: "Første interne melding som ble sendt. Fint som referanse til neste melding, fordi det ofte baserer seg på tidligerer kommuniserte ting.",
    author: "rhaugen",
    reportedDateTime: new Date(),
  },
  {
    text: "Forrige interne melding som ble sendt. For å se tråden av meldinger som er sendt på hendelsen.",
    author: "rhaugen",
    reportedDateTime: new Date(),
  },
  {
    text: "Siste interne melding som ble sendt. For å se tråden av meldinger som er sendt på hendelsen.",
    author: "rhaugen",
    reportedDateTime: new Date(),
  },
];

export const MessageList = ({ collapsed }: { collapsed: boolean }) => {
  const { data } = useQueryMock(mockData);
  return (
    <DropsExpandable
      collapsed={collapsed}
      mt={collapsed ? "0px" : "12px !important"}
    >
      <Divider />
      <Flex
        flexDir="column"
        maxHeight="205px"
        overflowY="auto"
        position="relative"
      >
        {data?.map((it, index) => (
          <MessageListElement
            key={`MessageListElement_${index}`}
            {...it}
            isLastEntry={index === data.length - 1}
          />
        ))}
        <Textarea label="Skriv ny melding..." resize="none" m="6px" my="18px" />
      </Flex>
    </DropsExpandable>
  );
};
