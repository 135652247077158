import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { processTransportList } from "../utils";

export enum RenderMessage {
  Alternative,
  Reserve,
}

const RenderNoDataMessage = ({
  processedList,
  renderMessage,
}: {
  processedList: ReturnType<typeof processTransportList>;
  renderMessage: RenderMessage;
}) => {
  if (processedList.length === 0) {
    return (
      <NoDataMessage reason="Det er ikke satt opp flere turer resten av valgte driftsdøgn. Trykk på 'Vis gamle turer'-knappen for å se gamle turer." />
    );
  }

  switch (renderMessage) {
    case RenderMessage.Alternative:
      return (
        <NoDataMessage reason="Det er ikke satt opp alternativ transport for valgte driftsdøgn." />
      );
    case RenderMessage.Reserve:
      return (
        <NoDataMessage reason="Det er ikke satt opp reservekjøretøy for valgte driftsdøgn." />
      );
    default:
      return (
        <NoDataMessage reason="Det er ikke satt opp flere turer for valgte driftsdøgn." />
      );
  }
};

export default RenderNoDataMessage;
