import { useMutation } from "@tanstack/react-query";
import { Button, Textarea } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import React, { useState } from "react";
import { DismissibleInfoMessage } from "shared/components/feedback/InfoMessage/DismissibleInfoMessage";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import {
  AlternativeTransportLog,
  AlternativeTransportLogReq,
} from "shared/types/alternativeTransport";
import { compareDateStringAsc } from "shared/utils/datetime";
import styled from "styled-components";
import { LogEntry } from "./LogEntry";

const OperationalLogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding: 0.75rem;
`;

const LogList = styled.ul`
  ${ObtrusiveScrollCss};
  overflow-y: scroll;
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  row-gap: 12px;
`;

type OperationalLogProps = {
  vehicleId: string;
  logs: AlternativeTransportLog[];
  distributionStopPlaces?: string;
};

const OperationalLog = ({
  vehicleId,
  logs,
  distributionStopPlaces,
}: OperationalLogProps) => {
  const [logMessage, setLogMessage] = useState("");

  const operationalLogMutation = useMutation({
    mutationKey: ["postAlternativeTransportLogs", vehicleId],
    mutationFn: (data: AlternativeTransportLogReq) =>
      mutationFnPOST<AlternativeTransportLog, AlternativeTransportLogReq>(
        `${getBackendUrl()}/alternativeTransportLogs/${vehicleId}`,
        data,
      ),
  });

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    operationalLogMutation.mutate({
      message: logMessage,
      distributionStopPlaces: distributionStopPlaces ?? "",
      alternativeTransportId: vehicleId,
    });

    setLogMessage("");
  }

  const getDisplayMessage = (message: string | null, tags: string[]) =>
    [message, ...tags].filter(Boolean).join(tags.length > 0 ? ". " : " ");

  return (
    <OperationalLogWrapper>
      <form
        onSubmit={onSubmit}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          marginBottom: "24px",
        }}
      >
        <Textarea
          key={vehicleId}
          label="Ny loggmelding"
          required
          value={logMessage}
          onChange={(e) => setLogMessage(e.currentTarget.value)}
          aria-invalid={logMessage.length === 0}
        />
        <DismissibleInfoMessage
          severity="error"
          summary="Kunne ikke sende loggmelding. Prøv på nytt eller kontakt IT."
          isDismissed={operationalLogMutation.status !== "error"}
          onDismiss={operationalLogMutation.reset}
        />
        <Button
          size="sm"
          variant="secondary"
          type="submit"
          isLoading={operationalLogMutation.status === "pending"}
          isDisabled={logMessage.length === 0}
          ml="auto"
        >
          Send logg
        </Button>
      </form>
      <LogList>
        {logs
          .sort((a, b) => compareDateStringAsc(a.timestamp, b.timestamp))
          .map(
            ({ id, timestamp, message, sourceSystem, locationName, tags }) => (
              <LogEntry
                key={id}
                timestamp={timestamp}
                message={getDisplayMessage(message, tags)}
                source={locationName}
                fallbackSource={sourceSystem.name}
              />
            ),
          )}
      </LogList>
    </OperationalLogWrapper>
  );
};

export default OperationalLog;
