import { Flex, Text, TextProps } from "@vygruppen/spor-react";
import { ReactElement } from "react";
import styled from "styled-components";

const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 10.5rem auto;
  padding: 0 0.5rem;
`;

const Column = styled(Flex)`
  flex-direction: column;
  &&& {
    align-items: start;
  }
`;

type ColumnOrRow =
  | { column: boolean; row?: boolean }
  | { column?: boolean; row: boolean };

type TitleSubtitleChildrenProps = {
  title?: string;
  subtitle?: string;
  smallSubtitle?: boolean;
  children?: ReactElement | ReactElement[];
} & TextProps;

const Content = ({
  title,
  subtitle,
  smallSubtitle,
  children,
  ...rest
}: TitleSubtitleChildrenProps) => (
  <>
    {title && (
      <Text
        fontSize="xs"
        fontWeight="bold"
        style={{ whiteSpace: "nowrap" }}
        {...rest}
      >
        {title}
      </Text>
    )}
    {subtitle && (
      <Text
        fontSize={smallSubtitle ? "xs" : undefined}
        style={{ whiteSpace: "nowrap" }}
        {...rest}
      >
        {subtitle}
      </Text>
    )}
    {children}
  </>
);

export const TitleSubtitleChildren = ({
  column: _,
  row,
  ...rest
}: ColumnOrRow & TitleSubtitleChildrenProps) =>
  row ? (
    <Row>
      <Content {...rest} />
    </Row>
  ) : (
    <Column>
      <Content {...rest} smallSubtitle />
    </Column>
  );
