import { z } from "zod";

export const incidentNoteSchema = z.object({
  identifier: z.string(),
  isirk: z.string(),
  content: z.string(),
  summary: z.string(),
  fromDate: z.string(),
  toDate: z.string(),
  type: z.string(),
});

export const incidentNoteResponseSchema = z.object({
  incidentNotes: z.array(incidentNoteSchema),
});

export type IncidentNote = z.infer<typeof incidentNoteSchema>;
export type IndicentNoteResponse = z.infer<typeof incidentNoteResponseSchema>;

export const incidentLogMessageSchema = z.object({
  id: z.string(),
  message: z.string().nullable(),
  timestamp: z.string(),
  sourceSystem: z.object({
    author: z.string().nullable(),
    name: z.string(),
  }),
  incidentId: z.string().nullable(),
  isirkId: z.string(),
});

export const incidentLogResponseSchema = z.object({
  incidentLogs: z.array(incidentLogMessageSchema),
});

export type IncidentLogMessage = z.infer<typeof incidentLogMessageSchema>;
export type IncidentLogResponse = z.infer<typeof incidentLogResponseSchema>;
