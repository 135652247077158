import {
  BaseGpsData,
  Gps,
  TrainGpsData,
} from "features/CenterContent/shared/types";
import L, {
  Direction,
  LatLngExpression,
  PointTuple,
  latLngBounds,
} from "leaflet";
import { css } from "styled-components";
import { NORWAY_BOUNDS, YESTERDAY, vehicleTypes } from "./constants";

export const getBounds = (coordinates: LatLngExpression[]) =>
  coordinates.length > 0
    ? latLngBounds(coordinates)
    : latLngBounds(NORWAY_BOUNDS);

type ClusterMarker = {
  getChildCount: () => number;
};

export const createClusterCustomIcon = (cluster: ClusterMarker) => {
  const count = cluster.getChildCount();
  const invisibleIcon = L.point(0, 0, true);

  const style = css`
    background-color: #00453e;
    color: #f5f5f5;
    border: 2px solid #f5f5f5;
    border-radius: 9999px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    display: flex;
    font-weight: 700;
    font-size: 0.75rem;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
  `;

  return L.divIcon({
    html: `<div style="${style}">${count}</div>`,
    iconSize: invisibleIcon,
  });
};

export const isAbove = (first: BaseGpsData, second: BaseGpsData) =>
  first.gps.latitude > second.gps.latitude;

export const getDirection = (
  first: BaseGpsData,
  second: BaseGpsData | null,
): Direction => {
  if (!second) return "top";
  return isAbove(first, second) ? "top" : "bottom";
};

export const getOffset = (
  first: BaseGpsData,
  second: BaseGpsData | null,
): PointTuple => {
  if (!second) return [0, -10];
  return isAbove(first, second) ? [0, -10] : [0, 10];
};

export const getFilteredVehicles = (
  vehicleData: { data: TrainGpsData[] } | undefined,
  query: string,
  filter: (string | number)[],
  showOldVehicleTypes: boolean,
): TrainGpsData[] | undefined =>
  vehicleData?.data.filter((vehicle) => {
    const isOldData =
      new Date(vehicle.timestamp).getTime() < YESTERDAY.getTime();
    const isCarriage = !vehicleTypes.some((type) =>
      vehicle.resourceId.startsWith(type),
    );
    const matchesFilter = filter.some((id) =>
      vehicle.resourceId.startsWith(id.toString()),
    );
    const matchesQuery = vehicle.resourceId.startsWith(query);
    const filterIncludesCarriage = filter.some((f) => f === "Vogn");

    if (isOldData && !showOldVehicleTypes) return false;
    if (!query && filter.length <= 0) return true;
    if (query && matchesQuery) return true;
    if (filter.length >= 0 && matchesFilter) return true;
    if (filterIncludesCarriage) return isCarriage;
    return false;
  });

export const makeBound = (gps: Gps) => {
  const zoomedWidth = 0.003;
  const zoomedHeight = 0.012;
  return latLngBounds([
    [gps.latitude - zoomedWidth / 2, gps.longitude - zoomedHeight / 2],
    [gps.latitude + zoomedWidth / 2, gps.longitude + zoomedHeight / 2],
  ]);
};
