import { ReserveAlternativeTransport } from "shared/types/alternativeTransport";
import { matchPhoneNumber } from "../utils";

type Filter = (
  reserveAlternativeTransport: ReserveAlternativeTransport,
  searchQuery: string,
) => boolean;

const matchesWorkShiftId = (workShiftId: string | null, query: string) =>
  workShiftId?.includes(query) ?? false;

const matchesDriverPhoneNumber = (
  driverPhoneNumber: string | null,
  query: string,
) => (driverPhoneNumber ? matchPhoneNumber(query, driverPhoneNumber) : false);

export const filterReserveAlternativeTransport: Filter = (
  reserveAlternativeTransport,
  searchQuery,
) => {
  const { workShiftId, driverPhoneNumber } = reserveAlternativeTransport;
  const lowerCaseQuery = searchQuery.toLowerCase();

  if (lowerCaseQuery.length >= 2) {
    return (
      matchesWorkShiftId(workShiftId, lowerCaseQuery) ||
      matchesDriverPhoneNumber(driverPhoneNumber, lowerCaseQuery)
    );
  }

  return true;
};
