import { ChoiceChip, HStack } from "@vygruppen/spor-react";
import { FC, useEffect, useMemo } from "react";
import { useTheme } from "styled-components";
import { groupTrainsByIncidentId } from "features/CenterContent/RoleContent/AffectedTrains/utils/groupTrainSeries";
import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";

export type AffectedTrainFilter = {
  incidentId: string;
  trainSeriesIds: string[];
} | null;

export type TrainFilterProps = {
  trains: AffectedTrain[];
  filter: AffectedTrainFilter;
  setFilter: (filter: AffectedTrainFilter) => void;
};

export const TrainFilter: FC<TrainFilterProps> = ({
  trains,
  filter,
  setFilter,
}) => {
  const theme = useTheme();

  const groupedTrains = useMemo(
    () => groupTrainsByIncidentId(trains),
    [trains],
  );

  // when train groups change, check if the current filter is still valid
  useEffect(() => {
    if (filter) {
      const groupsContainIncidentFilter = Object.keys(groupedTrains).includes(
        filter.incidentId,
      );
      if (groupsContainIncidentFilter) {
        const groupsInFilteredIndident =
          groupedTrains[filter.incidentId].trainSeries;
        const validSeriesInFilter = filter.trainSeriesIds.filter((seriesId) =>
          Object.keys(groupsInFilteredIndident).includes(seriesId),
        );
        setFilter({ ...filter, trainSeriesIds: validSeriesInFilter });
      } else {
        setFilter(null);
      }
    }
  }, [groupedTrains]);

  return groupedTrains && Object.keys(groupedTrains).length > 0 ? (
    <HStack
      pb={2}
      pt={2}
      wrap="wrap"
      borderBottom={`1px solid ${theme.colorSeparationLine}`}
    >
      {Object.entries(groupedTrains).map(([incident, group], i, groups) => {
        const allSeriesInIncident = Object.keys(group.trainSeries);
        const filterIncludesAllSeriesInIncident =
          filter?.trainSeriesIds.length === allSeriesInIncident.length &&
          filter.trainSeriesIds.every((seriesId) =>
            allSeriesInIncident.includes(seriesId),
          );
        const incidentIsChecked =
          incident === filter?.incidentId && filterIncludesAllSeriesInIncident;

        return (
          <HStack
            id="incident-group"
            w="100%"
            key={incident}
            gap={1}
            wrap="wrap"
            py={2}
            mx={2}
            borderBottom={
              i !== groups.length - 1
                ? `1px solid ${theme.colorSeparationLine}`
                : undefined
            }
          >
            <ChoiceChip
              size="xs"
              key={incident}
              isChecked={incidentIsChecked}
              onChange={() => {
                if (incidentIsChecked) {
                  setFilter(null);
                } else {
                  setFilter({
                    incidentId: incident,
                    trainSeriesIds: allSeriesInIncident,
                  });
                }
              }}
            >
              {group.stretchName}
            </ChoiceChip>
            {Object.entries(group.trainSeries).map(([seriesId]) => {
              const seriesIsChecked =
                filter?.incidentId === incident &&
                filter?.trainSeriesIds.includes(seriesId);

              return (
                <ChoiceChip
                  size="xs"
                  key={`${incident}_${seriesId}`}
                  isChecked={seriesIsChecked}
                  onChange={() => {
                    if (filter?.incidentId === incident) {
                      if (seriesIsChecked) {
                        const isOnlyCheckedSeries =
                          filter.trainSeriesIds.length === 1;
                        if (isOnlyCheckedSeries) {
                          setFilter(null);
                        } else {
                          setFilter({
                            incidentId: incident,
                            trainSeriesIds: filter.trainSeriesIds.filter(
                              (seriesIdInFilter) =>
                                seriesIdInFilter !== seriesId,
                            ),
                          });
                        }
                      } else {
                        setFilter({
                          incidentId: incident,
                          trainSeriesIds: [...filter.trainSeriesIds, seriesId],
                        });
                      }
                    } else {
                      setFilter({
                        incidentId: incident,
                        trainSeriesIds: [seriesId],
                      });
                    }
                  }}
                >
                  {seriesId}
                </ChoiceChip>
              );
            })}
          </HStack>
        );
      })}
    </HStack>
  ) : null;
};
