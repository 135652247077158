import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";

// Group Bergensbanen 61 ... 606 together with 6X
// Three-digit codes other than 600 are shortened
export const getTrainSeriesFromIdentifier = (identifier: string) => {
  if (identifier.length === 2 || identifier.startsWith("60")) {
    return `${identifier[0]}x`;
  }
  if (identifier.length === 4) {
    return `${identifier[0]}${identifier[1]}xx`;
  }

  return `${identifier.substring(0, 1)}${identifier.substring(1).replaceAll(/[0-9]/g, "x")}`;
};

export type TrainsGroupedBySeries = {
  [trainSeriesId: string]: AffectedTrain[];
};

export type TrainSeriesGrouping = {
  trainSeries: TrainsGroupedBySeries;
  stretchName: string;
  eventUuid: string;
  incidentId: string;
};

export type TrainSeriesGroupsByIncident = {
  [incidentId: string]: TrainSeriesGrouping;
};

export const groupTrainsByIncidentId = (affectedTrains: AffectedTrain[]) =>
  affectedTrains.reduce((acc: TrainSeriesGroupsByIncident, affectedTrain) => {
    const { trainId, incidentId, eventStretchName, eventUuid } = affectedTrain;
    const { identifier } = trainId;

    const trainSeriesId = getTrainSeriesFromIdentifier(identifier);

    if (acc[incidentId]) {
      // Incident has already been encountered
      if (acc[incidentId].trainSeries[trainSeriesId]) {
        // The train series has also been encountered, add the train to it
        acc[incidentId].trainSeries[trainSeriesId].push(affectedTrain);
      } else {
        // The train series has not been encountered, add it to the incident
        acc[incidentId].trainSeries[trainSeriesId] = [affectedTrain];
      }
    } else {
      // Incident has not been encountered, create it
      acc[incidentId] = {
        trainSeries: {
          [trainSeriesId]: [affectedTrain],
        },
        stretchName: eventStretchName,
        eventUuid,
        incidentId,
      };
    }

    return acc;
  }, {});

export const groupTrainsByTrainSeries = (trains: AffectedTrain[]) =>
  trains.reduce((accumulated: TrainsGroupedBySeries, train) => {
    const trainSeries = getTrainSeriesFromIdentifier(train.trainId.identifier);

    if (accumulated[trainSeries]) {
      accumulated[trainSeries].push(train);
    } else {
      accumulated[trainSeries] = [train];
    }

    return accumulated;
  }, {});

export function buildTrainSeriesGroupForSingleIncident(
  trains: AffectedTrain[],
): TrainSeriesGrouping | null {
  if (trains.length === 0) return null;

  const { eventStretchName: stretchName, eventUuid, incidentId } = trains[0];

  return {
    trainSeries: groupTrainsByTrainSeries(
      trains.filter((train) => train.incidentId === incidentId),
    ),
    stretchName,
    eventUuid,
    incidentId,
  };
}

type TrainStretchesGroupedBySeries = {
  [trainIdSeries: string]: string[];
};

// Check if a train in the same series has different start and stop stations
// TODO: Expand to check for discrepancies en route. 2xx series is a prime example
export const findSeriesDiscrepancies = (trains: AffectedTrain[]) => {
  const trainGroupByTrainSeries = trains.reduce(
    (groups: TrainStretchesGroupedBySeries, train) => {
      const series = getTrainSeriesFromIdentifier(train.trainId.identifier);

      // Treat oncoming and ongoing trains as the same
      const trainString = [train.origin ?? "YYY", train.destination ?? "ZZZ"]
        .toSorted()
        .join("___");

      if (groups[series]) {
        return {
          ...groups,
          [series]: Array.from(new Set([...groups[series], trainString])),
        };
      }
      return {
        ...groups,
        [series]: [trainString],
      };
    },
    {},
  );

  const discrepantSeries = { ...trainGroupByTrainSeries };

  Object.entries(trainGroupByTrainSeries).forEach(([series, group]) => {
    if (group.length <= 1) {
      delete discrepantSeries[series];
    }
  });

  return discrepantSeries;
};
