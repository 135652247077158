import { TrainRoute } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { Text } from "shared/components/typography/TitleStyles";
import { formatDateString, formatTimeString } from "shared/utils/datetime";
import { Tooltip } from "@chakra-ui/react";
import {
  SeatNotAvailableOutline24Icon,
  TrainFill30Icon,
} from "@vygruppen/spor-icon-react";
import { SummaryWrapper, TrainDate, TrainNumbers } from "./styles";

type TrainSummaryProps = {
  train: TrainRoute;
  fillrateFailure: boolean;
};

export const TrainSummary = ({ train, fillrateFailure }: TrainSummaryProps) => (
  <SummaryWrapper>
    <TrainFill30Icon />
    {fillrateFailure && (
      <Tooltip
        hasArrow
        placement="right"
        label="Kunne ikke hente oppdatert passasjertellinger for toget"
      >
        <span style={{ cursor: "pointer", width: "max-content" }}>
          <SeatNotAvailableOutline24Icon />
        </span>
      </Tooltip>
    )}
    <TrainNumbers className="train-numbers">
      <Text bold>{train.trainId}</Text>
      <Text secondary>{train.lineId}</Text>
    </TrainNumbers>
    <Text className="route-details">
      {train.origin} - {train.destination}
    </Text>
    <Text
      className="train-times"
      style={{
        display: "initial",
        textDecoration:
          train.cancelled === "CANCELLED" ? "line-through" : "none",
      }}
    >
      {formatTimeString(train.originTime)} -{" "}
      {formatTimeString(train.destinationTime)}
      <TrainDate>({formatDateString(train.nominalDate, "dd.MM.yy")})</TrainDate>
    </Text>
  </SummaryWrapper>
);
