import React, { useEffect, useState } from "react";
import { useApiAction } from "../../../../../../../../api/drops/useApiAction";
import { usePuzzelExtendedStateInformation } from "../../../../../provider/contexts/ListenForCallStatusContext";
import { usePuzzelAuthenticationContext } from "../../../../../provider/contexts/PuzzelAuthenticationContext";
import { useUserValidationState } from "../../../../../provider/contexts/UserValidationContext";
import { userSearchTelephoneApi } from "../../../../../../../../api/puzzel/puzzel";
import { validPhoneNumber } from "../../../../../utils";
import { PuzzelUser, SessionCommands } from "../../../../types";
import { getIncludedPuzzelGroupNames } from "../../../../../../../../api/common";
import { environment } from "../../../../../../../../environment";
import SessionCommand from "../../../../SessionCommand";
import CatalogSearchInput from "../../../CatalogSearchInput";
import { DropdownUserList } from "./DropdownUserList";
import { useOnOutsideMouseclick } from "../../../../../../../../shared/hooks/useOnOutsideMouseclick";

interface Props {
  requestId: string;
  closeSearch: () => void;
}

const TransferWithSearch = ({ requestId, closeSearch }: Props) => {
  const { iqSessionId }: any = usePuzzelExtendedStateInformation();
  const { phoneNumberPrefix } = usePuzzelAuthenticationContext();
  const { userId } = useUserValidationState();
  const [textInput, setInputText] = useState("");
  const [hasResults, setHasResults] = useState(false);
  const [userSearchState, performUserSearch] = useApiAction(
    userSearchTelephoneApi,
    false,
  );
  const operationalUserGroups = getIncludedPuzzelGroupNames(environment());
  const userSearch = userSearchState.data?.result?.filter((user: PuzzelUser) =>
    operationalUserGroups.includes(user.userGroupName),
  );
  const { mouseclickRef } = useOnOutsideMouseclick(() => {
    if (!validPhoneNumber(textInput)) {
      closeSearch();
    }
  });

  const search = () => {
    performUserSearch({
      payload: {
        searchString: textInput,
        userSearchFilter: "UserLoggedOn",
      },
      param: {
        userId,
        iqSessionId,
      },
    });
  };

  const keyDownHandler = (event: any) => {
    if (event.key === "Escape") {
      closeSearch();
    } else if (event.key === "Enter") {
      search();
    }
  };

  useEffect(() => search(), [textInput]);

  useEffect(() => setHasResults(userSearch?.length > 0), [userSearch]);

  return (
    <div style={{ position: "relative" }} ref={mouseclickRef}>
      <div style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
        <CatalogSearchInput
          hasDropdown={hasResults}
          onKeyDown={keyDownHandler}
          onChange={(e) => {
            setInputText(e.target.value);
          }}
          value={textInput}
          placeholder="Nummer eller søketekst"
        />
        {validPhoneNumber(textInput) && (
          <>
            <SessionCommand
              command={SessionCommands.TransferToPhone}
              requestId={requestId}
              destinationPhoneNumber={phoneNumberPrefix + textInput}
              disabled={!validPhoneNumber(textInput)}
            />
            <SessionCommand
              command={SessionCommands.ConsultWithPhone}
              requestId={requestId}
              destinationPhoneNumber={phoneNumberPrefix + textInput}
              disabled={!validPhoneNumber(textInput)}
            />
          </>
        )}
      </div>

      {hasResults && (
        <DropdownUserList users={userSearch} requestId={requestId} />
      )}
    </div>
  );
};

export default TransferWithSearch;
