enum Environment {
  Development = "DEV",
  Test = "TEST",
  Stage = "STAGE",
  Production = "PROD",
  Unknown = "UNKNOWN",
}

const environment = (): Environment => {
  if (
    import.meta.env.DEV ||
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("127.0.0.1")
  ) {
    return Environment.Development;
  }
  if (window.location.origin.endsWith(".test.trafficgui.vydev.io")) {
    return Environment.Test;
  }
  if (window.location.origin.endsWith(".stage.trafficgui.vydev.io")) {
    return Environment.Stage;
  }
  if (window.location.origin.endsWith("drops.vy.no")) {
    return Environment.Production;
  }
  return Environment.Unknown;
};

export { Environment, environment };
