import { ChakraProps } from "@chakra-ui/react";
import { Grid } from "@vygruppen/spor-react";
import { ReactNode } from "react";

// Spor's expandable forces you to have title, button, borders.
// Drops' expandable will only expand/collapse the children.

export const DropsExpandable = ({
  collapsed,
  children,
  ...rest
}: {
  collapsed: boolean;
  children: ReactNode;
} & ChakraProps) => (
  <Grid
    gridTemplateRows={collapsed ? "0fr" : "1fr"}
    transition="all 0.3s ease"
    {...rest}
  >
    <div style={{ overflow: "hidden" }}>{children}</div>
  </Grid>
);
