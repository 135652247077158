import { LatLngBounds, latLngBounds } from "leaflet";
import { NORWAY_BOUNDS } from "./constants";

export type Location = {
  name: string;
  bounds: LatLngBounds;
};

export const locations: Location[] = [
  {
    name: "Norge",
    bounds: latLngBounds(NORWAY_BOUNDS),
  },
  {
    name: "Oslo S",
    bounds: latLngBounds([
      [59.90817347258006, 10.75290620326996],
      [59.91139013952822, 10.764439702033998],
    ]),
  },
  {
    name: "Lodalen",
    bounds: latLngBounds([
      [59.90725861528831, 10.786107237683332],
      [59.90236833467884, 10.768576319561996],
    ]),
  },
  {
    name: "Sundland",
    bounds: latLngBounds([
      [59.7465585390083, 10.19587069357764],
      [59.736729958505386, 10.160808857334967],
    ]),
  },
  {
    name: "Filipstad",
    bounds: latLngBounds([
      [59.91325699029739, 10.722816024512143],
      [59.90836759314588, 10.705285106390805],
    ]),
  },
  {
    name: "Ski",
    bounds: latLngBounds(
      [59.72096449116654, 10.841231369602799],
      [59.71604679935689, 10.825535082447646],
    ),
  },
  {
    name: "Skien",
    bounds: latLngBounds(
      [59.22315206768847, 9.609228372573854],
      [59.218161383998506, 9.593532085418703],
    ),
  },
  {
    name: "Lillestrøm",
    bounds: latLngBounds(
      [59.97399924090872, 11.088123438895325],
      [59.93493551519257, 10.962553141654112],
    ),
  },
  {
    name: "Moss",
    bounds: latLngBounds(
      [59.43438876821427, 10.665031470541459],
      [59.429429011456996, 10.649335183386308],
    ),
  },
  {
    name: "Eidsvoll",
    bounds: latLngBounds(
      [60.341178953683816, 11.283216476440431],
      [60.321869734714085, 11.220431327819826],
    ),
  },
  {
    name: "Hamar",
    bounds: latLngBounds(
      [60.80532934924593, 11.106060955451223],
      [60.78629543243553, 11.043275806830618],
    ),
  },
  {
    name: "Lillehammer",
    bounds: latLngBounds(
      [61.11978070357848, 10.476558208465578],
      [61.11035876077917, 10.445165634155275],
    ),
  },
  {
    name: "Halden",
    bounds: latLngBounds(
      [59.12709813003366, 11.399903097908755],
      [59.11708794999998, 11.368510523598449],
    ),
  },
  {
    name: "Kongsvinger",
    bounds: latLngBounds(
      [60.19369173214014, 12.027947280660287],
      [60.18399494759379, 11.996554706349984],
    ),
  },
  {
    name: "Kongsberg",
    bounds: latLngBounds(
      [59.676219001343306, 9.655040608174128],
      [59.67129473344444, 9.639344321018976],
    ),
  },
  {
    name: "Bergen",
    bounds: latLngBounds(
      [60.39154363714503, 5.344355106353761],
      [60.386724855142894, 5.328658819198608],
    ),
  },
  {
    name: "Voss",
    bounds: latLngBounds(
      [60.6441166910679, 6.453354738364872],
      [60.624987011344324, 6.390569589744267],
    ),
  },
  {
    name: "Gjøvik",
    bounds: latLngBounds(
      [60.7981744868791, 10.703022524021785],
      [60.793416004977345, 10.687326236866634],
    ),
  },
  {
    name: "Jaren",
    bounds: latLngBounds(
      [60.403023141588754, 10.586442947387697],
      [60.383750531503715, 10.52365779876709],
    ),
  },
  {
    name: "Mysen",
    bounds: latLngBounds(
      [59.56014688372585, 11.338706524131618],
      [59.55026353336091, 11.307313949821316],
    ),
  },
  {
    name: "Flåm",
    bounds: latLngBounds(
      [60.86471347480457, 7.127230149784479],
      [60.855215585183075, 7.095837575474176],
    ),
  },
];
