import { css } from "styled-components";
import {
  Severity,
  severityBackgroundColor,
  severityBorderColor,
  severityTitleColor,
  severitySubtitleColor,
} from "./severity";

/* Spacing between Severity Icon og summary/title */
export const COLUMN_GAP = 6;

/* Border radius of the info message components */
export const BORDER_RADIUS = 6;

export const InfoMessageBaseStyling = (
  severity: Severity,
  borderColorOverride?: string,
  backgroundColorOverride?: string,
  titleColorOverride?: string,
) => css`
  padding: 9px;
  border-radius: ${BORDER_RADIUS}px;
  border: 1px solid
    ${({ theme }) =>
      borderColorOverride ?? severityBorderColor(severity, theme)};
  background-color: ${({ theme }) =>
    backgroundColorOverride ?? severityBackgroundColor(severity, theme)};
  color: ${({ theme }) =>
    titleColorOverride ?? severityTitleColor(severity, theme)};
`;
