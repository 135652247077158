import { fadein } from "shared/components/transitions/fade";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import styled from "styled-components";
import { ChoiceChip, Flex } from "@vygruppen/spor-react";

export const TodoHeader = styled.span`
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.colorTextMain};
  font-family: "Vy Sans";
`;

export const TodoMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: messages;
  overflow-y: auto;
  width: 420px;
  position: relative;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
`;

export const TodoElementWrapper = styled.li`
  animation: ${fadein} 300ms ease-in;
  border-bottom: 1px solid ${({ theme }) => theme.colorSeparationLine};
  padding: 0 1rem 0.5rem;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  border-bottom: 2px solid ${({ theme }) => theme.colorSeparationLine};
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
`;

export const CheckboxListContainer = styled.div`
  display: flex;
  align-content: center;
  align-self: center;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  width: 100%;
  gap: 6px;
`;

export const TodoRowContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  padding-top: 12px;
`;

const TodoSection = styled.div`
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  width: 100%;
  padding: 0 1rem 1rem;
  border-radius: 6px;
  box-sizing: border-box;
`;

export const ListContainer = styled(TodoSection)`
  padding: 0;
  display: flex;
  ${ObtrusiveScrollCss};
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 3px 3px;
`;

export const CircleIndicator = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.filterIndicator};
  border-radius: 50%;
`;

export const FilterButtonWrapper = styled.div`
  position: relative;
  display: inline-blo;
`;

export const FilterContainer = styled.div`
  margin: 1.5rem;
  max-width: 300px;
  width: 300px;
  box-sizing: border-box;
  overflow-y: hidden;
`;

export const PaddedContainer = styled.div`
  padding: 0.2rem;
  margin-bottom: 0.5rem;
`;

export const WrappingContainer = styled(Flex)`
  flex-wrap: wrap;
  gap: 3px;
  max-width: 300px;
`;

export const StyledChoiceChip = styled(ChoiceChip)`
  white-space: normal;
  padding: 3px 1px;
  max-width: 100%;
  flex-grow: 1;
`;

export const TopFlex = styled(Flex)`
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  width: 420px;
`;
