import { useState } from "react";
import constate from "constate";

export interface Tab {
  countryCode: string;
  trainNumber: string;
  trainDate: string;
  tabId: string;
}

function useTabs() {
  const [activeTrainTabs, setActiveTrainTabs] = useState<Tab[]>([]);

  return {
    activeTrainTabs,
    setActiveTrainTabs,
  };
}

const [TabsProvider, useActiveTrainTabs, useSetActiveTrainTabs] = constate(
  useTabs,
  (value) => value.activeTrainTabs,
  (value) => value.setActiveTrainTabs,
);

export { TabsProvider, useActiveTrainTabs, useSetActiveTrainTabs };
