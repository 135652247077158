export type DelayThreshold = {
  minor: number;
  moderate: number;
  major: number;
};

export const delayThreshold: DelayThreshold = {
  minor: 4,
  moderate: 6,
  major: 11,
};
