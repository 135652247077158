import { DateValue, getLocalTimeZone, today } from "@internationalized/date";
import {
  CloseFill24Icon,
  SearchOutline24Icon,
} from "@vygruppen/spor-icon-react";
import {
  Button,
  DatePicker,
  Divider,
  Flex,
  FormControl,
  Grid,
  IconButton,
  Text,
  TimePicker,
} from "@vygruppen/spor-react";
import { format } from "date-fns/format";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { commonPadding } from "../formSchema";
import { OploggSendMessage } from "./OploggSendMessage";
import { useDate } from "./useOploggContext";

export const OpLoggInput = () => {
  const setSelectedDate = useDate();
  const theme = useTheme();

  const [date, setDate] = useState<DateValue>(today(getLocalTimeZone()));
  const [time, setTime] = useState<string | undefined>(undefined);

  // change date when you pass midnight
  function handleChangeTime(newTime: string | undefined) {
    const selectedTime = time ? time.slice(0, 5) : "00:00";
    const newTimeFormatted = newTime ? newTime.slice(0, 5) : "00:00";

    if (selectedTime === "00:00" && newTimeFormatted === "23:00") {
      setDate(date.subtract({ days: 1 }));
    } else if (selectedTime === "23:00" && newTimeFormatted === "00:00") {
      setDate(date.add({ days: 1 }));
    }

    setTime(newTimeFormatted);
  }

  const [messaging, setMessaging] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);

  useEffect(() => {
    if (date && time) {
      const combinedDateTime = `${format(date.toDate(getLocalTimeZone()), "yyyy-MM-dd")}T${time}`;
      setSelectedDate(combinedDateTime);
    }
  }, [date, time, setSelectedDate]);

  return (
    <div style={{ gridArea: "input" }}>
      <Flex p="20px" justifyContent="space-between" alignItems="center">
        <Text fontWeight="bold">Operativ logg</Text>

        <Flex>
          <Button
            onClick={() => {
              setMessaging(!messaging);
              setSearching(false);
            }}
            mr="10px!"
          >
            Ny melding
          </Button>
          <IconButton
            aria-label="Søk"
            variant="tertiary"
            date-testid="filter-button"
            style={{
              backgroundColor: searching
                ? theme.searchFilterActive
                : "transparent", // Bright background when filter is active
            }}
            onClick={() => {
              if (searching) {
                setSelectedDate(null);
              } else {
                setDate(today(getLocalTimeZone()));
                setTime(format(new Date(), "HH:mm"));
              }
              setSearching(!searching);
              setMessaging(false);
            }}
            icon={searching ? <CloseFill24Icon /> : <SearchOutline24Icon />}
          />
        </Flex>
      </Flex>
      <Divider />

      {searching && (
        <>
          <Grid gridArea="todooplogg" gap={2} padding={commonPadding}>
            <FormControl>
              <Flex>
                <TimePicker
                  label="Tidspunkt"
                  minuteInterval={60}
                  onChange={(newTime) => handleChangeTime(newTime?.toString())}
                  mr="5px"
                />
                <DatePicker
                  label="Dato"
                  variant="base"
                  defaultValue={date}
                  value={date}
                  withPortal={false}
                  minValue={today(getLocalTimeZone()).subtract({ days: 7 })}
                  maxValue={today(getLocalTimeZone()).add({ days: 1 })}
                  onChange={(value) =>
                    setDate(value ?? today(getLocalTimeZone()))
                  }
                  showYearNavigation={false}
                />
              </Flex>
            </FormControl>
          </Grid>
          <Divider />
        </>
      )}

      {messaging && <OploggSendMessage setMessaging={setMessaging} />}
    </div>
  );
};
