const outboundPhoneNumberMap = new Map([
  ["Vaktleder", "23150800"],
  ["Ass Vaktleder", "23150800"],
  ["Lokleder Region", "23150810"],
  ["Materiellstyrer Region", "23150812"],
  ["Lokleder Lokal", "23150820"],
  ["Materiellstyrer Lokal", "23150822"],
  ["Personellstyrer Lok", "23150840"],
  ["Personellstyrer Kond", "23150850"],
  ["Trafikkplanlegger 60", "23150860"],
  ["Trafikkplanlegger 63", "23150863"],
  ["Transportleder", "56090051"],
  ["Personellstyrer Vest", "56090051"],
]);

export const outboundPhoneNumber = (
  PuzzelProfileName: string,
): string | undefined => {
  if (outboundPhoneNumberMap.has(PuzzelProfileName)) {
    return outboundPhoneNumberMap.get(PuzzelProfileName);
  }
  return undefined;
};
