import { DropdownUpFill24Icon } from "@vygruppen/spor-icon-react";
import { Button, Flex } from "@vygruppen/spor-react";
import styled from "styled-components";

const ChevronIconStyled = styled(DropdownUpFill24Icon)<{
  $active: boolean;
}>`
  ${({ $active }) => $active && `transform: rotate(180deg)`}
`;

const ButtonWithTransition = styled(Button)`
  * {
    transition: all 0.3s ease;
  }
`;

export const ChevronButton = ({
  active,
  setActive,
}: {
  active: boolean;
  setActive: any;
}) => (
  <ButtonWithTransition
    variant="tertiary"
    w="42px"
    h="42px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    onClick={() => setActive(!active)}
  >
    <Flex>
      <ChevronIconStyled $active={active} />
    </Flex>
  </ButtonWithTransition>
);
