import { toCalendarDateTime } from "@internationalized/date";
import {
  getCurrentCalendarDateTime,
  getInitialDuration,
  getInitialOpenEndedDuration,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/initialDurationValues";
import { InfrastructureProviderEnum } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureProvider";
import { InfraStructureInformation } from "shared/types/infrastructureResponse";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";

const getReason = (event: InfraStructureInformation) => {
  if (isPlannedInfraStructureEvent(event.type))
    return { type: "WORK_ON_TRACK" };
  return event.reason
    ? {
        type: event.reason.type,
        description: {
          NOB: event.reason.description.nob || "",
          ENG: event.reason.description.eng || "",
        },
      }
    : { type: "" };
};

const getPrognosisTime = (event: InfraStructureInformation) => {
  if (event.prognosis) {
    if (
      event.prognosis.type === "ESTIMATED_OPENING" &&
      event.prognosis.estimatedResolved
    )
      return toCalendarDateTime(event.prognosis.estimatedResolved);
    if (
      event.prognosis.type === "ESTIMATED_INFO_UPDATE" &&
      event.prognosis.estimatedInfoUpdate
    )
      return toCalendarDateTime(event.prognosis.estimatedInfoUpdate);
  }
  return getCurrentCalendarDateTime();
};

export const getDefaultValuesEdit = (event: InfraStructureInformation) => ({
  infrastructureForm: {
    event: event.type,
    reason: getReason(event),
    action: event.action
      ? {
          type: event.action.type,
          description: {
            NOB: event.action.description.nob || "",
            ENG: event.action.description.eng || "",
          },
        }
      : { type: "" },
    actionCard: event.actionCard?.type || "",
    consequence: event.consequence
      ? {
          type: event.consequence.type,
          description: {
            NOB: event.consequence.description.nob || "",
            ENG: event.consequence.description.eng || "",
          },
        }
      : { type: "" },
    prognosisType: event.prognosis
      ? {
          type: event.prognosis.type,
          description: {
            NOB: event.prognosis.description.nob || "",
            ENG: event.prognosis.description.eng || "",
          },
        }
      : { type: "" },
    prognosisTime: getPrognosisTime(event),
    stretchName: event.stretchName,
    affectedLegs: event.affectedLegs,
    redirectStretches: event.redirectStretches,
    durations: getInitialDuration(event.durations),
    isirkId: event.isirkId,
    infrastructureProvider:
      event.infrastructureProvider === null
        ? InfrastructureProviderEnum.NONE
        : event.infrastructureProvider,
  },
});

export const defaultValuesCreate = {
  infrastructureForm: {
    event: "",
    reason: { type: "" },
    actionCard: "",
    consequence: { type: "" },
    prognosisType: undefined,
    prognosisTime: getCurrentCalendarDateTime(),
    stretchName: "",
    affectedLegs: [],
    redirectStretches: [],
    durations: getInitialOpenEndedDuration(),
    isirkId: "",
  },
};
