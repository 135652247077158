import { Box, Divider, HStack, tokens } from "@vygruppen/spor-react";
import { FC } from "react";
import styled from "styled-components";
/*
import { DashboardHistoryTabs } from "features/Header/DashboardHistoryTabs";
*/
import { Center } from "@chakra-ui/react";
import { getEnvBool } from "api/common";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import { Environment, environment } from "environment";
import Clock from "features/Header/Clock/Clock";
import { Logo } from "features/Header/Logo/Logo";
import { Menu } from "features/Header/Menu/Menu";
import Puzzel from "features/Header/PuzzelTelephone/Puzzel";
import SelectRole from "features/Header/SelectRole/selectRole";
import { SystemStatus } from "features/Header/SystemStatus/SystemStatus";
import { OpenAlerts } from "features/Header/TrainResumed/OpenAlerts";
import { useLocation } from "react-router-dom";
import * as ROUTES from "shared/utils/routes";

export const HeaderWrapper = styled.div`
  grid-area: header;
  height: 5rem;
  font-weight: 300;
  font-size: 15px;
  align-self: stretch;
  z-index: ${tokens.default.depth["z-index"].banner};
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colorBackgroundMain};
  border-bottom: 1px solid ${({ theme }) => theme.colorOutline};
`;

const HeaderFlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DevelopmentStatusBox = styled(Box)`
  margin-left: 20px;
  position: absolute;
  text-align: center;
  width: 90px;
  color: grey;
`;

const DividerContainer = () => (
  <Center height="70%">
    <Divider
      size="lg"
      borderLeftWidth="1px"
      orientation="vertical"
      style={{ margin: "20px" }}
    />
  </Center>
);

const env: Environment = environment();

const Header: FC = () => {
  const headerPagerduty = useFeatureFlag("headerPagerduty").enabled;
  const location = useLocation();
  if (location.pathname === ROUTES.OPLOGG) {
    return null;
  }
  return (
    <HeaderWrapper>
      {env === Environment.Development && (
        <DevelopmentStatusBox
          fontSize="2xs"
          as="span"
          flex="1"
          textAlign="left"
        >
          {getEnvBool("VITE_USE_LOCAL_BACKEND") ? "Local" : "Test"}
        </DevelopmentStatusBox>
      )}
      <HStack px={4}>
        <Logo />
        <DividerContainer />
        <Clock />
        {headerPagerduty && (
          <>
            <DividerContainer />
            <SystemStatus />
          </>
        )}
      </HStack>
      <HeaderFlexContainer>
        <OpenAlerts />
        <DividerContainer />
        <Puzzel />
        <DividerContainer />
        <SelectRole />
        <DividerContainer />
        <Menu />
      </HeaderFlexContainer>
    </HeaderWrapper>
  );
};

export default Header;
