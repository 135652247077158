import React from "react";
import styled from "styled-components";
import { UserListStyled } from "../../../../../PuzzelStyle";
import {
  ContactCentreStatus,
  PuzzelUser,
  SessionCommands,
} from "../../../../types";
import SessionCommand from "../../../../SessionCommand";

interface DropdownUserListParams {
  users: PuzzelUser[];
  requestId: string;
}

const UserListItem = styled.div`
  display: flex;
  align-self: flex-start;
  margin: auto;
  flex: 1;
`;

export const DropdownUserList = ({
  users,
  requestId,
}: DropdownUserListParams) => (
  <UserListStyled>
    {users.map((user: PuzzelUser, i: number) => {
      const disabled =
        user.contactCentreStatus === ContactCentreStatus.LoggedOff;
      return (
        <div
          key={`puzzel-user-${i}`}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "0 1rem",
          }}
        >
          <UserListItem>
            {user.firstName} {user.lastName}
          </UserListItem>
          <SessionCommand
            command={SessionCommands.TransferToPhone}
            requestId={requestId}
            destinationAgentId={user.id}
            disabled={disabled}
            small
          />
          <SessionCommand
            command={SessionCommands.ConsultWithAgent}
            requestId={requestId}
            destinationAgentId={user.id}
            disabled={disabled}
            small
          />
        </div>
      );
    })}
  </UserListStyled>
);
