import {
  Leg,
  Stop,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";

const findEndpoints = (legs: Leg[]) => {
  const allPoints = legs.flatMap((leg) => [leg.fromStop, leg.toStop]);
  const endpoints = allPoints.filter((point, index, self) => {
    const pointsBeforeIndex = self.slice(0, index);
    const pointsAfterIndex = self.slice(index + 1);
    const pointIsRepeated =
      pointsBeforeIndex.some((p) => point.id === p.id) ||
      pointsAfterIndex.some((p) => point.id === p.id);
    return !pointIsRepeated;
  });
  return endpoints;
};

const findNextLegIndex = (stop: Stop, legsToSearch: Leg[]) =>
  legsToSearch.findIndex(
    (leg) => leg.fromStop.id === stop.id || leg.toStop.id === stop.id,
  );

const isStopEndpoint = (stop: Stop, endpoints: Stop[]) =>
  endpoints.some((endpoint) => endpoint.id === stop.id);

const findVisitedStopWithMoreConnections = (
  visitedStops: Stop[],
  remainingLegs: Leg[],
) =>
  visitedStops.find((stop) =>
    remainingLegs.some(
      (leg) => leg.fromStop.id === stop.id || leg.toStop.id === stop.id,
    ),
  );

export const generateStretchName = (selectedLegs: Leg[]) => {
  const remainingLegs = [...selectedLegs];
  const stretchNames: string[] = [];

  while (remainingLegs.length > 0) {
    const endpoints = findEndpoints(remainingLegs);
    const start = endpoints[0];
    let currentStop = start;
    let hasFoundEnd = false;

    const visitedStops: Stop[] = [];

    if (endpoints.length === 0) {
      break;
    }

    if (endpoints.length === 1) {
      stretchNames.push(endpoints[0].name);
      break;
    }

    while (!hasFoundEnd && endpoints.length > 0 && remainingLegs.length > 0) {
      visitedStops.push(currentStop);

      const nextLegIndex = findNextLegIndex(currentStop, remainingLegs);

      const nextStop =
        remainingLegs[nextLegIndex].fromStop.id === currentStop.id
          ? remainingLegs[nextLegIndex].toStop
          : remainingLegs[nextLegIndex].fromStop;

      remainingLegs.splice(nextLegIndex, 1);

      const nextStopIsEndpoint = isStopEndpoint(nextStop, endpoints);
      if (nextStopIsEndpoint) {
        hasFoundEnd = true;
        stretchNames.push(`${start.name}\u2013${nextStop.name}`);
      } else if (visitedStops.some((stop) => stop.id === nextStop.id)) {
        const nextNextStop = findVisitedStopWithMoreConnections(
          visitedStops,
          remainingLegs,
        );
        if (nextNextStop) {
          currentStop = nextNextStop;
        }
      } else {
        currentStop = nextStop;
      }
    }
  }
  return stretchNames;
};
