import { Button, ButtonProps } from "@vygruppen/spor-react";
import { ReactNode } from "react";
import styled from "styled-components";

const ButtonStyled = styled(Button)`
  &&& {
    padding: 0px;
    min-height: 0px;
    min-width: 0px;
  }
  > * > * > * {
    /* Why is it so nested Spor? */
    display: flex;
  }
`;

export const NoStyleButton = ({
  children,
  ...rest
}: { children: ReactNode } & ButtonProps) => (
  <ButtonStyled variant="ghost" {...rest}>
    {children}
  </ButtonStyled>
);
