import ReactDOM from "react-dom/client";
import React from "react";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Start the mocking conditionally.
if (import.meta.env.DEV && import.meta.env.VITE_MSW_ENABLED === "true") {
  const startWorker = async () => {
    const { worker } = await import("./mocks/browser");
    worker.start();
  };
  startWorker();
}
