import { Textarea } from "@vygruppen/spor-react";
import styled, { css } from "styled-components";

// It is expanded using height instead of overflow: hidden (e.x. DropsExpandable)
// Looks better, but this type of expanding only works for single elements

const CollapsedHeightStyle = css`
  height: 0px;
  min-height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-width: 0px;
`;

export const TextareaExpandable = styled(Textarea)<{
  $collapsed: boolean;
}>`
  &&& {
    ${({ $collapsed }) => $collapsed && CollapsedHeightStyle}
    resize: none;
    transition: all 0.3s ease;
  }
`;
