import { FormControl, FormErrorMessage, Textarea } from "@vygruppen/spor-react";
import {
  FormErrorWithDiscriminatedField,
  FormSchema,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/formSchema";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type StationPersonProps = {};

export const StationPerson: FC<StationPersonProps> = () => {
  const formMethods = useFormContext<FormSchema>();

  // Nested errors within discriminated types aren't great to work with in
  // react-hook-form – add error types for each layer below discriminator
  const fieldErrors: FormErrorWithDiscriminatedField<"NOB" | "ENG"> = (
    formMethods.formState.errors
      .stationForm as FormErrorWithDiscriminatedField<"personDescription">
  )?.personDescription;

  return (
    <>
      <FormControl isInvalid={!!fieldErrors?.NOB}>
        <Textarea
          label="Norsk beskrivelse"
          {...formMethods.register("stationForm.personDescription.NOB")}
        />

        <FormErrorMessage>{fieldErrors?.NOB?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!fieldErrors?.ENG}>
        <Textarea
          label="Engelsk beskrivelse"
          {...formMethods.register("stationForm.personDescription.ENG")}
        />

        <FormErrorMessage>{fieldErrors?.ENG?.message}</FormErrorMessage>
      </FormControl>
    </>
  );
};
