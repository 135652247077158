import { toCalendarDateTime } from "@internationalized/date";
import { contextsForGlobalWarning } from "features/CenterContent/RoleContent/GlobalEvents/formToGlobalInformationRequest";
import {
  GlobalEvent,
  GlobalFormSchema,
  visibilitySchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { getCurrentCalendarDateTime } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/initialDurationValues";
import { InfrastructureEventCreatedResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";

const getDefaultValuesForEdit = (
  globalEvent: GlobalEvent,
): GlobalFormSchema => {
  const { visibility } = globalEvent;
  return {
    globalForm: {
      customTexts: globalEvent.customTexts[0] ?? {
        contexts: contextsForGlobalWarning,
        texts: { NOB: { title: "", description: "" } },
      },
      visibility:
        visibility.type === "TIMED"
          ? {
              type: "TIMED",
              fromTime: getCurrentCalendarDateTime(),
              toTime: toCalendarDateTime(visibility.toTime),
            }
          : {
              type: "OPEN_ENDED",
              fromTime: getCurrentCalendarDateTime(),
            },
      incidentIds: globalEvent.referencedIncidentIds,
    },
  };
};

const getDefaultValuesFromInfrastructureEvent = (
  relatedInfrastructureEvent: InfrastructureEventCreatedResponse,
): GlobalFormSchema => {
  const visibility = visibilitySchema.parse(
    relatedInfrastructureEvent.visibility,
  );
  return {
    globalForm: {
      customTexts: {
        contexts: contextsForGlobalWarning,
        texts: relatedInfrastructureEvent.texts,
      },
      visibility: {
        type: "OPEN_ENDED",
        fromTime:
          visibility.type === "OPEN_ENDED"
            ? toCalendarDateTime(visibility.fromTime)
            : getCurrentCalendarDateTime(),
      },
      incidentIds: [relatedInfrastructureEvent.incidentId],
    },
  };
};

export const getDefaultValues = (
  relatedInfrastructureEvent?: InfrastructureEventCreatedResponse,
  globalEventToEdit?: GlobalEvent,
): GlobalFormSchema => {
  if (globalEventToEdit) {
    return getDefaultValuesForEdit(globalEventToEdit);
  }

  if (relatedInfrastructureEvent) {
    return getDefaultValuesFromInfrastructureEvent(relatedInfrastructureEvent);
  }

  return {
    globalForm: {
      customTexts: {
        contexts: contextsForGlobalWarning,
        texts: {
          NOB: {
            title: "",
            description: "",
          },
        },
      },
      visibility: {
        type: "TIMED",
        fromTime: getCurrentCalendarDateTime(),
        toTime: getCurrentCalendarDateTime(1),
      },
      incidentIds: [],
    },
  };
};
