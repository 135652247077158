import { Button, ButtonGroup, Radio, RadioGroup } from "@vygruppen/spor-react";
import { LogLevel, log } from "api/cloudWatch";
import { getLocalStorageUsername } from "api/dropsLocalStorage";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { ROLE_TEXTS } from "shared/types/roles";
import { TodoRowContainer } from "../../TodoStyles";
import { ErrorTextDescriptionElement } from "../../common/ErrorMessage";
import TextInput from "../../common/TextInput";
import { updateExistingTodo } from "../todoListApi";
import { Todo } from "./TodoElement";
import useForm, { UseFormReturnType } from "./useForm";

interface ITodoEdit {
  todo: Todo;
  setSelectedTodoId: Dispatch<SetStateAction<string>>;
  selectedTodoId: string;
}

const TodoEdit = (props: ITodoEdit) => {
  const userName = getLocalStorageUsername();
  const { setSelectedTodoId, selectedTodoId, todo } = props;

  const [showError, setShowError] = useState(false);

  const { inputs, handleInputChange, handleSubmit }: UseFormReturnType =
    useForm(() => {
      updateExistingTodo({
        ...todo,
        createdAt: todo.dateCreated,
        role: inputs.role,
        description: inputs.description,
        alarmTime: null,
        todoId: selectedTodoId,
        username: userName,
      })
        .then(() => {
          setSelectedTodoId("");
        })
        .catch((exception: Error) => {
          setShowError(true);
          log(
            LogLevel.error,
            "TodoEdit",
            `Could not perform api call for updating Todo message with exception: ${exception.message} `,
          );
        });
    }, todo);

  const setRole = (value: string) => {
    handleInputChange({
      target: { value, name: "role" },
      type: "radio",
    } as ChangeEvent<HTMLInputElement & HTMLTextAreaElement>);
  };

  const descriptionCurrentLength = (inputs.description || []).length;
  const disableSubmitButton = descriptionCurrentLength <= 0;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (descriptionCurrentLength > 0 && inputs.role) {
          handleSubmit(e);
        }
      }}
    >
      <TodoRowContainer
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {ErrorTextDescriptionElement(
          showError,
          "Kunne ikke lagre endret melding. Vennligst prøv igjen.",
          {
            gridColumn: "span 2",
          },
        )}

        <TextInput
          title="Rediger melding"
          messageText={inputs.description}
          handleInputChange={handleInputChange}
        />
        <RadioGroup
          value={inputs.role}
          direction="column"
          onChange={setRole}
          name="role"
        >
          {ROLE_TEXTS.map((role) => (
            <Radio key={`${selectedTodoId}-${role.value}`} value={role.value}>
              {role.text}
            </Radio>
          ))}
        </RadioGroup>

        <ButtonGroup ml="auto" my={1}>
          <Button
            variant="tertiary"
            size="sm"
            onClick={() => {
              setSelectedTodoId("");
            }}
          >
            Avbryt
          </Button>
          <Button
            variant="secondary"
            size="sm"
            type="submit"
            disabled={disableSubmitButton}
          >
            Lagre
          </Button>
        </ButtonGroup>
      </TodoRowContainer>
    </form>
  );
};

export default TodoEdit;
