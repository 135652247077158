import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

type State = {
  trainListOpen: boolean;
  oploggTodoListOpen: boolean;
};

type Action = {
  setTrainListOpen: (newState: State["trainListOpen"]) => void;
  setOploggTodoListOpen: (newState: State["oploggTodoListOpen"]) => void;
};

export const useSidePanelsState = createWithEqualityFn<State & Action>(
  (set) => ({
    trainListOpen: true,
    oploggTodoListOpen: true,
    setTrainListOpen: (newState) => set(() => ({ trainListOpen: newState })),
    setOploggTodoListOpen: (newState) =>
      set(() => ({ oploggTodoListOpen: newState })),
  }),
  shallow,
);
