import { Box, Textarea } from "@vygruppen/spor-react";
import { useFormContext } from "react-hook-form";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";

type InputTextareaProps = {
  label: string;
  formField: string;
};

export const InputTextarea = ({ label, formField }: InputTextareaProps) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const description = watch(formField);
  if (description === undefined) {
    throw new Error(
      "FormField must have a defaultValue for this component to behave correctly",
    );
  }

  return (
    <>
      <Textarea
        {...register(formField)}
        label={label}
        css={`
          width: 100%;
          transition: all 250ms ease;
          resize: none;
          min-height: 7em;
        `}
      />
      <Box mt="12px">
        <RenderErrorInPath errors={errors} errorPath={formField} />
      </Box>
    </>
  );
};
