import { TabIds } from "features/CenterContent/RoleContent/getTabsForRole";
import { FC } from "react";
import { generatePath, Navigate, useLocation } from "react-router-dom";
import { UserRole } from "shared/types/roles";

// DASHBOARD ROUTE CONSTANTS
export const DASHBOARD = "/dashboard";

export const LANDING = `${DASHBOARD}/landing/:roleIdParam/:tabIdParam`;
export const TRAIN = `${DASHBOARD}/train/:countryCode/:trainNumber/:trainDate`;
export const BUS = `${DASHBOARD}/bus/:vehicleUuid`;
export const RESERVEBUS = `${DASHBOARD}/reservebus/:vehicleUuid`;

// HISTORY ROUTE CONSTANTS
export const HISTORY = "/history";

// OPLOGG ROUTE CONSTANTS
export const OPLOGG = "/oplogg";

export const DETAIL = `${HISTORY}/detail/:eventTypeParam/:eventUuidParam`;

// UTIL ROUTE CONSTANTS
export const LOGIN_REDIRECT = "/login-redirect";

export const getDashboardStartPath = (role: UserRole) =>
  generatePath(LANDING, { roleIdParam: role, tabIdParam: TabIds.START });

export const DashboardRedirect: FC = () => {
  const location = useLocation();
  return <Navigate to={`${DASHBOARD}${location.pathname}`} replace />;
};
