import { CSSProperties } from "react";
import styled from "styled-components";
import { Text } from "../../../../shared/components/typography/TitleStyles";

// @ts-ignore
export const ErrorData = styled(Text)`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colorAlarm};
`;

export const ErrorTextDescriptionElement = (
  showError: boolean,
  errorMessage: string,
  style: CSSProperties,
) => (showError ? <ErrorData style={style}>{errorMessage}</ErrorData> : null);
