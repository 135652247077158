import { FormControl, FormErrorMessage } from "@vygruppen/spor-react";
import { FieldErrorOrUndefined } from "./formSchema";

export const FormControlErrorMessage = ({
  field,
}: {
  field: FieldErrorOrUndefined;
}) => (
  <FormControl isInvalid={!!field}>
    <FormErrorMessage data-testid="form-control-error-msg">
      {field?.message}
    </FormErrorMessage>
  </FormControl>
);
