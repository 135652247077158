import { AddOutline24Icon } from "@vygruppen/spor-icon-react";
import { Button, Flex } from "@vygruppen/spor-react";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import { TrainInfoMessage } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoMessage";
import { CreateTrainInfoModal } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/CreateTrainInfoModal";
import { useTrainInformation } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/useTrainInformation";
import { sortTrainInfoByUrgencyStateCompareFn } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/utils";
import { DetailsParams } from "features/CenterContent/VehicleDetails/TrainDetails/TrainDetails";
import { useState } from "react";
import { useParams } from "react-router-dom";
import InternalMessageModal from "shared/components/InternalMessage/InternalMessageModal";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { NoteOutline24Icon } from "shared/icons/NoteOutline24Icon";
import styled from "styled-components";

const TrainInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
  width: 100%;
`;

export const OperationalTrainInfo = () => {
  const { trainNumber, trainDate } = useParams<DetailsParams>();
  const { data, status } = useTrainInformation();

  const [internalMessageModalOpen, setInternalMessageModalOpen] =
    useState<boolean>(false);
  const [trainInfoModalOpen, setTrainInfoModalOpen] = useState<boolean>(false);
  const internalMessageEnabled = useFeatureFlag("internalMessage").enabled; // Enabled: Show "Intern melding" at train page

  const trainInfoWithOpenState =
    data?.trainInformation
      .filter(
        (info) => info.versions.length > 0 && info.versions[0].state === "OPEN",
      )
      .map((info) => info.versions[0].type) ?? [];

  const getTrainInfoMessages = () => {
    switch (status) {
      case "pending":
        return null;
      case "success":
        return (data?.trainInformation ?? [])
          .filter(
            (info) =>
              info.versions !== undefined &&
              info.versions.length > 0 &&
              info.versions[0].state !== "REPLACED",
          )
          .toSorted(sortTrainInfoByUrgencyStateCompareFn)
          .map((info) => (
            <TrainInfoMessage
              info={info}
              key={info.latestInfoUuid}
              trainInfoWithOpenState={trainInfoWithOpenState}
            />
          ));
      case "error":
      default:
        return (
          <FailureMessage customMessage="Kunne ikke hente aktive hendelser for dette toget." />
        );
    }
  };

  if (trainNumber === undefined || trainDate === undefined) {
    return (
      <FailureMessage customMessage="Kunne ikke hente tognummer eller dato for dette toget." />
    );
  }

  return (
    <TrainInfoWrapper>
      <Flex gap="8px">
        {internalMessageEnabled && (
          <Button
            variant="tertiary"
            mb={2}
            onClick={() => setInternalMessageModalOpen(true)}
            leftIcon={<NoteOutline24Icon />}
          >
            Intern melding
          </Button>
        )}
        <Button
          mb={2}
          onClick={() => setTrainInfoModalOpen(true)}
          rightIcon={<AddOutline24Icon />}
        >
          Ny hendelse
        </Button>
      </Flex>
      {getTrainInfoMessages()}
      {internalMessageModalOpen && (
        <InternalMessageModal
          train={{
            identifier: trainNumber,
            nominalDate: trainDate,
            countryCode: "NO", // TODO: CountryCode should not be hardcoded
          }}
          setModalOpen={setInternalMessageModalOpen}
        />
      )}
      {trainInfoModalOpen && (
        <CreateTrainInfoModal
          train={{
            identifier: trainNumber,
            nominalDate: trainDate,
            countryCode: "NO", // TODO: CountryCode should not be hardcoded
          }}
          setModalOpen={setTrainInfoModalOpen}
          trainInfoWithOpenState={trainInfoWithOpenState}
        />
      )}
    </TrainInfoWrapper>
  );
};
