import { FormControlErrorMessage } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/FormControlErrorMessage";
import { FieldError, FieldErrors, FieldValues } from "react-hook-form";
import { getFieldFromNestedObject } from "shared/utils/objectUtils";

export const RenderErrorInPath = ({
  errors,
  errorPath,
}: {
  errors?: FieldErrors<FieldValues>;
  errorPath: string;
}) => {
  const field = getFieldFromNestedObject<FieldError>(errors, errorPath);
  if (!field) {
    return null;
  }

  return <FormControlErrorMessage field={field} />;
};
