import colors from "shared/theme/colors";

export const lightTheme = {
  themeName: "light",

  /// ///////////////////////////////////////
  // Use SPOR components and color variables if possible
  // Is component is custom or overwritten, use the following color variables
  // Don't create color variables inside your custom components: Create them here
  // Don't even think about using a color without a variable
  /// ///////////////////////////////////////

  // BACKGROUND
  colorBackgroundMain: colors.lightGrey, // #F5F5F5
  colorBackgroundSecondary: colors.white, // #FDFDFD
  colorBackgroundTertiary: colors.platinum, // #EBEBEC
  colorHoverItem: colors.blackAlpha["100"], // #000000 10% Opacity

  // TEXT
  colorTextMain: colors.darkGrey, // #2B2B2C
  colorTextSecondary: colors.dimGrey, // #606568
  colorTextDisabled: colors.steel, // #AFB2B3
  colorSelectedText: colors.lightGrey, // #F5F5F5

  // OUTLINES
  colorSeparationLine: colors.silver, // #D7D8D9
  colorOutline: colors.blackAlpha["100"], // rgba(0, 0, 0, 0.4)
  colorOutlineGreen: colors.greenHaze, // #00957A

  // SEMANTICS
  // Alarm 1
  colorAlarmTextMain: colors.red800,
  colorAlarmTextSecondary: colors.region,
  colorAlarm: colors.raspberryCustom,
  colorAlarmPulse: colors.wineCustom,
  colorAlarmFill: colors.pink,
  colorAlarmFillPulse: colors.lightRed,
  colorAlarmOutline: colors.lightRed,

  // Alarm 2
  colorSecondaryAlarmTextMain: colors.orange900,
  colorSecondaryAlarmTextSecondary: colors.chocolate,
  colorSecondaryAlarm: colors.chocolate,
  colorSecondaryAlarmFill: colors.bisque,
  colorSecondaryAlarmFillPulse: colors.champagne,
  colorSecondaryAlarmOutline: colors.champagne,

  // Warning
  colorWarning: colors.burntYellow,
  colorWarningFill: colors.cornsilk,
  colorWarningTextMain: colors.yellow900,
  colorWarningTextSecondary: colors.yellow700,
  colorWarningFillPulse: colors.blonde,
  colorWarningOutline: colors.sunshine,

  // Success
  colorSuccess: colors.greenHaze,
  colorSuccessFill: colors.green25,
  colorSuccessTextMain: colors.darkTeal,
  colorSuccessTextSecondary: colors.greenHaze,
  colorSuccessFillPulse: colors.seaMist,
  colorSuccessOutline: colors.coralGreen,

  // Info
  colorInfo: colors.ocean,
  colorInfoFill: colors.icyBlue,
  colorInfoTextMain: colors.blue900,
  colorInfoTextSecondary: colors.ocean,
  colorInfoFillPulse: colors.lightBlue,
  colorInfoOutline: colors.cloudy,

  // Neutral
  colorNeutral: colors.dimGrey,
  colorNeutralTextMain: colors.darkGrey,
  colorNeutralTextSecondary: colors.dimGrey,
  colorNeutralFill: colors.platinum,
  colorNeutralFillPulse: colors.silver,
  colorNeutralOutline: colors.silver,

  // SPECIALSFill
  primaryBtnColor: colors.darkTeal, // #00453E Need Access to Puzzle to check this (We can use spor´s component.) //
  colorSelected: colors.seaMist, // #00453E
  skeletonElementColor: colors.silver, // #D7D8D9
  searchFilterActive: colors.mint, // #e5f4f1
  filterIndicator: colors.azure, // #38B49E
};

export type LightTheme = typeof lightTheme;
