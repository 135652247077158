import {
  ZonedDateTime,
  fromDate,
  getLocalTimeZone,
} from "@internationalized/date";
import { useQuery } from "@tanstack/react-query";
import {
  CalendarOutline24Icon,
  WarningOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import {
  GlobalEvent,
  Visibility,
  globalEventsResponseSchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { EventsOverviewModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewModal";
import { useState } from "react";
import { SemanticButton } from "shared/components/buttons/SemanticButton";
import { useQueryMock } from "shared/hooks/useQueryMock";
import {
  InfraStructureInformation,
  upcomingEventsResponseSchema,
} from "shared/types/infrastructureResponse";
import { dropsRole } from "stores/dropsRole";

export const UPCOMING_EVENTS_QUERY_KEY = "upcomingInfrastructureEvents";
export const GLOBAL_EVENTS_QUERY_KEY = "globalEvents";
export type InternalMessageMockType = {
  uuid: string;
  category: "global" | "internal" | "station" | "train";
  stations: string[] | null;
  train: string | null;
  visibility: Visibility;
  customText: { title: string; description: string };
  updatedAt: ZonedDateTime;
};

const internalMessageMock: InternalMessageMockType[] = [
  {
    uuid: "c9bcd610-e5f2-41fc-a7fb-ddf7195077f9",
    category: "global",
    stations: null,
    train: null,
    visibility: {
      type: "OPEN_ENDED",
      fromTime: fromDate(new Date(), getLocalTimeZone()),
    },
    customText: {
      title: "Intern melding knyttet til global driftsmelding",
      description:
        "Oslo S er stengt pga flom. Dette påvirker all togtrafikk, innstillinger må påregnes. Oppdatering følger.",
    },
    updatedAt: fromDate(new Date(), getLocalTimeZone()),
  },
  {
    uuid: "c9bcd610-e5f2-41fc-a7fb-ddf7195077f8",
    category: "internal",
    stations: null,
    train: null,
    visibility: {
      type: "CONSTANT",
      visible: true,
    },
    customText: {
      title: "Global intern melding",
      description:
        "Oslo S er stengt pga flom. Dette påvirker all togtrafikk, innstillinger må påregnes. Oppdatering følger.",
    },
    updatedAt: fromDate(new Date(), getLocalTimeZone()),
  },
  {
    uuid: "c9bcd610-e5f2-41fc-a7fb-ddf7195077f7",
    category: "station",
    stations: ["Bleiken stasjon"],
    train: null,
    visibility: {
      type: "OPEN_ENDED",
      fromTime: fromDate(new Date(), getLocalTimeZone()),
    },
    customText: {
      title: "Intern melding knyttet til stasjon",
      description: "Bilettautomat ute av drift lorem ipsum dolor sit amet ",
    },
    updatedAt: fromDate(new Date(), getLocalTimeZone()),
  },
  {
    uuid: "c9bcd610-e5f2-41fc-a7fb-ddf7195077f6",
    category: "station",
    stations: Array(5).fill("Bleiken stasjon"),
    train: null,
    visibility: {
      type: "OPEN_ENDED",
      fromTime: fromDate(new Date(), getLocalTimeZone()),
    },
    customText: {
      title: "Intern melding knyttet til flere stasjoner",
      description: "Glatte plattformer lorem ipsum dolor sit amet",
    },
    updatedAt: fromDate(new Date(), getLocalTimeZone()),
  },
  {
    uuid: "c9bcd610-e5f2-41fc-a7fb-ddf7195077f5",
    category: "train",
    stations: null,
    train: "1614/L2",
    visibility: {
      type: "OPEN_ENDED",
      fromTime: fromDate(new Date(), getLocalTimeZone()),
    },
    customText: {
      title: "Intern melding knyttet til tog",
      description:
        "Bane Nor jobber på strekningen Riksgrensen - Skotterud fra fredag 8. mar. til og med søndag 10.mar. mellom kl. 00:00 og 23:59 og fra onsdag 12. jun. til og med torsdag 13. jun. mellom kl.03:00 og 04:00",
    },
    updatedAt: fromDate(new Date(), getLocalTimeZone()),
  },
];

export const EventsOverviewButtonAndModal = () => {
  const [showModal, setShowModal] = useState(false);
  const { enabled: globalEventsEnabled } = useFeatureFlag("gobalEvents");
  const { isVaktleder } = dropsRole();

  const upcomingInfrastructureEvents = useQuery({
    queryKey: [UPCOMING_EVENTS_QUERY_KEY],
    staleTime: 0,
    queryFn: ({ signal }) =>
      axiosClient
        .get<InfraStructureInformation[]>(
          `${getBackendUrl()}/infrastructure-event/upcoming?countryCode=NO`,
          {
            signal,
          },
        )
        .then((res) => upcomingEventsResponseSchema.parse(res.data)),
  });

  const globalEvents = useQuery({
    queryKey: [GLOBAL_EVENTS_QUERY_KEY],
    staleTime: 0,
    queryFn: ({ signal }) =>
      axiosClient
        .get<GlobalEvent[]>(
          `${getBackendUrl()}/global-information/visible/NO`,
          {
            signal,
          },
        )
        .then((res) => globalEventsResponseSchema.parse(res.data)),
  });

  const internalMessageEvents = useQueryMock(internalMessageMock);

  const numberOfEvents =
    upcomingInfrastructureEvents.isSuccess && globalEvents.isSuccess
      ? `(${upcomingInfrastructureEvents.data.length + globalEvents.data.length})`
      : "";

  const isActiveGlobalEvents =
    globalEvents.isSuccess && globalEvents.data.length > 0;

  return (
    <>
      <SemanticButton
        title={
          globalEventsEnabled && isVaktleder()
            ? `Oversikt hendelser ${numberOfEvents}`
            : "Fremtidige hendelser"
        }
        severity={
          isActiveGlobalEvents && globalEventsEnabled && isVaktleder()
            ? "warning"
            : undefined
        }
        size="md"
        leftIcon={
          globalEventsEnabled && isVaktleder() ? (
            <WarningOutline24Icon />
          ) : (
            <CalendarOutline24Icon />
          )
        }
        aria-label="Se fremtidige hendelser"
        onClick={() => setShowModal(true)}
        style={{ width: "fit-content" }}
      />

      {showModal && (
        <EventsOverviewModal
          onClose={() => setShowModal(false)}
          upcomingInfrastructureEvents={upcomingInfrastructureEvents}
          activeGlobalEvents={globalEvents}
          internalMessageEvents={internalMessageEvents}
        />
      )}
    </>
  );
};
