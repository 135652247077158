import CallerNumberInfo from "../../../CallerNumberInfo";
import { Text } from "../../../../../../../shared/components/typography/TitleStyles";
import { IqSessionRequestType, SessionCommands, Status } from "../../../types";
import SessionCommand from "../../../SessionCommand";

type Props = {
  req: IqSessionRequestType;
};

const StatusSetup = (props: Props) => {
  const { req } = props;
  const { status, remoteAddress } = req;

  if (status === Status.Setup) {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
        <Text bold>I spørreanrop</Text>
        <CallerNumberInfo status={status} remoteAddress={remoteAddress} />
        <SessionCommand
          command={SessionCommands.ConsultOff}
          requestId={req.id}
        />
        <SessionCommand
          command={SessionCommands.TransferToPhone}
          requestId={req.id}
          destinationPhoneNumber={req.remoteAddress}
        />
      </div>
    );
  }
  return null;
};

export default StatusSetup;
