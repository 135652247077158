import { useMutation } from "@tanstack/react-query";
import { Divider, Grid, Stack, Text } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { EventActionButtons } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/EventActionButtons";
import {
  StationEventResponse,
  getAffectedStops,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/stationEventResponseSchema";
import { FC, useEffect } from "react";
import { useStretchBuilder } from "stores/useStretchBuilder";
import { useTheme } from "styled-components";

type StationEventItemProps = {
  stationEvent: StationEventResponse;
  isFirstItem: boolean;
  onClose: () => void;
  askConfirmation: boolean;
  setAskConfirmation: (value: string | null) => void;
};

export const StationEventItem: FC<StationEventItemProps> = ({
  stationEvent,
  isFirstItem,
  onClose,
  askConfirmation,
  setAskConfirmation,
}) => {
  const theme = useTheme();

  const setStretchBuilderModalState = useStretchBuilder(
    (state) => state.setStretchBuilderModalState,
  );
  const { mutate: closeStationEvent, status: closeStatus } = useMutation({
    mutationFn: (uuid: string) =>
      mutationFnPOST<string, undefined>(
        `${getBackendUrl()}/station-information/${uuid}/close`,
      ),
  });

  // Close the selectEvent modal after one second if closeEvent is successful
  useEffect(() => {
    if (closeStatus === "success") {
      setTimeout(onClose, 1000);
    }
  }, [closeStatus]);

  return (
    <>
      {!isFirstItem && <Divider size="sm" my={0} />}
      <Grid
        as="li"
        autoFlow="column"
        autoColumns="1fr max-content"
        gap={1}
        py="9px"
        my={1}
        style={
          askConfirmation
            ? {
                border: `1px solid ${theme.colorInfo}`,
                borderRadius: "6px",
              }
            : {}
        }
        px={askConfirmation ? "9px" : "0"}
        alignItems="center"
      >
        <EventActionButtons
          closeStatus={closeStatus}
          askConfirmation={askConfirmation}
          setAskConfirmation={() => setAskConfirmation(stationEvent.uuid)}
          closeEvent={() => closeStationEvent(stationEvent.uuid)}
          editEvent={() =>
            setStretchBuilderModalState({
              type: "station",
              eventInfo: stationEvent,
              stretchBuilderMode: false,
            })
          }
          cancelCloseEvent={() => setAskConfirmation(null)}
        >
          <Stack gridColumn="span 2" gap={1}>
            <Text variant="xs" fontWeight="bold">
              {getAffectedStops(stationEvent)}
            </Text>
            <Text variant="xs">{stationEvent.message}</Text>
          </Stack>
        </EventActionButtons>
      </Grid>
    </>
  );
};
