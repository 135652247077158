import styled from "styled-components";

export const InfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  margin: 0 10px 3px 0;
  height: 21px;
`;

export const StopName = styled.p<{ isArrived: boolean }>`
  font-family: "Vy Sans", sans-serif;
  font-weight: bold;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${({ isArrived, theme }) =>
    isArrived ? theme.colorTextMain : theme.colorTextSecondary};
`;

export const DelaySpan = styled.span`
  color: ${({ theme }) => theme.colorTextSecondary};
  margin-left: 9px;
  text-decoration: line-through;
`;
