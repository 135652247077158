import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import {
  InfrastructureEventCreatedResponse,
  OpenInfrastructureEventBody,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { UPCOMING_EVENTS_QUERY_KEY } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewButtonAndModal";

export const usePostInfrastructureEvent = (uuid?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: OpenInfrastructureEventBody) =>
      mutationFnPOST<
        InfrastructureEventCreatedResponse,
        OpenInfrastructureEventBody
      >(
        `${getBackendUrl()}/infrastructure-event${uuid ? `/${uuid}/change` : ""}`,
        body,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [UPCOMING_EVENTS_QUERY_KEY] });
    },
  });
};
