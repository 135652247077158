import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { TrainGpsResponse } from "features/CenterContent/shared/types";
import { trainGpsDataToBaseGpsData } from "features/CenterContent/shared/utils";
import { useParams } from "react-router-dom";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { GPSContent } from "../../shared/GPSContent";

export const TrainGPS = () => {
  // @ts-ignore
  const { trainNumber, trainDate } = useParams();

  const { status, data: trainGps } = useQuery({
    queryKey: ["trainGps", trainNumber, trainDate],
    refetchInterval: 60000,
    queryFn: ({ signal }) =>
      queryFnGET<TrainGpsResponse>({
        signal,
        url: `${getBackendUrl()}/trainGps/gpsDataForTrain/NO/${trainDate}/${trainNumber}`,
      }),
  });

  const getGPS = () => {
    switch (status) {
      case "pending":
        return <SkeletonLoader skeletonType="trainmap" />;
      case "success":
        if (trainGps.data.length > 0) {
          return (
            <GPSContent gpsData={trainGpsDataToBaseGpsData(trainGps.data)} />
          );
        }
        return (
          <NoDataMessage reason="Det finnes ikke GPS-data for dette toget" />
        );
      case "error":
      default:
        return (
          <FailureMessage customMessage="Kunne ikke hente GPS-data for dette toget" />
        );
    }
  };

  return getGPS();
};
