import React from "react";
import ReasonCodes from "features/CenterContent/RoleContent/Lokleder/ReasonCodes/ReasonCodes";
import Tilsyn from "features/CenterContent/RoleContent/Lokleder/Tilsyn";
import TrainMap from "features/CenterContent/RoleContent/TrainMap/TrainMap";
import {
  Bottom,
  LandingpageContentGrid,
  Sidebar,
} from "../../shared/MainContentGrid";

const Lokleder = () => (
  <LandingpageContentGrid>
    <TrainMap />
    <Sidebar>
      <ReasonCodes />
    </Sidebar>
    <Bottom>
      <Tilsyn />
    </Bottom>
  </LandingpageContentGrid>
);

export default Lokleder;
