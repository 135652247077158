import { QueryClient } from "@tanstack/react-query";

// The apps TanStack Query client and its global configurations.
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 60000,
      refetchOnWindowFocus: false, // TODO: override the default value until we decide how to intertwine it with the staleDataMonitor
    },
  },
});
