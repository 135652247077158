import {
  CalendarDate,
  getDayOfWeek,
  getLocalTimeZone,
  Time,
  toCalendarDate,
  toCalendarDateTime,
  toTime,
  toZoned,
  ZonedDateTime,
} from "@internationalized/date";
import { Rule } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { WeekRepeat } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/PeriodicDuration";
import { PeriodRequestDuration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { PeriodicDuration } from "shared/types/infrastructureResponse";

export const getDatesFromWeekdays = (
  startDate: CalendarDate,
  endDate: CalendarDate,
  weekdays: number[],
  repeat: WeekRepeat,
) => {
  // finner ukedagnummeret til startDate
  const startDay = getDayOfWeek(startDate, "nb-NO");

  const dates: CalendarDate[] = [];
  weekdays.forEach((weekday) => {
    // Finner første dato som matcher ønsket ukedag
    let currentDateMatchingWeekday = startDate.add({
      days: (weekday - startDay + 7) % 7,
    });

    // så lenge sluttdatoen ikke er passert, legg til currentDateMatchingWeekday og hopp en/to uke(r) fram
    while (currentDateMatchingWeekday.compare(endDate) <= 0) {
      dates.push(currentDateMatchingWeekday);
      currentDateMatchingWeekday = currentDateMatchingWeekday.add({
        days: repeat === WeekRepeat.WEEKLY ? 7 : 14,
      });
    }
  });
  return dates;
};

const createPeriods = (
  id: string,
  dates: CalendarDate[],
  startTime: Time,
  endTime: Time,
) => {
  const periods: { id: string; fromTime: string; toTime: string }[] = [];
  dates.forEach((date) => {
    const isPassingMidnight = endTime.compare(startTime) < 0;
    const fromDateTime = toCalendarDateTime(date, startTime);
    const toDateTime = isPassingMidnight
      ? toCalendarDateTime(date.add({ days: 1 }), endTime)
      : toCalendarDateTime(date, endTime);
    periods.push({
      id,
      fromTime: toZoned(fromDateTime, getLocalTimeZone()).toString(),
      toTime: toZoned(toDateTime, getLocalTimeZone()).toString(),
    });
  });
  return periods;
};

const getRuleFromPeriodWithId = (
  period: { id: string; fromTime: ZonedDateTime; toTime: ZonedDateTime }[],
): Rule => {
  const sortedPeriods = period.sort((a, b) => a.fromTime.compare(b.fromTime));
  const startDateTime = sortedPeriods[0].fromTime;
  const endDateTime = sortedPeriods[sortedPeriods.length - 1].toTime;

  const daysOfWeek: number[] = [];
  sortedPeriods.forEach((p) => {
    daysOfWeek.push(getDayOfWeek(p.fromTime, "nb-NO"));
  });

  const uniqueDaysOfWeek = daysOfWeek.filter(
    (day, index, array) => array.indexOf(day) === index,
  );

  const getRepeatInterval = () => {
    const startDay = getDayOfWeek(startDateTime, "nb-NO");
    const nextOfSameWeekday = sortedPeriods.find((p, index) => {
      if (index > 0) {
        return getDayOfWeek(p.fromTime, "nb-NO") === startDay;
      }
      return false;
    });
    const nextStartDateTime = nextOfSameWeekday?.fromTime;

    const plusForteenDays = startDateTime.add({ days: 14 });
    const repeat =
      nextStartDateTime && plusForteenDays.compare(nextStartDateTime) === 0
        ? WeekRepeat.BIWEEKLY
        : WeekRepeat.WEEKLY;

    return repeat;
  };
  return {
    id: period[0].id,
    startDateTime: toCalendarDateTime(startDateTime),
    endDateTime: toCalendarDateTime(endDateTime),
    days: uniqueDaysOfWeek,
    repeat: getRepeatInterval(),
  };
};

const getRuleFromPeriodWithoutId = (
  startDateTime: ZonedDateTime,
  endDateTime: ZonedDateTime,
): Rule => ({
  id: Math.random().toString(16),
  startDateTime: toCalendarDateTime(startDateTime),
  endDateTime: toCalendarDateTime(endDateTime),
  days: [getDayOfWeek(startDateTime, "nb-NO")],
  repeat: WeekRepeat.WEEKLY,
});

export const createRulesFromPeriodicDuration = (duration: PeriodicDuration) => {
  const rulesFromPeriodsWithoutId: Rule[] = duration.periods
    .filter((period) => period.id === null)
    .map((p) => getRuleFromPeriodWithoutId(p.fromTime, p.toTime));
  const periodsGroupedById: Map<
    string,
    { id: string; fromTime: ZonedDateTime; toTime: ZonedDateTime }[]
  > = new Map();

  duration.periods.forEach((period) => {
    if (period.id !== null) {
      if (periodsGroupedById.has(period.id)) {
        const prev = periodsGroupedById.get(period.id)!;
        periodsGroupedById.set(period.id, [
          ...prev,
          { ...period, id: period.id! },
        ]);
      } else periodsGroupedById.set(period.id, [{ ...period, id: period.id! }]);
    }
  });

  const rulesFromPeriodsWithId: Rule[] = [];

  periodsGroupedById.forEach((periodList) => {
    const rule = getRuleFromPeriodWithId(periodList);
    rulesFromPeriodsWithId.push(rule);
  });

  return [...rulesFromPeriodsWithoutId, ...rulesFromPeriodsWithId];
};

export const ruleToPeriod = (rule: Rule): PeriodRequestDuration[] => {
  const datesFromWeekdays = getDatesFromWeekdays(
    toCalendarDate(rule.startDateTime),
    toCalendarDate(rule.endDateTime),
    rule.days,
    rule.repeat,
  );
  const period = createPeriods(
    rule.id,
    datesFromWeekdays,
    toTime(rule.startDateTime),
    toTime(rule.endDateTime),
  );

  return period;
};
