import { z } from "zod";

export const ReasonCodeItemSchema = z.object({
  id: z.string(),
  trainNumber: z.string(),
  trainDate: z.string(),
  stationName: z.string(),
  registeredAt: z.string(),
  employeeReasonCode: z.string().nullable(),
  employeeNumber: z.string().nullable(),
  employeeComment: z.string().nullable(),
  employeeRole: z
    .union([z.literal("DRIVER"), z.literal("CONDUCTOR"), z.string()])
    .nullable(),
  delay: z.number().nullable(),
  dropsReasonCode: z.string().nullable(),
  dropsComment: z.string().nullable(),
  regionReasonCode: z.string().nullable(),
  regionComment: z.string().nullable(),
  tiosReasonCode: z.string().nullable(),
  tiosComment: z.string().nullable(),
});

export type ReasonCodeItem = z.infer<typeof ReasonCodeItemSchema>;

export const ReasonCodesSchema = z.array(ReasonCodeItemSchema);

export type ReasonCodes = z.infer<typeof ReasonCodesSchema>;

export const SetReasonCodeCommentPayloadSchema = z.object({
  code: z.string(),
  comment: z.string(),
  referenceId: z.string(),
  field: z.string(),
});

export type SetReasonCodeCommentPayload = z.infer<
  typeof SetReasonCodeCommentPayloadSchema
>;
