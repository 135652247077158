import { z } from "zod";

export const GpsSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
});

export type Gps = z.infer<typeof GpsSchema>;

export const BaseTrainGpsDataSchema = z.object({
  trainId: z.string().nullable(),
  resourceId: z.string(),
  timestamp: z.string().datetime(),
  gps: GpsSchema,
});

export type TrainGpsData = z.infer<typeof BaseTrainGpsDataSchema> & {
  closestNeighbor: TrainGpsData | null;
};

export const TrainGpsDataSchema: z.ZodType<TrainGpsData> =
  BaseTrainGpsDataSchema.extend({
    closestNeighbor: z.lazy(() => TrainGpsDataSchema).nullable(),
  });

export const TrainGpsResponseSchema = z.object({
  data: z.array(TrainGpsDataSchema),
});

export type TrainGpsResponse = z.infer<typeof TrainGpsResponseSchema>;

const LocationSchema = z.object({
  timestamp: z.string().datetime(),
  gps: GpsSchema,
});

export const BaseBusGpsDataSchema = z.object({
  workshiftId: z.string().nullable(),
  trainNumber: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  timestamp: z.string().datetime(),
  gps: GpsSchema,
  path: z.array(LocationSchema).nullable(),
});

export type BusGpsData = z.infer<typeof BaseBusGpsDataSchema> & {
  closestNeighbor: BusGpsData | null;
};

export const BusGpsDataSchema: z.ZodType<BusGpsData> =
  BaseBusGpsDataSchema.extend({
    closestNeighbor: z.lazy(() => BusGpsDataSchema).nullable(),
  });

export const BusGpsResponseSchema = z.object({
  data: z.array(BusGpsDataSchema),
});

export type BusGpsResponse = z.infer<typeof BusGpsResponseSchema>;

export const BaseGpsDataSchema = z.object({
  id: z.string(),
  phoneNumber: z.string().nullable(),
  timestamp: z.string().datetime(),
  gps: GpsSchema,
  path: z.array(LocationSchema).nullable(),
});

export type BaseGpsData = z.infer<typeof BaseGpsDataSchema> & {
  closestNeighbor: BaseGpsData | null;
};

export const BaseGpsDataSchemaExtended: z.ZodType<BaseGpsData> =
  BaseGpsDataSchema.extend({
    closestNeighbor: z.lazy(() => BaseGpsDataSchemaExtended).nullable(),
  });

export const BaseGpsResponseSchema = z.object({
  data: z.array(BaseGpsDataSchemaExtended),
});

export type BaseGpsResponse = z.infer<typeof BaseGpsResponseSchema>;
