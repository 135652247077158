import { Stop } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { delayThreshold } from "shared/utils/delayUtils";
import { DefaultTheme } from "styled-components";

export const getTrainStopColor = ({
  isLineColor = false,
  isReachedOrInBetween,
  isCancelled,
  delay,
  theme,
}: {
  isLineColor?: boolean;
  isReachedOrInBetween: boolean;
  isCancelled: boolean;
  delay: number;
  theme: DefaultTheme;
}) => {
  if (isCancelled || !isReachedOrInBetween)
    return isLineColor ? theme.colorSeparationLine : theme.colorSeparationLine;

  if (delay >= delayThreshold.major) return theme.colorAlarm;
  if (delay >= delayThreshold.moderate) return theme.colorSecondaryAlarm;
  if (delay >= delayThreshold.minor) return theme.colorWarning;

  return theme.colorTextMain;
};

export const isCancelled = (stop?: Stop) => stop?.cancelled === "CANCELLED";

/*
True if the stop is not cancelled and the train should have passed the stop(later stops have real time passing data),
but there are noe real time passing data for the stop.
* */
export const isStopWithNoPassing = (
  stopIndex: number,
  lastArrivedStopIndex: number,
  stop?: Stop,
) =>
  !isCancelled(stop) &&
  !stop?.isArrived &&
  !stop?.actualDepartureTime &&
  stopIndex < lastArrivedStopIndex;
