import { Text } from "shared/components/typography/TitleStyles";
import unknownVehicle from "shared/icons/unknown-carriage.gif";
import styled from "styled-components";
import { CompositionPosition } from "features/CenterContent/VehicleDetails/TrainDetails/TrainComposition/types";
import { getVehicleImage } from "features/CenterContent/VehicleDetails/TrainDetails/TrainComposition/vehicleImages";
import { PasswordFill18Icon } from "@vygruppen/spor-icon-react";
import { HStack } from "@chakra-ui/react";

const VehicleCompositionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-wrap: none;
`;

const Composition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const sortByDescPosition = (compositions: CompositionPosition[]) =>
  compositions.sort((a, b) => {
    if (!b.position) {
      return -1;
    }

    if (!a.position) {
      return 1;
    }

    return +b.position - +a.position;
  });

type VehicleCompositionProps = {
  compositions: CompositionPosition[];
};

export const VehicleComposition = ({
  compositions,
}: VehicleCompositionProps) => (
  <VehicleCompositionWrapper>
    {sortByDescPosition(compositions).map((comp) => (
      <Composition
        key={`${comp.vehicleID}_${comp.position}_${comp.vehicleType}`}
      >
        <HStack>
          {!comp.closed &&
            (comp.carriageNumber ? (
              <Text>{comp.carriageNumber}</Text>
            ) : (
              <Text>&nbsp;</Text>
            ))}
          {comp.closed && (
            <div
              style={{
                height: "22px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PasswordFill18Icon />
            </div>
          )}
        </HStack>

        <img
          alt=""
          src={getVehicleImage(comp.vehicleType, comp.orientation)}
          style={{ maxWidth: "100%", maxHeight: "100%", flexGrow: 1 }}
          onError={({ target }) => {
            (target as HTMLImageElement).onerror = null;
            (target as HTMLImageElement).src = unknownVehicle;
          }}
        />
        <Text style={{ marginTop: "6px" }}>{comp.vehicleType}</Text>
      </Composition>
    ))}
  </VehicleCompositionWrapper>
);
