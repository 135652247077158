import axios from "axios";
import {
  authorizationHeader,
  registerAxiosAuthInterceptor,
} from "app/Auth/axiosAuthInterceptor";
import { getBackendUrl } from "../common";

axios.defaults.headers.post["Content-Type"] = "application/json";
const dropsHttpClient = axios.create();
const logSource = "dropsHttpClient";

dropsHttpClient.interceptors.request.use((config) => {
  const url = (config.url?.startsWith("/") ? "" : "/") + config.url;
  config.url = `${getBackendUrl()}${url}`;
  config.headers.Authorization = authorizationHeader().Authorization;
  return config;
});

registerAxiosAuthInterceptor(dropsHttpClient, logSource);

export { dropsHttpClient };
