import { useMutation } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { infrastructureParametersSchema } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { getInfrastructureEventBody } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/createRequestUtils";
import {
  OpenInfrastructureEventBody,
  PreviewResponse,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { useEffect } from "react";
import { z } from "zod";

export const usePreview = (
  formState: z.infer<typeof infrastructureParametersSchema>,
) => {
  const {
    mutate: preview,
    data: previewData,
    status: previewStatus,
    reset: resetPreview,
  } = useMutation({
    mutationFn: (body: OpenInfrastructureEventBody) =>
      mutationFnPOST<PreviewResponse, OpenInfrastructureEventBody>(
        `${getBackendUrl()}/infrastructure-event/preview`,
        body,
      ),
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      const parsedForm = infrastructureParametersSchema.safeParse(formState);
      if (parsedForm.success) {
        const body = getInfrastructureEventBody(parsedForm.data);
        if (body) {
          preview(body);
        }
      } else {
        resetPreview();
      }
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [formState]);

  return {
    previewData,
    previewStatus,
  };
};
