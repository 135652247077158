import styled, { css } from "styled-components";

export const MenuContent = styled.div<{ $isOpen?: boolean }>`
  display: grid;
  grid-template-rows: auto 1fr auto;
  background: ${({ theme }) => theme.colorBackgroundTertiary};
  z-index: 49;
  text-align: left;
  width: 367px;
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  border-left: 1px solid ${({ theme }) => theme.colorSeparationLine};
  transition: transform 0.3s ease-in-out;

  transform: ${({ $isOpen }) =>
    $isOpen ? "translateX(0)" : "translate(100%)"};
  color: ${({ theme }) => theme.colorTextMain};
`;

export const Overlay = styled.div<{ $isOpen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 48;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  pointer-events: ${({ $isOpen }) => ($isOpen ? "auto" : "none")};

  ${({ $isOpen }) =>
    $isOpen &&
    `
    body {
      overflow: hidden;
    }
  `}
`;

export const MenuLink = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colorTextMain};
  text-decoration: none;
  transition: color 0.2s linear;
  font-family: "Vy Sans", sans-serif;
  gap: 6px;
  font-size: 1rem;
  line-height: 1.33rem;

  &:hover {
    color: ${({ theme }) => theme.colorHoverItem};

    & svg path {
      fill: ${({ theme }) => theme.colorHoverItem};
    }
  }
`;

export const MenuButton = styled.button`
  display: flex;
  flex-direction: column;
  right: 2rem;
  justify-content: space-around;
  height: 1.6rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 50;
  margin: 1.5rem;

  &:focus {
    outline: none;
  }
`;
export const MenuButtonLine = styled.div<{
  $isOpen?: boolean;
  $isOpenStyling?: string;
}>`
  width: 26px;
  height: 2px;
  background: ${({ theme }) => theme.colorTextMain};

  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;

  ${({ $isOpen, $isOpenStyling }) =>
    $isOpen &&
    css`
      ${$isOpenStyling}
    `}
`;
