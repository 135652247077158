import {
  CheckmarkOutline24Icon,
  CloseOutline24Icon,
  TrainOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Button, HStack } from "@vygruppen/spor-react";
import AffectedTrain from "features/CenterContent/RoleContent/AffectedTrains/AffectedTrain";
import {
  AffectedTrainFilter,
  TrainFilter,
} from "features/CenterContent/RoleContent/AffectedTrains/TrainFilter";
import { TrainRouteDiscrepancyWarning } from "features/CenterContent/RoleContent/AffectedTrains/TrainRouteDiscrepancyWarning";
import { useMinute } from "features/CenterContent/RoleContent/AffectedTrains/useMinute";
import { sortByIncidentArrivalTime } from "features/CenterContent/RoleContent/AffectedTrains/utils/AffectedTrainSorter";
import {
  getFilteredTrains,
  getSessionStorageFilter,
  setSessionStorageFilter,
} from "features/CenterContent/RoleContent/AffectedTrains/utils/filterAffectedTrains";
import { findSeriesDiscrepancies } from "features/CenterContent/RoleContent/AffectedTrains/utils/groupTrainSeries";
import { TrainIdentifierWithIncidentIdString } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { AffectedTrain as AffectedTrainType } from "features/CenterContent/RoleContent/Vaktleder/types";
import { FC, RefObject, useEffect, useMemo, useState } from "react";
import { useInfiniteScroll } from "shared/hooks/useInfiniteScroll";

export type TrainRouteDiscrepancies = {
  [key: string]: string[];
} | null;

type UnhandledTrainsProps = {
  containerRef: RefObject<HTMLElement>;
  trains: AffectedTrainType[];
  selectedTrains: AffectedTrainType[];
  updateSelectedTrains: (train: AffectedTrainType) => void;
  clearSelectedTrains: () => void;
  batchUpdatedSelectedTrains: (affectedTrains: AffectedTrainType[]) => void;
  handleCreateEvents: () => void;
  showFilter: boolean;
  pendingTrains: TrainIdentifierWithIncidentIdString[];
};
const UnhandledTrains: FC<UnhandledTrainsProps> = ({
  containerRef,
  trains,
  selectedTrains,
  updateSelectedTrains,
  clearSelectedTrains,
  batchUpdatedSelectedTrains,
  handleCreateEvents,
  showFilter,
  pendingTrains,
}) => {
  const currentTime = useMinute();

  const [filter, setFilter] = useState<AffectedTrainFilter>(
    getSessionStorageFilter,
  );

  useEffect(() => {
    setSessionStorageFilter(filter);
  }, [filter]);

  const [routeDiscrepancies, setRouteDiscrepancies] =
    useState<TrainRouteDiscrepancies>(null);

  useEffect(() => {
    const discrepancies = findSeriesDiscrepancies(selectedTrains);
    setRouteDiscrepancies(
      Object.keys(discrepancies).length > 0 ? discrepancies : null,
    );
  }, [selectedTrains]);

  // Ensure that only trains from the selected incident is included in batch
  useEffect(() => {
    clearSelectedTrains();
  }, [filter?.incidentId]);

  const hasFilteredTrains =
    filter?.trainSeriesIds && filter.trainSeriesIds.length > 0;
  const hasSelectedTrains = selectedTrains.length > 0;
  const shouldShowFilterButtons = showFilter && hasFilteredTrains;

  const shouldShowRemoveAllButton = hasSelectedTrains;
  const shouldShowChooseAllButton =
    shouldShowFilterButtons && !hasSelectedTrains;

  const filteredTrains = useMemo(
    () => getFilteredTrains(filter, trains),
    [filter, trains],
  );

  const { numberOfItemsToRender } = useInfiniteScroll({
    containerRef,
    initialItemsToRender: 30,
    maxItemsToRender: filteredTrains.length,
  });

  return (
    <>
      {showFilter && (
        <TrainFilter trains={trains} filter={filter} setFilter={setFilter} />
      )}
      <TrainRouteDiscrepancyWarning
        trainRouteDiscrepancies={routeDiscrepancies}
      />
      <HStack id="buttonGroup" p={2} justifyContent="space-between">
        {shouldShowRemoveAllButton && (
          <Button
            size="xs"
            fontWeight="600"
            leftIcon={<CloseOutline24Icon />}
            variant="secondary"
            onClick={clearSelectedTrains}
          >
            Fjern valgte
          </Button>
        )}
        {shouldShowChooseAllButton && (
          <Button
            size="xs"
            leftIcon={<CheckmarkOutline24Icon />}
            variant="tertiary"
            fontWeight="600"
            isDisabled={filteredTrains.length <= 0}
            onClick={() =>
              batchUpdatedSelectedTrains(
                filteredTrains.filter((train) => {
                  // Don't include trains that are currently pending
                  const identifier: TrainIdentifierWithIncidentIdString = `${train.incidentId}-${train.trainId.countryCode}-${train.trainId.identifier}-${train.trainId.nominalDate}`;
                  return !pendingTrains.includes(identifier);
                }),
              )
            }
          >
            Velg alle tog
          </Button>
        )}
        {hasSelectedTrains ? (
          <Button
            size="xs"
            fontWeight="600"
            leftIcon={<TrainOutline24Icon />}
            variant="primary"
            onClick={handleCreateEvents}
            isDisabled={selectedTrains.length <= 0}
          >
            Behandle {selectedTrains.length} tog
          </Button>
        ) : null}
      </HStack>
      <ul>
        {filteredTrains
          .toSorted(sortByIncidentArrivalTime)
          .slice(0, numberOfItemsToRender)
          .map((train) => {
            const identifier: TrainIdentifierWithIncidentIdString = `${train.incidentId}-${train.trainId.countryCode}-${train.trainId.identifier}-${train.trainId.nominalDate}`;

            return (
              <AffectedTrain
                train={train}
                selectedTrains={selectedTrains}
                updateSelectedTrains={updateSelectedTrains}
                key={identifier}
                isPending={pendingTrains.includes(identifier)}
                currentTime={currentTime}
              />
            );
          })}
      </ul>
    </>
  );
};

export default UnhandledTrains;
