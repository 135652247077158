import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import styled, { css, keyframes } from "styled-components";

const oploggPulse = (fromAlarmColor: string, toAlarmColor: string) => keyframes`
    0% {
        background-color: ${fromAlarmColor};
    }
    50% {
        background-color: ${toAlarmColor};    
    }
    
    100% {
        background-color: ${fromAlarmColor};
    }
`;

export const IndicatorWrapper = styled.div`
  grid-row: 1 / ${() => (useFeatureFlag("readReceipts").enabled ? "4" : "3")};
`;

export const LogItem = styled.li<{ $readonly: boolean }>`
  display: grid;
  grid-template-columns: 15px max-content 1fr;
  grid-template-rows: 1fr "24px" ${() =>
      useFeatureFlag("readReceipts").enabled ? "24px" : ""};
  gap: 6px;
  padding: 6px;
  border-radius: 6px;
  max-width: 100%;
  box-sizing: border-box;

  outline: 1px solid transparent;

  transition: background-color 0.2s ease-in-out;

  &:not(:first-child) {
    margin-top: ${() => (useFeatureFlag("readReceipts").enabled ? "0" : "3px")};
  }

  ${({ $readonly, theme }) =>
    !$readonly
      ? css`
          cursor: pointer;

          &:hover {
            background-color: ${theme.colorHoverItem};
          }

          &[data-bookmarked="true"] {
            color: ${theme.colorTextMain};
            background-color: ${theme.colorSelected};
            transition: background-color 0.2s ease-in-out;
          }

          &[data-new="true"] {
            background-color: ${theme.colorAlarmFill};
            outline-color: ${theme.colorAlarmOutline};
            transition: background-color 0.2s ease-in-out;

            animation: ${oploggPulse(
                theme.colorAlarmFill,
                theme.colorAlarmFillPulse,
              )}
              2s ease-in-out 10 forwards;
          }

          &[data-new="true"]:hover {
            background-color: ${theme.colorAlarmFillPulse};
            animation: none;
          }

          &[data-new="true"][data-bookmarked="true"] {
            background-color: ${theme.colorAlarmOutline};
            animation: none;
          }
        `
      : css`
          &[data-new="true"] {
            outline-color: ${theme.colorAlarm};
          }
        `}
`;

export const VerticalLineForHighlightedBox = styled.div`
  width: 1px;
  height: 18px;
  background-color: ${({ theme }) => theme.colorSeparationLine};
  margin-top: -2px;
  margin-left: 13px;
`;

export const VerticalLine = styled.div<{
  color: string;
}>`
  width: 1px;
  height: calc(100% + 12px);
  background-color: ${({ theme }) => theme.colorSeparationLine};
  margin: auto;
`;

export const HorizontalLineContainer = styled.div`
  width: calc(100% - 33px);
  height: 1px;
  margin: 3px 6px 3px 0;
  align-self: flex-end;
`;
