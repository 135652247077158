import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import {
  InfrastructureEventResponse,
  InfrastructureEventType,
  infrastructureEventResponseSchema,
} from "shared/types/infrastructureResponse";

export const eventByUuidQueryKey = "infrastructureEvent";

type Response = (InfrastructureEventType | null)[];

export const useInfrastructureEvent = (uuids: string[]) =>
  useQuery<InfrastructureEventResponse>({
    queryKey: [eventByUuidQueryKey, ...uuids],
    queryFn: async ({ signal }) => {
      const { data } = await axiosClient.get<Response>(
        `${getBackendUrl()}/infrastructure-event?uuids=${uuids.join("&uuids=")}`,
        {
          signal,
        },
      );

      return data.reduce((acc, infrastructureEvent) => {
        if (infrastructureEvent === null) {
          return acc;
        }
        return {
          [infrastructureEvent.infrastructureInformation.uuid]: {
            ...infrastructureEventResponseSchema.parse(infrastructureEvent),
          },
          ...acc,
        };
      }, {}) as InfrastructureEventResponse;
    },
    staleTime: 0,
  });
