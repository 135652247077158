import { LatLngBounds } from "leaflet";
import { FC, useEffect } from "react";
import { useMap } from "react-leaflet";

type FitToBoundsProps = {
  bounds: LatLngBounds;
};

const FitToBounds: FC<FitToBoundsProps> = ({ bounds }) => {
  const map = useMap();

  useEffect(() => {
    map.fitBounds(bounds);
  }, [bounds.toBBoxString()]);

  return null;
};

export default FitToBounds;
