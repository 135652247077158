import { useQuery } from "@tanstack/react-query";
import { ArrowRightOutline24Icon } from "@vygruppen/spor-icon-react";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { VehicleComposition } from "features/CenterContent/VehicleDetails/TrainDetails/TrainComposition/VehicleComposition";
import { TrainCompositionResponse } from "features/CenterContent/VehicleDetails/TrainDetails/TrainComposition/types";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { Text } from "shared/components/typography/TitleStyles";
import styled from "styled-components";

const VehicleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
`;

const OrientationArrow = styled(ArrowRightOutline24Icon)`
  path {
    fill: ${({ theme }) => theme.colorTextMain};
  }
  margin-top: 30px;
  margin-left: 6px;
  flex-shrink: 0;
`;

type TrainCompositionProps = {
  nominalDate: string | undefined;
  trainId: string | undefined;
};

const TrainComposition = ({ nominalDate, trainId }: TrainCompositionProps) => {
  const { status, data } = useQuery({
    queryKey: ["trainComposition", nominalDate, trainId],
    refetchOnWindowFocus: true,
    queryFn: ({ signal }) =>
      queryFnGET<TrainCompositionResponse>({
        signal,
        url: `${getBackendUrl()}/trainComposition/NO/${nominalDate}/${trainId}`,
      }),
  });

  const getTrainComposition = () => {
    switch (status) {
      case "pending":
        return <SkeletonLoader skeletonType="trainComposition" />;
      case "success": {
        if (
          data.trainComposition.length > 0 &&
          data.trainComposition.some(
            (set) => set.compositionPositions.length > 0,
          )
        ) {
          return (
            <>
              {data.trainComposition
                ?.sort((a, b) => +b.position - +a.position)
                .map((vehicleSet) => (
                  <VehicleWrapper
                    key={`${vehicleSet.setId}_${vehicleSet.position}_${vehicleSet.setTypeId}`}
                  >
                    <Text bold>{vehicleSet.setId}</Text>
                    {vehicleSet.compositionPositions.length > 0 && (
                      <VehicleComposition
                        compositions={vehicleSet.compositionPositions}
                      />
                    )}
                  </VehicleWrapper>
                ))}
              <OrientationArrow />
            </>
          );
        }
        return (
          <NoDataMessage reason="Fant ingen informasjon om komposisjonen til dette toget." />
        );
      }
      case "error":
      default:
        return <FailureMessage />;
    }
  };

  return getTrainComposition();
};

export default withErrorBoundary(TrainComposition);
