import { BusOutline24Icon } from "@vygruppen/spor-icon-react";
import { Button, VStack } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { usePatchRequest } from "api/http/hooks";
import { isFailureRequest, isSucessOrFailure } from "api/http/httpRequest";
import React, { FC, useEffect, useState } from "react";
import { Text } from "shared/components/typography/TitleStyles";

import { useMutation, useQuery } from "@tanstack/react-query";
import { mutationFnPOST, queryFnGET } from "api/tanStackQuery/helpers";
import { SelectReserveVehicleDropdown } from "features/CenterContent/VehicleDetails/AlternativeTransportDetails/Itinerary/VehicleReplacement/SelectReserveDropdown";
import {
  getDetailedReplacementLogMessage,
  getLogMessageForReserveVehicle,
} from "features/CenterContent/VehicleDetails/AlternativeTransportDetails/Itinerary/VehicleReplacement/utils";
import { ActionModal } from "shared/components/ActionModal";
import {
  AlternativeTransportLog,
  AlternativeTransportLogReq,
  AlternativeTransportVehicle,
  GetReserveAlternativeTransportResponse,
  ReserveAlternativeTransportBookingRequest,
  StopReference,
  UpdateAlternativeTransportRequest,
  reserveAlternativeTransportBookingRequestSchema,
  updateAlternativeTransportRequestSchema,
} from "shared/types/alternativeTransport";
import { getISODrivingFromToTimes } from "shared/utils/dateTimeUtils";
import styled from "styled-components";

type Props = {
  vehicle: AlternativeTransportVehicle;
  distributionStopReferences: Array<StopReference>;
};

const SelectedReserveContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: inherit;
`;

export const VehicleReplacement: FC<Props> = ({
  vehicle,
  distributionStopReferences,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedReserveId, setSelectedReserveId] = useState<string>("");
  const { from, to } = getISODrivingFromToTimes(
    vehicle.scheduledDepartureTimeOrigin,
  );

  const distributionStopPlaces = distributionStopReferences
    .filter((stop) => !!stop.nsrStopPlace)
    .map((stop) => stop.nsrStopPlace)
    .join(",");

  const searchParams = new URLSearchParams({ from, to, countryCode: "NO" });

  const {
    data: reserveVehicles,
    refetch: fetchReserveVehicles,
    isFetching: isReserveLoading,
    isError: isReserveError,
  } = useQuery({
    queryKey: ["getReserveVehicleReplacement", from, to],
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: ({ signal }) =>
      queryFnGET<GetReserveAlternativeTransportResponse>({
        signal,
        url: `${getBackendUrl()}/alternativeTransports/reserve?${searchParams.toString()}`,
      }),
  });

  const {
    patchRequestStatus: updateVehicleRequestStatus,
    patchRequest: updateVehicleRequest,
    resetStatus: resetUpdateVehicleRequest,
  } = usePatchRequest<any, UpdateAlternativeTransportRequest>(
    `${getBackendUrl()}/alternativeTransports/${vehicle.id}`,
  );

  const {
    patchRequestStatus: bookRequestStatus,
    patchRequest: bookVehicleRequest,
    resetStatus: resetBookVehicleRequest,
  } = usePatchRequest<any, ReserveAlternativeTransportBookingRequest>(
    `${getBackendUrl()}/alternativeTransports/reserve/${selectedReserveId}/book`,
  );

  const {
    mutate: postVehicleLog,
    status: logRequestStatus,
    reset: resetLogRequest,
  } = useMutation({
    mutationKey: ["postVehicleLog", vehicle.id],
    mutationFn: (data: AlternativeTransportLogReq) =>
      mutationFnPOST<AlternativeTransportLog, AlternativeTransportLogReq>(
        `${getBackendUrl()}/alternativeTransportLogs/${vehicle.id}`,
        data,
      ),
  });

  const selectedReserve =
    reserveVehicles?.vehicles.find(
      (reserveVehicle) => reserveVehicle.id === selectedReserveId,
    ) ?? undefined;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedReserve) {
      const payload: UpdateAlternativeTransportRequest = {
        phoneNumberUpdateRequest: {
          phoneNumber: selectedReserve.driverPhoneNumber ?? "",
          distributionStopReferences,
        },
        operatorNameUpdateRequest: {
          operatorName: selectedReserve.operatorName,
          distributionStopReferences,
        },
      };

      bookVehicleRequest(
        reserveAlternativeTransportBookingRequestSchema.parse({
          available: false,
        }),
      );
      updateVehicleRequest(
        updateAlternativeTransportRequestSchema.parse(payload),
      );
    }
  };

  useEffect(() => {
    if (
      selectedReserve &&
      isSucessOrFailure(bookRequestStatus) &&
      isSucessOrFailure(updateVehicleRequestStatus)
    ) {
      const logMessage = getDetailedReplacementLogMessage(
        bookRequestStatus,
        updateVehicleRequestStatus,
        vehicle,
        selectedReserve,
      );

      if (logMessage) {
        postVehicleLog({
          message: logMessage,
          distributionStopPlaces,
          alternativeTransportId: vehicle.id,
        });

        const logMessageForReserveAlternativeTransport =
          getLogMessageForReserveVehicle(vehicle);

        postVehicleLog({
          message: logMessageForReserveAlternativeTransport,
          distributionStopPlaces,
          alternativeTransportId: selectedReserve.id,
        });

        resetUpdateVehicleRequest();
        resetBookVehicleRequest();
      }
    }
  }, [updateVehicleRequestStatus, bookRequestStatus, selectedReserve]);

  const onOpen = () => {
    fetchReserveVehicles();
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
    resetUpdateVehicleRequest();
    resetBookVehicleRequest();
    resetLogRequest();
    setSelectedReserveId("");
  };

  const isOverallError =
    [updateVehicleRequestStatus, bookRequestStatus].some((status) =>
      isFailureRequest(status),
    ) || logRequestStatus === "error";

  return (
    <>
      <Button
        variant="tertiary"
        size="sm"
        leftIcon={<BusOutline24Icon />}
        onClick={onOpen}
        title="Erstatt planlagt tur med ett reservekjøretøy"
      >
        Erstatt med reserve
      </Button>
      {showModal && (
        <ActionModal
          title="Erstatt med reserve"
          actionTitle="Gjennomfør"
          onClose={onClose}
          onSubmit={onSubmit}
          isLoading={logRequestStatus === "pending"}
          isSuccess={logRequestStatus === "success"}
          isError={isOverallError}
          isSubmitDisabled={selectedReserveId.length === 0}
          successMessage="Reserve satt inn"
          failureMessage="Kunne ikke erstatte avgang. Prøv igjen, eller kontakt IT hvis feilen vedvarer."
        >
          <VStack w="100%" alignItems="stretch" gap={4} py={4}>
            <SelectReserveVehicleDropdown
              reserveVehicles={reserveVehicles}
              isReserveLoading={isReserveLoading}
              isReserveError={isReserveError}
              selectedReserveId={selectedReserveId}
              setSelectedReserveId={setSelectedReserveId}
            />
            {selectedReserve && (
              <SelectedReserveContent>
                <Text>{`Leverandør: ${selectedReserve.operatorName}`}</Text>
                <Text>{`Skift: ${selectedReserve.workShiftId ?? ""}`}</Text>
                <Text>{`Telefon: ${
                  selectedReserve.driverPhoneNumber ?? ""
                }`}</Text>
              </SelectedReserveContent>
            )}
          </VStack>
        </ActionModal>
      )}
    </>
  );
};
