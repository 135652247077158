export const ONE_MINUTE = 60 * 1000;
export const THIRTY_SECONDS = 30 * 1000;
export const FIVE_SECONDS = 5 * 1000;
export const TWO_SECONDS = 2 * 1000;

export const withBearerToken = (url: string, token: string) =>
  `${url}?authorization=Bearer ${token}`;

export const verboseReadyState = (readyState: number) => {
  switch (readyState) {
    case WebSocket.CONNECTING:
      return "CONNECTING";
    case WebSocket.OPEN:
      return "OPEN";
    case WebSocket.CLOSING:
      return "CLOSING";
    case WebSocket.CLOSED:
      return "CLOSED";
    default:
      return `Unknown readyState: ${readyState}`;
  }
};
