import {
  SingleTrainFormSchema,
  TrickFormHookGroupedTrainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import {
  BatchSubTypeProps,
  CommonSubTypeProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export type GroupedTrainContext = {
  trainFormPrefix: `\${groupKey}.${number}.trainForm`;
  formContext: ReturnType<
    typeof useFormContext<TrickFormHookGroupedTrainFormSchema>
  >;
};

export type SingleTrainContext = {
  trainFormPrefix: keyof SingleTrainFormSchema;
  formContext: ReturnType<typeof useFormContext<SingleTrainFormSchema>>;
};

export type TrainContext = SingleTrainContext | GroupedTrainContext;

type WrapperProps = {
  Field: FC<CommonTrainInfoFormProps & CommonSubTypeProps & TrainContext>;
};

export const BatchSubfieldWrapper: FC<
  CommonTrainInfoFormProps &
    CommonSubTypeProps &
    BatchSubTypeProps &
    WrapperProps
> = ({ activeSeriesKey, groupIndex, Field, ...props }) => {
  const formContext = useFormContext<TrickFormHookGroupedTrainFormSchema>();
  const trainFormPrefix = `${activeSeriesKey}.${groupIndex}.trainForm` as const;

  return (
    <Field
      formContext={formContext}
      trainFormPrefix={trainFormPrefix}
      {...props}
    />
  );
};

export const SingleSubfieldWrapper: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps & WrapperProps
> = ({ Field, ...props }) => {
  const formContext = useFormContext<SingleTrainFormSchema>();
  const trainFormPrefix = `trainForm` as const;

  return (
    <Field
      formContext={formContext}
      trainFormPrefix={trainFormPrefix}
      {...props}
    />
  );
};
