import styled from "styled-components";

export const CircleIndicator = styled.div<{ $color: string }>`
  height: 10px;
  width: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 100px;
  background-color: ${({ $color }) => $color};
`;
