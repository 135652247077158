import React from "react";
import styled, { useTheme } from "styled-components";
import { Text } from "@vygruppen/spor-react";
import { ArrowIcon } from "../../../../icons/ArrowIcon";
import { Severity, SeverityIcon, severitySubtitleColor } from "../severity";
import { BORDER_RADIUS, COLUMN_GAP } from "../style";

const Title = styled.h3`
  padding: 0;
  margin: 0;
  font-family: "Vy Sans", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.33rem;
  text-align: start;
`;

const Subtitle = styled(Text)`
  padding: 0;
  margin: 0;
  font-family: "Vy Sans", sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.33rem;
  grid-row: 2;
  grid-column: 2;
`;

const ButtonWrapper = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  outline: none;
  width: 100%;
  border-radius: ${BORDER_RADIUS}px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-items: start;
  align-items: center;
  column-gap: ${COLUMN_GAP}px;
  grid-column: 1 / span 2;
  grid-row: 1;

  &:focus {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.__focusBorderColor};
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.__focusBorderColor};
  }
`;

type ExpandableButtonProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  severity: Severity;
  title: string;
  subTitle?: string;
  iconColorOverride?: string;
};

export const ExpandableButton = ({
  isOpen,
  setIsOpen,
  severity,
  title,
  subTitle,
  iconColorOverride,
}: ExpandableButtonProps) => {
  const theme = useTheme();
  return (
    <ButtonWrapper
      type="button"
      aria-expanded={isOpen}
      aria-controls="collapsible_info_message_content"
      onClick={() => setIsOpen(!isOpen)}
    >
      <SeverityIcon severity={severity} fill={iconColorOverride} />
      <Title>{title}</Title>
      <Subtitle variant="xs" color={severitySubtitleColor(severity, theme)}>
        {subTitle}
      </Subtitle>
      <ArrowIcon
        color={severitySubtitleColor(severity, theme)}
        rotate={isOpen ? -180 : 0}
      />
    </ButtonWrapper>
  );
};
