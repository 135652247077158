import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import styled from "styled-components";

export const ResultListWrapper = styled.div`
  ${ObtrusiveScrollCss};
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  border-top: 1px solid ${({ theme }) => theme.colorSeparationLine};
`;
