import {
  Button,
  DateRangePicker,
  FormControl,
  HStack,
  Input,
} from "@vygruppen/spor-react";
import { HistoryOutline24Icon } from "@vygruppen/spor-icon-react";
import { useReasonCodesTableFilter } from "stores/useReasonCodesTableFilter";
import { FC } from "react";
import styled from "styled-components";
import { parseDate } from "@internationalized/date";

const FilterContainer = styled(HStack)`
  div {
    flex: min-content;
  }
  input {
    min-width: 100px;
    max-width: max-content;
  }
`;
const TableFilter: FC = () => {
  const {
    trainFilter,
    codeFilter,
    dateRange,
    setTrainFilter,
    setCodeFilter,
    setDateRange,
    reset,
  } = useReasonCodesTableFilter();
  return (
    <>
      <FilterContainer flexWrap="wrap">
        <FormControl maxWidth="max-content">
          <DateRangePicker
            variant="base"
            startLabel="Fra"
            endLabel="Til"
            aria-label="Dato"
            value={
              dateRange.start && dateRange.end
                ? {
                    start: parseDate(dateRange.start),
                    end: parseDate(dateRange.end),
                  }
                : null
            }
            onChange={(range) => {
              if (range) {
                setDateRange({
                  start: range.start?.toString() ?? undefined,
                  end: range.end?.toString() ?? undefined,
                });
              }
            }}
          />
        </FormControl>

        <Input
          label="Tognr"
          value={trainFilter}
          onChange={(e) => setTrainFilter(e.target.value)}
        />

        <Input
          type="number"
          label="Kode"
          value={codeFilter}
          onChange={(e) => setCodeFilter(e.target.value)}
        />
      </FilterContainer>
      <Button
        size="sm"
        variant="tertiary"
        onClick={reset}
        leftIcon={<HistoryOutline24Icon />}
      >
        Nullstill filter
      </Button>
    </>
  );
};

export default TableFilter;
