import { Td, Tr } from "@chakra-ui/react";
import { Skeleton } from "@vygruppen/spor-react";
import { CustomResponsiveTr } from "features/History/utils/HistoryCustomTableStyle";
import { FC } from "react";

type TableSkeletonProps = {
  numOfRows: number;
  numOfColumns: number;
};

export const TableSkeleton: FC<TableSkeletonProps> = ({
  numOfRows,
  numOfColumns,
}) => (
  <>
    {[...new Array(numOfRows)].map((_row, index) => (
      <Tr key={index}>
        {[...new Array(numOfColumns)].map((_column, i) => (
          <Td key={i}>
            <Skeleton height={3} my={1} width="100%" />
          </Td>
        ))}
      </Tr>
    ))}
  </>
);

export const TableSkeletonHistory: FC<TableSkeletonProps> = ({
  numOfRows,
  numOfColumns,
}) => (
  <>
    {[...new Array(numOfRows)].map((_row, index) => (
      <CustomResponsiveTr key={index}>
        {[...new Array(numOfColumns)].map((_column, i) => (
          <Td className={i === 3 ? "DontShowIfSmallWidth" : ""} key={i}>
            <Skeleton height={4} my={1} width="70%" />
          </Td>
        ))}
      </CustomResponsiveTr>
    ))}
  </>
);
