import { GenericType } from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { z } from "zod";

export const isCustomInputType = (inputType: string | undefined) =>
  inputType === "CUSTOM" || inputType === "TRAIN_GENERAL";

export const reasonErrorMessage =
  "Du må velge årsak for å kunne opprette hendelsen";

export const distributionErrorMessage =
  "Du må fylle ut en beskrivelse av hendelsen";

export const reasonInput = z
  .object({
    type: z.string().min(1, reasonErrorMessage),
    description: z
      .object({
        NOB: z.string(),
        ENG: z.string(),
      })
      .optional(),
  })
  .superRefine((val, ctx) => {
    if (!isCustomInputType(val.type)) {
      return true;
    }

    const NOB_length = val.description?.NOB.length ?? 0;
    const ENG_length = val.description?.ENG.length ?? 0;

    if (NOB_length === 0) {
      ctx.addIssue({
        type: "string",
        code: "too_small",
        path: ["description", "NOB"],
        message: distributionErrorMessage,
        minimum: 1,
        inclusive: true,
      });
    }

    if (ENG_length === 0) {
      ctx.addIssue({
        type: "string",
        code: "too_small",
        path: ["description", "ENG"],
        message: distributionErrorMessage,
        minimum: 1,
        inclusive: true,
      });
    }

    return NOB_length > 0 && ENG_length > 0;
  });

export const optionalInputWithDescription = z
  .object({
    type: z.string(),
    description: z
      .object({
        NOB: z.string(),
        ENG: z.string(),
      })
      .optional(),
  })
  .refine((val) => {
    if (isCustomInputType(val.type)) {
      return (
        (val.description?.NOB?.length ?? 0) >= 1 &&
        (val.description?.ENG?.length ?? 0) >= 1
      );
    }
    return true;
  });

export const distributionInput = z.object({
  // thusanarul: No need for `message` in contextTypes because default value is set in the form that is not visible in the UI
  contextTypes: z.array(z.string()).min(1),
  texts: z.object({
    NOB: z.string().min(1, { message: distributionErrorMessage }),
    ENG: z.string().min(1, { message: distributionErrorMessage }),
  }),
});

export const dropsLogTextInput = z.object({
  enabled: z.boolean(),
  texts: z.object({
    NOB: z.string(),
  }),
});

/*
 * Filter out irrelevant or non-supported reasons.
 * Sort the remaining reasons alphabetically.
 * */
export function sortAndFilterReasons(
  reasons: GenericType[],
  filterTag: "TRAIN" | "INFRASTRUCTURE",
) {
  return reasons
    .filter((r) => r.type !== "OTHER" && r.tags.includes(filterTag))
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
}

const plannedEventActions = [
  "PLANNED_ALTRANS_NOT_UPDATED_LONG_DISTANCE",
  "PLANNED_ALTRANS_NOT_UPDATED_LOCAL",
  "PLANNED_ALTRANS_UPDATED",
];

export const filterActions = (
  actions: GenericType[],
  isPlanned: boolean = false,
) =>
  actions.filter((action) =>
    isPlanned
      ? plannedEventActions.includes(action.type) ||
        isCustomInputType(action.type)
      : !plannedEventActions.includes(action.type),
  );
