import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
} from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST, queryFnGET } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import constate from "constate";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useDropsWebsocket } from "websocket/drops/DropsWebsocketContext";
import { OploggForm, TodoForm } from "../formSchema";
import { DropsLogEntry, DropsLogResponse } from "./OploggElement/types";
import { upsertPages } from "./OploggElement/utils";

export const dropsLogQueryKey = (selectedDateTime: string | null) => [
  "dropsLogV2",
  selectedDateTime,
];

type ReadReceiptRequest = {
  entryUuid: string;
  role: string;
};

function useOplogg() {
  const [notifications, setNotifications] = useState(0);
  const [selectedDateTime, setSelectedDateTime] = useState<string | null>(null);
  const { message } = useDropsWebsocket("useDropsLog", ["DROPS_LOG_V2"]);
  const [showRoles, setShowRoles] = useState(false);
  const [showVehicleSets, setShowVehicleSets] = useState(false);

  const upsertMessageToPages = (newMessage: DropsLogEntry) => {
    queryClient.setQueryData<InfiniteData<DropsLogResponse> | undefined>(
      dropsLogQueryKey(null),
      (prev) =>
        prev && {
          ...prev,
          pages: upsertPages(prev.pages, newMessage),
        },
    );
  };

  const { mutate: post, status: postStatus } = useMutation({
    mutationFn: (data: OploggForm) =>
      mutationFnPOST<DropsLogEntry | null, OploggForm>(
        `${getBackendUrl()}/drops-log-v2/logs`,
        data,
      ),
    onSuccess: (entry) => {
      if (entry) upsertMessageToPages(entry);
    },
  });

  const { mutate: postReceipt, status: postReceiptStatus } = useMutation({
    mutationFn: (data: ReadReceiptRequest) =>
      mutationFnPOST<DropsLogEntry, ReadReceiptRequest>(
        `${getBackendUrl()}/drops-log-v2/logs/read`,
        data,
      ),
    onSuccess: (entry) => {
      if (entry) upsertMessageToPages(entry);
    },
  });

  const { mutate: postToDo } = useMutation({
    mutationFn: (data: TodoForm) =>
      mutationFnPOST<undefined, TodoForm>(`${getBackendUrl()}/todo`, data),
  });

  const PAGINATION_LIMIT = selectedDateTime ? 5000 : 20; // if selectedDateTime is set, we want to fetch more logs

  const TIME_INTERVAL_HOURS = 35 / 60;
  const HOUR_MULTIPLIER = 60 * 60 * 1000;
  // START/END_TIME_MODIFIER += 1 * HOUR_MULTIPLIER might be necessary during summertime
  const START_TIME_MODIFIER = TIME_INTERVAL_HOURS * HOUR_MULTIPLIER;
  const END_TIME_MODIFIER = -TIME_INTERVAL_HOURS * HOUR_MULTIPLIER;

  // Set endDateTime to the end of the day if selectedDateTime is present
  const startDateTime = selectedDateTime
    ? new Date(new Date(selectedDateTime).getTime() + START_TIME_MODIFIER)
    : null;
  const startDateFormatted = startDateTime
    ? format(startDateTime, "yyyy-MM-dd'T'HH:mm:ss")
    : null;
  const endDateTime = selectedDateTime
    ? new Date(new Date(selectedDateTime).getTime() + END_TIME_MODIFIER)
    : null;
  const endDateFormatted = endDateTime
    ? format(endDateTime, "yyyy-MM-dd'T'HH:mm:ss")
    : null;

  const { data, hasNextPage, status, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: dropsLogQueryKey(selectedDateTime),
      initialPageParam: 0,
      queryFn: ({ signal, pageParam }) =>
        queryFnGET<DropsLogResponse>({
          signal,
          url: `${getBackendUrl()}/drops-log-v2/logs?pageSize=${PAGINATION_LIMIT}${
            pageParam > 0 ? `&pageOffset=${pageParam}` : ""
          }${selectedDateTime ? `&minDateTime=${startDateFormatted}` : ""}${
            endDateTime ? `&maxDateTime=${endDateFormatted}` : ""
          }`,
        }),
      getNextPageParam: (lastPage) =>
        lastPage.hasNext ? lastPage.lastSeen : null,
      refetchOnWindowFocus: true,
    });

  useEffect(() => {
    if (
      message.status === "received" &&
      message.data.topic === "DROPS_LOG_V2"
    ) {
      setNotifications(notifications + 1);
      upsertMessageToPages(message.data.message);
    }
  }, [message]);
  return {
    post,
    postStatus,
    postToDo,
    postReceipt,
    postReceiptStatus,
    setSelectedDateTime,
    data,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    notifications,
    setNotifications,
    showRoles,
    setShowRoles,
    showVehicleSets,
    setShowVehicleSets,
  };
}

const [
  OploggProvider,
  usePost,
  usePostStatus,
  usePostTodo,
  usePostReceipt,
  usePostReceiptStatus,
  useDate,
  useData,
  useStatus,
  useHasNextPage,
  useIsFetchingNextPage,
  useFetchNextPage,
  useNotifications,
  useSetNotifications,
  useShowRoles,
  useSetShowRoles,
  useShowVehicleSets,
  useSetShowVehicleSets,
] = constate(
  useOplogg,
  (value) => value.post,
  (value) => value.postStatus,
  (value) => value.postToDo,
  (value) => value.postReceipt,
  (value) => value.postReceiptStatus,
  (value) => value.setSelectedDateTime,
  (value) => value.data,
  (value) => value.status,
  (value) => value.hasNextPage,
  (value) => value.isFetchingNextPage,
  (value) => value.fetchNextPage,
  (value) => value.notifications,
  (value) => value.setNotifications,
  (value) => value.showRoles,
  (value) => value.setShowRoles,
  (value) => value.showVehicleSets,
  (value) => value.setShowVehicleSets,
);

export {
  OploggProvider,
  useData,
  useDate,
  useFetchNextPage,
  useHasNextPage,
  useIsFetchingNextPage,
  useNotifications,
  usePost,
  usePostReceipt,
  usePostReceiptStatus,
  usePostStatus,
  usePostTodo,
  useSetNotifications,
  useSetShowRoles,
  useSetShowVehicleSets,
  useShowRoles,
  useShowVehicleSets,
  useStatus,
};
