import {
  AddOutline24Icon,
  EditOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Button } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { usePatchRequest } from "api/http/hooks";
import { truthy } from "features/CenterContent/VehicleDetails/shared/utils";
import React, { FC, useState } from "react";
import { ActionModal } from "shared/components/ActionModal";
import { CallButton } from "shared/components/buttons/CallButton";
import { InputField } from "shared/components/forms/Inputfield";
import { RadioButton } from "shared/components/forms/RadioButton";
import { Text } from "shared/components/typography/TitleStyles";
import { useTypedForm } from "shared/hooks/useTypedForm";
import {
  PhoneNumberUpdateRequest,
  UpdateMultipleAlternativeTransportRequest,
  WorkShiftPlan,
} from "shared/types/alternativeTransport";
import styled from "styled-components";

type RadioOptions = "SINGLE_TRIP" | "WHOLE_SHIFT";

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

// Regex er hentet fra
// https://github.com/validatorjs/validator.js/blob/master/src/lib/isMobilePhone.js
const phoneRegexes: RegExp[] = [
  /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
  /^(\+?47)?[49]\d{7}$/,
  /^(\+?46|0)[\s-]?7[\s-]?[02369]([\s-]?\d){7}$/,
  /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
];

export const isValidPhoneNumber = (
  phoneNumber: string,
  errorMessage: string,
) =>
  phoneRegexes.some((regex) => regex.test(phoneNumber)) || phoneNumber === ""
    ? undefined
    : errorMessage;

type Props = {
  id: string;
  phoneNumber: string | null;
  workShift?: WorkShiftPlan | null;
};

export const VehiclePhoneNumber: FC<Props> = ({
  id,
  workShift,
  phoneNumber,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRadio, setSelectedRadio] =
    useState<RadioOptions>("SINGLE_TRIP");
  const hasPhoneNumber = phoneNumber !== null && phoneNumber !== "";

  const currentTrip = workShift?.workShiftTrips.find(
    (trip) => trip.vehicleUuid === id,
  );
  const firstEditTrip = currentTrip ?? workShift?.workShiftTrips.at(0);
  const selectedWorkShiftTrips =
    selectedRadio === "SINGLE_TRIP"
      ? [currentTrip].filter(truthy)
      : workShift?.workShiftTrips.filter(
          (trip) =>
            trip.scheduledDepartureTimeOrigin &&
            firstEditTrip?.scheduledDepartureTimeOrigin &&
            trip.scheduledDepartureTimeOrigin >=
              firstEditTrip?.scheduledDepartureTimeOrigin,
        );
  const selectedVehicleIds =
    selectedWorkShiftTrips?.map((trip) => trip.vehicleUuid) ?? [];
  const selectedDistributionStopReferences =
    selectedWorkShiftTrips
      ?.flatMap((trip) => trip.stopReferences)
      .filter(truthy) ?? [];

  const { patchRequestStatus, patchRequest, resetStatus } = usePatchRequest<
    any,
    UpdateMultipleAlternativeTransportRequest
  >(`${getBackendUrl()}/alternativeTransports`);

  const {
    values,
    handleChange,
    reset: resetForm,
    startValidation,
    errors: formErrors,
  } = useTypedForm<PhoneNumberUpdateRequest>(
    {
      phoneNumber: phoneNumber ? phoneNumber : "",
      distributionStopReferences: selectedDistributionStopReferences,
    },
    (formValues) => ({
      phoneNumber: isValidPhoneNumber(
        formValues.phoneNumber.replaceAll(" ", ""),
        `Gjenkjenner ikke formatet på nummeret. Er du sikker så klikk ${
          hasPhoneNumber ? "fullfør" : "opprett"
        }.`,
      ),
    }),
  );

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload: UpdateMultipleAlternativeTransportRequest = {
      uuids: selectedRadio === "SINGLE_TRIP" ? [id] : selectedVehicleIds,
      updateRequest: {
        phoneNumberUpdateRequest: {
          ...values,
          phoneNumber: values.phoneNumber.replaceAll(" ", ""),
        },
      },
    };
    patchRequest(payload);
  };

  const onClose = () => {
    setShowModal(false);
    resetForm();
    resetStatus();
  };

  /*
    This is a workaround. If we send in the plus to Puzzel, it is stripped away
    before the 18740 prefix is added. This works fine if you're an admin, but
    not for normal DROPS users. But if we replace the + with 00, it is not stripped,
    and with the 00 it works just fine. Don't ask me why.
   
    These formats work for everyone:
    18740 0047 12345678 <- Result from what we send in with the workaround
    18740 12345678
    0047 12345678
    12345678

    This format only works for admins:
    18740 47 12345678 <- Result from what we send in without the workaround
  */
  const phoneNumberWithDigitsOnly = phoneNumber
    ? phoneNumber.replace("+", "00")
    : null;

  return (
    <>
      {hasPhoneNumber && (
        <CallButton phoneNumber={phoneNumberWithDigitsOnly!} size="sm" />
      )}
      <Button
        variant="tertiary"
        size="sm"
        onClick={() => setShowModal(true)}
        title={
          hasPhoneNumber
            ? "Endre telefonnummer for kjøretøyet"
            : "Legg inn telefonnummer for kjøretøyet"
        }
        leftIcon={hasPhoneNumber ? <EditOutline24Icon /> : <AddOutline24Icon />}
        aria-label={
          hasPhoneNumber
            ? "Endre telefonnummer for kjøretøyet"
            : "Legg inn telefonnummer for kjøretøyet"
        }
      >
        {hasPhoneNumber ? "Rediger" : "Legg inn tlf.nr"}
      </Button>
      {showModal && (
        <ActionModal
          title={
            hasPhoneNumber ? "Endre telefonnummer" : "Legg inn telefonnummer"
          }
          actionTitle={hasPhoneNumber ? "Fullfør" : "Opprett"}
          onClose={onClose}
          onSubmit={onSubmit}
          isLoading={patchRequestStatus.status === "loading"}
          isSuccess={patchRequestStatus.status === "success"}
          isError={patchRequestStatus.status === "failure"}
          successMessage={
            hasPhoneNumber
              ? "Endringen av telefonnummer var vellykket."
              : "Opprettelsen av telefonnummer var vellykket."
          }
          failureMessage={`Kunne ikke ${
            hasPhoneNumber ? "endre" : "opprette"
          } telefonnummer. Prøv igjen, eller kontakt IT hvis feilen vedvarer.`}
        >
          <InputField
            style={{
              width: "240px",
            }}
            placeholder="Skriv inn telefonnummer"
            name="phoneNumber"
            value={values.phoneNumber}
            aria-labelledby="modal-heading-title"
            onChange={(e) => handleChange({ phoneNumber: e.target.value })}
            onBlur={() => startValidation()}
            aria-invalid={formErrors.phoneNumber !== undefined}
            aria-errormessage="phoneNumber-error-message"
          />
          {formErrors.phoneNumber && (
            <Text id="phoneNumber-error-message" secondary>
              {formErrors.phoneNumber}
            </Text>
          )}
          <RadioGroup role="radiogroup" style={{ paddingTop: "12px" }}>
            <RadioButton
              name="edit-type"
              value="SINGLE_TRIP"
              checked={selectedRadio === "SINGLE_TRIP"}
              onChange={() => setSelectedRadio("SINGLE_TRIP")}
            >
              Bare denne turen
            </RadioButton>
            <RadioButton
              name="edit-type"
              value="WHOLE_SHIFT"
              checked={selectedRadio === "WHOLE_SHIFT"}
              onChange={() => setSelectedRadio("WHOLE_SHIFT")}
            >
              {currentTrip ? "Denne turen og ut skiftet" : "Hele skiftet"}
            </RadioButton>
          </RadioGroup>
          {selectedRadio === "WHOLE_SHIFT" && (
            // <TextWrapper>
            <>
              <Text style={{ textAlign: "center" }}>
                Dette vil endre telefonnummer til {phoneNumber} på{" "}
                {currentTrip ? "denne og resterende" : "alle"} turer tilhørende
                skift {workShift?.shiftId}.
              </Text>
              <Text>Er du sikker på at du vil gå videre?</Text>
            </>
            // </TextWrapper>
          )}
        </ActionModal>
      )}
    </>
  );
};
