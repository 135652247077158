import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import {
  VehicleAlertResponse,
  VehicleAlertResponseSchema,
} from "features/CenterContent/shared/TilsynAlerts/schema";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import TilsynAlerts from "features/CenterContent/shared/TilsynAlerts/TilsynAlerts";

const Tilsyn = () => {
  const { data, isPending, isError, isSuccess } = useQuery({
    queryKey: ["getImportantActiveVehicleAlerts"],
    refetchInterval: 60000,
    queryFn: ({ signal }) =>
      axiosClient
        .get<VehicleAlertResponse>(
          `${getBackendUrl()}/vehicle-alerts?onlyImportant=true`,
          {
            signal,
          },
        )
        .then((res) => VehicleAlertResponseSchema.parse(res.data)),
  });

  if (isError) {
    return <FailureMessage />;
  }

  return (
    <TilsynAlerts
      alerts={data?.alerts}
      isLoading={isPending}
      isSuccess={isSuccess}
      fullSize
    />
  );
};

export default withErrorBoundary(Tilsyn);
