import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@vygruppen/spor-react";
import { formatDayDateString } from "shared/utils/datetime";
import styled from "styled-components";
import { DayPlanTrackingType } from "./types";

const ModalList = styled.dl`
  text-align: left;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
`;

const ModalText = styled.dd`
  float: left;
  margin-bottom: 12px;
  width: 50%;
  padding: 0;
  color: ${({ theme }) => theme.colorTextMain};
`;

const ModalLabel = styled.dt`
  float: left;
  width: 50%;
  margin: 0;
  padding: 0;
  font-weight: bolder;
  color: ${({ theme }) => theme.colorTextMain};
`;

interface IDayPlanTrackingModal {
  showModal: boolean;
  closeModal: () => void;
  dayPlanTracking: DayPlanTrackingType;
}

const DayPlanTrackingModal = ({
  showModal,
  closeModal,
  dayPlanTracking,
}: IDayPlanTrackingModal) => (
  <Modal isOpen={showModal} onClose={closeModal}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        {dayPlanTracking.fullName ?? "Detaljer for Tjenestevarsling"}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <ModalList>
          <ModalLabel>TPO WorkerId</ModalLabel>
          <ModalText>{dayPlanTracking.employeeId}</ModalText>
          <ModalLabel>Rolle</ModalLabel>
          <ModalText>
            {/* eslint-disable-next-line no-nested-ternary */}
            {dayPlanTracking.isConductor
              ? "Konduktør"
              : dayPlanTracking.isDriver
                ? "Lokfører"
                : "\u00a0"}
          </ModalText>
          <ModalLabel>Selskap</ModalLabel>
          <ModalText>{dayPlanTracking.companyCode}</ModalText>
          <ModalLabel>GSM-R</ModalLabel>
          <ModalText>{dayPlanTracking.phoneNumber ?? "\u00a0"}</ModalText>
          <ModalLabel>Dagsverknummer</ModalLabel>
          <ModalText>{dayPlanTracking.dutyId}</ModalText>
          <ModalLabel>Starttidspunkt</ModalLabel>
          <ModalText>
            {formatDayDateString(dayPlanTracking.dutyStart)}
          </ModalText>
          <ModalLabel>Sluttidspunkt</ModalLabel>
          <ModalText>{formatDayDateString(dayPlanTracking.dutyEnd)}</ModalText>
          <ModalLabel>Sist oppdatert</ModalLabel>
          <ModalText>
            {formatDayDateString(dayPlanTracking.lastUpdatedAt)}
          </ModalText>
          <ModalLabel>Mottatt i app</ModalLabel>
          <ModalText>
            {formatDayDateString(dayPlanTracking.fetchedAt)}
          </ModalText>
          <ModalLabel>Bekreftet i app</ModalLabel>
          <ModalText>
            {formatDayDateString(dayPlanTracking.acknowledgedAt)}
          </ModalText>
          <ModalLabel>Bekreftet av Drops</ModalLabel>
          <ModalText>
            {formatDayDateString(dayPlanTracking.acknowledgedManuallyAt)}
          </ModalText>
          <ModalLabel>Bekreftet av</ModalLabel>
          <ModalText>
            {dayPlanTracking.acknowledgedManuallyBy ?? "\u00a0"}
          </ModalText>
        </ModalList>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export { DayPlanTrackingModal };
