import { ReasonCodeItem } from "features/CenterContent/RoleContent/Lokleder/ReasonCodes/schemas";
import { SortOptions } from "features/CenterContent/RoleContent/Lokleder/ReasonCodes/TableContent/TableContent";

export const sortRCList = (sortOptions: SortOptions) => {
  const field = sortOptions.sortColumn;

  if (sortOptions.sortDirection === "DESC") {
    return (a: ReasonCodeItem, b: ReasonCodeItem) =>
      (b[field] || "")
        .toString()
        .localeCompare((a[field] || "").toString(), "nb");
  }
  return (a: ReasonCodeItem, b: ReasonCodeItem) =>
    (a[field] || "")
      .toString()
      .localeCompare((b[field] || "").toString(), "nb");
};

export const filterRCList = (
  reasonCode: ReasonCodeItem,
  trainFilter: string,
  codeFilter: string,
) => {
  const codeMatch =
    reasonCode.tiosReasonCode?.startsWith(codeFilter) ||
    reasonCode.employeeReasonCode?.startsWith(codeFilter) ||
    reasonCode.dropsReasonCode?.startsWith(codeFilter);

  if (trainFilter.length >= 2 && codeFilter.length > 0) {
    return reasonCode.trainNumber.startsWith(trainFilter) && codeMatch;
  }

  if (trainFilter.length >= 2) {
    return reasonCode.trainNumber.startsWith(trainFilter);
  }

  if (codeFilter.length > 0) {
    return codeMatch;
  }
  return true;
};
