import {
  ShareOutline24Icon,
  PhoneOutline24Icon,
  QuestionOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Button } from "@vygruppen/spor-react";
import { log, LogLevel } from "api/cloudWatch";
import { useApiAction } from "api/drops/useApiAction";
import { sessionCommandObject } from "api/puzzel/puzzel";
import React, { ReactElement, useEffect } from "react";
import { usePuzzelExtendedStateInformation } from "../provider/contexts/ListenForCallStatusContext";
import { useUserValidationState } from "../provider/contexts/UserValidationContext";
import { CallPauseIconStyled } from "../PuzzelStyle";
import { useSetTransfered } from "./CallControl/views/CallInSession/CallTransfer/TransferProvider";
import { SessionCommands } from "./types";

type Props = {
  command: SessionCommands;
  requestId: string;
  destinationPhoneNumber?: string;
  disabled?: boolean;
  destinationAgentId?: string;
  small?: boolean;
};

type ButtonIconText = {
  icon: ReactElement;
  text: string;
};

const getButtonIconText = (
  command: SessionCommands,
  small?: boolean,
): ButtonIconText => {
  switch (command) {
    case SessionCommands.ConsultOff:
    case SessionCommands.Hangup:
      return {
        icon: <PhoneOutline24Icon style={{ rotate: "135deg" }} />,
        text: "Legg på",
      };
    case SessionCommands.ConsultWithAgent:
    case SessionCommands.ConsultWithPhone:
      return {
        icon: <QuestionOutline24Icon />,
        text: small ? "" : "Spørreanrop",
      };
    case SessionCommands.TransferToAgent:
    case SessionCommands.TransferToPhone:
      return {
        icon: <ShareOutline24Icon />,
        text: small ? "" : "Sett videre",
      };
    case SessionCommands.MuteOn:
      return {
        icon: <CallPauseIconStyled />,
        text: "Sett på vent",
      };
    case SessionCommands.MuteOff:
      return {
        icon: <CallPauseIconStyled />,
        text: "Hent inn samtale",
      };
    default:
      return {
        icon: <PhoneOutline24Icon />,
        text: "",
      };
  }
};

const SessionCommand = (props: Props) => {
  const {
    command,
    requestId,
    destinationPhoneNumber,
    destinationAgentId,
    small,
  } = props;
  const setTransfered = useSetTransfered();

  const { iqSessionId }: any = usePuzzelExtendedStateInformation();

  const { userId } = useUserValidationState();

  const [transferedResponse, performTransfer] = useApiAction(
    sessionCommandObject,
    false,
  );

  const [, performSessionCommandObject] = useApiAction(
    sessionCommandObject,
    false,
  );

  useEffect(() => {
    if (transferedResponse.data?.code === 0) {
      setTransfered(true);
    } else if (transferedResponse.data !== null) {
      log(
        LogLevel.warn,
        "SessionCommand",
        `Unexpected response when transferring phone call: ${transferedResponse.data?.code} - ${transferedResponse.data?.message}.`,
      );
    }
  }, [transferedResponse.data?.code]);

  const param = {
    userId,
    iqSessionId,
  };
  const payload = {
    currentUserId: userId,
    requestId,
  };

  function transferTo() {
    performTransfer({
      payload: {
        ...payload,
        command,
        phoneNumber: destinationPhoneNumber,
        agentId: destinationAgentId,
      },
      param,
    });
  }

  function consultWith() {
    performSessionCommandObject({
      payload: {
        ...payload,
        command,
        phoneNumber: destinationPhoneNumber,
        agentId: destinationAgentId,
      },
      param,
    });
  }

  function sessionCommand() {
    performSessionCommandObject({
      payload: { ...payload, command },
      param,
    });
  }

  const { disabled } = props;
  let onSessionClick = sessionCommand;

  // eslint-disable-next-line default-case
  switch (command) {
    case SessionCommands.ConsultWithAgent:
    case SessionCommands.ConsultWithPhone:
      onSessionClick = consultWith;
      break;
    case SessionCommands.TransferToAgent:
    case SessionCommands.TransferToPhone:
      onSessionClick = transferTo;
      break;
  }

  return (
    <Button
      leftIcon={getButtonIconText(command, small).icon}
      variant="tertiary"
      size="sm"
      isDisabled={disabled}
      onClick={onSessionClick}
    >
      {getButtonIconText(command, small).text}
    </Button>
  );
};

SessionCommand.defaultProps = {
  destinationPhoneNumber: undefined,
  disabled: undefined,
  destinationAgentId: undefined,
};

export default SessionCommand;
