import { Tooltip } from "@chakra-ui/react";
import {
  Box,
  ClosableAlert,
  HStack,
  Radio,
  RadioGroup,
  Skeleton,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { log, LogLevel } from "api/cloudWatch";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { CommonSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { EitherTrainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import {
  AffectedStopOption,
  getToStopOptions,
  splitPassedStopsAndBuildOptGroups,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/affectedStopsUtils";
import { TrainContext } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import { Stop } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { FC, useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormUnregister,
} from "react-hook-form";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { ExpandableInfoMessage } from "shared/components/feedback/InfoMessage/ExpandableInfoMessage/ExpandableInfoMessage";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import { Select } from "shared/components/forms/Select";
import { InfrastructureEventType } from "shared/types/infrastructureResponse";
import { useBatchUseTrainRoute } from "../useTrainInfoContext";
import {
  SearchDirection,
  toNonExcludedStopId,
  toNonExcludedStops,
} from "./utils/listofExcludedStations";

type AffectedStopsFieldsProps = {
  alreadySelectedStops?: string[];
  infrastructureEvent?: InfrastructureEventType;
};

export const AffectedStopsFields: FC<
  CommonTrainInfoFormProps &
    CommonSubTypeProps &
    AffectedStopsFieldsProps &
    TrainContext
> = ({
  mode,
  trainId,
  alreadySelectedStops,
  infrastructureEvent,
  formContext,
  trainFormPrefix,
}) => {
  // Cast context fields, the default from react-hook-form isn't good enough
  const { errors } = formContext.formState;
  const getValues =
    formContext.getValues as UseFormGetValues<EitherTrainFormSchema>;
  const setValue =
    formContext.setValue as UseFormSetValue<EitherTrainFormSchema>;
  const control = formContext.control as Control<EitherTrainFormSchema>;
  const resetField =
    formContext.resetField as UseFormResetField<EitherTrainFormSchema>;
  const register =
    formContext.register as UseFormRegister<EitherTrainFormSchema>;
  const unregister =
    formContext.unregister as UseFormUnregister<EitherTrainFormSchema>;

  // Need useState here to be able to unregister when switching tabs
  const [fromStopFieldPath, setFromStopFieldPath] = useState(
    `${trainFormPrefix}.trainRouteSection.fromStop` as const,
  );
  const [toStopFieldPath, setToStopFieldPath] = useState(
    `${trainFormPrefix}.trainRouteSection.toStop` as const,
  );
  const [typeFieldPath, setTypeFieldPath] = useState(
    `${trainFormPrefix}.trainRouteSection.type` as const,
  );
  const [affectedStopsFieldPath, setAffectedStopsFieldPath] = useState(
    `${trainFormPrefix}.affectedStops` as const,
  );

  const trainEventType = getValues(`${trainFormPrefix}.type` as const); // Used for autofilling only

  const batchUseTrainRoute = useBatchUseTrainRoute();
  const { data: trainRoute, status: fetchStatus } = batchUseTrainRoute(trainId);

  // Unregister old fields when field path changes
  useEffect(() => {
    unregister(
      [
        fromStopFieldPath,
        toStopFieldPath,
        typeFieldPath,
        affectedStopsFieldPath,
      ],
      {
        keepDefaultValue: true,
        keepDirty: true,
        keepDirtyValues: true,
        keepError: true,
        keepIsSubmitSuccessful: true,
        keepIsValid: true,
        keepIsValidating: true,
        keepTouched: true,
        keepValue: true,
      },
    );
    setFromStopFieldPath(`${trainFormPrefix}.trainRouteSection.fromStop`);
    setToStopFieldPath(`${trainFormPrefix}.trainRouteSection.toStop`);
    setTypeFieldPath(`${trainFormPrefix}.trainRouteSection.type`);
    setAffectedStopsFieldPath(`${trainFormPrefix}.affectedStops`);
  }, [trainFormPrefix]);

  const stops = useMemo(() => trainRoute?.stops ?? [], [trainRoute]);
  const selectableStops = useMemo(() => toNonExcludedStops(stops), [stops]); // Stops you can select from <Select/>

  // Display non-excluded stop. In WHOLE_ROUTE fromStop/toStop will differ from displayedFromStop
  const displayedFromStop = toNonExcludedStopId(
    stops,
    getValues(fromStopFieldPath),
    SearchDirection.SearchBackward,
  );
  const displayedToStop = toNonExcludedStopId(
    stops,
    getValues(toStopFieldPath),
    SearchDirection.SearchForward,
  );

  const stopSelectionType = getValues(typeFieldPath);

  const firstStop = stops.at(0)?.stopId;
  const lastStop = stops.at(stops.length - 1)?.stopId;
  const lastDisplayedStop = selectableStops.at(
    selectableStops.length - 1,
  )?.stopId;

  const displayedFromStopIsLastDisplayedStop =
    stops.length > 0 && displayedFromStop === lastDisplayedStop;

  // Attempt to autofill the form
  useEffect(() => {
    // Do nothing if this train series has already been autofilled, or if
    // its route is empty.
    if (stopSelectionType !== undefined || stops.length === 0) {
      return;
    }

    // Do nothing unless the fetch is finished and successful
    if (fetchStatus !== "success") {
      return;
    }

    // Try to autofill from InfrastructureEvent
    if (mode === "create" && infrastructureEvent) {
      const affectedLegs =
        infrastructureEvent.infrastructureInformation.affectedLegs.flatMap(
          (leg) => [leg.fromStop, leg.toStop],
        );
      const firstStopIndex = stops.findIndex(({ stopId }) =>
        affectedLegs.includes(stopId),
      );
      const lastStopIndex = stops.findLastIndex(({ stopId }) =>
        affectedLegs.includes(stopId),
      );

      if (firstStopIndex === -1 || lastStopIndex === -1) {
        // The route is not affected by this event, cannot autofill from event
      } else if (firstStopIndex === 0 && lastStopIndex === stops.length - 1) {
        // Event covers the entire route
        setValue(typeFieldPath, "WHOLE_ROUTE");
        setValue(fromStopFieldPath, stops[firstStopIndex].stopId);
        setValue(toStopFieldPath, stops[lastStopIndex].stopId);
      } else if (firstStopIndex === lastStopIndex) {
        // The event covers a single stop
        setValue(typeFieldPath, "AT_STOP");
        setValue(fromStopFieldPath, stops[firstStopIndex].stopId);
      } else {
        // The event covers a part of the train route
        setValue(typeFieldPath, "BETWEEN_STOPS");
        setValue(fromStopFieldPath, stops[firstStopIndex].stopId);
        setValue(toStopFieldPath, stops[lastStopIndex].stopId);
      }
    }

    // Try to autofill the form based on the trainRoute's currentTrainRouteSection
    if (
      (mode === "create" && !infrastructureEvent) ||
      (mode === "edit" && !stopSelectionType)
    ) {
      // Handle specific train event special cases
      if (
        [
          TrainEventTypeEnum.TRAIN_CAPACITY_REDUCED,
          TrainEventTypeEnum.TRAIN_CAPACITY_INCREASED,
        ].includes(trainEventType)
      ) {
        // Fill the entire route by default for capacity changes
        setValue(typeFieldPath, "WHOLE_ROUTE");
        setValue(fromStopFieldPath, firstStop!);
        setValue(toStopFieldPath, lastStop!, { shouldValidate: true });
        return;
      }

      if (
        [
          TrainEventTypeEnum.TRAIN_DELAY_EXPECTED,
          TrainEventTypeEnum.TRAIN_DELAYED,
        ].includes(trainEventType)
      ) {
        // Fill the current stop for delays, or set "AT_STOP" without filling
        // (invalid, actionable state) if the train is not running.
        // The latter case should not happen because it is covered by
        // TRAIN_LATE_TO_TRACK
        const stop =
          trainRoute?.currentTrainRouteSection?.toStopId ??
          trainRoute?.currentTrainRouteSection?.fromStopId;

        setValue(typeFieldPath, "AT_STOP", { shouldValidate: true });
        if (stop) {
          setValue(fromStopFieldPath, stop, { shouldValidate: true });
        }
        return;
      }

      if ([TrainEventTypeEnum.TRAIN_LATE_TO_TRACK].includes(trainEventType)) {
        // Fill the first stop for TRAIN_LATE_TO_TRACK
        setValue(typeFieldPath, "AT_STOP");
        setValue(fromStopFieldPath, firstStop!);
        return;
      }

      // Check if the train is currently somewhere along its route
      const fromStopIdFromTrainRoute =
        trainRoute?.currentTrainRouteSection?.fromStopId;
      const toStopIdFromTrainRoute =
        trainRoute?.currentTrainRouteSection?.toStopId;

      // Autofill with next stop if present, otherwise current
      const atStop =
        fromStopIdFromTrainRoute ?? toStopIdFromTrainRoute ?? firstStop!; // Use firstStop if atStop is not set
      const atStopIsFirstStop = atStop === firstStop;
      const atStopIsLastStop = atStop === lastStop;

      // If a next stop exists, use that as the fromStop
      setValue(fromStopFieldPath, atStop);
      // Set lastStop as toStop if train has not already reached it
      setValue(toStopFieldPath, lastStop!);

      if (atStopIsFirstStop) {
        setValue(typeFieldPath, "WHOLE_ROUTE", { shouldValidate: true });
        return;
      }
      if (atStopIsLastStop) {
        setValue(typeFieldPath, "AT_STOP", { shouldValidate: true });
        return;
      }
      // Set to AT_STOP if train is at or approaching last station, BETWEEN_STOPS otherwise
      setValue(typeFieldPath, "BETWEEN_STOPS", { shouldValidate: true });
    }
  }, [
    fetchStatus,
    infrastructureEvent,
    stopSelectionType,
    stops,
    trainFormPrefix,
    trainEventType,
  ]);

  // Validate consistency of fromStop and toStop along route
  useEffect(() => {
    if (!stopSelectionType || stopSelectionType === "AT_STOP") {
      // Do nothing if state is invalid or if AT_STOP (which is always internally consistent)
      return;
    }

    if (displayedFromStopIsLastDisplayedStop) {
      // If the last stop is selected as fromStop, and the stopSelectionType is not AT_STOP,
      // toStop would be an empty list and the form would not be submittable.
      // Set the stopSelectionType to AT_STOP to ensure valid state.
      setValue(typeFieldPath, "AT_STOP", {
        shouldValidate: true,
      });
    }

    // Should not be able to select a toStop that is equal to or before the fromStop
    const fromStopIndex = stops.findIndex(
      (s) => s.stopId === displayedFromStop,
    );
    const toStopIndex = stops.findIndex((s) => s.stopId === displayedToStop);
    if (fromStopIndex === -1 || toStopIndex === -1) {
      // Neither fromStop nor toStop are in the route. This may be due to switching between tabs in
      // the batch modal – do nothing and assume it will pass, but log it just in case
      log(
        LogLevel.warn,
        "AffectedStopsFields",
        "either fromStop, toStop or both are not in the selected train's route.",
      );
    } else if (
      stopSelectionType === "BETWEEN_STOPS" &&
      toStopIndex <= fromStopIndex
    ) {
      // toStop is on or before fromStop in the route, empty it
      resetField(toStopFieldPath);
    }
  }, [displayedFromStop, typeFieldPath]);

  // Keep affectedStops in sync with fromStop and toStop
  useEffect(() => {
    // If either fromStop or stopSelectionType are missing, empty affectedStops
    if (!displayedFromStop || !stopSelectionType) {
      setValue(affectedStopsFieldPath, [], {
        shouldValidate: true,
      });
      return;
    }

    // Do nothing if we haven't successfully fetched the route yet
    if (fetchStatus !== "success") {
      return;
    }

    if (!displayedToStop && stopSelectionType !== "AT_STOP") {
      // If fromStop is set but toStop is missing, and selection type is not AT_STOP, empty affectedStops
      setValue(affectedStopsFieldPath, [], {
        shouldValidate: true,
      });
      return;
    }
    if (stopSelectionType === "AT_STOP") {
      const fromIndex = stops.findIndex(
        (stop) => stop.stopId === displayedFromStop,
      );
      const toIndex = fromIndex + 1;

      setValue(
        affectedStopsFieldPath,
        stops.slice(fromIndex, toIndex).map((stop) => stop.stopId),
        {
          shouldValidate: true,
        },
      );
    }
    if (stopSelectionType === "BETWEEN_STOPS") {
      const fromIndex = stops.findIndex(
        (stop) => stop.stopId === displayedFromStop,
      );
      const toIndex = displayedToStop
        ? stops.findIndex((stop) => stop.stopId === displayedToStop) + 1 // Plus 1 to include the last stop
        : fromIndex + 1;

      setValue(
        affectedStopsFieldPath,
        stops.slice(fromIndex, toIndex).map((stop) => stop.stopId),
        {
          shouldValidate: true,
        },
      );
    }
    if (stopSelectionType === "WHOLE_ROUTE") {
      setValue(
        affectedStopsFieldPath,
        stops.map((stop) => stop.stopId),
        {
          shouldValidate: true,
        },
      );
    }
  }, [
    displayedFromStop,
    displayedToStop,
    stopSelectionType,
    stops,
    fetchStatus,
  ]);

  // Group stops by whether the train has passed them or not
  const displayStops = useMemo(
    () => splitPassedStopsAndBuildOptGroups(selectableStops),
    [stops],
  );

  // Compute whether we should show an info box warning that the train has already
  // passed the fromStop.
  const showTrainPassedWarningBox = useMemo(() => {
    if (infrastructureEvent || stopSelectionType === "WHOLE_ROUTE")
      return false;

    const selectedStop = stops.find((s) => s.stopId === displayedFromStop);
    return !!selectedStop?.isArrived;
  }, [stops, displayedFromStop, infrastructureEvent]);

  // Compute whether any of the selected affectedStops are already affected for this train
  const stopsAlreadySelected = useMemo(() => {
    const stopIds = stops.map((s) => s.stopId);

    const selectedStopIds = stopIds.slice(
      displayedFromStop ? stopIds.indexOf(displayedFromStop) : 0,
      displayedToStop ? stopIds.indexOf(displayedToStop) + 1 : -1,
    );

    return (
      (alreadySelectedStops
        ?.filter((stop) => selectedStopIds.includes(stop))
        .map((stopId) => stops.find((s) => s.stopId === stopId))
        .filter((s) => s !== undefined) as Stop[]) ?? []
    );
  }, [displayedFromStop, displayedToStop, alreadySelectedStops, stops]);

  // Compute the options for the toStop Select
  const memoGetToSelect = useMemo(
    () => getToStopOptions(selectableStops, displayedFromStop!!),
    [stops, displayedFromStop],
  );

  if (fetchStatus === "pending") {
    return <Skeleton height={6} />;
  }

  if (fetchStatus === "error" || !trainRoute || trainRoute.stops.length <= 0) {
    return (
      <FailureMessage customMessage="Klarte ikke hente ut stoppene til toget. Prøv igjen, eller ta kontakt med IT dersom feilen vedvarer." />
    );
  }

  return (
    <VStack width="100%" alignItems="flex-start" gap={3} my={1}>
      <Controller
        control={control}
        name={typeFieldPath}
        render={({ field }) => (
          <RadioGroup
            name="Stasjonsvalg"
            value={field.value ?? ""}
            onChange={(value) => {
              if (value === "WHOLE_ROUTE" && firstStop && lastStop) {
                setValue(fromStopFieldPath, firstStop);
                setValue(toStopFieldPath, lastStop);
              }
              field.onChange(value);
            }}
          >
            <Radio value="AT_STOP" name="AT_STOP">
              På stasjon
            </Radio>
            <Radio
              value="BETWEEN_STOPS"
              name="Mellom stasjoner"
              isDisabled={displayedFromStopIsLastDisplayedStop}
            >
              <Tooltip
                label={
                  displayedFromStopIsLastDisplayedStop
                    ? `Velg annen "på stasjon"-stopp enn siste stopp`
                    : undefined
                }
              >
                Mellom stasjoner
              </Tooltip>
            </Radio>
            <Radio value="WHOLE_ROUTE" name="Hel strekning">
              Hel strekning
            </Radio>
          </RadioGroup>
        )}
      />

      <VStack gap={2} width="100%" alignItems="flex-start">
        <HStack width="100%">
          <VStack gap={0} flexBasis={1} flexGrow={1} alignItems="flex-start">
            <Select
              {...register(fromStopFieldPath)}
              value={displayedFromStop ?? ""}
              minWidth="200px"
              width="100%"
              label={
                stopSelectionType === "AT_STOP"
                  ? "På stasjon"
                  : "Fra og med stasjon"
              }
              placeholder="Velg stasjon"
              disabled={stopSelectionType === "WHOLE_ROUTE"}
            >
              {infrastructureEvent
                ? selectableStops.map((stop) => (
                    <AffectedStopOption key={stop.stopId} stop={stop} />
                  ))
                : displayStops}
            </Select>
            <RenderErrorInPath errors={errors} errorPath={fromStopFieldPath} />
          </VStack>

          {stopSelectionType !== "AT_STOP" && (
            <VStack gap={0} flexBasis={1} flexGrow={1} alignItems="flex-start">
              <Select
                {...register(toStopFieldPath)}
                value={displayedToStop ?? ""}
                minWidth="200px"
                width="100%"
                label="Til og med stasjon"
                placeholder="Velg stasjon"
                disabled={stopSelectionType === "WHOLE_ROUTE"}
              >
                {memoGetToSelect}
              </Select>
              <RenderErrorInPath errors={errors} errorPath={toStopFieldPath} />
            </VStack>
          )}
        </HStack>
        <RenderErrorInPath errors={errors} errorPath={affectedStopsFieldPath} />
      </VStack>

      {showTrainPassedWarningBox && (
        <Box w="73%">
          <ClosableAlert flexBasis="100%" variant="info">
            Toget har allerede passert stasjonen.
          </ClosableAlert>
        </Box>
      )}

      {stopsAlreadySelected.length > 0 && (
        <ExpandableInfoMessage
          severity="warning"
          title="Stopp på valgt strekning er allerede innstilt"
        >
          <VStack alignItems="flex-start">
            <Text variant="xs" pt={2} fontWeight="bold">
              Følgende stopp er allerede innstilt:
            </Text>
            <Text variant="xs">
              {stopsAlreadySelected.map((s) => s.name.trim()).join(", ")}
            </Text>
          </VStack>
        </ExpandableInfoMessage>
      )}
    </VStack>
  );
};
