import { Tooltip } from "@chakra-ui/react";
import { StopwatchOutline18Icon } from "@vygruppen/spor-icon-react";
import { Stop } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { TrainTime } from "features/CenterContent/shared/styles";
import { Text } from "shared/components/typography/TitleStyles";
import { formatTimeString } from "shared/utils/datetime";
import { useTheme } from "styled-components";
import { Count, StopCount } from "../../types";
import { FillRate } from "../FillRate";
import { isCancelled } from "../utils";
import { DelaySpan, InfoHeader, StopName } from "./styles";

const combineCounts = (counts: Count[]): Count =>
  counts.reduce((prev, current) => ({
    passengerCountStatus:
      current.passengerCountStatus !== "ACTUAL"
        ? current.passengerCountStatus
        : prev.passengerCountStatus,
    vehicleSetId: `${prev.vehicleSetId}, ${current.vehicleSetId}`,
    passengersIn: prev.passengersIn + current.passengersIn,
    passengersOut: prev.passengersOut + current.passengersOut,
    passengerCount: prev.passengerCount + current.passengerCount,
  }));

type StopInfoProps = {
  stop: Stop;
  stopCount: StopCount | undefined;
  isMissingData: boolean;
};

export const StopInfo = ({ stop, stopCount, isMissingData }: StopInfoProps) => {
  const theme = useTheme();
  const operatingArrivalTime =
    stop.actualArrivalTime || stop.estimatedArrivalTime;
  const operatingDepartureTime =
    stop.actualDepartureTime || stop.estimatedDepartureTime;

  const arrivalTime = operatingArrivalTime || stop.scheduledArrivalTime;
  const departureTime = operatingDepartureTime || stop.scheduledDepartureTime;

  const arrivalCancelled =
    isCancelled(stop) || stop.cancelled === "ARRIVAL_CANCELLED";
  const departureCancelled =
    isCancelled(stop) || stop.cancelled === "DEPARTURE_CANCELLED";

  const showOutedScheduledArrivalTime =
    operatingArrivalTime &&
    stop.scheduledArrivalTime &&
    formatTimeString(stop.scheduledArrivalTime) !==
      formatTimeString(operatingArrivalTime);

  const showOutedScheduledDepartureTime =
    operatingDepartureTime &&
    stop.scheduledDepartureTime &&
    formatTimeString(stop.scheduledDepartureTime) !==
      formatTimeString(operatingDepartureTime);

  return (
    <>
      <InfoHeader>
        <StopName
          title={stop.name}
          isArrived={
            (stop.isArrived || stop.actualDepartureTime !== null) &&
            !isCancelled(stop)
          }
        >
          {stop.name}
        </StopName>
        {isMissingData && (
          <Tooltip
            hasArrow
            placement="right"
            label="Det finnes ikke passeringsmeldinger for dette stoppet"
          >
            <span style={{ cursor: "pointer" }}>
              <StopwatchOutline18Icon color={theme.colorTextSecondary} />
            </span>
          </Tooltip>
        )}
      </InfoHeader>
      <TrainTime
        $hasActualTime={stop.actualArrivalTime !== null && !arrivalCancelled}
        $isCancelled={arrivalCancelled}
      >
        {formatTimeString(arrivalTime)}
        {showOutedScheduledArrivalTime && (
          <DelaySpan>{formatTimeString(stop.scheduledArrivalTime)}</DelaySpan>
        )}
      </TrainTime>
      <TrainTime
        $hasActualTime={
          stop.actualDepartureTime !== null && !departureCancelled
        }
        $isCancelled={departureCancelled}
      >
        {formatTimeString(departureTime)}
        {showOutedScheduledDepartureTime && (
          <DelaySpan>{formatTimeString(stop.scheduledDepartureTime)}</DelaySpan>
        )}
      </TrainTime>
      {stop.track && <Text secondary>Spor {stop.track}</Text>}
      {stopCount && stopCount.counts.length > 0 && (
        <FillRate count={combineCounts(stopCount.counts)} />
      )}
    </>
  );
};
