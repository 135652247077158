import React, { ReactElement, useEffect, useState } from "react";
import {
  puzzelLogin,
  puzzelRefreshToken,
} from "../../../../../api/puzzel/puzzel";
import { useApiAction } from "../../../../../api/drops/useApiAction";
import {
  PUZZEL_ACCESS_TOKEN,
  PUZZEL_CUSTOMER_KEY,
} from "../../../../../api/puzzel/puzzelHttpClient";

type PuzzelAuthenticationValue = {
  isAuthenticated: boolean;
  phoneNumberPrefix: string;
  functionalNumberPrefix: string;
  countryCode: string;
  maxAttempts: number;
  secondsBetweenAttempts: number;
  ciqType: string;
  queueKey: string;
  maxOneRequestForPreferredAgent: boolean;
  preferredAgentWaitTimeSeconds: number;
  reservedAgentWaitTimeSeconds: number;
};

const initialValue: PuzzelAuthenticationValue = {
  isAuthenticated: false,
  ciqType: "",
  phoneNumberPrefix: "",
  functionalNumberPrefix: "",
  countryCode: "",
  maxAttempts: 0,
  maxOneRequestForPreferredAgent: false,
  preferredAgentWaitTimeSeconds: 0,
  queueKey: "",
  reservedAgentWaitTimeSeconds: 0,
  secondsBetweenAttempts: 0,
};

const fromPuzzelLoginResponse = (data: any): PuzzelAuthenticationValue => ({
  isAuthenticated: !!data.accessToken,
  ciqType: data.ciqType,
  phoneNumberPrefix: data.phoneNumberPrefix,
  functionalNumberPrefix: data.functionalNumberPrefix,
  countryCode: data.countryCode,
  maxAttempts: data.maxAttempts,
  maxOneRequestForPreferredAgent: data.maxOneRequestForPreferredAgent,
  preferredAgentWaitTimeSeconds: data.preferredAgentWaitTimeSeconds,
  queueKey: data.queueKey,
  reservedAgentWaitTimeSeconds: data.reservedAgentWaitTimeSeconds,
  secondsBetweenAttempts: data.secondsBetweenAttempts,
});

export const PuzzelAuthenticationContext = React.createContext(initialValue);

const PuzzelAuthenticationProvider = (
  props: Record<string, unknown>,
): ReactElement => {
  const [puzzelContext, setPuzzelContext] =
    useState<PuzzelAuthenticationValue>(initialValue);
  const [loginResponse, performLogin] = useApiAction(puzzelLogin, false);
  const [refreshToken, setRefreshToken] = useApiAction(
    puzzelRefreshToken,
    false,
  );
  useEffect(() => {
    performLogin();
  }, []);

  useEffect(() => {
    let intervalId = 0;
    if (loginResponse.data !== null) {
      localStorage.setItem(
        PUZZEL_ACCESS_TOKEN,
        loginResponse.data?.accessToken,
      );
      localStorage.setItem(
        PUZZEL_CUSTOMER_KEY,
        loginResponse.data?.customerKey,
      );

      setPuzzelContext(() => fromPuzzelLoginResponse(loginResponse.data));

      intervalId = window.setInterval(() => {
        const { sessionId } = loginResponse.data;
        setRefreshToken({
          sessionId,
        });
      }, 1680000); // refresh token every 28 minutes
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [loginResponse.data]);

  useEffect(() => {
    if (refreshToken.data !== null) {
      localStorage.setItem(PUZZEL_ACCESS_TOKEN, refreshToken.data?.accessToken);
    }
  }, [refreshToken.data]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <PuzzelAuthenticationContext.Provider value={puzzelContext} {...props} />
  );
};

function usePuzzelAuthenticationContext(): PuzzelAuthenticationValue {
  const context = React.useContext(PuzzelAuthenticationContext);
  if (context === undefined) {
    throw new Error(
      "usePuzzelContext must be used within a PuzzelContextProvider",
    );
  }
  return context;
}

export { PuzzelAuthenticationProvider, usePuzzelAuthenticationContext };
