import { FormSchema } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { AddRuleButton } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/AddRuleButton";
import { OpenEndedDuration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/OpenEndedDuration";
import { PeriodicDuration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/PeriodicDuration";
import { TimedDuration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/TimedDuration";
import { initialPeriodicDuration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/initialDurationValues";
import { FC, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ruleEditLabel, useOpenEditFields } from "stores/useOpenEditFields";

export const DurationInput: FC = () => {
  const [addRule, setAddRule] = useState<boolean>(false);

  const { control, setValue } = useFormContext<FormSchema>();
  const durationsType = useWatch({
    control,
    name: "infrastructureForm.durations.type",
  });

  const [addToOpenEditFields, removeFromOpenEditFields] = useOpenEditFields(
    (state) => [state.addToOpenEditFields, state.removeFromOpenEditFields],
  );

  const getDurationInput = () => {
    switch (durationsType) {
      case "TIMED":
        return (
          <>
            <TimedDuration />
            <AddRuleButton
              onClick={() => {
                setAddRule(true);
                setValue(
                  "infrastructureForm.durations",
                  initialPeriodicDuration,
                  { shouldValidate: true },
                );
              }}
            />
          </>
        );
      case "PERIODIC":
        return (
          <>
            <PeriodicDuration addRule={addRule} setAddRule={setAddRule} />
            {!addRule && (
              <AddRuleButton
                onClick={() => {
                  setAddRule(true);
                }}
              />
            )}
          </>
        );
      case "OPEN_ENDED":
      default:
        return <OpenEndedDuration />;
    }
  };

  useEffect(() => {
    if (addRule) {
      addToOpenEditFields(ruleEditLabel);
    } else {
      removeFromOpenEditFields(ruleEditLabel);
    }
  }, [addRule]);

  return getDurationInput();
};
