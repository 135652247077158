import styled, { css } from "styled-components";

export const baseTextStyle = css`
  font-size: 1rem;
  margin: 0;
  padding: 0;
`;

const centerText = css`
  text-align: center;
`;

type TitleProps = {
  centered?: boolean;
};

export const SectionTitle = styled.h3<TitleProps>`
  ${baseTextStyle};
  color: ${({ theme }) => theme.colorTextMain};
  font-family: "Vy Sans", sans-serif;
  font-weight: bold;
  ${({ centered }) => centered && centerText};
`;

type TextProps = {
  bold?: boolean;
  secondary?: boolean;
  truncated?: boolean;
};

export const Text = styled.p<TextProps>`
  ${baseTextStyle};
  line-height: 1.33rem;
  word-break: break-word;
  font-family: "Vy Sans", sans-serif;
  font-weight: ${({ bold }) => (bold ? `bold` : `normal`)};
  color: ${({ secondary, theme }) =>
    secondary ? theme.colorTextSecondary : theme.colorTextMain};

  ${({ truncated }) =>
    truncated &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
`;
