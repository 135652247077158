import { getLocalTimeZone, today } from "@internationalized/date";
import { getLocalStorageRole } from "api/dropsLocalStorage";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type UseReasonCodesTableFilter = {
  dateRange: {
    start?: string;
    end?: string;
  };
  trainFilter: string;
  codeFilter: string;
};

type Action = {
  setDateRange: (newState: UseReasonCodesTableFilter["dateRange"]) => void;
  setTrainFilter: (newState: UseReasonCodesTableFilter["trainFilter"]) => void;
  setCodeFilter: (newState: UseReasonCodesTableFilter["codeFilter"]) => void;
  reset: () => void;
};

export const initialFilterState: UseReasonCodesTableFilter = {
  dateRange: {
    start: today(getLocalTimeZone()).subtract({ days: 4 }).toString(),
    end: today(getLocalTimeZone()).toString(),
  },
  trainFilter: "",
  codeFilter:
    getLocalStorageRole() !== "vaktleder" &&
    getLocalStorageRole() !== "transportleder"
      ? "81"
      : "85",
};

/*
 * Persisted filter state for the reason codes table
 * */
export const useReasonCodesTableFilter = create<
  UseReasonCodesTableFilter & Action
>()(
  persist(
    (set) => ({
      ...initialFilterState,
      setDateRange: (newState) => set({ dateRange: newState }),
      setTrainFilter: (newState) => set({ trainFilter: newState }),
      setCodeFilter: (newState) => set({ codeFilter: newState }),
      reset: () => set({ ...initialFilterState, codeFilter: "" }),
    }),
    {
      name: "reason-code-table-filter",
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => key === "dateRange"),
        ),
    },
  ),
);
