import { useQuery } from "@tanstack/react-query";
import {
  Box,
  FormLabel,
  HStack,
  NumericStepper,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { TrainCompositionResponse } from "features/CenterContent/VehicleDetails/TrainDetails/TrainComposition/types";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { CommonSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { EitherTrainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { AffectedStopsFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/AffectedStopsFields";
import {
  calcTrainCompMetaData,
  getDefaultCapacity,
  getNumPassengerCarsFromLineId,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/changedCapacity";
import { TrainContext } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";
import { FC, useEffect } from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { useBatchUseTrainRoute } from "../useTrainInfoContext";

const MIN_CARS_OLD = 2;
const MIN_CARS_NEW = 1;
const MAX_CARS = 15;

export const TrainCapacityReduced: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps & TrainContext
> = (props) => {
  const { mode, trainId, formContext, trainFormPrefix } = props;

  // Cast context fields, the default from react-hook-form isn't good enough
  const getValues =
    formContext.getValues as UseFormGetValues<EitherTrainFormSchema>;
  const setValue =
    formContext.setValue as UseFormSetValue<EitherTrainFormSchema>;
  const control = formContext.control as Control<EitherTrainFormSchema>;

  const oldCapacityUnitPath = `${trainFormPrefix}.oldCapacityUnit` as const;
  const newCapacityUnitPath = `${trainFormPrefix}.newCapacityUnit` as const;
  const oldCapacityPath = `${trainFormPrefix}.oldCapacity` as const;
  const newCapacityPath = `${trainFormPrefix}.newCapacity` as const;

  // Set capacity unit once on first load
  useEffect(() => {
    setValue(oldCapacityUnitPath, "CARS", {
      shouldValidate: true,
    });
    setValue(newCapacityUnitPath, "CARS", {
      shouldValidate: true,
    });
  }, []);

  const oldCapacity = getValues(oldCapacityPath) ?? MIN_CARS_OLD;
  const newCapacity = getValues(newCapacityPath) ?? MIN_CARS_NEW;

  const { status: compositionStatus, data: compositionData } = useQuery({
    queryKey: ["trainComposition", trainId.nominalDate, trainId.identifier],
    refetchOnWindowFocus: true,
    queryFn: ({ signal }) =>
      queryFnGET<TrainCompositionResponse>({
        signal,
        url: `${getBackendUrl()}/trainComposition/NO/${trainId.nominalDate}/${trainId.identifier}`,
      }),
  });

  const batchUseTrainRoute = useBatchUseTrainRoute();
  const { data: trainRoute } = batchUseTrainRoute(trainId);

  // Autofill based on train composition
  useEffect(() => {
    if (
      mode === "create" ||
      (!getValues(oldCapacityPath) && !getValues(newCapacityPath))
    ) {
      if (
        compositionStatus === "success" &&
        compositionData.trainComposition.length > 0
      ) {
        const { numPassengerCars, type } = calcTrainCompMetaData(
          compositionData.trainComposition,
        );
        const capacity = getDefaultCapacity(numPassengerCars, type, "reduced");
        setValue(oldCapacityPath, capacity.old, {
          shouldValidate: true,
        });
        setValue(newCapacityPath, capacity.new, {
          shouldValidate: true,
        });
      } else {
        const numPassengerCars = trainRoute?.lineId
          ? getNumPassengerCarsFromLineId(trainRoute.lineId)
          : 5;
        const capacity = getDefaultCapacity(
          numPassengerCars,
          "MULTIPLE_UNIT",
          "reduced",
        );
        if (!getValues(oldCapacityPath)) {
          setValue(oldCapacityPath, capacity.old, {
            shouldValidate: true,
          });
        }

        if (!getValues(newCapacityPath)) {
          setValue(newCapacityPath, capacity.new, {
            shouldValidate: true,
          });
        }
      }
    }
  }, [compositionStatus]);

  return (
    <VStack width="100%" alignItems="flex-start" gap={3}>
      <AffectedStopsFields {...props} />
      <VStack paddingTop="1rem">
        <Box>
          <FormLabel marginBottom="0">Gammel kapasitet</FormLabel>
          <HStack gap="1rem" alignContent="center" display="flex">
            <Controller
              control={control}
              name={oldCapacityPath}
              render={({ field }) => (
                <NumericStepper
                  name={oldCapacityPath}
                  minValue={MIN_CARS_OLD}
                  maxValue={MAX_CARS}
                  withInput={false}
                  showZero
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);

                    // Make sure 'newCapacity' is always lower than 'oldCapacity'
                    if (value - 1 < newCapacity) {
                      setValue(
                        newCapacityPath,
                        Math.max(value - 1, MIN_CARS_NEW),
                        { shouldValidate: true },
                      );
                    }
                  }}
                />
              )}
            />
            <Text>Vogner</Text>
          </HStack>
        </Box>
        <Box>
          <FormLabel marginBottom="0">Ny kapasitet</FormLabel>
          <HStack gap="1rem">
            <Controller
              control={control}
              name={newCapacityPath}
              render={({ field }) => (
                <NumericStepper
                  name={newCapacityPath}
                  minValue={MIN_CARS_NEW}
                  maxValue={oldCapacity - 1}
                  showZero
                  withInput={false}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
            <Text>Vogner</Text>
          </HStack>
        </Box>
      </VStack>
    </VStack>
  );
};
