import { createCognitoState } from "api/dropsLocalStorage";
import React, { useCallback } from "react";
import {
  Button,
  Heading,
  useColorMode,
  VStack,
  VyLogo,
} from "@vygruppen/spor-react";
import { LOGIN_REDIRECT } from "shared/utils/routes";
import { getPKCETokens } from "app/Auth/auth";
import { getCognitoBaseUrl, getCognitoClientId } from "app/Auth/authConfig";
import { LoginContainer } from "./LoginStyle";

const LoginPage = () => {
  const { colorMode } = useColorMode();

  const initiateLogin = useCallback(async () => {
    const pkceChallengeObject = await getPKCETokens();
    const baseUrl = getCognitoBaseUrl();
    const clientId = getCognitoClientId();
    const codeChallenge = pkceChallengeObject.code_challenge;
    const redirectUri = window.location.origin + LOGIN_REDIRECT;
    const state = createCognitoState();

    window.location.href = `${baseUrl}/oauth2/authorize?response_type=code&client_id=${clientId}&code_challenge=${codeChallenge}&redirect_uri=${redirectUri}&code_challenge_method=S256&state=${state}`;
  }, []);

  return (
    <LoginContainer>
      <VStack justify="center">
        <Heading variant="xl-display" as="h1">
          Drops Dashboard
        </Heading>
        <Button
          size="lg"
          width="180px"
          onClick={initiateLogin}
          data-testid="login"
        >
          Logg inn
        </Button>
      </VStack>
      <VyLogo
        colorScheme={colorMode}
        width="100px"
        top="15px"
        left="15px"
        position="absolute"
      />
    </LoginContainer>
  );
};
export default LoginPage;
