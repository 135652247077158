import React, { ErrorInfo } from "react";
import {
  Text,
  SectionTitle,
} from "../../shared/components/typography/TitleStyles";
import { log, LogLevel } from "../../api/cloudWatch";

type ErrorState = {
  errorInfo: ErrorInfo | null;
};

export class ErrorBoundary extends React.Component<any, ErrorState> {
  constructor(props: any) {
    super(props);
    this.state = { errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({ errorInfo });
    log(
      LogLevel.error,
      errorInfo.componentStack || "[No component stack]",
      error?.stack || "[No error message]",
    );
  }

  render() {
    const { props, state } = this;
    if (state.errorInfo) {
      // Error path
      return (
        <div>
          <SectionTitle>En feil har oppstått</SectionTitle>
          <Text>
            Feilen er rapportert til utviklerne. Oppdater siden for å gå tilbake
          </Text>
          <Text>
            Dersom feilen er gjentakende, send gjerne en liten beskrivelse om
            hvordan du gikk frem da feilen oppsto
          </Text>
        </div>
      );
    }
    // Normally, just render children
    return props.children;
  }
}

export function withErrorBoundary<P extends {}>(
  Component: React.ComponentType<P>,
) {
  const displayName =
    Component.displayName ||
    Component.name ||
    "ComponentWrappedWithErrorBoundary";

  const ComponentWithErrorBoundary = (props: P) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );

  ComponentWithErrorBoundary.displayName = displayName;

  return ComponentWithErrorBoundary;
}
