import { Checkbox, CheckboxGroup, Expandable } from "@vygruppen/spor-react";

import { TodoForm } from "features/TodoOplogg/formSchema";

import { useFormContext } from "react-hook-form";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import { ROLE_TEXTS } from "shared/types/roles";

export const RoleOptions = () => {
  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<TodoForm>();
  const value = watch("roles");

  return (
    <div style={{ paddingTop: "15px" }}>
      <Expandable title="Send til" allowToggle>
        <CheckboxGroup
          direction="column"
          value={value}
          onChange={(val: string[]) => {
            setValue("roles", val);
          }}
        >
          {ROLE_TEXTS.map((role) => (
            <Checkbox key={role.value} value={role.value}>
              {role.text}
            </Checkbox>
          ))}
        </CheckboxGroup>
      </Expandable>

      {value && value.length === 0 && (
        <RenderErrorInPath errors={errors} errorPath="roles" />
      )}
    </div>
  );
};
