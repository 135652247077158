import React, { useEffect } from "react";
import { useSetTransfered } from "./TransferProvider";

const TransferredSuccessMsg = () => {
  const setTransferred = useSetTransfered();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTransferred(false);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return <div>Transferred</div>;
};

export default TransferredSuccessMsg;
