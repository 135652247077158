import { fromDate, getLocalTimeZone } from "@internationalized/date";
import { InfrastructureProviderEnum } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureProvider";
import { z } from "zod";

const openEndedDurationSchema = z.object({
  type: z.literal("OPEN_ENDED"),
  from_time: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
  extension_time_in_minutes: z
    .number()
    .transform((extensionTime) => extensionTime.toString()),
});

const timedDurationSchema = z.object({
  type: z.literal("TIMED"),
  from_time: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
  to_time: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
});

const periodSchema = z.object({
  id: z.string().nullable(),
  fromTime: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
  toTime: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
});

const periodicDurationSchema = z.object({
  type: z.literal("PERIODIC"),
  periods: z.array(periodSchema),
});

const durationSchema = z.discriminatedUnion("type", [
  openEndedDurationSchema,
  timedDurationSchema,
  periodicDurationSchema,
]);

export const customTypeSchema = z
  .object({
    type: z.string(),
    description: z.object({
      eng: z.string().nullable(),
      nob: z.string().nullable(),
    }),
  })
  .nullable();

const infraInfoSchema = z.object({
  uuid: z.string(),
  referenceId: z.string(),
  type: z.string(),
  affectedLegs: z.array(z.object({ fromStop: z.string(), toStop: z.string() })),
  redirectStretches: z.array(
    z.object({
      name: z.string(),
      legs: z.array(z.object({ fromStop: z.string(), toStop: z.string() })),
    }),
  ),
  durations: durationSchema,
  stretchName: z.string(),
  countryCode: z.literal("NO"),
  reason: customTypeSchema,
  action: customTypeSchema,
  consequence: customTypeSchema,
  actionCard: z.object({ type: z.string() }).nullable(),
  isirkId: z.string().nullable(),
  prognosis: customTypeSchema
    .and(
      z.object({
        estimatedResolved: z.coerce
          .date()
          .transform((time) => fromDate(time, getLocalTimeZone()))
          .nullable(),
        estimatedInfoUpdate: z.coerce
          .date()
          .transform((time) => fromDate(time, getLocalTimeZone()))
          .nullable(),
      }),
    )
    .nullable(),
  message: z.string(),
  updatedAt: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
  title: z.string(),
  priority: z.number().int(),
  infrastructureProvider: z.nativeEnum(InfrastructureProviderEnum).nullable(),
  incidentId: z.string().nullish(),
});

export type InfraStructureInformation = z.infer<typeof infraInfoSchema>;

export const infrastructureEventResponseSchema = z.object({
  infrastructureInformation: infraInfoSchema,
});

export const upcomingEventsResponseSchema = z.array(infraInfoSchema);

export type InfrastructureEventType = z.infer<
  typeof infrastructureEventResponseSchema
>;

export type InfrastructureEventResponse = {
  [uuid: string]: InfrastructureEventType;
};

export type PeriodicDuration = z.infer<typeof periodicDurationSchema>;

export type Duration = z.infer<typeof durationSchema>;
