import React, { FC } from "react";
import { StaticInfoMessage } from "shared/components/feedback/InfoMessage/StaticInfoMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { ISelectListOption } from "shared/components/SelectList/SelectList";
import { GetReserveAlternativeTransportResponse } from "shared/types/alternativeTransport";
import { sortReserveTransport } from "shared/utils/alternativeTransport";
import {
  isAvailableFilter,
  mapReserveAlternativeTransportToOption,
} from "features/CenterContent/VehicleDetails/AlternativeTransportDetails/Itinerary/VehicleReplacement/utils";
import {
  InfoSelect,
  Item,
  ItemDescription,
  ItemLabel,
} from "@vygruppen/spor-react";

type Props = {
  reserveVehicles: GetReserveAlternativeTransportResponse | undefined;
  isReserveLoading: boolean;
  isReserveError: boolean;
  selectedReserveId: string;
  setSelectedReserveId: (reserveId: string) => void;
};

export const SelectReserveVehicleDropdown: FC<Props> = ({
  reserveVehicles,
  isReserveLoading,
  isReserveError,
  selectedReserveId,
  setSelectedReserveId,
}) => {
  const reserveVehicleOptions: ISelectListOption[] =
    reserveVehicles?.vehicles
      .filter(isAvailableFilter)
      .sort(sortReserveTransport)
      .map(mapReserveAlternativeTransportToOption) ?? [];

  if (isReserveLoading) {
    return <SkeletonLoader skeletonType="selectReserveDropdown" />;
  }

  if (isReserveError) {
    return (
      <StaticInfoMessage
        severity="error"
        summary="Kunne ikke hente tilgjengelige reservebusser. Prøv igjen, eller kontakt IT hvis feilen vedvarer."
        style={{ boxShadow: "none" }}
      />
    );
  }

  return (
    <InfoSelect
      label="Tilgjengelige kjøretøy"
      placeholder="Velg kjøretøy"
      value={selectedReserveId}
      onChange={(value) => setSelectedReserveId(value.toString())}
      items={
        reserveVehicleOptions.length > 0
          ? reserveVehicleOptions
          : [
              {
                value: "123",
                text: "Ingen reservekjøretøy tilgjengelig",
              },
            ]
      }
    >
      {(item) => (
        <Item key={item.value} textValue={item.text}>
          <ItemLabel>{item.text}</ItemLabel>
          <ItemDescription>{item.additionalText}</ItemDescription>
        </Item>
      )}
    </InfoSelect>
  );
};
