import { toCalendarDate, toTime } from "@internationalized/date";
import {
  DatePicker,
  FormControl,
  Grid,
  Input,
  Stack,
  TimePicker,
} from "@vygruppen/spor-react";
import { FormSchema } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

const TimePickerStyled = styled(TimePicker)`
  label {
    position: absolute;
    left: 20px;
  }
  button {
    align-self: flex-end;
  }
`;

const InputStyleContainer = styled.div`
  &&& label {
    font-size: 16px;
    width: 100%;
  }
`;

const sxSmallDatePicker = {
  ".chakra-input__group > *": { paddingLeft: "15px", paddingRight: "0px" },
  button: { boxShadow: "none", left: "-5px" },
};

export const OpenEndedDuration: FC = () => {
  const { register, setValue, getValues } = useFormContext<FormSchema>();

  return (
    <Stack spacing={3}>
      <Grid templateColumns="repeat(3, 1fr)" gap="0.5rem">
        <TimePickerStyled
          width="100%"
          label="Starttidspunkt"
          value={toTime(getValues("infrastructureForm.durations.from_time"))}
          minuteInterval={5}
          onChange={(time) => {
            if (time !== null) {
              setValue(
                "infrastructureForm.durations.from_time.hour",
                time.hour ?? 0,
              );
              setValue(
                "infrastructureForm.durations.from_time.minute",
                time.minute ?? 0,
              );
            }
          }}
        />
        <FormControl sx={sxSmallDatePicker}>
          <DatePicker
            width="100%"
            minHeight="0"
            variant="base"
            label="Dato"
            value={toCalendarDate(
              getValues("infrastructureForm.durations.from_time"),
            )}
            onChange={(date) => {
              if (date !== null) {
                setValue(
                  "infrastructureForm.durations.from_time.day",
                  date.day ?? 0,
                );
                setValue(
                  "infrastructureForm.durations.from_time.month",
                  date.month ?? 0,
                );
                setValue(
                  "infrastructureForm.durations.from_time.year",
                  date.year ?? 0,
                );
              }
            }}
          />
        </FormControl>
        <InputStyleContainer>
          <Input
            {...register(
              "infrastructureForm.durations.extension_time_in_minutes",
            )}
            label="Tidshorisont i min."
          />
        </InputStyleContainer>
      </Grid>
    </Stack>
  );
};
