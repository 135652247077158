import { CatalogContactType } from "./components/types";

const MIN_LENGTH_PHONE_NUMBER = 5;
export const NO_COUNTRY_CODE_PREFIX = "47";

export const validPhoneNumber = (textInput: string | undefined) => {
  if (!textInput) {
    return false;
  }
  return (
    textInput.length >= MIN_LENGTH_PHONE_NUMBER &&
    !Number.isNaN(Number(textInput))
  );
};

export const getPhoneNumberWithoutNOPrefix = (phoneNumber: string) => {
  if (
    phoneNumber.startsWith(NO_COUNTRY_CODE_PREFIX) &&
    phoneNumber.length > 8
  ) {
    return phoneNumber.slice(NO_COUNTRY_CODE_PREFIX.length);
  }
  return phoneNumber;
};

export const removePhoneNumberPrefix = (
  phoneNumber: string,
  prefix: string,
): string => {
  if (phoneNumber?.startsWith(prefix) && phoneNumber.length >= 10) {
    return phoneNumber.slice(prefix.length);
  }
  return phoneNumber;
};

export const removePhoneNumberPrefixes = (
  phoneNumber: string,
  prefixesInOrder: string[],
) =>
  prefixesInOrder.reduce(
    (number, prefix) => removePhoneNumberPrefix(number, prefix),
    phoneNumber,
  );

export const assembleFullName = (catalogResponse: CatalogContactType[]) => {
  let fullName = "";
  if (catalogResponse[0].firstName) {
    fullName = catalogResponse[0].firstName;
  }
  if (catalogResponse[0].lastName) {
    fullName += ` ${catalogResponse[0].lastName}`;
  }
  return fullName.trim();
};
