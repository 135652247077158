import { z } from "zod";

export const VEHICLE_SET_TYPES = [
  { text: "69", value: "69" },
  { text: "70", value: "70" },
  { text: "72", value: "72" },
  { text: "73", value: "73" },
  { text: "74", value: "74" },
  { text: "75", value: "75" },
  { text: "Lok og vogn", value: "lokvogn" },
];

export const oploggForm = z.object({
  message: z.string().min(1, { message: "Du må skrive inn en melding" }),
});

export type OploggForm = z.infer<typeof oploggForm>;

export const todoForm = z.object({
  description: z
    .string()
    .min(1, { message: "Du må skrive inn en melding for description" }),
  roles: z.string().array().min(1, { message: "Du må velge en rolle" }),
  vehicleSetTypes: z.string().array().optional(),
  alarmTime: z.string().datetime().optional(),
});

export type TodoForm = z.infer<typeof todoForm>;

export type SubmitTodoForm = Omit<TodoForm, "vehicleSetTypes"> & {
  vehicleSetTypes?: string;
};

export const commonPadding = 3;
