import { Divider } from "@vygruppen/spor-react";
import styled, { DefaultTheme, css } from "styled-components";

const makeBackground = (props: DashedLineProps) => {
  const { theme, $color, $strokeWidth, $spacing } = props;
  const color = $color ?? theme.colorOutline;
  const strokeWidth = $strokeWidth ?? "2px";
  const spacing = $spacing ?? "4px";
  return css`
    repeating-linear-gradient(
      to right,
      ${color},
      ${color} ${strokeWidth},
      transparent ${strokeWidth},
      transparent calc(${strokeWidth} + ${spacing})
    );
  `;
};

type DashedLineProps = {
  theme: DefaultTheme;
  $color?: string;
  $height?: string;
  $strokeWidth?: string;
  $spacing?: string;
};
export const DashedLine = styled(Divider)<DashedLineProps>`
  &&& {
    border: 0;
    min-height: ${({ $height }) => $height ?? "1px"};
    background: ${(props: DashedLineProps) => makeBackground(props)};
  }
`;
