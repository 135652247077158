import {
  ChoiceChip,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Textarea,
} from "@vygruppen/spor-react";
import {
  FormErrorWithDiscriminatedField,
  FormSchema,
  StationCustomEventSeverityEnum,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/formSchema";
import {
  OperationalTextContextType,
  useOperationalTextContexts,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { FC, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FailureMessageWithRetryButton } from "shared/components/feedback/FailureMessageWithRetryButton";
import { DROPS_CONTEXT_TYPE } from "shared/types/types";
import { filterAndSortContexts } from "shared/utils/contextUtils";
import { useStretchBuilder } from "stores/useStretchBuilder";

type StationCustomProps = {};

export const StationCustom: FC<StationCustomProps> = () => {
  // Fetch distribution contexts
  const {
    data: allContexts,
    isFetching,
    isPending,
    isSuccess,
    isError,
    refetch,
  } = useOperationalTextContexts();

  const [contexts, setContexts] = useState(allContexts ?? []);

  // The original `isLoading` from tanstack query is only true on first request, not on retries
  const isLoading = isFetching || isPending;

  const formMethods = useFormContext<FormSchema>();
  const severity = formMethods.watch("stationForm.severity");
  const contextTypes = formMethods.watch(
    "stationForm.distributions.contextTypes",
  );

  const [stretchBuilderModalState] = useStretchBuilder((state) => [
    state.stretchBuilderModalState,
  ]);

  // Nested errors within discriminated types aren't great to work with in
  // react-hook-form – add error types for each layer below discriminator
  const fieldErrors: FormErrorWithDiscriminatedField<
    "severity" | "distributions"
  > = formMethods.formState.errors.stationForm;
  const distributionErrors: FormErrorWithDiscriminatedField<
    "contextTypes" | "texts"
  > = fieldErrors?.distributions;
  const textFieldErrors: FormErrorWithDiscriminatedField<"NOB" | "ENG"> =
    distributionErrors?.texts;

  // Add context to selected list if missing, remove from list if present
  const toggleContextSelected = useCallback(
    (context: OperationalTextContextType["type"]) => {
      const currentValues = contextTypes ?? [];
      if (!currentValues.includes(context)) {
        formMethods.setValue("stationForm.distributions.contextTypes", [
          ...currentValues,
          context,
        ]);
      } else {
        formMethods.setValue(
          "stationForm.distributions.contextTypes",
          currentValues.filter((v) => v !== context) ?? [],
        );
      }
    },
    [formMethods.setValue, contextTypes],
  );

  useEffect(() => {
    const toggledContexts = [DROPS_CONTEXT_TYPE];

    if (
      stretchBuilderModalState.type === "station" &&
      stretchBuilderModalState.eventInfo
    ) {
      const modalState = stretchBuilderModalState.eventInfo;
      if (modalState.stationInformationType === "STATION_GENERAL") {
        toggledContexts.push(...modalState.distributions.contextTypes);
      }
    }

    formMethods.setValue(
      "stationForm.distributions.contextTypes",
      toggledContexts,
      {
        shouldValidate: true,
      },
    );
  }, []);

  useEffect(() => {
    setContexts(filterAndSortContexts(allContexts));
  }, [allContexts]);

  return (
    <>
      <FormControl as="fieldset" isInvalid={!!fieldErrors?.severity}>
        <FormLabel as="legend">Alvorlighetsgrad</FormLabel>

        <RadioGroup
          name="severity"
          value={severity}
          onChange={(value: StationCustomEventSeverityEnum) => {
            formMethods.setValue("stationForm.severity", value);
            formMethods.clearErrors("stationForm.severity");
          }}
        >
          <Radio value="INFO">Informasjon</Radio>
          <Radio value="WARNING">Advarsel</Radio>
        </RadioGroup>

        <FormErrorMessage marginTop={2}>
          {fieldErrors?.severity?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!textFieldErrors?.NOB}>
        <Textarea
          label="Norsk beskrivelse"
          {...formMethods.register("stationForm.distributions.texts.NOB")}
        />

        <FormErrorMessage>{textFieldErrors?.NOB?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!textFieldErrors?.ENG}>
        <Textarea
          label="Engelsk beskrivelse"
          {...formMethods.register("stationForm.distributions.texts.ENG")}
        />

        <FormErrorMessage>{textFieldErrors?.ENG?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!distributionErrors?.contextTypes}>
        <FormLabel as="legend">Mottagere</FormLabel>

        {isLoading && (
          <Stack flexDirection="row" width="100%">
            <Skeleton height={6} borderRadius={300} flexGrow={2} />
            <Skeleton height={6} borderRadius={300} flexGrow={3} />
            <Skeleton height={6} borderRadius={300} flexGrow={1} />
          </Stack>
        )}

        {isError && !isLoading && (
          <FailureMessageWithRetryButton
            customMessage="Kunne ikke hente mottagere fra Operational Text-tjenesten"
            retryFunction={() => refetch()}
          />
        )}

        {isSuccess && !isLoading && (
          <Stack flexDirection="row" flexWrap="wrap">
            {contexts?.map((context) => (
              <ChoiceChip
                disabled={context.type === DROPS_CONTEXT_TYPE}
                key={context.type}
                onChange={() => toggleContextSelected(context.type)}
                isChecked={contextTypes?.includes(context.type)}
              >
                {context.name}
              </ChoiceChip>
            ))}
          </Stack>
        )}
      </FormControl>
    </>
  );
};
