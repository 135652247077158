import { OploggMessageWrapper } from "features/TodoOplogg/Oplogg/OploggMessages";
import { RefObject, useEffect, useRef, useState } from "react";

export const useElementVisibilityState = (ref: RefObject<HTMLElement>) => {
  const [elementVisiblityState, setElementVisibilityState] = useState({
    isVisible: false,
  });
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        setElementVisibilityState({
          isVisible: entry.isIntersecting,
        });
      },
      {
        root: document.querySelector(`${OploggMessageWrapper}`),
        rootMargin: "62px 0px 0px 0px",
        threshold: 1.0,
      },
    );
  }, []);

  useEffect(() => {
    if (ref.current === null || observerRef.current === null) {
      return () => {};
    }

    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current?.disconnect();
    };
  }, [ref]);

  return elementVisiblityState;
};
