import {
  TrainCustomEventSeverityEnum,
  TrainMissingProductsCoverageEnum,
  TrainMissingProductsEnum,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { CapacityUnit } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainCommonFieldtypes";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import { RouteSection } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";

type DropsLogText = {
  NOB: string;
};

type TrainStoppedDetailedResponse = {
  type: TrainEventTypeEnum.TRAIN_STOPPED;
  trainRouteSection?: RouteSection;
  dropsLogText?: DropsLogText;
};

type TrainCancelledDetailedResponse = {
  type: TrainEventTypeEnum.TRAIN_CANCELLED;
  trainRouteSection: RouteSection;
  affectedStops: string[];
  dropsLogText?: DropsLogText;
};

type TrainCapacityDetailedResponse = {
  type:
    | TrainEventTypeEnum.TRAIN_CAPACITY_REDUCED
    | TrainEventTypeEnum.TRAIN_CAPACITY_INCREASED;
  trainRouteSection: RouteSection;
  affectedStops: string[];
  oldCapacity: number[];
  newCapacity: number[];
  oldCapacityUnit: CapacityUnit;
  newCapacityUnit: CapacityUnit;
  dropsLogText?: DropsLogText;
};

type TrainDelayedDetailedResponse = {
  type: TrainEventTypeEnum.TRAIN_DELAYED;
  trainRouteSection: RouteSection;
  affectedStops: string[];
  delayInMinutes?: number;
  dropsLogText?: DropsLogText;
};

type TrainLateToTrackDetailedResponse = {
  type: TrainEventTypeEnum.TRAIN_LATE_TO_TRACK;
  trainRouteSection: RouteSection;
  affectedStops: string[];
  minutesLate?: number;
  dropsLogText?: DropsLogText;
};

type TrainDelayExpectedDetailedResponse = {
  type: TrainEventTypeEnum.TRAIN_DELAY_EXPECTED;
  trainRouteSection: RouteSection;
  affectedStops: string[];
  delayInMinutes?: number;
  dropsLogText?: DropsLogText;
};

type TrainClosedSetDetailedResponse = {
  type: TrainEventTypeEnum.TRAIN_CLOSED_SET;
  trainRouteSection: RouteSection;
  affectedStops: string[];
  dropsLogText?: DropsLogText;
};

type TrainNotStoppingAtStationDetailedResponse = {
  type: TrainEventTypeEnum.TRAIN_NOT_STOPPING_AT_STATION;
  skippedStops: string[];
  dropsLogText?: DropsLogText;
};

type TrainMissingProductDetailedResponse = {
  type: TrainEventTypeEnum.TRAIN_MISSING_PRODUCT;
  trainRouteSection: RouteSection;
  affectedStops: string[];
  products: TrainMissingProductsEnum;
  coverage: TrainMissingProductsCoverageEnum;
  dropsLogText?: DropsLogText;
};

type TrainStoppingExtraAtStationDetailedResponse = {
  type: TrainEventTypeEnum.TRAIN_STOPPING_EXTRA_AT_STATION;
  extraStops: string[];
  dropsLogText?: DropsLogText;
};

type CustomDistributions = {
  contextTypes: string[];
  texts: DropsLogText;
};

type TrainCustomDetailedResponse = {
  type: "TRAIN_GENERAL_INFO" | "TRAIN_GENERAL_WARNING";
  affectedStops: string[];
  severity: TrainCustomEventSeverityEnum;
  distributions: CustomDistributions;
  dropsLogText?: undefined;
};

type TrainChangedRouteDetailedResponse = {
  type: TrainEventTypeEnum.TRAIN_CHANGED_ROUTE;
  affectedStops: string[];
  newRoute: string[];
  cancelledStops: string[];
  originalRouteName: string;
  newRouteName: string;
  dropsLogText?: DropsLogText;
};

type CommonFields = {
  uuid: string;
  reason: { type: string; description?: { NOB: string; ENG: string } };
  action: { type: string; description?: { NOB: string; ENG: string } };
  incidentIds?: string[];
};

export type TrainInformationDetailedResponse = (
  | TrainStoppedDetailedResponse
  | TrainCancelledDetailedResponse
  | TrainCapacityDetailedResponse
  | TrainDelayedDetailedResponse
  | TrainLateToTrackDetailedResponse
  | TrainDelayExpectedDetailedResponse
  | TrainClosedSetDetailedResponse
  | TrainNotStoppingAtStationDetailedResponse
  | TrainMissingProductDetailedResponse
  | TrainStoppingExtraAtStationDetailedResponse
  | TrainCustomDetailedResponse
  | TrainChangedRouteDetailedResponse
) &
  CommonFields;

export function trainResponseIsTrainCustomResponse(
  trainResponse: TrainInformationDetailedResponse,
): trainResponse is TrainCustomDetailedResponse & CommonFields {
  switch (trainResponse.type) {
    case "TRAIN_GENERAL_INFO":
    case "TRAIN_GENERAL_WARNING":
      return true;

    default:
      return false;
  }
}
