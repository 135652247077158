import { AffectedLeg } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";

export interface Stretch {
  id: number;
  name: string;
  from: string;
  to: string;
  stops: string[];
  legs: { fromStop: string; toStop: string }[];
}

export const ALTERNATIVE_STRETCHES: Stretch[] = [
  {
    id: 0,
    name: "Gardermobanen",
    from: "Oslo S",
    to: "Lillestrøm",
    stops: ["OSL", "HLR", "LLS"],
    legs: [
      { fromStop: "OSL", toStop: "HLR" },
      { fromStop: "HLR", toStop: "LLS" },
    ],
  },
  {
    id: 1,
    name: "Hovedbanen",
    from: "Oslo S",
    to: "Lillestrøm",
    stops: [
      "OSL",
      "BR",
      "BRB",
      "ALA",
      "AKE",
      "NYL",
      "GRO",
      "HGA",
      "HØB",
      "LØR",
      "HAB",
      "FJE",
      "STN",
      "SDA",
      "LLS",
    ],
    legs: [
      { fromStop: "OSL", toStop: "BR" },
      { fromStop: "BR", toStop: "BRB" },
      { fromStop: "BRB", toStop: "ALA" },
      { fromStop: "ALA", toStop: "AKE" },
      { fromStop: "AKE", toStop: "NYL" },
      { fromStop: "NYL", toStop: "GRO" },
      { fromStop: "GRO", toStop: "HGA" },
      { fromStop: "HGA", toStop: "HØB" },
      { fromStop: "HØB", toStop: "LØR" },
      { fromStop: "LØR", toStop: "HAB" },
      { fromStop: "HAB", toStop: "FJE" },
      { fromStop: "FJE", toStop: "STN" },
      { fromStop: "STN", toStop: "SDA" },
      { fromStop: "SDA", toStop: "LLS" },
    ],
  },
  {
    id: 2,
    name: "Østfoldbanen",
    from: "Oslo S",
    to: "Ski",
    stops: [
      "OSL",
      "BEK",
      "NST",
      "LJA",
      "HTO",
      "HMA",
      "RSH",
      "KOL",
      "SOL",
      "MYV",
      "GUD",
      "OPG",
      "VEV",
      "LAN",
      "SKI",
    ],
    legs: [
      { fromStop: "OSL", toStop: "BEK" },
      { fromStop: "BEK", toStop: "NST" },
      { fromStop: "NST", toStop: "LJA" },
      { fromStop: "LJA", toStop: "HTO" },
      { fromStop: "HTO", toStop: "HMA" },
      { fromStop: "HMA", toStop: "RSH" },
      { fromStop: "RSH", toStop: "KOL" },
      { fromStop: "KOL", toStop: "SOL" },
      { fromStop: "SOL", toStop: "MYV" },
      { fromStop: "MYV", toStop: "GUD" },
      { fromStop: "GUD", toStop: "OPG" },
      { fromStop: "OPG", toStop: "VEV" },
      { fromStop: "VEV", toStop: "LAN" },
      { fromStop: "LAN", toStop: "SKI" },
    ],
  },
  {
    id: 3,
    name: "Follobanen (tunnelen)",
    from: "Oslo S",
    to: "Ski",
    stops: ["OSL", "SKI"],
    legs: [{ fromStop: "OSL", toStop: "SKI" }],
  },
  {
    id: 4,
    name: "Drammenbanen",
    from: "Lysaker",
    to: "Sandvika",
    stops: ["LYS", "STB", "HVK", "BLO", "SV"],
    legs: [
      { fromStop: "LYS", toStop: "STB" },
      { fromStop: "STB", toStop: "HVK" },
      { fromStop: "HVK", toStop: "BLO" },
      { fromStop: "BLO", toStop: "SV" },
    ],
  },
  {
    id: 5,
    name: "Askerbanen",
    from: "Lysaker",
    to: "Sandvika",
    stops: ["LYS", "SV"],
    legs: [{ fromStop: "LYS", toStop: "SV" }],
  },
  {
    id: 6,
    name: "Drammenbanen",
    from: "Sandvika",
    to: "Asker",
    stops: ["SV", "SLE", "BST", "HVA", "VAK", "HØN", "ASR"],
    legs: [
      { fromStop: "SV", toStop: "SLE" },
      { fromStop: "SLE", toStop: "BST" },
      { fromStop: "BST", toStop: "HVA" },
      { fromStop: "HVA", toStop: "VAK" },
      { fromStop: "VAK", toStop: "HØN" },
      { fromStop: "HØN", toStop: "ASR" },
    ],
  },
  {
    id: 7,
    name: "Askerbanen",
    from: "Sandvika",
    to: "Asker",
    stops: ["SV", "ASR"],
    legs: [{ fromStop: "SV", toStop: "ASR" }],
  },
  {
    id: 8,
    name: "Drammenbanen",
    from: "Lysaker",
    to: "Asker",
    stops: [
      "LYS",
      "STB",
      "HVK",
      "BLO",
      "SV",
      "SLE",
      "BST",
      "HVA",
      "VAK",
      "HØN",
      "ASR",
    ],
    legs: [
      { fromStop: "LYS", toStop: "STB" },
      { fromStop: "STB", toStop: "HVK" },
      { fromStop: "HVK", toStop: "BLO" },
      { fromStop: "BLO", toStop: "SV" },
      { fromStop: "SV", toStop: "SLE" },
      { fromStop: "SLE", toStop: "BST" },
      { fromStop: "BST", toStop: "HVA" },
      { fromStop: "HVA", toStop: "VAK" },
      { fromStop: "VAK", toStop: "HØN" },
      { fromStop: "HØN", toStop: "ASR" },
    ],
  },
  {
    id: 9,
    name: "Askerbanen",
    from: "Lysaker",
    to: "Asker",
    stops: ["LYS", "SV", "ASR"],
    legs: [
      { fromStop: "LYS", toStop: "SV" },
      { fromStop: "SV", toStop: "ASR" },
    ],
  },
  {
    id: 10,
    name: "Hovedbanen",
    from: "Lillestrøm",
    to: "Kløfta",
    stops: ["LLS", "LSD", "FRO", "LBG", "KLØ"],
    legs: [
      { fromStop: "LLS", toStop: "LSD" },
      { fromStop: "LSD", toStop: "FRO" },
      { fromStop: "FRO", toStop: "LBG" },
      { fromStop: "LBG", toStop: "KLØ" },
    ],
  },
  {
    id: 11,
    name: "Gardermobanen",
    from: "Lillestrøm",
    to: "Kløfta",
    stops: ["LLS", "KLØ"],
    legs: [{ fromStop: "LLS", toStop: "KLØ" }],
  },
  {
    id: 12,
    name: "Hovedbanen",
    from: "Langeland",
    to: "Eidsvoll",
    stops: ["LAL", "JEH", "NBY", "HSR", "SAD", "DAL", "BØN", "EVL"],
    legs: [
      { fromStop: "LAL", toStop: "JEH" },
      { fromStop: "JEH", toStop: "NBY" },
      { fromStop: "NBY", toStop: "HSR" },
      { fromStop: "HSR", toStop: "SAD" },
      { fromStop: "SAD", toStop: "DAL" },
      { fromStop: "DAL", toStop: "BØN" },
      { fromStop: "BØN", toStop: "EVL" },
    ],
  },
  {
    id: 13,
    name: "Gardermobanen",
    from: "Langeland",
    to: "Eidsvoll",
    stops: ["EVL", "EVV", "BKH", "GAR", "LAL"],
    legs: [
      { fromStop: "EVL", toStop: "EVV" },
      { fromStop: "EVV", toStop: "BKH" },
      { fromStop: "BKH", toStop: "GAR" },
      { fromStop: "GAR", toStop: "LAL" },
    ],
  },
  {
    id: 14,
    name: "Via Drammen",
    from: "Hønefoss",
    to: "Oslo S",
    stops: [
      "HFS",
      "TYR",
      "VKS",
      "GHS",
      "ÅMO",
      "SEV",
      "HOK",
      "SBG",
      "MJD",
      "DLR",
      "GUL",
      "DRM",
      "BRA",
      "LIE",
      "ERU",
      "ASR",
      "SV",
      "LYS",
      "SKØ",
      "NTH",
      "OSL",
    ],
    legs: [
      { fromStop: "HFS", toStop: "TYR" },
      { fromStop: "TYR", toStop: "VKS" },
      { fromStop: "VKS", toStop: "GHS" },
      { fromStop: "GHS", toStop: "ÅMO" },
      { fromStop: "ÅMO", toStop: "SEV" },
      { fromStop: "SEV", toStop: "HOK" },
      { fromStop: "HOK", toStop: "SBG" },
      { fromStop: "SBG", toStop: "MJD" },
      { fromStop: "MJD", toStop: "DLR" },
      { fromStop: "DLR", toStop: "GUL" },
      { fromStop: "GUL", toStop: "DRM" },
      { fromStop: "DRM", toStop: "BRA" },
      { fromStop: "BRA", toStop: "LIE" },
      { fromStop: "LIE", toStop: "ERU" },
      { fromStop: "ERU", toStop: "ASR" },
      { fromStop: "ASR", toStop: "SV" },
      { fromStop: "SV", toStop: "LYS" },
      { fromStop: "LYS", toStop: "SKØ" },
      { fromStop: "SKØ", toStop: "NTH" },
      { fromStop: "NTH", toStop: "OSL" },
    ],
  },
  {
    id: 15,
    name: "Via Roa",
    from: "Hønefoss",
    to: "Oslo S",
    stops: [
      "HFS",
      "HV",
      "JEV",
      "GVL",
      "ROA",
      "GRU",
      "BSR",
      "HST",
      "MON",
      "SY",
      "JEN",
      "HAK",
      "VAR",
      "ÅBY",
      "NIT",
      "MVT",
      "SNI",
      "SDM",
      "KJE",
      "NYD",
      "GRE",
      "TØY",
      "OSL",
    ],
    legs: [
      { fromStop: "HFS", toStop: "HV" },
      { fromStop: "HV", toStop: "JEV" },
      { fromStop: "JEV", toStop: "GVL" },
      { fromStop: "GVL", toStop: "ROA" },
      { fromStop: "ROA", toStop: "GRU" },
      { fromStop: "GRU", toStop: "BSR" },
      { fromStop: "BSR", toStop: "HST" },
      { fromStop: "HST", toStop: "MON" },
      { fromStop: "MON", toStop: "SY" },
      { fromStop: "SY", toStop: "JEN" },
      { fromStop: "JEN", toStop: "HAK" },
      { fromStop: "HAK", toStop: "VAR" },
      { fromStop: "VAR", toStop: "ÅBY" },
      { fromStop: "ÅBY", toStop: "NIT" },
      { fromStop: "NIT", toStop: "MVT" },
      { fromStop: "MVT", toStop: "SNI" },
      { fromStop: "SNI", toStop: "SDM" },
      { fromStop: "SDM", toStop: "KJE" },
      { fromStop: "KJE", toStop: "NYD" },
      { fromStop: "NYD", toStop: "GRE" },
      { fromStop: "GRE", toStop: "TØY" },
      { fromStop: "TØY", toStop: "OSL" },
    ],
  },
];

export const shouldFlipStretchDirection = (
  alternativeStretchLegs: AffectedLeg[],
  selectedStops: string[],
) => {
  if (alternativeStretchLegs.length < 2 || selectedStops.length < 2)
    return false;
  const { fromStop: fromStopAlternative } = alternativeStretchLegs[0];
  const { toStop: toStopAlternative } =
    alternativeStretchLegs[alternativeStretchLegs.length - 1];
  const fromStop = selectedStops[0];
  const toStop = selectedStops[selectedStops.length - 1];
  return fromStop === toStopAlternative || toStop === fromStopAlternative;
};

export const orderLegs = (legs: AffectedLeg[]) => {
  // Create a map to track connections between legs.
  const fromMap: Map<string, string> = new Map();
  const toMap: Map<string, string> = new Map();

  legs.forEach(({ fromStop, toStop }) => {
    fromMap.set(fromStop, toStop);
    toMap.set(toStop, fromStop);
  });

  // Identify the starting leg which does not appear as a "toStop".
  let start = null;

  for (let i = 0; i < legs.length; i += 1) {
    if (!toMap.get(legs[i].fromStop)) {
      start = legs[i].fromStop;
      break;
    }
  }

  // Order the nodes starting from the identified start.
  const orderedLegs = [];
  let current = start;
  while (current) {
    const next = fromMap.get(current);
    if (next) {
      orderedLegs.push({ fromStop: current, toStop: next });
      current = next;
    } else {
      current = null;
    }
  }

  return orderedLegs;
};

export const flipLegDirections = (legs: AffectedLeg[]): AffectedLeg[] =>
  legs.reverse().map((leg) => ({
    fromStop: leg.toStop,
    toStop: leg.fromStop,
  }));

export const formatStretchName = (stretch: Stretch, flipDirection: boolean) =>
  `${stretch.name} (${flipDirection ? stretch.to : stretch.from} - ${
    flipDirection ? stretch.from : stretch.to
  })`;
