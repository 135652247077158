import { Checkbox, HStack, Stack } from "@chakra-ui/react";
import { ChoiceChip, Text } from "@vygruppen/spor-react";
import { Dispatch, FC, SetStateAction } from "react";

export type Weekday = {
  name: string;
  dayNumber: number;
};

export const daysOfWeek: Weekday[] = [
  { name: "man", dayNumber: 0 },
  { name: "tir", dayNumber: 1 },
  { name: "ons", dayNumber: 2 },
  { name: "tor", dayNumber: 3 },
  { name: "fre", dayNumber: 4 },
  { name: "lør", dayNumber: 5 },
  { name: "søn", dayNumber: 6 },
];

type DayChoiceProps = {
  selectedDays: number[];
  setSelectedDays: Dispatch<SetStateAction<number[]>>;
};

export const DayChoice: FC<DayChoiceProps> = ({
  selectedDays,
  setSelectedDays,
}) => (
  <Stack paddingTop={3} paddingBottom={3}>
    <Text variant="xs">Hendelsen er aktiv hver</Text>
    <HStack>
      {daysOfWeek.map((day) => (
        <ChoiceChip
          key={day.dayNumber}
          isChecked={selectedDays.includes(day.dayNumber)}
          onChange={() => {
            if (selectedDays.includes(day.dayNumber)) {
              setSelectedDays(
                selectedDays.filter(
                  (selectedDay) => selectedDay !== day.dayNumber,
                ),
              );
            } else {
              setSelectedDays([...selectedDays, day.dayNumber]);
            }
          }}
          size="sm"
        >
          {day.name}
        </ChoiceChip>
      ))}
    </HStack>
    <Checkbox
      isChecked={selectedDays.length === 7}
      onChange={() =>
        setSelectedDays(selectedDays.length === 7 ? [] : [0, 1, 2, 3, 4, 5, 6])
      }
    >
      Alle dager
    </Checkbox>
  </Stack>
);
