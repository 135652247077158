import styled from "styled-components";

export const HorizontalContainer = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 1rem;

  & > * + * {
    margin-left: 1rem;
  }
`;
