import { useEffect, useRef } from "react";

export const useOnOutsideMouseclick = (callback: () => void) => {
  const mouseclickRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event: Event) => {
      if (
        mouseclickRef.current &&
        // @ts-ignore
        !mouseclickRef.current.contains(event.target)
      ) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [mouseclickRef, callback]);
  return { mouseclickRef };
};
