import { useState } from "react";
import constate from "constate";

export type UserValidationType = {
  userId: string | null;
};

const initStates = {
  userId: localStorage.getItem("puzzelUserId"),
};

function useUserValidation() {
  const [userValidationState, setUserValidation] =
    useState<UserValidationType>(initStates);

  return {
    userValidationState,
    setUserValidation,
  };
}

const [UserValidationProvider, useUserValidationState, useSetUserValidation] =
  constate(
    useUserValidation,
    (value) => value.userValidationState,
    (value) => value.setUserValidation,
  );

export { UserValidationProvider, useUserValidationState, useSetUserValidation };
