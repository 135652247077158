import { Badge, BadgeProps } from "@vygruppen/spor-react";
import { ReactNode } from "react";
import { DefaultTheme, useTheme } from "styled-components";

export type Semantics =
  | "alarm"
  | "secondary-alarm"
  | "warning"
  | "info"
  | "success"
  | "neutral";

const getColorSchemeForSemantics = (
  semantics: Semantics,
  theme: DefaultTheme,
) => {
  switch (semantics) {
    case "alarm":
      return {
        backgroundColor: theme.colorAlarmFill,
        borderColor: theme.colorAlarmOutline,
        color: theme.colorAlarmTextMain,
      };
    case "secondary-alarm":
      return {
        backgroundColor: theme.colorSecondaryAlarmFill,
        borderColor: theme.colorSecondaryAlarmOutline,
        color: theme.colorSecondaryAlarmTextMain,
      };
    case "warning":
      return {
        backgroundColor: theme.colorWarningFill,
        borderColor: theme.colorWarningOutline,
        color: theme.colorWarningTextMain,
      };
    case "info":
      return {
        backgroundColor: theme.colorInfoFill,
        borderColor: theme.colorInfoOutline,
        color: theme.colorInfoTextMain,
      };
    case "success":
      return {
        backgroundColor: theme.colorSuccessFill,
        borderColor: theme.colorSuccessOutline,
        color: theme.colorSuccessTextMain,
      };
    case "neutral":
    default:
      return {
        backgroundColor: theme.colorNeutralFill,
        borderColor: theme.colorNeutralOutline,
        color: theme.colorNeutralTextMain,
      };
  }
};

export const DropsBadge = ({
  semantics,
  children,
  ...rest
}: {
  semantics: Semantics;
  children: ReactNode;
} & BadgeProps) => {
  const theme = useTheme();

  return (
    <Badge
      size="xs"
      variant="outline"
      px="12px !important"
      {...getColorSchemeForSemantics(semantics, theme)}
      {...rest}
    >
      {children}
    </Badge>
  );
};
