import { Td, Tr } from "@chakra-ui/react";
import { FilterType } from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/TableFilter/constants";

import { tableColumns } from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/DayPlanTrackings";
import DayPlanTrackingActionButton from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/TableContent/ActionButton";
import {
  filterDayPlanTrackings,
  getStatus,
} from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/TableContent/utils";
import { DayPlanTrackingType } from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/types";
import { FC } from "react";
import { CallButton } from "shared/components/buttons/CallButton";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { formatDayDateString } from "shared/utils/datetime";
import styled, { DefaultTheme, useTheme } from "styled-components";
import { getPhoneNumberWithoutNOPrefix } from "features/Header/PuzzelTelephone/utils";

const getTextColor = (theme: DefaultTheme, severityStatus: string) => {
  switch (severityStatus) {
    case "WARN":
      return theme.colorWarningTextMain;
    case "ERROR":
      return theme.colorAlarmTextMain;
    default:
      return "inherit";
  }
};

const getBackgroundColor = (theme: DefaultTheme, severityStatus: string) => {
  switch (severityStatus) {
    case "WARN":
      return theme.colorWarningFill;
    case "ERROR":
      return theme.colorAlarmFill;
    default:
      return "inherit";
  }
};

const getBorderColor = (theme: DefaultTheme, severityStatus: string) => {
  switch (severityStatus) {
    case "WARN":
      return theme.colorWarningOutline;
    case "ERROR":
      return theme.colorAlarmOutline;
    default:
      return theme.colorOutline;
  }
};

const getHoverColor = (theme: DefaultTheme, severityStatus: string) => {
  switch (severityStatus) {
    case "WARN":
      return theme.colorWarningFillPulse;
    case "ERROR":
      return theme.colorAlarmFillPulse;
    default:
      return theme.colorHoverItem;
  }
};

const ForceOverrideButtonStyles = styled.div<{ overrideColor: string }>`
  &&& button {
    outline-color: ${(props) => props.overrideColor} !important;
    border-color: ${(props) => props.overrideColor} !important;
    color: ${(props) => props.overrideColor} !important;
  }
`;

type TableProps = {
  data: DayPlanTrackingType[];
  filterParams: FilterType;
  onRowClick: (dayPlanTrackingType: DayPlanTrackingType) => void;
};

export const TableContent: FC<TableProps> = ({
  data,
  filterParams,
  onRowClick,
}) => {
  const theme = useTheme();
  const filteredDayPlanTrackings = filterDayPlanTrackings(data, filterParams);

  return filteredDayPlanTrackings.length > 0 ? (
    <>
      {filteredDayPlanTrackings.map((it) => {
        const status = getStatus(it.status);
        return (
          <Tr
            cursor="pointer"
            key={`${it.employeeId}-${it.dayPlanDate}`}
            textColor={getTextColor(theme, status.severity)}
            backgroundColor={getBackgroundColor(theme, status.severity)}
            sx={{
              "& td": {
                borderBottom: `1px solid ${getBorderColor(theme, status.severity)}`,
              },
            }}
            _hover={{
              backgroundColor: `${getHoverColor(theme, status.severity)} !important`,
              // border: `1px solid ${getBorderColor(theme, status.severity)}`,
            }}
            onClick={() => onRowClick(it)}
          >
            <Td>{status.text}</Td>
            <Td>{it.companyCode}</Td>
            <Td>{formatDayDateString(it.lastUpdatedAt)}</Td>
            <Td>{formatDayDateString(it.dutyStart)}</Td>
            <Td>
              {it.fullName
                ? `${it.fullName} (${it.employeeId})`
                : it.employeeId}
            </Td>
            <Td>{`${it.isConductor ? "K" : ""} ${it.isDriver ? "L" : ""}`}</Td>
            <Td>{`${it.dutyId} ${it.deletion ? "(slettet)" : ""}`}</Td>
            <Td>
              {it.phoneNumber && (
                <ForceOverrideButtonStyles
                  overrideColor={getTextColor(theme, status.severity)}
                >
                  <CallButton
                    phoneNumber={getPhoneNumberWithoutNOPrefix(it.phoneNumber)}
                    size="xs"
                  />
                </ForceOverrideButtonStyles>
              )}
            </Td>
            <Td>
              <ForceOverrideButtonStyles
                overrideColor={getTextColor(theme, status.severity)}
              >
                <DayPlanTrackingActionButton
                  status={it.status}
                  employeeId={it.employeeId}
                />
              </ForceOverrideButtonStyles>
            </Td>
          </Tr>
        );
      })}
    </>
  ) : (
    <Tr>
      <Td colSpan={tableColumns.length} p={5}>
        <NoDataMessage reason="Fant ingen tjenestevarslinger som matcher aktivt filter" />
      </Td>
    </Tr>
  );
};
