import { GlobalEvent } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { TabPanel, TabPanels, Tabs } from "@vygruppen/spor-react";
import { GlobalEventsList } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/GlobalEventsList";

type GlobalEventsProps = {
  data: GlobalEvent[];
  onEditClick: (event: GlobalEvent) => void;
};

export const GlobalEvents = ({ data, onEditClick }: GlobalEventsProps) => (
  <Tabs
    variant="base"
    colorScheme="base"
    isFitted
    gap={3}
    size="sm"
    marginTop={3}
  >
    <TabPanels>
      <TabPanel>
        <GlobalEventsList data={data} onEditClick={onEditClick} />
      </TabPanel>
    </TabPanels>
  </Tabs>
);
