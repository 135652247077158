import axios from "axios";
import {
  authorizationHeader,
  registerAxiosAuthInterceptor,
} from "app/Auth/axiosAuthInterceptor";

axios.defaults.headers.post["Content-Type"] = "application/json";

export const axiosClient = axios.create();

axiosClient.interceptors.request.use((config) => {
  config.headers.Authorization = authorizationHeader().Authorization;
  return config;
});

const logSource = "axiosHttpClient";

registerAxiosAuthInterceptor(axiosClient, logSource);
