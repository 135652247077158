import { compareAsc, isFuture } from "date-fns";

const MINIMUM_SEARCH_LENGTH_FOR_PHONE_NUMBERS = 4;

export const matchPhoneNumber = (
  searchQuery: string,
  phoneNumber: string,
): boolean => {
  if (searchQuery.startsWith("+")) {
    return phoneNumber.startsWith(searchQuery);
  }

  if (searchQuery.length < MINIMUM_SEARCH_LENGTH_FOR_PHONE_NUMBERS) {
    return false;
  }

  const re = new RegExp(`((\\+|0|00)\\d{0,2})?(\\d+)?${searchQuery}(\\d+)?`);
  return re.test(phoneNumber);
};

type AlternativeTransportTimeDestination = {
  scheduledArrivalTimeDestination: Date | null;
};

type ReserveAlternativeTransportTimeDestination = {
  shiftEndTime: Date | null;
};

type Time =
  | AlternativeTransportTimeDestination
  | ReserveAlternativeTransportTimeDestination;

type NonNullableDateProp<V> = {
  [K in keyof V]: Date;
};

type Narrowed<V extends Time, K extends keyof V> = V &
  NonNullableDateProp<Pick<V, K>>;

type PropMustHave<
  Object,
  Key extends keyof Object,
  MustHaveType,
> = Object[Key] extends MustHaveType ? Key : never;

export function processTransportList<T extends Time, K extends keyof T>({
  transportList,
  showAllVehicles,
  timeKey,
}: {
  transportList: T[];
  showAllVehicles: boolean;
  timeKey: PropMustHave<T, K, Date | null>;
}) {
  const sortedList = transportList
    .filter((at): at is Narrowed<T, typeof timeKey> => at[timeKey] !== null)
    .sort((a, b) => compareAsc(a[timeKey], b[timeKey]));

  if (showAllVehicles) {
    return sortedList;
  }

  const futureIndex = sortedList.findIndex((at) => isFuture(at[timeKey]));

  if (futureIndex === -1) {
    return [];
  }

  return sortedList.slice(futureIndex);
}
