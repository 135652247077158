import { FormSchema } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Select } from "shared/components/forms/Select";

export enum InfrastructureProviderEnum {
  BANE_NOR = "BANE_NOR",
  TRAFIKVERKET = "TRAFIKVERKET",
  NONE = "NONE",
}

type InfrastructureProviderProps = {
  isPlanned: boolean;
};

export const InfrastructureProvider: FC<InfrastructureProviderProps> = ({
  isPlanned,
}) => {
  const { register } = useFormContext<FormSchema>();
  return (
    <Select
      label="Infrastrukturleverandør"
      {...register(`infrastructureForm.infrastructureProvider`)}
      defaultValue={InfrastructureProviderEnum.BANE_NOR}
    >
      <option value={InfrastructureProviderEnum.BANE_NOR}>Bane NOR</option>
      <option value={InfrastructureProviderEnum.TRAFIKVERKET}>
        Trafikverket
      </option>
      {!isPlanned && (
        <option value={InfrastructureProviderEnum.NONE}>Ingen</option>
      )}
    </Select>
  );
};
