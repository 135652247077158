import { RefObject, useCallback, useEffect, useState } from "react";

export interface InfiniteScrollProps {
  containerRef: RefObject<HTMLElement>;
  initialItemsToRender: number;
  maxItemsToRender: number;
  pageSize?: number;
}

/**
 * This hook can be used to implement "infinite scroll". This hook does not render anything, it simply
 * provides you with a number of items to render. This hook will take care of setting up scroll listeners and updating
 * the number of items to render whenever the bottom of the container is near, but the caller is responsible for
 * rendering the actual items.
 *
 * Example:
 * const data = ['lots', 'of', 'data'];
 *
 * const scrollableContainerRef = useRef<HTMLDivElement>(null);
 *
 * const { numberOfItemsToRender } = useLazyRender({
 *     containerRef: scrollableContainerRef,
 *     initialItemsToRender: 25,
 *     maxItemsToRender: data.length,
 *     pageSize: 20,
 *   });
 *
 * return (
 *    <div ref={scrollableContainerRef}>
 *      {data
 *        .slice(0, numberOfItemsToRender)
 *        .map((item) => <div>{item}</div>)
 *        }
 *    </div>
 *    );
 *
 * The easiest mistake to make when using this hook is to pass the wrong component as containerRef. This must be the
 * container that performs the scrolling.
 *
 * @param initialItemsToRender the initial number of items to render
 * @param maxItemsToRender the total size of the data to be rendered
 * @param containerRef a ref to the **scrollable container**
 * @param pageSize the number of items in each "page"
 */
export const useInfiniteScroll = ({
  initialItemsToRender,
  maxItemsToRender,
  containerRef,
  pageSize = 25,
}: InfiniteScrollProps) => {
  const [numberOfItemsToRender, setNumberOfItemsToRender] =
    useState(initialItemsToRender);

  const fetchMoreBottomReached = useCallback(
    (containerRefElement?: HTMLElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        // once the user has scrolled within 150px of the bottom of the container,
        // add a new "page" to the number of items to render
        if (
          scrollHeight - scrollTop - clientHeight < 150 &&
          maxItemsToRender > numberOfItemsToRender
        ) {
          setNumberOfItemsToRender((prev) => prev + pageSize);
        }
      }
    },
    [maxItemsToRender, numberOfItemsToRender],
  );

  useEffect(() => {
    const container = containerRef.current;
    const scrollListener = (e: Event) =>
      fetchMoreBottomReached(e.target as HTMLDivElement);

    container?.addEventListener("scroll", scrollListener);

    // cleanup
    return () => {
      container?.removeEventListener("scroll", scrollListener);
    };
  }, [containerRef, fetchMoreBottomReached]);

  return { numberOfItemsToRender };
};
