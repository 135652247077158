import { Tab, TabList, Tabs } from "@vygruppen/spor-react";
import React from "react";

export const vehicleListTabs = [
  { value: "Tog", label: "Søk på tognr, stasjon, linjenr eller sett-ID" },
  { value: "Buss", label: "Søk på skiftnr, tlfnr eller tognr" },
  { value: "Reservebuss", label: "Søk på skiftnr eller tlfnr" },
] as const;

type VehicleTabsProps = {
  onChange: (index: number) => void;
};

const VehicleTabs: React.FC<VehicleTabsProps> = ({ onChange }) => (
  <Tabs
    variant="base"
    onChange={onChange}
    colorScheme="base"
    isFitted
    mb={2}
    width="max-content"
  >
    <TabList>
      {vehicleListTabs.map((tab, index) => (
        <Tab key={tab.value} aria-controls={`tabs-:ra:--tabpanel-${index}`}>
          {tab.value}
        </Tab>
      ))}
    </TabList>
  </Tabs>
);

export default VehicleTabs;
