import { useMutation } from "@tanstack/react-query";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { useEffect, useState } from "react";
import { Text } from "shared/components/typography/TitleStyles";
import { formatDayDateString, formatTrainDate } from "shared/utils/datetime";
import SelectList from "../../../../../../shared/components/SelectList/SelectList";
import { ReasonCodeItem, SetReasonCodeCommentPayload } from "../schemas";
import { FieldContainer, TopContainer } from "./ReasonModalComponents";

export const Codes = [
  {
    text: "Uten kode",
    value: "",
  },
  {
    text: "Kode 1 - Bane",
    value: "1",
  },
  {
    text: "Kode 2 - Sikringsanlegg, silgnalanlegg og fjernstyring",
    value: "2",
  },
  {
    text: "Kode 3 - Elkraft/kontaktledning",
    value: "3",
  },
  {
    text: "Kode 4 - Tele og transmisjonsfeil",
    value: "4",
  },
  {
    text: "Kode 5 - Planlagt vedlikehold infrastruktur",
    value: "5",
  },
  {
    text: "Kode 7 - Trafikkavvikling (infrastrukturs andel)",
    value: "7",
  },
  {
    text: "Kode 81 - Feil ved kjøretøy",
    value: "81",
  },
  {
    text: "Kode 82 - Kjøretøy sent fra hensettingsspor",
    value: "82",
  },
  {
    text: "Kode 83 - Manglende personell",
    value: "83",
  },
  {
    text: "Kode 84 - Stasjonsopphold",
    value: "84",
  },
  {
    text: "Kode 85 - Planforutseninger ikke oppfylt",
    value: "85",
  },
  {
    text: "Kode 6 - Kjøretøy med feil sperrer spor/blokkstrekning",
    value: "6",
  },
  {
    text: "Kode 7 - Trafikkavvikling (Jernbaneforetakets andel)",
    value: "7",
  },
  {
    text: "Kode 91 - Forsinkelse fra utlandet",
    value: "91",
  },
  {
    text: "Kode 92 - Ytre forhold",
    value: "92",
  },
  {
    text: "Kode 93 - Uhell, påkjørsel",
    value: "93",
  },
  {
    text: "Kode 94 - Uønsket hendelse",
    value: "94",
  },
];

type ModalProps = {
  reasonCodeItem: ReasonCodeItem;
  closeModal: () => void;
  refetchReasonCodes: () => void;
};

const EditReasonCodeModal = ({
  reasonCodeItem,
  closeModal,
  refetchReasonCodes,
}: ModalProps) => {
  const [code, setCode] = useState(Codes[0].value);
  const [comment, setComment] = useState("");

  const { mutate } = useMutation({
    mutationKey: ["setReasonCodeComment"],
    mutationFn: (payload: SetReasonCodeCommentPayload) =>
      axiosClient.post(
        `${getBackendUrl()}/reason-codes/${encodeURIComponent(
          payload.referenceId,
        )}`,
        payload,
      ),
    onSuccess: () => {
      refetchReasonCodes();
    },
  });

  useEffect(() => {
    const setSelectedCode = () => {
      let existingValue;
      if (reasonCodeItem !== null) {
        existingValue = Codes.find(
          (element) => element.value === reasonCodeItem.dropsReasonCode,
        );

        if (!existingValue) {
          existingValue = Codes.find(
            (element) => element.value === reasonCodeItem.tiosReasonCode,
          );

          if (!existingValue) {
            existingValue = Codes.find(
              (element) => element.value === reasonCodeItem.employeeComment,
            );
          }
        }
      }
      if (existingValue) {
        setCode(existingValue.value);
      } else {
        setCode("");
      }
    };

    if (reasonCodeItem) {
      setComment(reasonCodeItem.dropsComment || "");
      setSelectedCode();
    }
  }, [reasonCodeItem]);

  const onSubmit = () => {
    if (reasonCodeItem !== null) {
      mutate({
        referenceId: reasonCodeItem.id,
        code,
        comment,
        field: "dropsCode",
      });
    }

    closeModal();
  };

  return (
    <Modal isOpen onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Rediger årsakskode </ModalHeader>
        <ModalBody>
          <TopContainer>
            <Text bold>{reasonCodeItem.trainNumber}</Text>
            <Text secondary>{reasonCodeItem.delay} min</Text>
            {reasonCodeItem.tiosReasonCode && (
              <Text>{`BN - Kode: ${reasonCodeItem.tiosReasonCode}`}</Text>
            )}
            <FieldContainer>
              <Text>
                {` Registrert: ${formatDayDateString(
                  reasonCodeItem.registeredAt,
                )}`}
              </Text>
              <Text>
                {`Tog-dato: ${formatTrainDate(reasonCodeItem.trainDate)}`}
              </Text>
              <Text>
                {`Stasjon: ${
                  reasonCodeItem.delay && reasonCodeItem.delay > 0
                    ? `${reasonCodeItem.stationName} (+${reasonCodeItem.delay})`
                    : reasonCodeItem.stationName
                }`}
              </Text>
              {reasonCodeItem.employeeReasonCode && (
                <Text>{`Lok - Kode: ${reasonCodeItem.employeeReasonCode}`}</Text>
              )}
              {reasonCodeItem.employeeComment && (
                <Text>{`Lok - Kommentar: ${reasonCodeItem.employeeComment}`}</Text>
              )}

              {reasonCodeItem.tiosComment && (
                <Text>{`BN - Kommentar: ${reasonCodeItem.tiosComment}`}</Text>
              )}
            </FieldContainer>
          </TopContainer>

          <Text style={{ marginTop: "20px" }}>DROPS-Kode:</Text>
          <SelectList
            width="300px"
            maxDropDownHeight="35rem"
            id="DropsCodes"
            name="DropsCodes"
            options={Codes}
            selected={code}
            onChange={(value: string) => {
              setCode(value);
            }}
            hiddenAndDisabledDefault={false}
          />
          <Text style={{ marginTop: "20px", marginBottom: "9px" }}>
            DROPS-Kommentar:
          </Text>
          <Textarea
            onChange={(e) => {
              setComment(e.target.value);
            }}
            label="Skriv kommentar her"
            value={comment || ""}
          />
        </ModalBody>
        <ModalFooter gap={2}>
          <Button variant="tertiary" onClick={closeModal}>
            Avbryt
          </Button>
          <Button variant="secondary" onClick={onSubmit}>
            Lagre
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { EditReasonCodeModal };
