import { Environment, environment } from "environment";

export const getCognitoBaseUrl = () => {
  switch (environment()) {
    case Environment.Test:
      return "https://auth.test.cognito.vydev.io";
    case Environment.Development:
      return "https://auth.test.cognito.vydev.io";
    case Environment.Production:
      return "https://auth.cognito.vydev.io";
    case Environment.Stage:
      return "https://auth.stage.cognito.vydev.io";
    case Environment.Unknown:
    default:
      return null;
  }
};

export const getCognitoClientId = () => {
  switch (environment()) {
    case Environment.Test:
      return "3g5ftruof6j5grtjscbgsvbqb1";
    case Environment.Development:
      return "3g5ftruof6j5grtjscbgsvbqb1";
    case Environment.Stage:
      return "7uti659n2ej28con3rukvtlqr6";
    case Environment.Production:
      return "e4s2vjiuod8akvokgqhvsn8mi";
    case Environment.Unknown:
    default:
      return null;
  }
};
