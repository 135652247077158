import {
  ChoiceChip,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
} from "@vygruppen/spor-react";
import {
  FormErrorWithDiscriminatedField,
  FormSchema,
  StationCommunicationSystemsEnum,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/formSchema";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type CommunicationSystem = {
  value: StationCommunicationSystemsEnum;
  name: string;
};

const communicationSystems: CommunicationSystem[] = [
  { value: "TRACK_MONITORS", name: "Anvisere" },
  { value: "SPEAKERS", name: "Høyttalere" },
  { value: "ARRIVAL_AND_DEPARTURE_MONITORS", name: "Monitorer" },
];

const updateAffectedSystems = (
  affectedSystems: StationCommunicationSystemsEnum[],
  system: StationCommunicationSystemsEnum,
): StationCommunicationSystemsEnum[] => {
  if (affectedSystems?.includes(system)) {
    return affectedSystems.filter((s) => s !== system);
  }
  return [...(affectedSystems ?? []), system];
};

export const StationCommunicationSystemsDown: FC = () => {
  const { watch, setValue, clearErrors, formState } =
    useFormContext<FormSchema>();
  const affectedSystems = watch("stationForm.affectedSystems");
  const fieldErrors: FormErrorWithDiscriminatedField<"affectedSystems"> =
    formState.errors.stationForm;

  return (
    <FormControl as="fieldset" isInvalid={!!fieldErrors?.affectedSystems}>
      <FormLabel as="legend">Velg påvirkesde systemer</FormLabel>

      <HStack flexWrap="wrap">
        {communicationSystems.map((system) => (
          <ChoiceChip
            key={system.value}
            isChecked={affectedSystems?.includes(system.value)}
            onChange={() => {
              setValue(
                "stationForm.affectedSystems",
                updateAffectedSystems(affectedSystems, system.value),
              );
              clearErrors("stationForm.affectedSystems");
            }}
          >
            {system.name}
          </ChoiceChip>
        ))}
      </HStack>

      <FormErrorMessage marginTop={2}>
        {fieldErrors?.affectedSystems?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
