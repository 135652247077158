import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 6px;
  align-items: center;
  max-width: 483px;
  margin: auto;

  & :nth-child(1) {
    grid-row: 1;
  }

  & :nth-child(2) {
    grid-row: 1 / span 2;
  }
`;

export const Message = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 21px;
  font-family: "Vy Sans", sans-serif;
  color: ${({ theme }) => theme.colorTextMain};
`;
