export const customInputInfo = {
  event: {
    formField: "trainForm.type",
    label: "Hendelse*",
    customFormField: "trainForm.distributions.texts",
  },
  reason: {
    formField: "trainForm.reason.type",
    label: "Årsak*",
    customFormField: "trainForm.reason.description",
  },
  action: {
    formField: "trainForm.action.type",
    label: "Handling",
    customFormField: "trainForm.action.description",
  },
} as const;
