import { AxiosResponse } from "axios";
import { dropsHttpClient } from "./dropsHttpClient";

const getUser = () => dropsHttpClient.get(`admin/userInfo`);

const fetchPhoneNumberType = (
  phoneNumber: string,
): Promise<AxiosResponse<{ phoneNumberType: "FUNCTIONAL" | "GSMR" }>> =>
  dropsHttpClient.get(`/crew/phoneNumberType?phoneNumber=${phoneNumber}`);

export type TrainStaffCallerResponse = {
  callerData: TrainStaffCaller | null;
};

export type TrainStaffCaller = {
  trainNumber: string;
  trainDate: string;
  functionRole: string;
  functionalTelephoneNumber: string;
  gsmrNumber: string;
};

const fetchTrainStaffCaller = (
  phoneNumber: string,
): Promise<AxiosResponse<TrainStaffCallerResponse>> =>
  dropsHttpClient.get(`/crew/trainStaffByCaller?phoneNumber=${phoneNumber}`);

export { fetchPhoneNumberType, fetchTrainStaffCaller, getUser };
