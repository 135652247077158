import React from "react";

type StopIconProps = {
  justPassingThrough: boolean;
  isCancelled: boolean;
  color: string;
};

export const StopIcon: React.FC<StopIconProps> = ({
  justPassingThrough,
  isCancelled,
  color,
}) => {
  if (isCancelled) {
    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Kansellert</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L3.89493 4.95559L0.46967 8.38085C0.176777 8.67374 0.176777 9.14861 0.46967 9.44151C0.762563 9.7344 1.23744 9.7344 1.53033 9.44151L4.95559 6.01625L8.38085 9.44151C8.67374 9.7344 9.14862 9.7344 9.44151 9.44151C9.7344 9.14861 9.7344 8.67374 9.44151 8.38085L6.01625 4.95559L9.44151 1.53033C9.7344 1.23744 9.7344 0.762563 9.44151 0.46967C9.14862 0.176777 8.67374 0.176777 8.38085 0.46967L4.95559 3.89493L1.53033 0.46967Z"
          fill={color}
        />
      </svg>
    );
  }

  return justPassingThrough ? (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.5" cy="4.5" r="4.5" fill={color} />
    </svg>
  ) : (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7.5" r="5.5" stroke={color} strokeWidth="3" />
    </svg>
  );
};
