import {
  DROPS_CONTEXT_TYPE,
  FILTERED_CONTEXT_TYPES,
  OPLOG_CONTEXT_TYPE,
} from "shared/types/types";
import { OperationalTextContextType } from "../../features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";

const contextCompare = (
  a: OperationalTextContextType,
  b: OperationalTextContextType,
) => {
  // DROPS should show up first always
  if (a.type === DROPS_CONTEXT_TYPE) return -1;
  if (b.type === DROPS_CONTEXT_TYPE) return 1;

  // OPLOG should show up second always
  if (a.type === OPLOG_CONTEXT_TYPE) return -1;
  if (b.type === OPLOG_CONTEXT_TYPE) return 1;

  // The rest should be alphabetical
  return a.name.localeCompare(b.name);
};

// We do not display Drops context, and add it to sent contexts by default
const filterUnwantedContexts = (
  contexts: OperationalTextContextType[],
  additionalFilteredContexts?: string[],
) => {
  const allFilteredContexts = FILTERED_CONTEXT_TYPES.concat(
    additionalFilteredContexts ?? [],
  );

  return contexts.filter(
    (context) => !allFilteredContexts.includes(context.type),
  );
};

export const filterAndSortContexts = (
  contexts?: OperationalTextContextType[],
  additionalFilteredContexts?: string[],
): OperationalTextContextType[] => {
  if (contexts === undefined || contexts.length === 0) return [];

  const filtered = filterUnwantedContexts(contexts, additionalFilteredContexts);
  return filtered.toSorted(contextCompare);
};
