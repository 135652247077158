import colors from "shared/theme/colors";

export const darkTheme = {
  themeName: "dark",

  /// ///////////////////////////////////////
  // Use SPOR components and color variables if possible
  // Is component is custom or overwritten, use the following color variables
  // Don't create color variables inside your custom components: Create them here
  // Don't even think about using a color without a variable
  /// ///////////////////////////////////////

  // BACKGROUND
  colorBackgroundMain: colors.darkerGrey, // #1C1C1D
  colorBackgroundSecondary: colors.darkGrey, // #2B2B2C
  colorBackgroundTertiary: colors.dimmedDarkGrey, // #414245
  colorHoverItem: colors.whiteAlpha["100"], // #00000 20%

  // TEXT
  colorTextMain: colors.lightGrey, // #F5F5F5
  colorTextSecondary: colors.steel, // #AFB2B3
  colorTextDisabled: colors.dimGrey, // #606568
  colorSelectedText: colors.darkTeal, // #00453E

  // OUTLINES
  colorSeparationLine: colors.dimGrey, // #606568
  colorOutline: colors.whiteAlpha["100"], // rgba(1,1,1,0.4)
  colorOutlineGreen: colors.azure, // #38B49E

  // SEMANTICS
  // Alarm 1
  colorAlarmTextMain: colors.pink,
  colorAlarmTextSecondary: colors.salmon,
  colorAlarm: colors.roseCustom,
  colorAlarmPulse: colors.raspberryCustom,
  colorAlarmFill: colors.red800,
  colorAlarmFillPulse: colors.red900,
  colorAlarmOutline: colors.red700,

  // Alarm 2
  colorSecondaryAlarmTextMain: colors.bisque,
  colorSecondaryAlarmTextSecondary: colors.rajah,
  colorSecondaryAlarm: colors.saffron,
  colorSecondaryAlarmFill: colors.orange900,
  colorSecondaryAlarmFillPulse: colors.orange1000,
  colorSecondaryAlarmOutline: colors.orange700,

  // Warning
  colorWarning: colors.sunshine,
  colorWarningFill: colors.yellow900,
  colorWarningTextMain: colors.cornsilk,
  colorWarningTextSecondary: colors.primrose,
  colorWarningFillPulse: colors.yellow1000,
  colorWarningOutline: colors.yellow700,

  // Success
  colorSuccess: colors.blueGreen,
  colorSuccessFill: colors.darkTeal,
  colorSuccessTextMain: colors.mint,
  colorSuccessTextSecondary: colors.coralGreen,
  colorSuccessFillPulse: colors.night,
  colorSuccessOutline: colors.pine,

  // Info
  colorInfo: colors.glacier,
  colorInfoFill: colors.blue900,
  colorInfoTextMain: colors.icyBlue,
  colorInfoTextSecondary: colors.cloudy,
  colorInfoFillPulse: colors.blue1000,
  colorInfoOutline: colors.darkBlue,

  // Neutral
  colorNeutral: colors.silver,
  colorNeutralTextMain: colors.silver,
  colorNeutralTextSecondary: colors.dimGrey,
  colorNeutralFill: colors.darkGrey,
  colorNeutralFillPulse: colors.dimGrey,
  colorNeutralOutline: colors.dimGrey,

  // SPECIALS
  primaryBtnColor: colors.darkTeal, // #00453E
  colorSelected: colors.darkTeal, // #CCEAE4
  skeletonElementColor: colors.dimGrey, // #606568
  searchFilterActive: colors.dimGrey, // #606568
  filterIndicator: colors.azure, // #38B49E
};

export type DarkTheme = typeof darkTheme;
