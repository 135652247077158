import { ChangeEvent, FC, ReactElement } from "react";
import { CommonSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { AffectedStopsFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/AffectedStopsFields";
import { ChoiceChip, FormLabel, Stack } from "@vygruppen/spor-react";
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import {
  EitherTrainFormSchema,
  TrainMissingProductsEnum,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import {
  CafeFill24Icon,
  CafeOutline24Icon,
  FamilyFill24Icon,
  FamilyOutline24Icon,
  PetAllowedFill24Icon,
  PetAllowedOutline24Icon,
  PetNotAllowedFill24Icon,
  PetNotAllowedOutline24Icon,
  PlussSeatFill24Icon,
  PlussSeatOutline24Icon,
  RecliningSeatFill24Icon,
  RecliningSeatOutline24Icon,
  Sleep6BedsFill24Icon,
  Sleep6BedsOutline24Icon,
  SleepFill24Icon,
  SleepOutline24Icon,
  SofaFill24Icon,
  SofaOutline24Icon,
  SoundOffFill24Icon,
  SoundOffOutline24Icon,
  ToiletFill24Icon,
  ToiletOutline24Icon,
  WifiFill24Icon,
  WifiOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Select } from "shared/components/forms/Select";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { TrainContext } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";

type ProductType = {
  type: TrainMissingProductsEnum;
  name: string;
  outlineIcon: ReactElement;
  fillIcon: ReactElement;
};

const products: ProductType[] = [
  {
    type: "FIRST_CLASS_CAR",
    name: "Pluss",
    outlineIcon: <PlussSeatOutline24Icon />,
    fillIcon: <PlussSeatFill24Icon />,
  },
  {
    type: "SLEEPING_CAR",
    name: "Sovevogn",
    outlineIcon: <SleepOutline24Icon />,
    fillIcon: <SleepFill24Icon />,
  },
  {
    type: "FAMILY_CAR",
    name: "Familievogn",
    outlineIcon: <FamilyOutline24Icon />,
    fillIcon: <FamilyFill24Icon />,
  },
  {
    type: "ANIMALS_ALLOWED_CAR",
    name: "Dyr tillatt vogn",
    outlineIcon: <PetAllowedOutline24Icon />,
    fillIcon: <PetAllowedFill24Icon />,
  },
  {
    type: "ANIMALS_NOT_ALLOWED_CAR",
    name: "Dyrefri vogn",
    outlineIcon: <PetNotAllowedOutline24Icon />,
    fillIcon: <PetNotAllowedFill24Icon />,
  },
  {
    type: "RESTAURANT_CAR",
    name: "Kafévogn",
    outlineIcon: <CafeOutline24Icon />,
    fillIcon: <CafeFill24Icon />,
  },
  {
    type: "WIFI",
    name: "Trådløst internett",
    outlineIcon: <WifiOutline24Icon />,
    fillIcon: <WifiFill24Icon />,
  },
  {
    type: "QUIET_SECTION",
    name: "Stille",
    outlineIcon: <SoundOffOutline24Icon />,
    fillIcon: <SoundOffFill24Icon />,
  },
  {
    type: "FUNCTIONING_TOILETS",
    name: "Fungerende toaletter",
    outlineIcon: <ToiletOutline24Icon />,
    fillIcon: <ToiletFill24Icon />,
  },
  {
    type: "COMPARTMENT",
    name: "Kupé",
    outlineIcon: <SofaOutline24Icon />,
    fillIcon: <SofaFill24Icon />,
  },
  {
    type: "REST",
    name: "Hvile",
    outlineIcon: <Sleep6BedsOutline24Icon />,
    fillIcon: <Sleep6BedsFill24Icon />,
  },
  {
    type: "PLUS_NIGHT",
    name: "PlussNatt",
    outlineIcon: <RecliningSeatOutline24Icon />,
    fillIcon: <RecliningSeatFill24Icon />,
  },
];

export const TrainMissingProduct: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps & TrainContext
> = ({ formContext, trainFormPrefix, ...props }) => {
  // Cast context fields, the default from react-hook-form isn't good enough
  const getValues =
    formContext.getValues as UseFormGetValues<EitherTrainFormSchema>;
  const setValue =
    formContext.setValue as UseFormSetValue<EitherTrainFormSchema>;
  const register =
    formContext.register as UseFormRegister<EitherTrainFormSchema>;

  const productsFieldPath = `${trainFormPrefix}.products` as const;
  const coverageFieldPath = `${trainFormPrefix}.coverage` as const;

  const productIsChecked = (product: TrainMissingProductsEnum): boolean =>
    getValues(productsFieldPath)?.some((v) => v === product) ?? false;

  const onProductClick = (
    product: TrainMissingProductsEnum,
    evt: ChangeEvent<HTMLInputElement>,
  ) => {
    const oldValues = getValues(productsFieldPath) ?? [];
    if (evt.target.checked) {
      if (!productIsChecked(product)) {
        setValue(productsFieldPath, [...oldValues, product], {
          shouldValidate: true,
        });
      }
    } else {
      setValue(
        productsFieldPath,
        oldValues.filter((v) => v !== product),
        { shouldValidate: true },
      );
    }
  };

  return (
    <>
      <AffectedStopsFields
        formContext={
          // TS apparently checks this type combinatorially, and isn't able to understand that
          // formContext matches trainFormPrefix on nested components. But it does, so we can
          // tell it not to worry about it
          formContext as any
        }
        trainFormPrefix={trainFormPrefix}
        {...props}
      />
      <div>
        <FormLabel marginBottom="2">Produkter som mangler:</FormLabel>
        <Stack flexDirection="row" flexWrap="wrap">
          {products.map((product) => (
            <ChoiceChip
              key={product.type}
              icon={{
                default: product.outlineIcon,
                checked: product.fillIcon,
              }}
              onChange={(evt) => onProductClick(product.type, evt)}
              isChecked={productIsChecked(product.type)}
            >
              {product.name}
            </ChoiceChip>
          ))}
        </Stack>
      </div>
      <Select
        {...register(coverageFieldPath)}
        value={getValues(coverageFieldPath)}
        label="Dekning"
        placeholder="Velg hvor produktene mangler"
      >
        <option value="WHOLE_TRAIN">Hele toget</option>
        <option value="FRONT_SET">Fremre togsett</option>
        <option value="REAR_SET">Bakre togsett</option>
      </Select>
    </>
  );
};
