import { formatISO, isBefore, isEqual } from "date-fns";
import { z } from "zod";

/**
 * SEARCH PARAMS
 */
export interface AlternativeTransportSearchParams
  extends Record<string, string> {
  countryCode: string;
  from: string;
  to: string;
}

export interface AlternativeTransportCachedSearchParams
  extends Record<string, string> {
  countryCode: string;
  date: string;
}

export const serviceTypeSchema = z.enum(["OPERATIONAL", "PLANNED"]);
export type ServiceType = z.infer<typeof serviceTypeSchema>;

export const vehicleTypeSchema = z.enum([
  "BUS",
  "MINIBUS",
  "TAXI",
  "MAXITAXI",
  "UNKNOWN",
]);
export type VehicleType = z.infer<typeof vehicleTypeSchema>;

export const vehicleStatusSchema = z.enum([
  "CONFIRMED",
  "PLANNED",
  "CANCELLED",
]);
export type VehicleStatus = z.infer<typeof vehicleStatusSchema>;

export const sourceSystemSchema = z.object({
  author: z.string().nullable(),
  name: z.string(),
});

/**
 * ALTERNATIVE TRANSPORT LOG
 */
export const alternativeTransportLogSchema = z.object({
  id: z.string(),
  message: z.string().nullable(),
  tags: z.array(z.string()),
  timestamp: z.string(), // ZonedDateTime
  sourceSystem: sourceSystemSchema,
  vehicleId: z.string(),
  locationName: z.string().nullable(),
});

export type AlternativeTransportLog = z.infer<
  typeof alternativeTransportLogSchema
>;

export type AlternativeTransportLogReq = {
  alternativeTransportId: String;
  distributionStopPlaces: String;
  message: String;
};

/**
 * ALTERNATIVE TRANSPORT
 */
export const alternativeTransportSchema = z.object({
  vehicles: z.array(
    z.object({
      id: z.string().uuid(),
      destinationStopName: z.string().min(1).nullable(),
      driverPhoneNumber: z.string().nullable(),
      isShuttleVehicle: z.boolean(),
      originStopName: z.optional(z.string()),
      firstStopName: z.string().nullable(),
      scheduledArrivalTimeDestination: z
        .string()
        .datetime({ offset: true })
        .nullable()
        .transform((input) => (input ? new Date(input) : null))
        .catch(null),
      scheduledDepartureTimeOrigin: z
        .string()
        .datetime({ offset: true })
        .nullable()
        .transform((input) => (input ? new Date(input) : null))
        .catch(null),
      trainIds: z.array(z.string()),
      workShiftId: z.string().nullable(),
      hasUnreadLogs: z.boolean().nullable(),
      vehicleStatus: vehicleStatusSchema,
    }),
  ),
});

export type AlternativeTransportResponse = z.infer<
  typeof alternativeTransportSchema
>;
export type AlternativeTransport =
  AlternativeTransportResponse["vehicles"][number];

/**
 * RESERVE VEHICLE
 */
export const reserveAlternativeTransportSchema = z.object({
  id: z.string().uuid(),
  trainIds: z.array(z.string()),
  baseStopName: z.string(),
  workShiftId: z.string().nullable(),
  driverPhoneNumber: z.string().nullable(),
  shiftStartTime: z.coerce.date().nullable(),
  shiftEndTime: z.coerce.date().nullable(),
  type: vehicleTypeSchema,
  isAvailable: z.boolean(),
  operatorName: z.string(),
  logs: z.array(alternativeTransportLogSchema).nullable(),
  notes: z.string().array(),
});

export type ReserveAlternativeTransport = z.infer<
  typeof reserveAlternativeTransportSchema
>;

export type GetReserveAlternativeTransport = Omit<
  ReserveAlternativeTransport,
  "shiftStartTime" | "shiftEndTime"
> & {
  shiftStartTime: string | null;
  shiftEndTime: string | null;
};

export type GetReserveAlternativeTransportResponse = {
  vehicles: Array<GetReserveAlternativeTransport>;
};

export const reserveAlternativeTransportListResponseSchema = z.object({
  vehicles: z.array(reserveAlternativeTransportSchema),
});

export type ReserveAlternativeTransportResponse = z.infer<
  typeof reserveAlternativeTransportListResponseSchema
>;

/**
 * STOP REFERENCE
 */
export const stopReferenceSchema = z
  .object({
    nsrCode: z.string().nullable(),
    uicCode: z.string().nullable(),
    jbvId: z.string().nullable(),
    hastusCode: z.string().nullable(),
    nsrStopPlace: z.string().nullable(),
  })
  .refine(
    ({ nsrCode, uicCode, jbvId, hastusCode, nsrStopPlace }) =>
      nsrCode !== null ||
      uicCode !== null ||
      jbvId !== null ||
      hastusCode !== null ||
      nsrStopPlace !== null,
    { message: "One of the fields must have a value" },
  );

export type StopReference = z.infer<typeof stopReferenceSchema>;

// SCHEMAS:
export const stopActivitySchema = z.enum([
  "BOARDING",
  "DISEMBARKING",
  "BOARDING_AND_DISEMBARKING",
  "NONE",
]);

const alternativeTransportItineraryStopSchema = z.object({
  name: z.string().nullable(),
  nsrCode: z.string().nullable(),
  stopReference: stopReferenceSchema,
  activity: stopActivitySchema,
  scheduledArrivalTime: z.coerce.date().nullable(), // ZonedDateTime
  scheduledDepartureTime: z.coerce.date().nullable(), // ZonedDateTime
  estimatedArrivalTime: z.coerce.date().nullable(), // ZonedDateTime
  estimatedDepartureTime: z.coerce.date().nullable(), // ZonedDateTime
  actualArrivalTime: z.coerce.date().nullable(), // ZonedDateTime
  actualDepartureTime: z.coerce.date().nullable(), // ZonedDateTime
  secondsTravelTimeToNextStop: z.number(),
  isArrivalCancelled: z.boolean(),
  isDepartureCancelled: z.boolean(),
});

const alternativeTransportItinerarySchema = z.object({
  stops: z.array(alternativeTransportItineraryStopSchema),
  returnStops: z.array(alternativeTransportItineraryStopSchema).nullable(),
  firstStopName: z.string().nullable(),
});

const workShiftTripSchema = z.object({
  vehicleUuid: z.string().uuid(),
  tripPatternId: z.string().nullable(),
  originStopName: z.string().nullable(),
  destinationStopName: z.string().nullable(),
  firstStopName: z.string().nullable(),
  scheduledDepartureTimeOrigin: z.coerce.date().nullable(), // ZonedDateTime
  scheduledArrivalTimeDestination: z.coerce.date().nullable(), // ZonedDateTime
  trainIds: z.array(z.string()),
  stopReferences: z.array(stopReferenceSchema).nullable(),
});

const workShiftPlanSchema = z.object({
  isirkId: z.string().nullable(),
  shiftId: z.string(),
  shiftDate: z.coerce.date(), // LocalDate
  workShiftTrips: z.array(workShiftTripSchema),
});

export const alternativeTransportVehicleSchema = z.object({
  id: z.string().uuid(),
  type: vehicleTypeSchema,
  serviceType: serviceTypeSchema,
  trainIds: z.array(z.string()),
  itinerary: alternativeTransportItinerarySchema.nullable(),
  originStopName: z.string().nullable(),
  firstStopName: z.string().nullable(),
  destinationStopName: z.string().nullable(),
  scheduledDepartureTimeOrigin: z.coerce.date().nullable(), // ZonedDateTime
  scheduledArrivalTimeDestination: z.coerce.date().nullable(), // ZonedDateTime
  lineNames: z.array(z.string()),
  logs: z.array(alternativeTransportLogSchema),
  operatorName: z.string().nullable(),
  currentStopReference: stopReferenceSchema.nullable(),
  driverPhoneNumber: z.string().nullable(),
  workShiftPlan: workShiftPlanSchema.nullable(),
  tripPatternId: z.string().nullable(),
  isPartOfShuttlePlan: z.boolean(),
  hastusShuttlePlanId: z.string().nullable(),
  vehicleStatus: vehicleStatusSchema,
  notes: z.string().array(),
});

export const alternativeTransportResponseSchema = z.object({
  alternativeTransportResponseVehicle:
    alternativeTransportVehicleSchema.nullable(),
});

// TYPES:
export type AlternativeTransportVehicle = z.infer<
  typeof alternativeTransportVehicleSchema
>;

export type AlternativeTransportItinerary = z.infer<
  typeof alternativeTransportItinerarySchema
>;

export type AlternativeTransportItineraryStop = z.infer<
  typeof alternativeTransportItineraryStopSchema
>;

export type AlternativeTransportCancelReq = {
  cancelledStops: CancelledStop[];
};

export type CancelledStop = {
  stopReference: StopReference;
  stopName: string | null;
  isArrivalCancelled: boolean;
  isDepartureCancelled: boolean;
};

export type WorkShiftPlan = z.infer<typeof workShiftPlanSchema>;

/**
 * UPDATE REQUEST ALTERNATIVE TRANSPORT
 */
export const updateAlternativeTransportRequestSchema = z
  .object({
    phoneNumberUpdateRequest: z.object({
      phoneNumber: z.string(),
      distributionStopReferences: z.array(stopReferenceSchema),
    }),
    operatorNameUpdateRequest: z.object({
      operatorName: z.string(),
      distributionStopReferences: z.array(stopReferenceSchema),
    }),
    actualStopTimeUpdateRequest: z
      .object({
        stopReference: stopReferenceSchema,
        stopName: z.string().nullish(),
        distributionStopReferences: z.array(stopReferenceSchema),
        actualArrivalTime: z
          .date()
          .nullish()
          .transform((value) => value && formatISO(value)),
        actualDepartureTime: z
          .date()
          .nullish()
          .transform((value) => value && formatISO(value)),
      })
      .refine(
        ({ actualArrivalTime, actualDepartureTime }) =>
          actualArrivalTime && actualDepartureTime
            ? isBefore(
                new Date(actualArrivalTime),
                new Date(actualDepartureTime),
              ) ||
              isEqual(
                new Date(actualArrivalTime),
                new Date(actualDepartureTime),
              )
            : true,
        {
          message:
            "If both arrival and departure time is set. Arrival must be before or equal to departure",
        },
      ),
  })
  .partial()
  .refine((data) => Object.values(data).filter((value) => !!value).length > 0, {
    message: "One of the fields must have a value",
  });

export const updateMultipleAlternativeTransportRequestSchema = z.object({
  uuids: z.array(z.string()),
  updateRequest: updateAlternativeTransportRequestSchema,
});

export type UpdateAlternativeTransportRequest = z.infer<
  typeof updateAlternativeTransportRequestSchema
>;

export type UpdateAlternativeTransportRequestInput = z.input<
  typeof updateAlternativeTransportRequestSchema
>;

export type UpdateMultipleAlternativeTransportRequest = z.infer<
  typeof updateMultipleAlternativeTransportRequestSchema
>;

/**
 * BOOK RESERVE VEHICLE
 */
export const reserveAlternativeTransportBookingRequestSchema = z.object({
  available: z.boolean(),
});

export type ReserveAlternativeTransportBookingRequest = z.infer<
  typeof reserveAlternativeTransportBookingRequestSchema
>;

/**
 * UPDATE PHONE NUMBER
 */
export type PhoneNumberUpdateRequest = NonNullable<
  UpdateAlternativeTransportRequest["phoneNumberUpdateRequest"]
>;

/**
 * UPDATE OPERATOR NAME
 */
export type OperatorNameUpdateRequest = NonNullable<
  UpdateAlternativeTransportRequest["operatorNameUpdateRequest"]
>;

/**
 * UPDATE STOP
 */
export type StopUpdateRequest = NonNullable<
  UpdateAlternativeTransportRequest["actualStopTimeUpdateRequest"]
>;
