import { tokens } from "@vygruppen/spor-react";
import { appPalette } from "shared/theme/colors/appPalette";

const colors = {
  ...appPalette,
  ...tokens.default.color.alias,
  ...tokens.default.color.palette,
};

export default colors;
