import { useQuery } from "@tanstack/react-query";
import { GeographicTrainMapTopology } from "@vygruppen/vy-train-map";
import { getTrafficguiBaseUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { environment } from "environment";

export const TOPOLOGY_QUERY_KEY = "topology";

export const useTrainMapTopology = () => {
  const { data: trainMapTopology, status: trainMapTopologyStatus } = useQuery({
    queryKey: [TOPOLOGY_QUERY_KEY],
    queryFn: ({ signal }) =>
      queryFnGET<GeographicTrainMapTopology>({
        signal,
        url: `${getTrafficguiBaseUrl(
          environment(),
        )}/trainmap-backend/topology?countryCode=NO`,
      }),
  });

  return { trainMapTopology, trainMapTopologyStatus };
};
