import { LogLevel, log } from "api/cloudWatch";
import { refreshCognitoTokens } from "api/cognito/cognito";
import {
  getDropsToken,
  getRefreshToken,
  setDropsToken,
} from "api/dropsLocalStorage";
import { resetSessionAndRedirectToRoot } from "app/Auth/auth";
import axios, { AxiosInstance, AxiosResponse } from "axios";

export const authorizationHeader = () => ({
  Authorization: `Bearer ${getDropsToken()}`,
});

/**
 * This field is used as a lock to prevent multiple calls to the refresh token endpoint in cognito
 */
let refreshInProgress: Promise<AxiosResponse> | null = null;

/**
 * To avoid multiple "concurrent" calls to the refresh token endpoint in cognito this function can be used
 * to synchronize access to this endpoint. This typically happens when the access token has expired, and multiple calls
 * are being made to the backend: all returning 401 and each one triggering a refresh token request.
 * This is not strictly necessary, as cognito will gladly handle all requests, but it just _feels_ wrong to
 * hammer cognito will so many unnecessary calls.
 * @param refreshToken
 */
export const refreshCognitoTokensSynchronized = (
  refreshToken: string,
): Promise<AxiosResponse> => {
  if (!refreshInProgress) {
    refreshInProgress = refreshCognitoTokens(refreshToken)
      .then((response) => {
        if (response.data) {
          setDropsToken(response.data.access_token);
        } else {
          // this should never happen, but in case it does, we redirect to root so AuthBoundary can figure
          // out what to do
          resetSessionAndRedirectToRoot();
        }
        refreshInProgress = null;
        return response;
      })
      .catch((error) => {
        log(
          LogLevel.warn,
          "AxiosAuthInterceptor",
          `Cognito token refresh failed with error - ${JSON.stringify(error)}`,
        );
        refreshInProgress = null;
        return Promise.reject(error);
      });
  }
  return refreshInProgress;
};
/**
 * Register an axios interceptor that will handle 401 responses and attempt to
 * refresh token before retrying the request.
 *
 * @param axiosClient the axios client to register this interceptor on
 * @param logSource an identifier for this axios client, for logging purposes
 */
export const registerAxiosAuthInterceptor = (
  axiosClient: AxiosInstance,
  logSource: string,
) => {
  axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
      const refresh_token = getRefreshToken();

      // if no refresh token is present, clear the local state and redirect to root so AuthBoundary
      // can figure out what to do
      if (
        error.response?.status === 401 &&
        error.config &&
        !error.config._retry &&
        (!refresh_token || refresh_token === "")
      ) {
        log(
          LogLevel.warn,
          logSource,
          `Call to ${error.response?.request?.responseURL} failed with status 401 - ${error.response.statusText}. No refresh token available, logging out.`,
        );
        resetSessionAndRedirectToRoot();
        return Promise.reject(error);
      }

      // use the refresh token to acquire a new access token and retry the request
      if (
        error.response?.status === 401 &&
        error.config &&
        !error.config._retry &&
        refresh_token
      ) {
        log(
          LogLevel.warn,
          logSource,
          `Call to ${error.response?.request?.responseURL} failed with status 401 - ${error.response.statusText}. Will attempt to refresh cognito tokens.`,
        );

        return new Promise((resolve, reject) => {
          // to avoid multiple concurrent requests to the refresh token endpoint, we use this synchronized variant of the function
          refreshCognitoTokensSynchronized(refresh_token)
            .then(() => {
              // We will perform a retry of the request, and to avoid an infinite loop we store this custom
              // field saying that this is a "retry request"
              error.config._retry = true;
              // We need to update the Authorization header to use the newly acquired access token on
              // the retry request (otherwise the old token would be used)
              error.config.headers.Authorization =
                authorizationHeader().Authorization;

              // retry the request by using the same request config (but with an updated Authorization-header)
              resolve(axios(error.config));
            })
            .catch((interceptError) => {
              log(
                LogLevel.warn,
                "AxiosAuthInterceptor",
                `Failed to refresh token: ${interceptError}`,
              );
              resetSessionAndRedirectToRoot();
              reject(interceptError);
            });
        });
      }

      // log errors
      if (error.response) {
        log(
          LogLevel.error,
          logSource,
          `Call to ${error.response?.request?.responseURL} failed with status ${error.response.status} - ${error.response.statusText}: ${error.response.data?.detail}`,
        );
      }

      return Promise.reject(error);
    },
  );
};
