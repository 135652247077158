import { UseQueryResult } from "@tanstack/react-query";
import {
  FilterFill24Icon,
  FilterOutline24Icon,
  InformationOutline18Icon,
  TimeOutline18Icon,
} from "@vygruppen/spor-icon-react";
import {
  ChoiceChip,
  Flex,
  HStack,
  Skeleton,
  SkeletonText,
  Tab,
  TabList,
  TabPanel,
  TabPanels as SporTabPanels,
  Tabs,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import HandledTrains from "features/CenterContent/RoleContent/AffectedTrains/HandledTrains";
import UnhandledTrains from "features/CenterContent/RoleContent/AffectedTrains/UnhandledTrains";
import { getSessionStorageFilter } from "features/CenterContent/RoleContent/AffectedTrains/utils/filterAffectedTrains";
import {
  TrainIdentifierWithIncidentIds_JSON,
  TrainIdentifierWithIncidentIdString,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import {
  AffectedTrain,
  AffectedTrainsResponse,
} from "features/CenterContent/RoleContent/Vaktleder/types";
import { CreateTrainInfoBatchModal } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/CreateTrainInfoBatchModal";
import { FC, useEffect, useRef, useState } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { ExpandableInfoMessage } from "shared/components/feedback/InfoMessage/ExpandableInfoMessage/ExpandableInfoMessage";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import styled, { useTheme } from "styled-components";
import { useDropsWebsocket } from "websocket/drops/DropsWebsocketContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: auto;
  width: 100%;
`;

const TabPanels = styled(SporTabPanels)`
  ${ObtrusiveScrollCss};
  border-top: 1px solid ${({ theme }) => theme.colorSeparationLine};
  overflow-y: auto;
`;

const InfoBox = styled.div`
  padding: 12px 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  border-top: 1px solid ${({ theme }) => theme.colorSeparationLine};
  color: ${({ theme }) => theme.colorTextSecondary};
  container-type: inline-size;

  p {
    @container (width < 350px) {
      font-size: 14px !important;
    }
  }
`;

const AffectedTrainsLoader = () => {
  const theme = useTheme();
  return (
    <ul>
      {[...new Array(5)].map((_, i) => (
        <HStack
          width="100%"
          key={i}
          p={2}
          justify="space-between"
          borderBottom={`1px solid ${theme.colorSeparationLine}`}
        >
          <SkeletonText width="150px" />
          <SkeletonText noOfLines={2} alignSelf="center" width="100px" />
          <Skeleton height={5} width="110px" borderRadius="100" />
        </HStack>
      ))}
    </ul>
  );
};

type AffectedTrainsProps = {
  affectedTrainsResult: UseQueryResult<AffectedTrainsResponse>;
};

const AffectedTrains: FC<AffectedTrainsProps> = ({ affectedTrainsResult }) => {
  /* Data */
  const {
    data: affectedTrainsData,
    isPending,
    isSuccess,
    isError,
  } = affectedTrainsResult;

  // Data source for no. incidents
  const incidentsCount = new Set(
    affectedTrainsData?.affectedTrains.map((t) => t.incidentId) ?? [],
  ).size;

  const { message: trainInfoMessage } = useDropsWebsocket("AffectedTrains", [
    "TRAIN_INFORMATION",
  ]);

  /**
   * refetch affected trains whenever we receive a new event for one of the affected trains
   * If we receive a lot of messages at once, we only refetch after the last one
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        trainInfoMessage.status === "received" &&
        trainInfoMessage.data.topic === "TRAIN_INFORMATION" &&
        affectedTrainsResult.isSuccess
      ) {
        const messageContainsAffectedTrain =
          trainInfoMessage.data.message.versions.some((version) =>
            [
              ...affectedTrainsResult.data.affectedTrains,
              ...affectedTrainsResult.data.handledAffectedTrains,
            ].some(
              (affectedTrain) =>
                affectedTrain.trainId.identifier ===
                  version.trainIdentifier.operational_identifier &&
                affectedTrain.trainId.nominalDate ===
                  version.trainIdentifier.nominal_date &&
                affectedTrain.trainId.countryCode ===
                  version.trainIdentifier.country_code,
            ),
          );
        if (messageContainsAffectedTrain) {
          affectedTrainsResult.refetch();
        }
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [trainInfoMessage]);

  /* State */
  const [selectedTrains, setSelectedTrains] = useState<AffectedTrain[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [showFilter, setShowfilter] = useState<boolean>(() =>
    getSessionStorageFilter() === null ? false : true,
  );
  const [showPassedTrains, setShowPassedTrains] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const [pendingTrains, setPendingTrains] = useState<
    TrainIdentifierWithIncidentIdString[]
  >([]);

  /* Handlers */
  const updateSelectedTrains = (affectedTrain: AffectedTrain) => {
    const idx = selectedTrains.findIndex(
      (selectedTrain) =>
        selectedTrain.trainId.identifier === affectedTrain.trainId.identifier &&
        selectedTrain.incidentId === affectedTrain.incidentId &&
        selectedTrain.trainId.nominalDate === affectedTrain.trainId.nominalDate,
    );
    if (idx !== -1) {
      setSelectedTrains(selectedTrains.toSpliced(idx, 1));
    } else {
      setSelectedTrains([...selectedTrains, affectedTrain]);
    }
  };

  const batchUpdateSelectedTrains = (affectedTrains: AffectedTrain[]) => {
    if (
      selectedTrains.length > 0 &&
      affectedTrains[0].incidentId === selectedTrains[0].incidentId
    ) {
      setSelectedTrains([...selectedTrains, ...affectedTrains]);
    } else {
      setSelectedTrains(affectedTrains);
    }
  };

  const clearSelectedTrains = () => {
    setSelectedTrains([]);
  };

  const handleCreateEvents = () => {
    // Will handle all trains in selectedTrains
    setModalOpen(true);
  };

  const affectedTrainsToShow = () => {
    if (showPassedTrains) {
      return affectedTrainsData?.affectedTrains;
    }
    return affectedTrainsData?.affectedTrains.filter((t) => {
      const time = t.incidentExitTime ?? t.incidentArrivalTime;
      const gracePeriod = 900_000; // 900s grace period
      if (!time) return true;
      return (
        time.getTime() + gracePeriod >= new Date().getTime() ||
        selectedTrains.find(
          (it) => it.trainId === t.trainId && it.incidentId === t.incidentId,
        )
      );
      // it.trainId === t.trainId check works since they have the same refererence
    });
  };

  const addPendingTrains = (trains: TrainIdentifierWithIncidentIds_JSON[]) => {
    const newTrainIds = trains
      .map<TrainIdentifierWithIncidentIdString>(
        (train) =>
          `${train.incident_ids[0]}-${train.country_code}-${train.operational_identifier}-${train.nominal_date}`,
      )
      .filter((trainId) => !pendingTrains.includes(trainId));

    setPendingTrains([...pendingTrains, ...newTrainIds]);
  };

  const removePendingTrains = (
    trains: TrainIdentifierWithIncidentIds_JSON[],
  ) => {
    const idsToRemove = trains.map<TrainIdentifierWithIncidentIdString>(
      (train) =>
        `${train.incident_ids[0]}-${train.country_code}-${train.operational_identifier}-${train.nominal_date}`,
    );

    setPendingTrains(
      pendingTrains.filter((trainId) => !idsToRemove.includes(trainId)),
    );
  };

  /* Side effects */

  // On data update sync trains and selectedTrains
  useEffect(() => {
    if (affectedTrainsData?.affectedTrains && selectedTrains.length > 0) {
      setSelectedTrains(
        selectedTrains.filter((selectedTrain) =>
          affectedTrainsData.affectedTrains.some(
            ({ trainId, incidentId }) =>
              trainId.identifier === selectedTrain.trainId.identifier &&
              trainId.nominalDate === selectedTrain.trainId.nominalDate &&
              selectedTrain.incidentId === incidentId,
          ),
        ),
      );
      removePendingTrains(
        affectedTrainsData.affectedTrains.map(({ trainId, incidentId }) => ({
          country_code: trainId.countryCode,
          operational_identifier: trainId.identifier,
          nominal_date: trainId.nominalDate,
          incident_ids: [incidentId],
        })),
      );
    }
  }, [affectedTrainsData]);

  // Reset show passed trains when switching tabs
  useEffect(() => {
    setShowPassedTrains(false);
  }, [tabIndex]);

  // Train lists need a ref to container for pagination of results on scroll
  const trainListContainerRef = useRef<HTMLDivElement>(null);
  return (
    <Flex flexDir="column" overflowY="auto" width="100%">
      <Container>
        {isPending && <AffectedTrainsLoader />}
        {isError && <FailureMessage style={{ margin: "12px" }} />}
        {isSuccess && (
          <>
            {affectedTrainsData.errorMessages.length > 0 && (
              <ExpandableInfoMessage
                severity="error"
                title="Kunne ikke hente berørte tog for enkelte hendelser"
                style={{ margin: "12px" }}
              >
                {affectedTrainsData.errorMessages.map((stretch) => (
                  <Text key={stretch} m={1}>
                    {stretch}: Kunne ikke hente berørte tog for hendelsen.
                  </Text>
                ))}
              </ExpandableInfoMessage>
            )}
            <Tabs
              variant="base"
              colorScheme="base"
              overflowY="auto"
              onChange={(index) => setTabIndex(index)}
            >
              <VStack mx={2} alignItems="flex-start">
                <HStack
                  justify="space-between"
                  w="100%"
                  flexWrap="wrap"
                  alignItems="center"
                >
                  <TabList width="max-content" mt="19px">
                    <Tab>
                      Ubehandlet ({affectedTrainsData.affectedTrains.length})
                    </Tab>
                    <Tab>
                      Behandlet (
                      {affectedTrainsData.handledAffectedTrains.length})
                    </Tab>
                  </TabList>
                </HStack>
                <Flex width="100%" justifyContent="space-between" gap="0px">
                  <Flex>
                    {tabIndex === 0 && (
                      <ChoiceChip
                        size="md"
                        isChecked={showPassedTrains}
                        onChange={() => setShowPassedTrains(!showPassedTrains)}
                        icon={{
                          default: <TimeOutline18Icon />,
                          checked: <TimeOutline18Icon />,
                        }}
                      >
                        Vis passerte tog
                      </ChoiceChip>
                    )}
                  </Flex>
                  <Flex gap="12px" alignItems="center">
                    {incidentsCount === 1 && (
                      <Text variant="xs" pl={2}>
                        {incidentsCount} aktivt brudd
                      </Text>
                    )}
                    {incidentsCount > 1 && (
                      <Text variant="xs" pl={2}>
                        {incidentsCount} aktive brudd
                      </Text>
                    )}
                    <ChoiceChip
                      size="xs"
                      icon={{
                        default: <FilterOutline24Icon />,
                        checked: <FilterFill24Icon />,
                      }}
                      isChecked={showFilter}
                      onChange={() => setShowfilter(!showFilter)}
                    >
                      Filter
                    </ChoiceChip>
                  </Flex>
                </Flex>
              </VStack>
              <TabPanels mt={2} ref={trainListContainerRef}>
                <TabPanel>
                  {affectedTrainsData?.affectedTrains.length > 0 ? (
                    <UnhandledTrains
                      containerRef={trainListContainerRef}
                      trains={affectedTrainsToShow() ?? []}
                      selectedTrains={selectedTrains}
                      updateSelectedTrains={updateSelectedTrains}
                      clearSelectedTrains={clearSelectedTrains}
                      batchUpdatedSelectedTrains={batchUpdateSelectedTrains}
                      handleCreateEvents={handleCreateEvents}
                      showFilter={showFilter}
                      pendingTrains={pendingTrains}
                    />
                  ) : (
                    <NoDataMessage
                      style={{ padding: "18px" }}
                      reason="Det er ingen ubehandlede tog for øyeblikket"
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {affectedTrainsData?.handledAffectedTrains.length > 0 ? (
                    <HandledTrains
                      trains={affectedTrainsData.handledAffectedTrains}
                      showFilter={showFilter}
                    />
                  ) : (
                    <NoDataMessage
                      style={{ padding: "18px" }}
                      reason="Det er ingen behandlede tog for øyeblikket"
                    />
                  )}
                </TabPanel>
              </TabPanels>
              {tabIndex === 0 && (
                <InfoBox>
                  <InformationOutline18Icon w="18px" h="18px" />
                  <Text variant="xs">
                    Tidene kan avvike litt avhengig av datagrunnlag
                  </Text>
                </InfoBox>
              )}
            </Tabs>
          </>
        )}
      </Container>
      {modalOpen && affectedTrainsData && (
        <CreateTrainInfoBatchModal
          selectedTrains={selectedTrains}
          setModalOpen={setModalOpen}
          updateSelectedTrains={updateSelectedTrains}
          addPendingTrains={addPendingTrains}
        />
      )}
    </Flex>
  );
};

export default withErrorBoundary(AffectedTrains);
