import {
  Button,
  ChoiceChip,
  DatePicker,
  Divider,
  FormControl,
  HStack,
  SearchInput,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { FilterCardSelect } from "features/History/Filter/FilterCardSelect";
import { FilterData } from "features/History/Filter/FilterData";
import { calendarDateToday } from "features/VehicleList/VehicleList";
import { DefaultTheme, useTheme } from "styled-components";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import { ButtonFit, ResponsiveHStack } from "./utils/HistoryCustomTableStyle";

const VerticalDivider = ({ theme }: { theme: DefaultTheme }) => (
  <Divider
    height="36px"
    orientation="vertical"
    borderWidth="1px"
    marginX="4px"
    boxSizing="border-box"
    borderColor={theme.colorSeparationLine}
  />
);

type FilterProps = {
  filter: FilterData;
  setFilter: (filter: FilterData) => void;
};

export const Filter = ({ filter, setFilter }: FilterProps) => {
  const theme = useTheme();
  const globalFeature = useFeatureFlag("gobalEvents").enabled;

  return (
    <VStack
      margin="42px 10px 12px 10px"
      alignItems="start"
      gap="18px"
      overflowX="auto"
    >
      <HStack>
        <SearchInput
          width="440px"
          label="Søk etter tognummer, stasjon, stikkord"
          value={filter.searchString}
          onChange={(e) => setFilter(filter.updateSearchString(e.target.value))}
        />
        <FormControl width="auto">
          <DatePicker
            label="Dato"
            variant="base"
            value={filter.date}
            onChange={(value) =>
              setFilter(filter.updateDate(value ?? calendarDateToday()))
            }
          />
        </FormControl>
      </HStack>
      <ResponsiveHStack gap="8px">
        <HStack>
          <ChoiceChip
            isChecked={filter.actions.includes("train")}
            onChange={() => setFilter(filter.updateAction("train"))}
          >
            <Text whiteSpace="nowrap">Tog</Text>
          </ChoiceChip>
          <ChoiceChip
            isChecked={filter.actions.includes("infrastructure")}
            onChange={() => setFilter(filter.updateAction("infrastructure"))}
          >
            <Text whiteSpace="nowrap">Infrastruktur</Text>
          </ChoiceChip>
          <ChoiceChip
            isChecked={filter.actions.includes("station")}
            onChange={() => setFilter(filter.updateAction("station"))}
          >
            <Text whiteSpace="nowrap">Stasjon</Text>
          </ChoiceChip>
          {globalFeature && (
            <ChoiceChip
              isChecked={filter.actions.includes("global")}
              onChange={() => setFilter(filter.updateAction("global"))}
            >
              <Text whiteSpace="nowrap">Global driftsmelding</Text>
            </ChoiceChip>
          )}

          <div className="DontShowIfSmallWidth">
            <VerticalDivider theme={theme} />
          </div>
        </HStack>

        <HStack>
          <FilterCardSelect
            label="Vis skjulte"
            selectedAmount={[filter.plannedEvents, filter.groupTravel].reduce(
              (sum, current) => (current ? sum + 1 : sum),
              0,
            )}
          >
            <ChoiceChip
              isChecked={filter.plannedEvents}
              onChange={() =>
                setFilter(filter.updatePlannedEvent(!filter.plannedEvents))
              }
            >
              Vis planlagte hendelser
            </ChoiceChip>

            <ChoiceChip
              isChecked={filter.groupTravel}
              onChange={() =>
                setFilter(filter.updateGroupTravel(!filter.groupTravel))
              }
            >
              Vis gruppereise/assistanse
            </ChoiceChip>
          </FilterCardSelect>

          <VerticalDivider theme={theme} />

          <FilterCardSelect
            label="Tilstand"
            selectedAmount={filter.condition !== null ? 1 : 0}
          >
            <ChoiceChip
              isChecked={filter.condition === "open"}
              onChange={() => setFilter(filter.updateCondition("open"))}
            >
              Åpen
            </ChoiceChip>
            <ChoiceChip
              isChecked={filter.condition === "closed"}
              onChange={() => setFilter(filter.updateCondition("closed"))}
            >
              Friskmeldt
            </ChoiceChip>
            <ChoiceChip
              isChecked={filter.condition === "unhandled"}
              onChange={() => setFilter(filter.updateCondition("unhandled"))}
            >
              Må behandles
            </ChoiceChip>
            <ChoiceChip
              isChecked={filter.condition === "replaced"}
              onChange={() => setFilter(filter.updateCondition("replaced"))}
            >
              Erstattet
            </ChoiceChip>
          </FilterCardSelect>
          <FilterCardSelect
            label="Synlighet"
            selectedAmount={filter.visibility !== null ? 1 : 0}
          >
            <ChoiceChip
              isChecked={filter.visibility === "visible"}
              onChange={() => setFilter(filter.updateVisibility("visible"))}
            >
              Synlig nå
            </ChoiceChip>
            <ChoiceChip
              isChecked={filter.visibility === "outdated"}
              onChange={() => setFilter(filter.updateVisibility("outdated"))}
            >
              Utgått
            </ChoiceChip>
          </FilterCardSelect>
          <FilterCardSelect
            label="Togserie"
            selectedAmount={filter.trainSeries.length}
          >
            <ChoiceChip
              isChecked={false}
              onChange={() => setFilter(filter.selectAllTrainIds())}
            >
              Velg alle
            </ChoiceChip>
            {filter.allTrainIds.map((trainId) => (
              <ChoiceChip
                key={trainId}
                isChecked={filter.trainSeries.includes(trainId)}
                onChange={() => setFilter(filter.updateTrainIds(trainId))}
                marginBottom="auto"
              >
                {trainId}
              </ChoiceChip>
            ))}
            <HStack justifyContent="flex-end" width="100%" marginTop="12px">
              <Button
                size="xs"
                justifySelf="right"
                variant="tertiary"
                onClick={() => setFilter(filter.deselectAllTrainIds())}
              >
                Fjern valgte
              </Button>
            </HStack>
          </FilterCardSelect>
          {!filter.isEmpty() && (
            <ButtonFit
              variant="ghost"
              onClick={() => {
                setFilter(new FilterData());
              }}
            >
              <Text whiteSpace="nowrap" fontWeight={400}>
                Fjern Filtre
              </Text>
            </ButtonFit>
          )}
        </HStack>
      </ResponsiveHStack>
    </VStack>
  );
};
