export const supportedOpInfoTypes = [
  "INFRASTRUCTURE_STRETCH_CLOSED",
  "INFRASTRUCTURE_STRETCH_PARTIALLY_CLOSED",
  "INFRASTRUCTURE_SLOW_DRIVING",
  "INFRASTRUCTURE_PLANNED_MAINTENANCE",

  "TRAIN_STOPPED",
  "TRAIN_CANCELLED",
  "TRAIN_CAPACITY_REDUCED",
  "TRAIN_MISSING_PRODUCT",
  "TRAIN_DELAY_EXPECTED",
  "TRAIN_CAPACITY_INCREASED",
  "TRAIN_DELAYED",
  "TRAIN_LATE_TO_TRACK",
  "TRAIN_NOT_STOPPING_AT_STATION",
  "TRAIN_STOPPING_EXTRA_AT_STATION",
  "TRAIN_CLOSED_SET",
  "TRAIN_CHANGED_ROUTE",

  "STATION_THREAT_LEVEL",
  "STATION_SALES_CHANNEL_REDUCED",
  "STATION_DIFFICULT_CUSTOMER",
  "STATION_WANTED_PERSON",
  "STATION_ELEVATOR_FAULT",
  "STATION_ARRIVAL_AND_DEPARTURE_MONITOR_FAULT",
  "STATION_TRACK_MONITOR_FAULT",
  "STATION_SPEAKER_FAULT",
  "STATION_WHEELCHAIR_RAMP_FAULT",
  "STATION_ALL_SALES_CHANNELS_DOWN",
  "STATION_APP_SALES_CHANNEL_DOWN",
  "STATION_TICKET_MACHINE_DOWN",
  "STATION_DEPOSIT_MACHINE_DOWN",
  "STATION_MAIN_MONITOR_FAULT",
  "STATION_PLATFORM_SLIPPERY",
  "STATION_CLOSED_FOR_PASSENGERS",
  "STATION_DROPS_EVACUATION",
  "STATION_COMMUNICATION_SYSTEMS_DOWN",
];
