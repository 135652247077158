import { DefaultError, UseMutationResult } from "@tanstack/react-query";
import { useToast } from "@vygruppen/spor-react";
import { useState } from "react";

export function useMutationMock<T, TVariables = unknown>(): UseMutationResult<
  T,
  DefaultError,
  TVariables
> {
  const [status, setStatus] = useState("idle");
  const toast = useToast();
  return {
    mutate: (_variables?: TVariables, _options?: any) => {
      setStatus("pending");
      toast({
        variant: "info",
        text: `Mock Mutation: ${JSON.stringify(_variables)}`,
        duration: 3000,
      });
      setTimeout(() => {
        setStatus("success");
      }, 500);
    },
    reset: () => {},
    data: undefined,
    context: undefined,
    failureCount: 0,
    failureReason: undefined,
    isPaused: false,
    submittedAt: undefined,
    error: null,
    variables: undefined,
    isError: false,
    isIdle: status === "idle",
    isPending: status === "pending",
    isSuccess: status === "success",
    status,
    mutateAsync: async (_variables?: TVariables, _options?: any) => undefined,
  } as unknown as UseMutationResult<T, DefaultError, TVariables>;
}
