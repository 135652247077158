import { refreshCognitoTokens } from "api/cognito/cognito";
import {
  getDropsToken,
  getRefreshToken,
  setDropsToken,
} from "api/dropsLocalStorage";
import Login from "app/Auth/Login/Login";
import LoginResult from "app/Auth/Login/LoginResult";
import { TrainLoader } from "app/Auth/Login/TrainLoader";
import UnauthorizedUser from "app/Auth/Login/UnauthorizedUser";
import NetworkError from "app/Auth/NetworkError";
import { checkUserPermissions, isExpired } from "app/Auth/auth";
import { ReactElement, useCallback, useState } from "react";
import * as ROUTES from "shared/utils/routes";
import { useAwsRum } from "../../logging/aws-rum";

interface AuthBoundaryProps {
  children: ReactElement;
}

type LoginState =
  | "Init"
  | "Authenticated"
  | "Unauthorized"
  | "RefreshFailed"
  | "Success"
  | "NetworkError";

export type LoginStatus = {
  state: LoginState;
  info?: string;
};

/**
 * This component acts like a boundary for authenticated users and will only render its children if the
 * current user is authenticated and authorized.
 */
export const AuthBoundary = ({ children }: AuthBoundaryProps): ReactElement => {
  const rum = useAwsRum();
  const [loginStatus, setLoginStatus] = useState<LoginStatus>({
    state: "Init",
  });

  const wrapperSetLoginStatus = useCallback(
    (status: LoginStatus) => {
      setLoginStatus(status);
    },
    [setLoginStatus],
  );

  // intercept login callback at /login-redirect
  if (window.location.pathname === ROUTES.LOGIN_REDIRECT) {
    return <LoginResult />;
  }

  switch (loginStatus.state) {
    case "Init": {
      const token = getDropsToken();

      // check if the user has an access token. If not, perform login.
      // We could check for a refresh token and try to use that to fetch an access token,
      // but this seems like a very unlikely scenario for real users, as it requires tampering
      // with local storage to delete the access token, but keep the refresh token.
      if (!token) {
        return <Login />;
      }

      if (!isExpired(token)) {
        setLoginStatus({ state: "Authenticated" });
      } else {
        // if the user has an access token, but it has expired, try to preform a refresh
        const refreshToken = getRefreshToken();

        if (!refreshToken) {
          return <Login />;
        }

        refreshCognitoTokens(refreshToken)
          .then((response) => {
            setDropsToken(response.data.access_token);
            setLoginStatus({ state: "Authenticated" });
          })
          .catch(() =>
            // if refresh fails, perform login
            setLoginStatus({ state: "RefreshFailed" }),
          );
      }

      return <TrainLoader />;
    }

    case "Authenticated": {
      // to verify that the user is authorized (with the correct permissions) we
      // perform a request to userInfo. That way we can redirect the user to
      // the appropriate error page
      checkUserPermissions().then((res) => {
        if (res.permission) {
          setLoginStatus({ state: "Success" });
        } else if (res.reason?.type === "ERR_NETWORK") {
          rum.recordError(res.reason.message);
          setLoginStatus({ state: "NetworkError", info: res.reason.message });
        } else if (
          res.reason?.type === "ERR_AXIOS" ||
          res.reason?.type === "ERR_AUTH"
        ) {
          rum.recordError(res.reason.message);
          setLoginStatus({ state: "Unauthorized" });
        } else {
          setLoginStatus({ state: "Unauthorized" });
        }
      });

      return <TrainLoader />;
    }

    case "Unauthorized": {
      return <UnauthorizedUser />;
    }

    case "RefreshFailed": {
      return <Login />;
    }

    // at this point the user should have a valid access token and be authorized to access the application, so let them through
    case "Success": {
      return children;
    }

    case "NetworkError": {
      return (
        <NetworkError
          updateLoginStatus={wrapperSetLoginStatus}
          message={loginStatus.info}
        />
      );
    }

    // this should never happen
    default:
      return <Login />;
  }
};
