import { PuzzelProvider } from "./provider/PuzzelProvider";
import CallControl from "./components/CallControl/CallControl";
import Logon from "./components/Logon/Logon";

const Puzzel = () => (
  <PuzzelProvider>
    <CallControl />
    <Logon />
  </PuzzelProvider>
);

export default Puzzel;
