import {
  AccordionButton,
  AccordionIcon,
  Badge,
  Spacer,
} from "@vygruppen/spor-react";
import { format } from "date-fns";
import { TitleSubtitleChildren } from "features/History/HistoryDetail/components/TitleSubtitleChildren";
import { EventState } from "features/History/OperationalEvents";
import { CustomResponsiveHStack } from "features/History/utils/HistoryCustomTableStyle";
import { FC } from "react";
import { DropsBadge } from "shared/components/badge/DropsBadge";

export type Status = "Friskmeldt" | "Åpen" | "Erstattet" | "Ubehandlet";
export type TableDetailAccordionButtonProps = {
  version: number;
  date: Date;
  state: EventState;
  title: string;
  subtitle: string;
  description: string;
  user: string;
};

export const TableDetailAccordionButton: FC<
  TableDetailAccordionButtonProps
> = ({ version, date, state, title, subtitle, description, user }) => (
  <h2>
    <AccordionButton paddingY="1rem" fontWeight="normal">
      <CustomResponsiveHStack gap="0.2rem" width="100%" paddingX="0.5rem">
        <TitleSubtitleChildren
          width="6rem"
          column
          title={`Versjon ${version}`}
        />
        <TitleSubtitleChildren
          column
          title={format(date, "dd.MM.yyyy")}
          subtitle={format(date, "HH:mm")}
        />
        <DropsBadge mx="30px" semantics={state.semantics}>
          {state.label}
        </DropsBadge>
        <TitleSubtitleChildren
          style={{
            // Behaviour: Use max 4 lines and show "..." at overflow
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: "4",
            WebkitBoxOrient: "vertical",
          }}
          column
          title={title}
          subtitle={subtitle}
        />
        <TitleSubtitleChildren
          style={{
            // Behaviour: Use max 4 lines and show "..." at overflow
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: "4",
            WebkitBoxOrient: "vertical",
          }}
          column
          marginX="1rem"
          subtitle={description}
        />
        <Spacer />
        <TitleSubtitleChildren
          column
          style={{ whiteSpace: "normal" }} // Must for some reason be style= and not whiteSpace=
          title="Opprettet av"
          subtitle={user}
        />
        <AccordionIcon boxSize="1.5em" />
      </CustomResponsiveHStack>
    </AccordionButton>
  </h2>
);
