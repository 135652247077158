import { useMutation } from "@tanstack/react-query";
import { Checkbox, Stack, Textarea } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { PreviewResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { useEffect, useState } from "react";

export const DropsLogTextInput = ({
  dropsLogText,
  setDropsLogText,
  uuid,
  defaultEnabled = false,
}: {
  dropsLogText: string | null;
  setDropsLogText: (text: string | null) => void;
  uuid: string;
  defaultEnabled?: boolean;
}) => {
  const [dropsLogTextEnabled, setDropsLogTextEnabled] =
    useState(defaultEnabled);
  const [previewText, setPreviewText] = useState<string | null>(null);

  const toggleDropsLogText = () => {
    if (dropsLogTextEnabled) {
      // Wipe text when setting enabled to false so we don't accidentally send it anyway
      setDropsLogText(null);
    }
    setDropsLogTextEnabled(!dropsLogTextEnabled);
  };

  const { mutate: getPreviewData } = useMutation<PreviewResponse>({
    mutationKey: ["closeTrainPreview", uuid],
    mutationFn: () =>
      mutationFnPOST<PreviewResponse, undefined>(
        `${getBackendUrl()}/trainInformation/preview/close/${uuid}`,
      ),
    onSuccess: (data) => {
      // Save the preview text so we can autofill with it when text is enabled
      setPreviewText(data.internalMessage);
    },
    onError: () => {
      // Remove any previous preview texts to avoid stale preview.
      // If dropsLogText hasn't been manually changed, remove it too.
      if (dropsLogText === previewText) {
        setDropsLogText(null);
      }
      setPreviewText(null);
    },
  });

  useEffect(() => {
    // Fetch preview data once when uuid changes
    getPreviewData();
  }, [uuid]);

  useEffect(() => {
    // If dropsLogText is enabled, autofill with preview text
    // whenever one exists and the text field is empty.
    if (dropsLogTextEnabled && !dropsLogText && previewText) {
      setDropsLogText(previewText);
    }
  }, [dropsLogText, previewText, dropsLogTextEnabled]);

  return (
    <Stack spacing={2}>
      <Checkbox isChecked={dropsLogTextEnabled} onChange={toggleDropsLogText}>
        Send melding til Operativ logg
      </Checkbox>
      {dropsLogTextEnabled && (
        <Textarea
          label="Melding"
          value={dropsLogText ?? undefined}
          onChange={(e) => {
            setDropsLogText(e.target.value);
          }}
        />
      )}
    </Stack>
  );
};
