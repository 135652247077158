import { LinkOverlay } from "@chakra-ui/react";
import { format, isToday } from "date-fns";
import { DateSpan } from "features/VehicleList/common/DateSpan";
import ElementWrapper from "features/VehicleList/common/ElementWrapper";
import { Header } from "features/VehicleList/common/Header";
import { Link, generatePath } from "react-router-dom";
import { CircleIndicator } from "shared/components/dataDisplay/CircleIndicator";
import { Text } from "shared/components/typography/TitleStyles";
import { formatDateString } from "shared/utils/datetime";
import { useTheme } from "styled-components";
import * as ROUTES from "../../../shared/utils/routes";
import { RightContent } from "./RightContent";
import { TrainData } from "./types";
import { calcDelayColor } from "./utils";

type Props = {
  train: TrainData;
  trainElementSelected: boolean;
};

export const TrainListElement = ({ train, trainElementSelected }: Props) => {
  const theme = useTheme();
  const trainHasDelay = train.delay !== null && train.delay !== 0;
  const trainDateIsToday = isToday(new Date(train.trainDate));

  const originTime = train.originScheduledDepartureTime
    ? format(new Date(train.originScheduledDepartureTime), "HH:mm")
    : "";

  const destinationTime = train.destinationScheduledArrivalTime
    ? format(new Date(train.destinationScheduledArrivalTime), "HH:mm")
    : "";

  const eventName =
    train.trainEventsTitles?.sort((a, b) => a.priority - b.priority) ?? [];

  return (
    <ElementWrapper selected={trainElementSelected}>
      <LinkOverlay
        as={Link}
        to={generatePath(ROUTES.TRAIN, {
          countryCode: train.countryCode,
          trainNumber: train.trainNumber,
          trainDate: train.trainDate,
        })}
        display="grid"
        gridTemplateColumns="auto 1fr"
        alignItems="center"
        columnGap={2}
        rowGap={0.5}
        flex={1}
        minW="max-content"
        _focusVisible={{
          outline: `2px solid ${theme.__focusBorderColor}`,
          borderRadius: "6px",
        }}
      >
        <CircleIndicator $color={calcDelayColor(train.delay, theme)} />
        <Header>
          <Text bold>{train.trainNumber}</Text>
          {trainHasDelay && <Text secondary>{train.delay} min</Text>}
        </Header>

        <Text
          style={{
            gridColumn: "2",
          }}
        >
          {train.originStopName}- {train.destinationStopName}
        </Text>
        <Text style={{ gridColumn: "2" }}>
          {originTime} - {destinationTime}
          {!trainDateIsToday && (
            <DateSpan>
              ({formatDateString(train.trainDate, "dd.MM.yy")})
            </DateSpan>
          )}
        </Text>
      </LinkOverlay>
      <RightContent
        trainStaffOnDuty={train.trainStaffOnDuty}
        eventNames={eventName}
      />
    </ElementWrapper>
  );
};
