import { create } from "zustand";

type State = {
  currentlyCalling: string | null;
};

type Action = {
  setCurrentlyCalling: (newCurrentlyCalling: State["currentlyCalling"]) => void;
};

export const usePuzzelCurrentlyCalling = create<State & Action>((set) => ({
  currentlyCalling: null,
  setCurrentlyCalling: (newCurrentlyCalling) => {
    set(() => ({ currentlyCalling: newCurrentlyCalling }));
  },
}));
