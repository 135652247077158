import styled from "styled-components";
import { VehicleAffectedContainerProps } from "./VehicleAffected";

export const VehicleAffectedContainer = styled.div<VehicleAffectedContainerProps>`
  grid-area: trainList;
  flex-direction: column;
  position: relative;
  justify-self: stretch;
  width: 420px;
  align-items: end;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  border-right: 1px solid ${({ theme }) => theme.colorOutline};
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  overflow-y: auto;
  min-height: 0; // https://css-tricks.com/preventing-a-grid-blowout/
`;
