import { Flex, Text, Textarea } from "@vygruppen/spor-react";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { Stop } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { BadgeText as BadgeTextStation } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/StationEventModal";
import { BadgeText } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { FieldValues, useForm } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { useMutationMock } from "shared/hooks/useMutationMock";
import { AllOrNone } from "shared/utils/objectUtils";
import { StretchBuilderModalState } from "stores/useStretchBuilder";

type InternalMessageType = {
  isGlobal: boolean;
  isTrain: boolean;
  isStation: boolean;
};

const getInternalMessageType = (
  train?: TrainIdentifier,
  stations: Stop[] = [],
): InternalMessageType => ({
  isGlobal: !train && stations.length === 0,
  isTrain: train !== undefined,
  isStation: !train && stations.length >= 1,
});

const makeTitle = (
  internalMessageType: InternalMessageType,
  stations: Stop[],
  isEditMode?: boolean,
) => {
  const { isGlobal, isTrain } = internalMessageType;
  const action = isEditMode ? "Oppdater" : "Ny";
  if (isGlobal) return `${action} global intern melding`;
  if (isTrain) return `${action} intern melding til tog`;
  return `${action} intern melding til ${stations.length >= 2 ? "stasjoner" : "stasjon"}`;
};

type StationProps = {
  stations?: Stop[];
  allStopsSelected?: boolean;
  stretchBuilderModalState?: StretchBuilderModalState;
  setStretchBuilderModalState?: (value: StretchBuilderModalState) => void;
  onClose?: () => void;
};

const InternalMessageModal = ({
  setModalOpen,
  isEditMode,
  train,
  stations = [],
  allStopsSelected,
  stretchBuilderModalState,
  setStretchBuilderModalState,
  onClose = () => {},
}: {
  setModalOpen: (value: boolean) => void;
  isEditMode?: boolean;
  train?: TrainIdentifier;
} & AllOrNone<StationProps>) => {
  const { mutate: onSubmitForm, status: submitStatus } = useMutationMock();
  const internalMessageType = getInternalMessageType(train, stations);
  const { isGlobal, isTrain, isStation } = internalMessageType;
  const { register, handleSubmit } = useForm();

  const onSubmit = ({ internalMessage }: FieldValues) => {
    const data: { internalMessage: string } = { internalMessage };
    onSubmitForm(data);
  };
  return (
    <ActionModal
      title={makeTitle(internalMessageType, stations, isEditMode)}
      titleBadge={isGlobal ? "Uavhengig" : undefined}
      actionTitle="Send melding"
      onClose={() => {
        setModalOpen(false);
        onClose();
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={submitStatus === "pending"}
      isSuccess={submitStatus === "success"}
      isError={submitStatus === "error"}
      successMessage={isEditMode ? "Hendelse endret" : "Hendelse opprettet"}
      failureMessage={
        isEditMode
          ? "Kunne ikke endre hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer"
          : "Kunne ikke opprette hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer"
      }
    >
      <ErrorBoundary>
        <Flex flexDir="column" gap="30px" w="100%">
          {isTrain && <BadgeText selectedTrain={train!} />}
          {isStation && (
            <BadgeTextStation
              selectedStops={stations!}
              allStopsSelected={allStopsSelected!}
              stretchBuilderModalState={stretchBuilderModalState!}
              setStretchBuilderModalState={() => {
                // Workaround for navigating back to stretchBuilder
                setStretchBuilderModalState!({
                  type: "station",
                  stretchBuilderMode: true,
                });
                setModalOpen(false);
              }}
            />
          )}
          <Textarea
            {...register("internalMessage")}
            label="Skriv din melding..."
            resize="none"
          />
          {isGlobal && (
            <Text>
              <b>OBS!</b> Globale meldinger blir synlig for all personell på
              alle linjer
            </Text>
          )}
        </Flex>
      </ErrorBoundary>
    </ActionModal>
  );
};

export default InternalMessageModal;
