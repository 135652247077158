import { getBackendUrl } from "api/common";
import { usePatchRequest } from "api/http/hooks";
import { isBefore } from "date-fns";
import React, { FC, useEffect } from "react";
import { ActionModal } from "shared/components/ActionModal";
import { InputField } from "shared/components/forms/Inputfield";
import { Text } from "shared/components/typography/TitleStyles";
import { useTypedForm } from "shared/hooks/useTypedForm";
import {
  AlternativeTransportItineraryStop,
  StopReference,
  UpdateAlternativeTransportRequest,
  UpdateAlternativeTransportRequestInput,
  updateAlternativeTransportRequestSchema,
} from "shared/types/alternativeTransport";
import styled from "styled-components";
import {
  getArrivalTimeStr,
  getDepartureTimeStr,
  isAfterOrEqual,
  isBeforeOrEqual,
  isTimeValidComparedTo,
  parseTimeAndApplyMidnightCheck,
} from "./utils";

const ItemContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 6px;
  }

  input {
    width: 100px;
  }
`;

type ChangeStopForm = {
  actualArrivalTime: string;
  actualDepartureTime: string;
};

type Props = {
  id: string;
  showModal: boolean;
  travelDate: Date;
  stop: AlternativeTransportItineraryStop | null;
  distributionStopReferences: Array<StopReference>;
  closeModal: () => void;
};

export const ChangeActualTime: FC<Props> = ({
  id,
  travelDate,
  showModal,
  stop,
  distributionStopReferences,
  closeModal,
}) => {
  const { patchRequestStatus, patchRequest, resetStatus } = usePatchRequest<
    any,
    UpdateAlternativeTransportRequest
  >(`${getBackendUrl()}/alternativeTransports/${id}`);

  const {
    values,
    handleChange,
    reset: resetForm,
    startValidation,
    errors: formErrors,
  } = useTypedForm<ChangeStopForm>(
    {
      actualArrivalTime: "",
      actualDepartureTime: "",
    },
    ({ actualArrivalTime, actualDepartureTime }) => ({
      actualArrivalTime: isTimeValidComparedTo(
        actualArrivalTime,
        actualDepartureTime,
        travelDate,
        isBeforeOrEqual,
        "Ankomsttid må være før avgangstid eller la feltet stå tomt",
      ),
      actualDepartureTime: isTimeValidComparedTo(
        actualDepartureTime,
        actualArrivalTime,
        travelDate,
        isAfterOrEqual,
        "Avgangstid må være etter ankomsttid eller la feltet stå tomt",
      ),
    }),
  );

  // We update "default values" every time we change stop due to re-using one modal for all stops
  useEffect(() => {
    if (stop) {
      handleChange({
        actualArrivalTime: getArrivalTimeStr(stop),
        actualDepartureTime: getDepartureTimeStr(stop),
      });
    }
  }, [stop]);

  const onClose = () => {
    resetStatus();
    resetForm();
    closeModal();
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    startValidation((state) => {
      if (state === "valid" && stop !== null) {
        const { actualArrivalTime, actualDepartureTime } = values;
        const payload: UpdateAlternativeTransportRequestInput = {
          actualStopTimeUpdateRequest: {
            stopReference: stop.stopReference,
            stopName: stop.name ?? "ukjent stoppested",
            actualArrivalTime:
              actualArrivalTime !== ""
                ? parseTimeAndApplyMidnightCheck(actualArrivalTime, travelDate)
                : undefined,
            actualDepartureTime:
              actualDepartureTime !== ""
                ? parseTimeAndApplyMidnightCheck(
                    actualDepartureTime,
                    travelDate,
                  )
                : undefined,
            distributionStopReferences,
          },
        };
        patchRequest(updateAlternativeTransportRequestSchema.parse(payload));
      }
    });
  };

  const onArrivalTimeChangeBlur = () => {
    const { actualArrivalTime, actualDepartureTime } = values;
    const actualArrival = parseTimeAndApplyMidnightCheck(
      actualArrivalTime,
      travelDate,
    );
    const actualDeparture = parseTimeAndApplyMidnightCheck(
      actualDepartureTime,
      travelDate,
    );
    // if departure time is before arrival time, we update it
    if (isBefore(actualDeparture, actualArrival)) {
      handleChange({ actualDepartureTime: actualArrivalTime });
    }
    startValidation();
  };

  return showModal && stop !== null ? (
    <ActionModal
      title="Rediger avgangs- og ankomsttid"
      actionTitle="Gjennomfør"
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={patchRequestStatus.status === "loading"}
      isSuccess={patchRequestStatus.status === "success"}
      isError={patchRequestStatus.status === "failure"}
      successMessage="Oppdatering av tidspunkter for stoppested vellykket"
      failureMessage="Kunne ikke oppdatere avgangstider for avgang. Prøv igjen, eller kontakt IT hvis feilen vedvarer."
    >
      <ItemContent>
        <Text
          title="Tidspunkt for når kjøretøyet ankommer stoppestedet"
          id="actualArrivalTime-label"
        >
          Ankomsttid
        </Text>
        <InputField
          style={{
            width: "100px",
            cursor: "text",
          }}
          type="time"
          name="actualArrivalTime"
          value={values.actualArrivalTime}
          onChange={(e) => handleChange({ actualArrivalTime: e.target.value })}
          onBlur={() => onArrivalTimeChangeBlur()}
          aria-labelledby="actualArrivalTime-label"
          aria-invalid={formErrors.actualArrivalTime !== undefined}
          aria-errormessage="actualArrivalTime-error-message"
        />
        {formErrors.actualArrivalTime && (
          <Text secondary id="actualArrivalTime-error-message">
            {formErrors.actualArrivalTime}
          </Text>
        )}
      </ItemContent>
      <ItemContent>
        <Text
          title="Tidspunkt for når kjøretøyet forlater stoppestedet"
          id="actualDepartureTime-label"
        >
          Avgangstid
        </Text>
        <InputField
          style={{
            width: "100px",
            cursor: "text",
          }}
          type="time"
          name="actualDepartureTime"
          value={values.actualDepartureTime}
          onChange={(e) =>
            handleChange({ actualDepartureTime: e.target.value })
          }
          onBlur={() => startValidation()}
          aria-labelledby="actualDepartureTime-label"
          aria-invalid={formErrors.actualDepartureTime !== undefined}
          aria-errormessage="actualDepartureTime-error-message"
        />
        {formErrors.actualDepartureTime && (
          <Text secondary id="actualDepartureTime-error-message">
            {formErrors.actualDepartureTime}
          </Text>
        )}
      </ItemContent>
    </ActionModal>
  ) : null;
};
