import { UseQueryResult } from "@tanstack/react-query";
import {
  CalendarOutline18Icon,
  WarningFill18Icon,
} from "@vygruppen/spor-icon-react";
import {
  Divider,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SkeletonCircle,
  SkeletonText,
  ModalBody as SporModalBody,
  VStack,
} from "@vygruppen/spor-react";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import { GlobalEvent } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { InternalMessageMockType } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewButtonAndModal";
import { GlobalEvents } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/GlobalEvents";
import { InternalMessageEvents } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/InternalMessageEvents";
import { UpcomingEvents } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/UpcomingEvents";
import {
  Tab,
  TabsWindows,
} from "features/CenterContent/shared/Tabs/TabsWindows";
import { FC, useState } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import { NoteOutline24Icon } from "shared/icons/NoteOutline24Icon";
import { InfraStructureInformation } from "shared/types/infrastructureResponse";
import { dropsRole } from "stores/dropsRole";
import { GlobalEventModal } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventModal";
import styled from "styled-components";

const ModalBody = styled(SporModalBody)`
  ${ObtrusiveScrollCss};
  overflow-y: auto;
  display: grid;
`;

type UpcomingEventsModalProps = {
  onClose: () => void;
  upcomingInfrastructureEvents: UseQueryResult<InfraStructureInformation[]>;
  activeGlobalEvents: UseQueryResult<GlobalEvent[]>;
  internalMessageEvents: UseQueryResult<InternalMessageMockType[]>;
};

export const EventsOverviewModal: FC<UpcomingEventsModalProps> = ({
  onClose,
  upcomingInfrastructureEvents,
  activeGlobalEvents,
  internalMessageEvents,
}) => {
  const { enabled: globalEventsEnabled } = useFeatureFlag("gobalEvents");
  const internalMessageEnabled = useFeatureFlag("internalMessage").enabled; // Enabled: Show "Intern melding" in upcomming events modal
  const { isVaktleder } = dropsRole();

  const tabs: Tab[] = [
    ...(globalEventsEnabled
      ? [
          {
            id: "activeEvents",
            icon: <WarningFill18Icon />,
            text: `Globale ${activeGlobalEvents.isSuccess ? `(${activeGlobalEvents.data.length})` : ""}`,
          },
        ]
      : []),
    {
      id: "upcomingEvents",
      icon: <CalendarOutline18Icon />,
      text: `Fremtidige ${upcomingInfrastructureEvents.isSuccess ? `(${upcomingInfrastructureEvents.data.length})` : ""}`,
    },
    ...(internalMessageEnabled
      ? [
          {
            id: "internalEvents",
            icon: <NoteOutline24Icon />,
            text: `Internmeldinger ${internalMessageEvents.isSuccess ? `(${internalMessageEvents.data.length})` : ""}`,
          },
        ]
      : []),
  ];

  // TODO: Når feature-flag fjernes: Sett activeEvents som default tab hvis det er aktive hendelser
  const [selectedTabIds, setSelectedTabIds] = useState(["upcomingEvents"]);

  const [globalEventToEdit, setGlobalEventToEdit] =
    useState<GlobalEvent | null>(null);

  const isPending =
    upcomingInfrastructureEvents.isPending ||
    activeGlobalEvents.isPending ||
    internalMessageEvents.isPending;

  const isFailure =
    (selectedTabIds.includes("activeEvents") && activeGlobalEvents.isError) ||
    (selectedTabIds.includes("upcomingEvents") &&
      upcomingInfrastructureEvents.isError) ||
    (selectedTabIds.includes("internalEvents") &&
      internalMessageEvents.isError);

  const showTabs = tabs.length > 1 && isVaktleder();

  return globalEventToEdit ? (
    <GlobalEventModal onClose={onClose} event={globalEventToEdit} />
  ) : (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxH="80vh" minW="700px">
        <ModalHeader>
          {showTabs ? "Oversikt hendelser" : "Fremtidige hendelser"}
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody gap={2}>
          {showTabs && (
            <TabsWindows
              tabs={tabs}
              selectedTabIds={selectedTabIds}
              setSelectedTabIds={setSelectedTabIds}
              justifyContent="start"
            />
          )}

          {isPending && (
            <VStack gap={1} w="100%">
              {Array.from(Array(3)).map((_, i) => (
                <HStack
                  key={i}
                  p={3}
                  w="100%"
                  justify="space-between"
                  alignItems="flex-top"
                  h="100px"
                >
                  <SkeletonText noOfLines={4} w="70%" />
                  <SkeletonCircle />
                </HStack>
              ))}
            </VStack>
          )}
          {isFailure && <FailureMessage />}

          {activeGlobalEvents.isSuccess &&
            selectedTabIds.includes("activeEvents") && (
              <GlobalEvents
                data={activeGlobalEvents.data}
                onEditClick={(event) => {
                  setGlobalEventToEdit(event);
                }}
              />
            )}

          {upcomingInfrastructureEvents.isSuccess &&
            selectedTabIds.includes("upcomingEvents") && (
              <UpcomingEvents data={upcomingInfrastructureEvents.data} />
            )}

          {internalMessageEvents.isSuccess &&
            selectedTabIds.includes("internalEvents") && (
              <InternalMessageEvents data={internalMessageEvents.data} />
            )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
