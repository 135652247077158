import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CountryCode } from "@vygruppen/vy-train-map";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import TrainInfoFormModal, {
  RequestBody,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { trainDetailedResponseToForm } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";
import { OperationalInformationTrainResponse } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/operationalInformationTrainResponse";
import { TrainInformationDetailedResponse } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationDetailedResponse";
import { TrainInfoRequest } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";
import { routeChangedEventsQueryKey } from "features/CenterContent/VehicleDetails/TrainDetails/useRouteChangedEvents";
import { FC } from "react";

type EditTrainInfoProps = {
  uuid: string;
  trainId: string;
  nominalDate: string;
  countryCode: CountryCode;
  setModalOpen: (v: boolean) => void;
  trainInfoWithOpenState: string[];
  title?: string;
  submitButtonLabel?: string;
};

export const EditTrainInfoModal: FC<EditTrainInfoProps> = ({
  uuid,
  setModalOpen,
  trainId,
  nominalDate,
  countryCode,
  trainInfoWithOpenState,
  title,
  submitButtonLabel,
}) => {
  const queryClient = useQueryClient();
  const { data: trainInformationResponse, status } = useQuery({
    queryKey: ["trainInformationDetailed", uuid],
    queryFn: ({ signal }) =>
      axiosClient.get<TrainInformationDetailedResponse>(
        `${getBackendUrl()}/trainInformation/information/${uuid}`,
        { signal },
      ),
    // when exiting the edit modal we want to immediately remove the old values from the query cache completely to
    // prevent the previous version of the Event to appear in the form before loading the newest version
    // of the Event when editing the Event again
    gcTime: 0,
  });

  const {
    mutate: postTrainInfo,
    status: postTrainInfoStatus,
    reset: resetMutation,
  } = useMutation({
    mutationFn: (data: RequestBody) =>
      mutationFnPOST<
        OperationalInformationTrainResponse | undefined,
        TrainInfoRequest
      >(
        `${getBackendUrl()}/trainInformation/information/${uuid}/change`,
        data.body as TrainInfoRequest,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [routeChangedEventsQueryKey, trainId, nominalDate],
      });
    },
  });

  return (
    <TrainInfoFormModal
      mode="edit"
      uuid={uuid}
      selectedTrain={{
        identifier: trainId,
        nominalDate,
        countryCode,
      }}
      formDataStatus={status}
      formData={
        trainInformationResponse
          ? {
              trainForm: trainDetailedResponseToForm(
                trainInformationResponse.data,
              ),
            }
          : undefined
      }
      onSubmit={postTrainInfo}
      submitStatus={postTrainInfoStatus}
      trainInfoWithOpenState={trainInfoWithOpenState}
      onClose={() => {
        resetMutation();
        setModalOpen(false);
      }}
      title={title}
      submitButtonLabel={submitButtonLabel}
    />
  );
};
