import { CountryCode, TrainIdentifier } from "@vygruppen/vy-train-map";
import {
  TextMap,
  Visibility,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { InfrastructureProviderEnum } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureProvider";

export type Stop = {
  name: string;
  id: string;
};

export type Leg = {
  fromStop: Stop;
  toStop: Stop;
};

export type TimedRequestDuration = {
  fromTime: string;
  toTime: string;
};

export type OpenEndedRequestDuration = {
  fromTime: string;
  timeHorizonInMinutes: number;
};

export type PeriodicRequestDuration = {
  periods: PeriodRequestDuration[];
};

export type PeriodRequestDuration = {
  id?: string;
  fromTime: string;
  toTime: string;
};

export type RequestDuration =
  | TimedRequestDuration
  | OpenEndedRequestDuration
  | PeriodicRequestDuration;

export type OpenInfrastructureEventBody = {
  type: string;
  affectedLegs: AffectedLeg[];
  redirectStretches: RedirectStretch[];
  duration: RequestDuration;
  stretchName: string;
  countryCode: "NO";
  reason: InputWithDescription;
  action: InputWithDescription | null;
  consequence: InputWithDescription | null;
  prognosis: {
    type: string;
    description: { NOB: string; ENG: string } | null;
    estimatedResolved: string | null;
    estimatedInfoUpdate: string | null;
  } | null;
  actionCard: { type: string } | null;
  isirkId: string | null;
  infrastructureProvider: InfrastructureProviderEnum | null;
};

export type PreviewResponse = {
  internalMessage: string;
};

export type OperationalIdentifier_JSON = {
  operational_identifier: string;
  nominal_date: string;
  country_code: CountryCode;
};

export function trainIdentifierToOperationalIdentifierJson({
  identifier,
  countryCode,
  nominalDate,
}: TrainIdentifier): OperationalIdentifier_JSON {
  return {
    operational_identifier: identifier,
    country_code: countryCode,
    nominal_date: nominalDate,
  };
}

export function operationalIdentifierJsonToTrainIdentifier({
  operational_identifier,
  country_code,
  nominal_date,
}: OperationalIdentifier_JSON): TrainIdentifier {
  return {
    identifier: operational_identifier,
    countryCode: country_code,
    nominalDate: nominal_date,
  };
}

export function trainIdentifierIsEqual(
  first: TrainIdentifier,
  second: TrainIdentifier,
): boolean {
  return (
    first.identifier === second.identifier &&
    first.nominalDate === second.nominalDate &&
    first.countryCode === second.countryCode
  );
}

export type TrainIdentifierWithIncidentIds_JSON = OperationalIdentifier_JSON & {
  incident_ids: string[];
};

export type TrainIdentifierWithIncidentIdString =
  `${string}-${OperationalIdentifier_JSON["country_code"]}-${OperationalIdentifier_JSON["operational_identifier"]}-${OperationalIdentifier_JSON["nominal_date"]}`;

export type PreviewResponseBatch = {
  preview: PreviewResponse;
  train: OperationalIdentifier_JSON;
}[];

export type InfrastructureEventCreatedResponse = {
  incidentId: string;
  texts: TextMap;
  visibility: Visibility;
};

type InputWithDescription = {
  type: string;
  description: { NOB: string; ENG: string } | null;
};

export type RedirectStretch = {
  name: string;
  legs: AffectedLeg[];
};

export type AffectedLeg = {
  fromStop: string;
  toStop: string;
};
