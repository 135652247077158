import { EditOutline18Icon } from "@vygruppen/spor-icon-react";
import { HStack, IconButton, Stack, VStack } from "@vygruppen/spor-react";
import { format } from "date-fns";
import { Badges } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/Badges";
import {
  getDisplayDuration,
  sortByDisplayDuration,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/utils";
import { FC } from "react";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { Text } from "shared/components/typography/TitleStyles";
import { InfraStructureInformation } from "shared/types/infrastructureResponse";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";
import { dropsRole } from "stores/dropsRole";
import { useTheme } from "styled-components";

type UpcomingEventProps = {
  data: InfraStructureInformation[];
  onEditClick: (event: InfraStructureInformation) => void;
};

export const UpcomingEventsList: FC<UpcomingEventProps> = ({
  data,
  onEditClick,
}) => {
  const theme = useTheme();
  const { isVaktleder } = dropsRole();
  if (data.length === 0)
    return (
      <NoDataMessage reason="Det er ikke registrert noen fremtidige hendelser" />
    );
  return (
    <Stack gap={3}>
      {data.sort(sortByDisplayDuration).map((event) => (
        <HStack
          key={event.referenceId}
          border={`1px solid ${theme.colorOutline}`}
          borderRadius="sm"
          p={2}
          justifyItems="space-between"
          alignItems="flex-start"
          gap={4}
        >
          <VStack alignItems="flex-start" flex="1">
            <Text bold>
              {[event.isirkId, event.stretchName].filter(Boolean).join(", ")}
            </Text>
            <Badges
              eventCategory={
                isPlannedInfraStructureEvent(event.type)
                  ? "plannedInfrastructure"
                  : "futureInfrastructure"
              }
              displayDuration={getDisplayDuration(event.durations)}
            />
            <Text>{event.message}</Text>
            <Text secondary>
              Sist oppdatert {format(event.updatedAt.toDate(), "dd.MM.yyyy")}{" "}
              kl. {format(event.updatedAt.toDate(), "HH:mm")}
            </Text>
          </VStack>
          {isVaktleder() && (
            <IconButton
              onClick={() => onEditClick(event)}
              size="sm"
              aria-label="Rediger"
              variant="ghost"
              icon={<EditOutline18Icon />}
            />
          )}
        </HStack>
      ))}
    </Stack>
  );
};
