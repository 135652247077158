import { BatchCommonInputProps } from "features/CenterContent/shared/operationalInformation/components/BatchTrainFormFieldWithCustomInput";
import { TrickFormHookGroupedTrainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

type IncidentHiddenInputProps = {
  incidentId?: string;
};

export const BatchIncidentHiddenInput: FC<
  IncidentHiddenInputProps & BatchCommonInputProps
> = ({ incidentId, pathPrefix, numberOfGroups }) => {
  const { setValue: _setValue, getValues } =
    useFormContext<TrickFormHookGroupedTrainFormSchema>();

  const incidentIdFieldPathForDisplay =
    `${pathPrefix}.0.trainForm.incidentId` as const;

  const currentId = getValues(incidentIdFieldPathForDisplay);

  // Update function that updates all forms in the group
  const setValue = (value: string | undefined) => {
    for (let i = 0; i < numberOfGroups; i += 1) {
      _setValue(`${pathPrefix}.${i}.trainForm.incidentId`, value, {
        shouldValidate: true,
      });
    }
  };

  useEffect(() => {
    if (currentId !== incidentId) {
      setValue(incidentId);
    }
  }, [incidentId]);

  return <input type="hidden" value={incidentId} />;
};
