import { TrainIdentifier } from "@vygruppen/vy-train-map";
import constate from "constate";
import { useState } from "react";
import { useTrainRoutes } from "../../../useTrainRoutes";

function useTrainInfoContext() {
  const [allTrainIds, setAllTrainIds] = useState<TrainIdentifier[]>([]);
  const trainRoutesResponse = useTrainRoutes(allTrainIds);
  const batchUseTrainRoute = (trainId: TrainIdentifier) => ({
    ...trainRoutesResponse,
    data: trainRoutesResponse.data && trainRoutesResponse.data(trainId),
  }); // use this in the same way you would use useTrainRoute (while all the data is retrieved by only one query)
  return { batchUseTrainRoute, setAllTrainIds };
}

const [TrainInfoProvider, useBatchUseTrainRoute, useSetAllTrainIds] = constate(
  useTrainInfoContext,
  (value) => value.batchUseTrainRoute,
  (value) => value.setAllTrainIds,
);

export { TrainInfoProvider, useBatchUseTrainRoute, useSetAllTrainIds };
