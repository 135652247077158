import { Tooltip } from "@chakra-ui/react";
import { EditOutline18Icon } from "@vygruppen/spor-icon-react";
import {
  Badge,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Skeleton,
  SkeletonText,
  Stack,
  StaticAlert,
  Text,
} from "@vygruppen/spor-react";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { Stop } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import {
  FormSchema,
  formToStationInformationRequest,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/formSchema";
import { SubTypeInputs } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/subTypeInputs";
import { StationInfoRequest } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/types/stationInformationRequest";
import { getStopsTexts } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stretchBuilderTexts";
import {
  EventGroup,
  useOperationalInformationTypes,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { Select } from "shared/components/forms/Select";
import {
  StretchBuilderModalState,
  useStretchBuilder,
} from "stores/useStretchBuilder";

type ModalContentProps = {
  onFormSubmit: (body: StationInfoRequest) => void;
  formSubmitStatus: "error" | "pending" | "idle" | "success";
  isEditMode: boolean;
};

export const BadgeText = ({
  selectedStops,
  allStopsSelected,
  stretchBuilderModalState,
  setStretchBuilderModalState,
}: {
  selectedStops: Stop[];
  allStopsSelected: boolean;
  stretchBuilderModalState: StretchBuilderModalState;
  setStretchBuilderModalState: (value: StretchBuilderModalState) => void;
}) => {
  const stopsTexts = getStopsTexts(selectedStops, allStopsSelected);
  return (
    <Flex gap="6px">
      <Text>
        {selectedStops.length < 2 ? "Stasjon: " : "Stasjoner: "}
        {stopsTexts.slice(0, 3).map((it) => (
          <Badge
            key={it}
            colorScheme="light-green"
            borderRadius="9px"
            fontWeight="400"
            paddingX="12px !important"
            marginX="3px"
          >
            {it}
          </Badge>
        ))}
        {stopsTexts.length > 3 && (
          <Tooltip label={stopsTexts.slice(3).join(", ")}>
            <Badge
              colorScheme="light-green"
              borderRadius="9px"
              fontWeight="400"
              paddingX="12px !important"
              marginX="3px"
            >
              +{stopsTexts.length - 3}
            </Badge>
          </Tooltip>
        )}
      </Text>
      <IconButton
        size="xs"
        variant="tertiary"
        icon={<EditOutline18Icon />}
        onClick={() => {
          const newState = {
            ...stretchBuilderModalState,
            stretchBuilderMode: true,
          };
          setStretchBuilderModalState(newState);
        }}
        aria-label="Rediger stasjoner"
        width="fit-content"
        height="fit-content"
      />
    </Flex>
  );
};

export const StationEventModal: FC<ModalContentProps> = ({
  onFormSubmit,
  formSubmitStatus,
  isEditMode,
}) => {
  const [
    selectedStops,
    setSelectedStops,
    allStopsSelected,
    closeAndResetStretchBuilder,
    stretchBuilderModalState,
    setStretchBuilderModalState,
  ] = useStretchBuilder((state) => [
    state.selectedStops,
    state.setSelectedStops,
    state.allStopsSelected,
    state.closeAndResetStretchBuilder,
    state.stretchBuilderModalState,
    state.setStretchBuilderModalState,
  ]);

  const modalState = stretchBuilderModalState.type;

  const { data: opInfoTypes, status: opInfoTypesStatus } =
    useOperationalInformationTypes();

  const formMethods = useFormContext<FormSchema>();
  const type = formMethods.watch("stationForm.stationInformationType");
  const stationFormErrors = formMethods.formState.errors.stationForm;

  useEffect(() => {
    if (selectedStops.length <= 0) {
      const stop = formMethods.getValues("stationForm.stop");
      const stops = formMethods.getValues("stationForm.stops");
      if (stop !== undefined) {
        setSelectedStops([{ name: stop, id: stop }]);
      } else if (stops.length > 0) {
        setSelectedStops(stops.map((st) => ({ name: st, id: st })));
      }
    }
  }, []);

  useEffect(() => {
    if (selectedStops.length > 0) {
      formMethods.setValue(
        "stationForm.stops",
        selectedStops.map((stop) => stop.id),
      );
    }
  }, [selectedStops]);

  useEffect(() => {
    if (type) {
      formMethods.clearErrors("stationForm");
    }
  }, [type]);

  if (modalState !== "station") return null;

  return (
    <ActionModal
      title={`${isEditMode ? "Endre" : "Opprett"} stasjonshendelse`}
      actionTitle={`${isEditMode ? "Endre" : "Opprett"} hendelse`}
      onClose={closeAndResetStretchBuilder}
      onSubmit={formMethods.handleSubmit((data) =>
        onFormSubmit(formToStationInformationRequest(data)),
      )}
      isLoading={formSubmitStatus === "pending"}
      isSuccess={formSubmitStatus === "success"}
      isError={formSubmitStatus === "error"}
      successMessage={isEditMode ? "Hendelse endret" : "Hendelse opprettet"}
      failureMessage={`Kunne ikke ${isEditMode ? "endre" : "opprette"} hendelsen. Prøv på nytt, eller kontakt IT hvis feilen vedvarer.`}
      internalMessage={isEditMode ? "edit" : "create"}
    >
      <ErrorBoundary>
        <Box w="100%" display="grid" gap={3}>
          <BadgeText
            selectedStops={selectedStops}
            allStopsSelected={allStopsSelected}
            stretchBuilderModalState={stretchBuilderModalState}
            setStretchBuilderModalState={setStretchBuilderModalState}
          />
          {opInfoTypesStatus === "pending" && (
            <>
              <Stack gap={2}>
                <Skeleton height={6} />
                <Skeleton height={6} />
              </Stack>
              <SkeletonText noOfLines={3} width="30%" />
            </>
          )}

          {opInfoTypesStatus === "error" && <FailureMessage />}

          {opInfoTypesStatus === "success" && (
            <>
              <FormControl
                isInvalid={!!stationFormErrors?.stationInformationType}
                as={Stack}
                gap="2"
              >
                <Select
                  {...formMethods.register(
                    "stationForm.stationInformationType",
                    {},
                  )}
                  label="Hendelse"
                  placeholder="Velg en hendelse"
                  isInvalid={!!stationFormErrors?.stationInformationType}
                >
                  {opInfoTypes.events
                    .filter((event) => event.group === EventGroup.STATION)
                    .toSorted((a, b) => a.label.localeCompare(b.label))
                    .map((event) => (
                      <option key={event.type} value={event.type}>
                        {event.label}
                      </option>
                    ))}
                </Select>

                <FormErrorMessage>
                  {stationFormErrors?.stationInformationType?.message}
                </FormErrorMessage>
              </FormControl>

              {type && <SubTypeInputs type={type} />}

              {type === "STATION_GENERAL" && (
                <StaticAlert variant="info" id="infoBoxActionModal">
                  Hendelsen inneholder egendefinert tekst. Sjekk at beskrivelsen
                  ser riktig ut før du går videre.
                </StaticAlert>
              )}
            </>
          )}
        </Box>
      </ErrorBoundary>
    </ActionModal>
  );
};
