import React, { ReactElement, useEffect, useMemo } from "react";
import {
  RealTimeStateInformationRequest,
  UserStateInformation,
} from "../../components/types";
import { useUserDetailsState } from "./UserDetailsAndLogOnOffContext";
import { useApiAction } from "../../../../../api/drops/useApiAction";
import { usePuzzelAuthenticationContext } from "./PuzzelAuthenticationContext";
import { extendedStateInformationGetTelephoneApi } from "../../../../../api/puzzel/puzzel";
import { useUserValidationState } from "./UserValidationContext";

type AuthContextValue = {
  iqSessionId: string | undefined;
  iqSessionState: string | undefined;
  iqSessionStateRequests: [] | undefined;
  userStateInformation: UserStateInformation | undefined;
  request: RealTimeStateInformationRequest | undefined;
};

const initialValue: AuthContextValue = {
  iqSessionId: undefined,
  iqSessionState: undefined,
  iqSessionStateRequests: undefined,
  userStateInformation: undefined,
  request: undefined,
};

export const AuthContext = React.createContext(initialValue);

const ListenForCallStatus = (props: Record<string, unknown>): ReactElement => {
  const { userId } = useUserValidationState();

  const userDetails = useUserDetailsState();

  const { isAuthenticated } = usePuzzelAuthenticationContext();
  const [extendedStateInformation, setExtendedStateInformation] = useApiAction(
    extendedStateInformationGetTelephoneApi,
    false,
  );

  useEffect(() => {
    let intervalId = 0;
    if (isAuthenticated && userId && userDetails.isLoggedOn) {
      intervalId = window.setInterval(() => {
        setExtendedStateInformation({ userId });
      }, 2000); // listen every 2 sec for incoming call
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isAuthenticated, userId, userDetails.isLoggedOn]);

  const extendedStateInfo = extendedStateInformation.data;

  const userStateInformation = extendedStateInfo?.result?.userStateInformation;
  const request = extendedStateInfo?.result?.request;
  const iqSessionId =
    extendedStateInfo?.result?.iqSessionStateInformation?.iqSessionId;
  const iqSessionState =
    extendedStateInfo?.result?.iqSessionStateInformation?.iqSessionState;
  const iqSessionStateRequests =
    extendedStateInfo?.result?.iqSessionStateInformation?.requests; // For testing [{status: "InService", remoteAddress: '123', id: '123'}]

  const value: AuthContextValue = useMemo(
    () => ({
      iqSessionId,
      iqSessionState,
      iqSessionStateRequests,
      userStateInformation,
      request,
    }),
    [
      iqSessionId,
      iqSessionId,
      iqSessionStateRequests,
      userStateInformation,
      request,
    ],
  );

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AuthContext.Provider value={value} {...props} />;
};

function usePuzzelExtendedStateInformation(): AuthContextValue {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      "useAuth must be used within a AuthPuzzelTelephoneProvider",
    );
  }
  return context;
}

export { ListenForCallStatus, usePuzzelExtendedStateInformation };
