import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { CountryCode, Train } from "@vygruppen/vy-train-map";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import {
  OTRMessage,
  TRAINMAP_WS_PROPERTIES,
  TrainMapWebsocketMessage,
  isOTRMessage,
} from "websocket/trainmap/trainMapWebsocketHelper";
import { useWebsocketClient } from "websocket/useWebsocketClient";
import { log, LogLevel } from "../../../../api/cloudWatch";
import { getTrafficguiBaseUrl } from "../../../../api/common";
import { environment } from "../../../../environment";

interface TrainData {
  trains: Train[];
}

const TRAINS_QUERY_KEY = "trains";

export const useRealtimeTrains = (countryCode?: CountryCode) => {
  const { data: trainData, isSuccess: isTrainsSuccess } = useQuery({
    queryKey: [TRAINS_QUERY_KEY],
    retry: 1,
    queryFn: ({ signal }) =>
      queryFnGET<TrainData>({
        signal,
        url: `${getTrafficguiBaseUrl(environment())}/trainmap-backend/trains${
          countryCode ? `?countryCode=${countryCode}` : ""
        }`,
      }),
    refetchOnWindowFocus: true,
  });

  const {
    message: websocketMessage,
    register,
    deregister,
  } = useWebsocketClient<TrainMapWebsocketMessage>({
    ...TRAINMAP_WS_PROPERTIES,
  });

  useEffect(() => {
    register("TrainMapTrains", ["OTR"]);
    return () => deregister("TrainMapTrains", ["OTR"]);
  }, []);

  const createIndex = (train: Train) =>
    `${train.train_id}_${train.train_date}_${train.country_code}`;

  const updateTrain = (message: Train, prev: Train[]): Train[] => [
    ...prev.filter((train) => createIndex(message) !== createIndex(train)),
    message,
  ];

  const deleteTrain = (message: Train, prev: Train[]) =>
    prev.filter((train) => createIndex(train) !== createIndex(message));

  const handleOTRMessage = (message: OTRMessage) => {
    if (!countryCode || countryCode === message.train.country_code) {
      const { type, train: newTrain, timestamp } = message;
      if (!newTrain) return;
      switch (type) {
        case "UPDATE":
          queryClient.setQueryData<TrainData>(
            [TRAINS_QUERY_KEY],
            (prevState) =>
              prevState
                ? { trains: updateTrain(newTrain, prevState.trains) }
                : { trains: [newTrain] },
          );
          break;
        case "DELETE":
          queryClient.setQueryData<TrainData>(
            [TRAINS_QUERY_KEY],
            (prevState) =>
              prevState
                ? { trains: deleteTrain(newTrain, prevState.trains) }
                : { trains: [] },
          );
          break;
        default:
          log(
            LogLevel.error,
            useRealtimeTrains.name,
            `Received message with invalid type: ${type}, at ${timestamp} for train ${createIndex(
              newTrain,
            )}`,
          );
      }
    }
  };

  useEffect(() => {
    if (
      websocketMessage.status === "received" &&
      isOTRMessage(websocketMessage.data)
    ) {
      handleOTRMessage(websocketMessage.data);
    }
  }, [websocketMessage]);

  return { trainData, isTrainsSuccess };
};
