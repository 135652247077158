import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { supportedOpInfoTypes } from "features/CenterContent/shared/operationalInformation/supportedOpInfoTypes";

export type GenericType = {
  type: string;
  label: string;
  tags: string[];
};

export type EventType = {
  type: string;
  group: EventGroup;
  label: string;
};

export enum EventGroup {
  TRAIN = "TRAIN",
  INFRASTRUCTURE = "INFRASTRUCTURE",
  STATION = "STATION",
  ALTERNATIVE_TRANSPORT = "ALTERNATIVE_TRANSPORT",
}

export type OperationalInformationTypes = {
  reasons: GenericType[];
  actionCards: GenericType[];
  actions: GenericType[];
  consequences: GenericType[];
  events: EventType[];
  prognoses: GenericType[];
};

export const useOperationalInformationTypes = () =>
  useQuery({
    queryKey: ["operationalInformationTypes"],
    queryFn: ({ signal }) =>
      axiosClient
        .get<OperationalInformationTypes>(
          `${getBackendUrl()}/operational-information/types`,
          {
            signal,
          },
        )
        .then((res) =>
          // This endpoint gives out the two events `TRAIN_GENERAL_INFO` and
          // `TRAIN_GENERAL_WARNING`, which our backend handles together.
          // Due to how we filter which events you can submit from the modal,
          // We can't show both as one form with the data we get from the API
          // directly, and we can't just use one or the other because the labels
          // are wrong. So instead we replace them with the common event here in
          // the format we expect, so it ends up in the Tanstack Query cache
          // correctly and allows it to show up and be filtered in the modal.
          ({
            ...res.data,
            events: [
              ...res.data.events.filter((event) =>
                supportedOpInfoTypes.includes(event.type),
              ),
              {
                type: "TRAIN_GENERAL",
                group: EventGroup.TRAIN,
                label: "Egendefinert hendelse",
              },
              {
                type: "STATION_GENERAL",
                group: EventGroup.STATION,
                label: "Egendefinert hendelse",
              },
            ],
            reasons: [
              ...res.data.reasons,
              {
                type: "NO_REASON",
                tags: ["TRAIN"],
                label: "Ingen årsak",
              },
            ],
          }),
        ),
  });

export type OperationalTextContextType = {
  type: string;
  name: string;
  description: string;
};

export const useOperationalTextContexts = () =>
  useQuery({
    queryKey: ["operationalTextContexts"],
    queryFn: ({ signal }) =>
      queryFnGET<Array<OperationalTextContextType>>({
        signal,
        url: `${getBackendUrl()}/operational-text/contexts`,
      }),
  });
