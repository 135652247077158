import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { SearchResultText } from "features/VehicleList/common/SearchResultText";
import { useMemo, useRef, useState } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { useInfiniteScroll } from "shared/hooks/useInfiniteScroll";
import { getDate } from "shared/utils/dateTimeUtils";
import * as z from "zod";
import { TrainListSection } from "features/VehicleList/TrainList/TrainListSection";
import {
  TrainData,
  trainListSchema,
} from "features/VehicleList/TrainList/types";
import {
  filterTrainList,
  sortTrainData,
} from "features/VehicleList/TrainList/utils";
import {
  ResultList,
  ResultListWrapper,
} from "features/VehicleList/TrainList/styles";
import { Checkbox, Text } from "@vygruppen/spor-react";

type TrainListProps = {
  searchQuery: string;
  isTrafficPlanner: boolean;
  selectedDate: Date;
};

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce(
    (groups, item) => {
      (groups[key(item)] ||= []).push(item);
      return groups;
    },
    {} as Record<K, T[]>,
  );

const TrainList = ({
  searchQuery,
  isTrafficPlanner,
  selectedDate,
}: TrainListProps) => {
  const day = getDate(selectedDate);
  const { status, data } = useQuery({
    queryKey: ["trainList", day],
    refetchInterval: 60000,
    queryFn: async ({ signal }) =>
      axiosClient
        .get<z.infer<typeof trainListSchema>>(
          `${getBackendUrl()}/trainList?date=${day}`,
          {
            signal,
          },
        )
        .then((res) => trainListSchema.parse(res.data)),
  });

  const [showTrainsMissingData, setShowTrainsMissingData] = useState(false);

  const trainList = status === "success" ? data.trainList : [];
  const filteredAndSortedTrains = useMemo(
    () =>
      trainList
        .filter((train) =>
          filterTrainList(
            train,
            searchQuery,
            selectedDate,
            showTrainsMissingData,
          ),
        )
        .sort((a, b) => sortTrainData(a, b, searchQuery)),
    [searchQuery, selectedDate, trainList, showTrainsMissingData],
  );

  const trainListContainerRef = useRef<HTMLDivElement>(null);

  const { numberOfItemsToRender } = useInfiniteScroll({
    containerRef: trainListContainerRef,
    initialItemsToRender: 20,
    maxItemsToRender: filteredAndSortedTrains.length,
  });

  const groupedTrainData: TrainData[][] = Object.values(
    groupBy(
      filteredAndSortedTrains.slice(0, numberOfItemsToRender),
      (train: TrainData) => train.trainDate,
    ),
  );

  switch (status) {
    case "pending":
      return <SkeletonLoader skeletonType="trainList" />;
    case "success":
      return (
        <>
          <SearchResultText
            searchQuery={searchQuery}
            resultLength={filteredAndSortedTrains.length}
          />
          {searchQuery.length >= 2 && (
            <Checkbox
              pl="12px"
              pb="16px"
              isChecked={showTrainsMissingData}
              onChange={() => setShowTrainsMissingData(!showTrainsMissingData)}
            >
              Vis også tog med manglende data
            </Checkbox>
          )}

          <ResultListWrapper
            id="tabPanel-Tog"
            ref={trainListContainerRef}
            role={isTrafficPlanner ? "tabpanel" : "generic"}
            data-testid="vehicle-list"
          >
            <ResultList>
              {filteredAndSortedTrains.length > 0
                ? groupedTrainData.map((trainDatas: TrainData[], index) => (
                    <TrainListSection key={index} trainDatas={trainDatas} />
                  ))
                : searchQuery.length < 2 && (
                    <NoDataMessage
                      style={{ marginTop: "15px" }}
                      reason="Fant ingen operative tog for øyeblikket. Prøv å laste inn siden på nytt eller kontakt IT"
                    />
                  )}
            </ResultList>
          </ResultListWrapper>
        </>
      );
    case "error":
    default:
      return <FailureMessage style={{ margin: "12px" }} />;
  }
};

export default withErrorBoundary(TrainList);
