import React, { ReactElement, ReactNode } from "react";
import { UserValidationProvider } from "./contexts/UserValidationContext";
import ValidateUserAndPresentLogon from "../components/ValidateUserAndPresentLogon";
import { PuzzelAuthenticationProvider } from "./contexts/PuzzelAuthenticationContext";

type Props = {
  children: ReactNode;
};

export const PuzzelProvider = ({ children }: Props): ReactElement | null => (
  <PuzzelAuthenticationProvider>
    <UserValidationProvider>
      <ValidateUserAndPresentLogon>{children}</ValidateUserAndPresentLogon>
    </UserValidationProvider>
  </PuzzelAuthenticationProvider>
);
