import { Stop } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import React from "react";
import {
  Line,
  RouteFraction,
  VehicleStopWrapper,
} from "features/CenterContent/shared/styles";
import { StopIcon } from "features/CenterContent/shared/StopIcon";
import { useTheme } from "styled-components";
import { Fillrate } from "../types";
import { StopInfo } from "./StopInfo/StopInfo";
import { getTrainStopColor, isCancelled } from "./utils";

type TrainStopProps = {
  stop: Stop;
  nextStop: Stop;
  isLastStop: boolean;
  isStopWithoutPassing: boolean;
  nextStopIsStopWithoutPassing: boolean;
  fillrate: Fillrate | null;
};

export const TrainStop: React.FC<TrainStopProps> = ({
  stop,
  nextStop,
  isLastStop,
  isStopWithoutPassing,
  nextStopIsStopWithoutPassing,
  fillrate,
}) => {
  const theme = useTheme();
  const stopIsCancelled = isCancelled(stop);
  const nextStopIsCancelled = isCancelled(nextStop);

  const isInBetweenStops =
    stop.actualDepartureTime !== null &&
    !nextStop?.isArrived &&
    !nextStopIsCancelled; // has departed from stop, but have not arrived at next stop yet

  // different color styling for stopIcon and line:
  const stopColor = getTrainStopColor({
    isReachedOrInBetween: stop.isArrived || isInBetweenStops,
    isCancelled: stopIsCancelled,
    delay: stop.departureDelay || stop.arrivalDelay || 0,
    theme,
  });

  const lineColor = getTrainStopColor({
    isLineColor: true,
    isReachedOrInBetween:
      nextStop?.isArrived || isInBetweenStops || isStopWithoutPassing, // add color also when we are missing data
    isCancelled: stopIsCancelled || nextStopIsCancelled,
    delay: stop.departureDelay || stop.arrivalDelay || 0,
    theme,
  });

  const stopCount = fillrate?.stops.find((s) => s.stopId === stop.stopId);

  return (
    <VehicleStopWrapper>
      <RouteFraction className="route_fraction">
        <StopIcon
          justPassingThrough={stop.justPassingThrough}
          isCancelled={stopIsCancelled}
          color={stopColor}
        />
        {!isLastStop && (
          <Line
            $color={lineColor}
            $isInBetweenStops={isInBetweenStops}
            $isMissingData={nextStopIsStopWithoutPassing}
          />
        )}
      </RouteFraction>
      <StopInfo
        stop={stop}
        stopCount={stopCount}
        isMissingData={isStopWithoutPassing}
      />
    </VehicleStopWrapper>
  );
};
