import { Flex, Text } from "@vygruppen/spor-react";
import { TextareaExpandable } from "features/CenterContent/VehicleDetails/shared/TextareaExpandable";
import { useState } from "react";
import { MessageList } from "shared/components/InternalMessage/components/MessageList";
import { ChevronButton } from "shared/components/buttons/ChevronButton";
import { PlusButton } from "shared/components/buttons/PlusButton";
import { NoteOutline24Icon } from "shared/icons/NoteOutline24Icon";
import styled from "styled-components";

const InternalMessageContainer = styled.div`
  /* Positioning */
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;

  /* Styling */
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  border: 1px solid ${({ theme }) => theme.colorOutline};
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;

  /* Spacing */
  padding: 18px 24px 18px 24px;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InternalMessage = ({ isEditMode }: { isEditMode: boolean }) => {
  const [active, setActive] = useState(false);
  return (
    <Flex height="100px" position="relative">
      <InternalMessageContainer>
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <Flex gap="6px">
            <NoteOutline24Icon />
            <Text fontWeight="900">Intern melding</Text>
          </Flex>
          {isEditMode ? (
            <ChevronButton active={active} setActive={setActive} />
          ) : (
            <PlusButton active={active} setActive={setActive} />
          )}
        </Flex>

        {isEditMode ? (
          <MessageList collapsed={!active} />
        ) : (
          <TextareaExpandable
            mt={active ? "12px" : "0px"}
            /* 
              {...register("internalMessage")} // Use this to retrieve the text at onSubmitForm
            */
            $collapsed={!active}
          />
        )}
      </InternalMessageContainer>
    </Flex>
  );
};
