import { format, isToday, isYesterday, parse, parseISO } from "date-fns";
import { nb } from "date-fns/locale";

export const debounce = (fn, ms = 0) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const formatTrainDate = (it) => {
  try {
    return format(new Date(it), "dd.MM");
  } catch (e) {
    return "Feil datoformat";
  }
};

export const formatDayDateString = (dateString) => {
  if (dateString === null || !dateString) return "\u00a0";

  try {
    const date = parseISO(dateString);
    if (isToday(date)) return format(date, "HH:mm");
    if (isYesterday(date)) return `i går ${format(date, "HH:mm")}`;
    return format(date, "d. MMM HH:mm", { locale: nb });
  } catch (e) {
    return "Feil datoformat";
  }
};

export const formatDateString = (
  dateString,
  formatString,
  failoverText = "",
  suffix = null,
) => {
  try {
    return `${format(parseISO(dateString), formatString)}${suffix || ""}`;
  } catch (e) {
    return failoverText;
  }
};

export const parseTime = (timeString, referenceDate = new Date()) =>
  parse(timeString, "HH:mm", referenceDate, { locale: nb });

export const formatTimeString = (timeString) =>
  timeString === null
    ? null
    : new Date(timeString).toLocaleTimeString("no-nb", {
        hour: "2-digit",
        minute: "2-digit",
      });

export function compareDateStringAsc(a, b) {
  return new Date(b).valueOf() - new Date(a).valueOf();
}
