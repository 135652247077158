import TrainLoaderDarkSvg from "app/Auth/Login/train_loader_dark.svg?react";
import TrainLoaderLightSvg from "app/Auth/Login/train_loader_light.svg?react";
import styled, { css, keyframes } from "styled-components";

export const LoginContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
`;

const TrainAnimation = keyframes`
  from {
    transform: translate(-380px, 0px);
  }
  to {
    transform: translate(100%, 0px);
  }
`;

const trainLoaderCSS = css`
  width: 600px;
  height: 262px;

  .train {
    transform: translate(-380px, 0px);
    animation: ${TrainAnimation} 3s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
`;

export const TrainLoaderLight = styled(TrainLoaderLightSvg)`
  ${trainLoaderCSS};
`;

export const TrainLoaderDark = styled(TrainLoaderDarkSvg)`
  ${trainLoaderCSS};
`;
