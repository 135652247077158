import { ArrowRightOutline18Icon } from "@vygruppen/spor-icon-react";
import {
  Button,
  HStack,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  VStack,
} from "@vygruppen/spor-react";
import { CountryCode } from "@vygruppen/vy-train-map";
import { format } from "date-fns";
import { FilterData } from "features/History/Filter/FilterData";

import { Tooltip } from "@chakra-ui/react";
import { getEventState } from "features/History/HistoryDetail/HistoryDetail";
import { State } from "features/History/HistoryDetail/fetchHistoryDetail";
import { useOperationalInformation } from "features/History/useOperationalInformation";
import {
  CustomResponsiveTr,
  CustomText,
  HistoryCustomTableStyle,
} from "features/History/utils/HistoryCustomTableStyle";
import { FC, useEffect, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import { DropsBadge, Semantics } from "shared/components/badge/DropsBadge";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { TableSkeletonHistory } from "shared/components/feedback/SkeletonLoader/TableSkeleton";
import { VisibilityIndicator } from "shared/icons/VisibilityIndicator";
import { AffectedStop, Distribution } from "shared/types/historyDetail";
import {
  CombinedHistoryResponse,
  InfrastructureOperationalInformationOverviewResponse,
  StationOperationalInformationOverviewResponse,
  TrainOperationalInformationOverviewResponse,
} from "shared/types/operationalInformation";
import { TRAIN } from "shared/utils/routes";
import { useFeatureFlag } from "../../app/FeatureFlags/useFeatureFlags";

function eventIsTrainEvent(
  event: CombinedHistoryResponse,
): event is TrainOperationalInformationOverviewResponse {
  return event.type.group === "TRAIN";
}

function eventIsInfrastructureEvent(
  event: CombinedHistoryResponse,
): event is InfrastructureOperationalInformationOverviewResponse {
  return event.type.group === "INFRASTRUCTURE";
}

function eventIsStationEvent(
  event: CombinedHistoryResponse,
): event is StationOperationalInformationOverviewResponse {
  return event.type.group === "STATION";
}

type OperationalEventProps = {
  countryCode: CountryCode;
  filter: FilterData;
  setFilter: (filter: FilterData) => void;
  setTrainDetailUUID: (uuid: string | null) => void;
};

const groupLabels = {
  TRAIN: "Tog",
  STATION: "Stasjon",
  INFRASTRUCTURE: "Infrastruktur",
  GLOBAL: "Global",
};

function nameOrId(affectedStop: AffectedStop) {
  return affectedStop.name ?? affectedStop.jbvId;
}

export const getSubTitleFromAffectedStops = (affectedStops: AffectedStop[]) => {
  if (!affectedStops || affectedStops.length === 0) return "-";
  const firstStop = nameOrId(affectedStops[0]);
  if (affectedStops.length === 1) return firstStop;
  const lastStop = nameOrId(affectedStops[affectedStops.length - 1]);
  return `${firstStop} - ${lastStop}`;
};

export type EventState = {
  label: string;
  semantics: Semantics;
};

export const stateMap: {
  [key in State]: EventState;
} = {
  OPEN: {
    label: "Åpen",
    semantics: "warning",
  },
  CLOSED: {
    label: "Friskmeldt",
    semantics: "success",
  },
  REPLACED: {
    label: "Erstattet",
    semantics: "info",
  },
  UNHANDLED: {
    label: "Må behandles",
    semantics: "alarm",
  },
  DEPUBLISHED: {
    label: "Depublisert",
    semantics: "neutral",
  },
};

const getDropsDistributionText = (distributions: Distribution[]) => {
  if (distributions.length > 0) {
    return (
      distributions.find((distribution) => distribution.contextName === "DROPS")
        ?.text.message ?? distributions[0].text.message
    );
  }
  return "";
};

export const OperationalEvents: FC<OperationalEventProps> = ({
  countryCode,
  filter,
  setFilter,
  setTrainDetailUUID,
}) => {
  const { data, status } = useOperationalInformation(
    countryCode,
    filter.date.toString(),
  );

  useEffect(() => {
    if (!data) return;
    const newAllTrainIds = data
      .map(
        (event) =>
          (event as TrainOperationalInformationOverviewResponse).trainIdentifier
            ?.operational_identifier,
      )
      .filter(Boolean);
    const newFilter = filter.setAllTrainIds(newAllTrainIds);
    const allTrainIdsJSON = JSON.stringify(filter.allTrainIds);
    const newAllTrainIdsJSON = JSON.stringify(newFilter.allTrainIds);
    if (allTrainIdsJSON !== newAllTrainIdsJSON) {
      setFilter(newFilter);
    }
  }, [data, filter]);

  const [showAllEvents, setShowAllEvents] = useState(false);

  const filteredData = data?.filter((event) => filter.isMatch(event));

  const displayedMinimized = 6;

  const displayedEvents = showAllEvents
    ? filteredData
    : filteredData?.slice(0, displayedMinimized);

  const globalFeatureFlag = useFeatureFlag("gobalEvents").enabled;

  return (
    <HistoryCustomTableStyle>
      <Thead>
        <CustomResponsiveTr>
          <Th>Versjonsdato</Th>
          <Th>Type</Th>
          <Th>Hendelse</Th>
          <Th className="DontShowIfSmallWidth">Versjon</Th>
          <Th>Tilstand</Th>
          <Th
            // minWidth is used here to make TableSkeleton follow the width more correctly
            minWidth="200px"
          >
            <HStack justifyContent="space-between" mr="10px">
              <Text>Siste tekst</Text>
              <HStack>
                <Text>{filteredData?.length}</Text>
                <CustomText fontWeight="normal">treff</CustomText>
              </HStack>
            </HStack>
          </Th>
        </CustomResponsiveTr>
      </Thead>

      <Tbody>
        {status === "pending" && (
          <TableSkeletonHistory numOfRows={1} numOfColumns={6} />
        )}
        {status === "error" && <FailureMessage />}
        {status === "success" &&
          displayedEvents
            ?.filter(
              (event) => globalFeatureFlag || event.type.group !== "GLOBAL",
            )
            .map((event) => {
              const eventState = getEventState(event.state, event.type.type);
              return (
                <CustomResponsiveTr key={event.uuid}>
                  <Td>
                    <Text fontWeight="bold">
                      {format(new Date(event.updatedAt), "yyyy.MM.dd")}
                    </Text>
                    {format(new Date(event.updatedAt), "HH.mm")}
                  </Td>
                  <Td>
                    <Text fontWeight="bold">
                      {groupLabels[event.type.group] || ""}
                    </Text>
                    <Text>
                      {eventIsTrainEvent(event) ? (
                        <Link
                          to={generatePath(TRAIN, {
                            countryCode: event.trainIdentifier.country_code,
                            trainNumber:
                              event.trainIdentifier.operational_identifier,
                            trainDate: event.trainIdentifier.nominal_date,
                          })}
                        >
                          <Button
                            variant="tertiary"
                            size="xs"
                            rightIcon={<ArrowRightOutline18Icon />}
                            marginTop={1}
                          >
                            <Text
                              style={{
                                whiteSpace: "nowrap",
                                minWidth: "auto",
                              }}
                              fontSize="xs"
                            >
                              {event.trainIdentifier.operational_identifier} /{" "}
                              {event.lineNumber}
                            </Text>
                          </Button>
                        </Link>
                      ) : (
                        "-"
                      )}
                    </Text>
                  </Td>
                  <Td>
                    <VStack align="start">
                      <Text fontWeight="bold" noOfLines={3}>
                        {event.type.label ?? event.type.type}
                      </Text>
                      {eventIsInfrastructureEvent(event) &&
                        event.stretchName && (
                          <Text noOfLines={2}>{event.stretchName}</Text>
                        )}

                      {eventIsStationEvent(event) &&
                        event.stops &&
                        event.stops.length > 0 && (
                          <Text noOfLines={2}>
                            {getSubTitleFromAffectedStops(event.stops)}
                          </Text>
                        )}
                      {eventIsTrainEvent(event) &&
                        event.affectedStops &&
                        event.affectedStops.length > 0 && (
                          <Text noOfLines={2}>
                            {getSubTitleFromAffectedStops(event.affectedStops)}
                          </Text>
                        )}
                    </VStack>
                  </Td>
                  <Td className="DontShowIfSmallWidth">
                    <CustomText>{event.version}</CustomText>
                  </Td>
                  <Td>
                    <HStack justifyContent="space-between">
                      <DropsBadge semantics={eventState.semantics}>
                        {eventState.label}
                      </DropsBadge>
                      <Tooltip
                        hasArrow
                        placement="right"
                        label={event.isVisible ? "Synlig" : "Usynlig"}
                      >
                        <VisibilityIndicator
                          semantics={event.isVisible ? "success" : "alarm"}
                        />
                      </Tooltip>
                    </HStack>
                  </Td>
                  <Td>
                    <HStack justifyContent="space-between">
                      <Text
                        style={{
                          // Behaviour: Use max 3 lines and show "..." at overflow
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: "3",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {getDropsDistributionText(event.distributions)}
                      </Text>

                      <Button
                        rightIcon={<ArrowRightOutline18Icon />}
                        flexShrink={0}
                        variant="ghost"
                        size="xs"
                        onClick={() => {
                          setTrainDetailUUID(event.uuid);
                        }}
                        id="seedetails"
                      >
                        <CustomText>Se detaljer</CustomText>
                      </Button>
                    </HStack>
                  </Td>
                </CustomResponsiveTr>
              );
            })}
      </Tbody>
      <Tfoot>
        <CustomResponsiveTr>
          <Td
            gridColumn="1 / -1"
            textAlign="right"
            fontWeight="normal"
            fontSize={16}
          >
            <HStack justifyContent="flex-end">
              <Text fontSize="xs">
                {displayedEvents && filteredData
                  ? `Viser ${displayedEvents.length} av ${filteredData.length}`
                  : "Laster..."}{" "}
              </Text>
              {filteredData && filteredData.length > displayedMinimized && (
                <Button
                  variant="tertiary"
                  size="sm"
                  ml={2}
                  onClick={() => setShowAllEvents(!showAllEvents)}
                >
                  {showAllEvents ? "Vis mindre" : "Vis alle"}
                </Button>
              )}
            </HStack>
          </Td>
        </CustomResponsiveTr>
      </Tfoot>
    </HistoryCustomTableStyle>
  );
};
