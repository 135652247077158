import { ReactNode, useEffect } from "react";
import { useApiAction } from "../../../../api/drops/useApiAction";
import { getLocalStorageUsername } from "../../../../api/dropsLocalStorage";
import { userListTelephoneApi } from "../../../../api/puzzel/puzzel";
import { Text } from "../../../../shared/components/typography/TitleStyles";
import { usePuzzelAuthenticationContext } from "../provider/contexts/PuzzelAuthenticationContext";
import { UserDetailsAndLogOnOffProvider } from "../provider/contexts/UserDetailsAndLogOnOffContext";
import { useSetUserValidation } from "../provider/contexts/UserValidationContext";
import { CallProvider } from "../provider/providers/CallStatusProvider";

type Props = {
  children: ReactNode;
};
const ValidateUserAndPresentLogon = (props: Props) => {
  const setUserId = useSetUserValidation();
  const { isAuthenticated } = usePuzzelAuthenticationContext();

  const [userListRequest, performGetUserList] = useApiAction(
    userListTelephoneApi,
    false,
  );

  useEffect(() => {
    if (isAuthenticated) {
      performGetUserList();
    }
  }, [isAuthenticated]);

  const foundUserId = userListRequest.data?.result?.find(
    (it: any) => it.userName === getLocalStorageUsername(),
  )?.id;

  useEffect(() => {
    if (foundUserId) {
      setUserId({
        userId: foundUserId,
      });
      localStorage.setItem("puzzelUserId", foundUserId);
    } else {
      setUserId({
        userId: null,
      });
      localStorage.removeItem("puzzelUserId");
    }
  }, [foundUserId]);

  if (!foundUserId) {
    return (
      <Text
        style={{
          marginRight: "2.5rem",

          // Behaviour: Use max 3 lines and show "..." at overflow
          overflow: "hidden",
          display: "-webkit-box",
          WebkitLineClamp: "3",
          WebkitBoxOrient: "vertical",
        }}
      >
        {`Bruker (${getLocalStorageUsername()}) er ikke registrert i Puzzel. Ta kontakt med admin`}
      </Text>
    );
  }

  const { children } = props;
  return (
    <UserDetailsAndLogOnOffProvider>
      <CallProvider>{children}</CallProvider>
    </UserDetailsAndLogOnOffProvider>
  );
};

export default ValidateUserAndPresentLogon;
