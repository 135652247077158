import { addDays, differenceInCalendarDays } from "date-fns";
import { useState } from "react";

const VALID_DATE_RANGE = { PAST: -1, FUTURE: 3 };

export const useVehicleDate = (inputDate: Date) => {
  const [selectedDate, setSelectedDate] = useState(inputDate);

  const differenceInDays = differenceInCalendarDays(selectedDate, new Date());

  const canGoToPreviousDay = differenceInDays > VALID_DATE_RANGE.PAST;
  const goToPreviousDay = () => {
    if (canGoToPreviousDay) {
      setSelectedDate(addDays(selectedDate, -1));
    }
  };

  const canGoToNextDay = differenceInDays < VALID_DATE_RANGE.FUTURE;
  const goToNextDay = () => {
    if (canGoToNextDay) {
      setSelectedDate(addDays(selectedDate, 1));
    }
  };

  return {
    selectedDate,
    setSelectedDate,
    canGoToPreviousDay,
    canGoToNextDay,
    goToPreviousDay,
    goToNextDay,
  };
};

export type VehicleDate = ReturnType<typeof useVehicleDate>;
