const flipOrientation = {
  A: "B",
  B: "A",
};

const baseUrl = "https://trainicons.vy.no/";

export const getVehicleImage = (vehicleType, orientation) =>
  `${baseUrl}${vehicleType.replace(" ", "%20")}-${
    flipOrientation[orientation.toUpperCase()]
  }.gif`;
