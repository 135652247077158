import { useUserDetailsState } from "../../provider/contexts/UserDetailsAndLogOnOffContext";
import CallAllocated from "./views/CallAllocated";
import { TransferProvider } from "./views/CallInSession/CallTransfer/TransferProvider";
import CallOut from "./views/CallOut";
import CallInSession from "./views/CallInSession/CallInSession";

const CallControl = () => {
  const userDetails = useUserDetailsState();

  if (!userDetails.isLoggedOn) return null;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CallAllocated />
      <TransferProvider>
        <CallInSession />
      </TransferProvider>
      <CallOut />
    </div>
  );
};

export default CallControl;
