import { FC } from "react";
import { CommonSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { TrainContext } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";
import { TrainDelayTypeFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainDelayed";

export const TrainDelayExpected: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps & TrainContext
> = (props) => (
  <TrainDelayTypeFields
    label="Forventet forsinkelse (min)"
    delayFieldKey="delayInMinutes"
    {...props}
  />
);
