import { Environment, environment } from "environment";
import { getTrafficguiBaseUrl } from "./common";
import { getLocalStorageUsername } from "./dropsLocalStorage";

// To include worker script in Vite build, they allow using query suffixes to import the script: https://v3.vitejs.dev/guide/features.html#import-with-query-suffixes
// Which is nice, because then we can have the script in the /src folder, and don't need to have it in the public folder
// eslint-disable-next-line import/no-unresolved
import CloudWatchWorker from "./cloud-watch/CloudWatchWorker?sharedworker";

export enum LogLevel {
  error = "error",
  info = "info",
  warn = "warn",
}

let worker = null;

if (typeof Worker !== "undefined") {
  worker = new CloudWatchWorker();
}

if (worker) {
  worker.port.onmessage = (event: MessageEvent<string>) =>
    console.log(event.data);
}

export const log = (
  logLevel: LogLevel,
  componentName: string,
  message: string,
) => {
  const env = environment();
  worker?.port.postMessage({
    logLevel,
    componentName,
    username: getLocalStorageUsername(),
    userAgent: navigator.userAgent,
    message,

    url: `${getTrafficguiBaseUrl(env)}/frontend-logger/log`,
    logToConsole: env === Environment.Development,
  });
};
