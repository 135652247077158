import { isFuture } from "date-fns/isFuture";
import { isBefore } from "date-fns/isBefore";
import { subHours } from "date-fns/subHours";
import { parseISO } from "date-fns/parseISO";

import {
  COMPANYCODE,
  ROLE,
  FilterType,
} from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/TableFilter/constants";
import { DayPlanTrackingStatus, DayPlanTrackingType } from "../types";

export const getStatus = (status: DayPlanTrackingStatus) => {
  switch (status) {
    case DayPlanTrackingStatus.SENT:
      return { severity: "ERROR", text: "Sendt" };
    case DayPlanTrackingStatus.RECEIVED:
      return { severity: "WARN", text: "Mottatt" };
    case DayPlanTrackingStatus.READ:
      return { severity: "OK", text: "Lest" };
    case DayPlanTrackingStatus.RECEIVED_BEFORE_START:
      return { severity: "OK", text: "Mottatt før start" };
    case DayPlanTrackingStatus.MANUAL:
      return { severity: "OK", text: "Bekreftet" };
    case DayPlanTrackingStatus.OUTDATED:
      return { severity: "OK", text: "Passert" };
    default:
      return { severity: "OK", text: status };
  }
};

const longTimeThresholdHours = 4;

export const filterDayPlanTrackings = (
  dayPlanTrackings: DayPlanTrackingType[],
  filterState: FilterType,
) => {
  const sortFunc = (a: DayPlanTrackingType, b: DayPlanTrackingType) =>
    parseISO(b.lastUpdatedAt).getTime() - parseISO(a.lastUpdatedAt).getTime();

  return dayPlanTrackings
    .filter((it: DayPlanTrackingType) => {
      const dutyHasEndedLongTimeAgo = it.dutyEnd
        ? isBefore(
            new Date(it.dutyEnd),
            subHours(new Date(), longTimeThresholdHours),
          )
        : false;

      const isDutyStartFuture = it.dutyStart
        ? isFuture(new Date(it.dutyStart))
        : false;

      const acknowledgedAfterLastUpdate =
        it.acknowledgedAt !== null &&
        isBefore(new Date(it.lastUpdatedAt), new Date(it.acknowledgedAt));

      const acknowledgedManuallyAfterLastUpdate =
        it.acknowledgedManuallyAt !== null &&
        isBefore(
          new Date(it.lastUpdatedAt),
          new Date(it.acknowledgedManuallyAt),
        );

      /**
       * We consider a day plan tracking as closed if it has been acknowledged after the last day plan update,
       * if the day plan was fetched/received before the duty started,
       * or if the duty has ended a long time ago.
       */
      const isClosed =
        acknowledgedAfterLastUpdate ||
        acknowledgedManuallyAfterLastUpdate ||
        it.status === DayPlanTrackingStatus.RECEIVED_BEFORE_START ||
        dutyHasEndedLongTimeAgo;

      const isDeletion = it.deletion;

      /**
       * Default filter matching day plan trackings that are;
       * - Not regarded as closed
       * - Not related to a duty with start time in the future
       * - Not a day plan deletion
       */
      const filterDefault = !isClosed && !isDutyStartFuture && !isDeletion;

      if (
        filterState.showClosed ||
        filterState.showFuture ||
        filterState.showDeleted
      ) {
        let filterByDutyStartFuture = false;
        let filterDeletion = false;
        let filterShowClosed = false;

        // Show day plan trackings for future duties that are not closed
        // unless the showClosed filter is enabled.
        if (filterState.showFuture) {
          filterByDutyStartFuture =
            isDutyStartFuture && (!isClosed || filterState.showClosed);
        }

        // Show day plan trackings that are day plan deletions.
        if (filterState.showDeleted) {
          filterDeletion = isDeletion;
        }

        // Show day plan trackings regarded as closed.
        if (filterState.showClosed) {
          filterShowClosed =
            isClosed &&
            (!isDeletion || filterState.showDeleted) &&
            (!isDutyStartFuture || filterState.showFuture);
        }

        return (
          filterByDutyStartFuture ||
          filterDeletion ||
          filterShowClosed ||
          filterDefault
        );
      }

      return filterDefault;
    })
    .filter((it: DayPlanTrackingType) => {
      switch (filterState.companyCode) {
        case COMPANYCODE.VY:
          return it.companyCode === COMPANYCODE.VY;

        case COMPANYCODE.VYG:
          return it.companyCode === COMPANYCODE.VYG;

        case COMPANYCODE.VYT:
          return it.companyCode === COMPANYCODE.VYT;

        case COMPANYCODE.VYT_VYG_VY:
        default:
          return (
            it.companyCode === COMPANYCODE.VY ||
            it.companyCode === COMPANYCODE.VYG ||
            it.companyCode === COMPANYCODE.VYT
          );
      }
    })
    .filter((it: DayPlanTrackingType) => {
      switch (filterState.role) {
        case ROLE.LOK:
          return it.isDriver;
        case ROLE.KOND:
          return it.isConductor;
        case ROLE.BOTH:
          return it.isConductor || it.isDriver;
        case ROLE.NONE:
          return !it.isConductor && !it.isDriver;
        default:
          return true;
      }
    })
    .sort(sortFunc);
};
