function hexToRgb(hex: string): { r: number; g: number; b: number } {
  // Remove the leading # if present
  hex = hex.replace(/^#/, "");

  // Check if the string is either 3 or 6 characters long
  if (hex.length !== 3 && hex.length !== 6) {
    throw new Error(
      "Invalid hex color format. String must be either 3 or 6 characters long after #",
    );
  }

  // If the string is 3 characters long, convert it to 6 characters
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // Convert the hex values to decimal
  const num = parseInt(hex, 16);
  if (Number.isNaN(num)) {
    throw new Error("Invalid hex color format. Could not convert to a number");
  }

  const r = Math.floor(num / (256 * 256)) % 256;
  const g = Math.floor(num / 256) % 256;
  const b = num % 256;

  return { r, g, b };
}

export const interpolateColor = (
  startColorHex: string,
  endColorHex: string,
  interpolant: number, // must be in the range [0, 1]
) => {
  if (interpolant < 0 || interpolant > 1) {
    throw new Error(`Interpolant must be between 0 and 1`);
  }
  const startColor = hexToRgb(startColorHex);
  const endColor = hexToRgb(endColorHex);
  const interpolate = (color: "r" | "g" | "b") =>
    startColor[color] + (endColor[color] - startColor[color]) * interpolant;
  return `rgb(${interpolate("r")}, ${interpolate("g")}, ${interpolate("b")})`;
};
