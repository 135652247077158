import { Button, Stack } from "@vygruppen/spor-react";
import { FC } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";

type FailureMessageWithRetryButtonProps = {
  customMessage: string;
  retryFunction: () => void;
  retryLabel?: string;
};

export const FailureMessageWithRetryButton: FC<
  FailureMessageWithRetryButtonProps
> = ({ customMessage, retryFunction, retryLabel }) => (
  <Stack flexDirection="row">
    <FailureMessage customMessage={customMessage} />
    <Button onClick={retryFunction} size="xs" variant="tertiary" width="30%">
      {retryLabel ?? "Prøv igjen"}
    </Button>
  </Stack>
);
