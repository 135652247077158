import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import {
  SharedContentGridLayout,
  SharedGridStyle,
} from "features/CenterContent/shared/MainContentGrid";
import Tabs from "features/CenterContent/shared/Tabs/Tabs";
import { useParams } from "react-router-dom";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { reserveAlternativeTransportSchema } from "shared/types/alternativeTransport";
import styled from "styled-components";
import * as z from "zod";
import { ExpandableInfoMessage } from "shared/components/feedback/InfoMessage/ExpandableInfoMessage/ExpandableInfoMessage";
import { VStack, Text } from "@vygruppen/spor-react";
import OperationalLog from "../shared/OperationalLog";
import { ReserveVehicleInfo } from "./ReserveVehicleInfo";

const ContentContainer = styled.div`
  ${SharedContentGridLayout};
  grid-template-columns: minmax(15rem, 40rem) 1fr;
  grid-template-rows: auto 1fr;

  & > * {
    ${SharedGridStyle};
  }
`;

const ErrorContainer = styled.div`
  ${SharedContentGridLayout};
  ${SharedGridStyle};
  box-sizing: border-box;
`;

const DetailsLoader = () => (
  <ContentContainer>
    <div style={{ gridColumn: "1 / span 2", alignItems: "center" }}>
      <SkeletonLoader skeletonType="trainStops" />
    </div>
    <div>
      <SkeletonLoader skeletonType="list" />
    </div>
  </ContentContainer>
);

const ReserveAlternativeTransportDetails = () => {
  const { vehicleUuid } = useParams();

  const { status, data: reserveVehicle } = useQuery({
    queryKey: ["reserveDetails", vehicleUuid],
    refetchInterval: 30000,
    queryFn: async ({ signal }) =>
      axiosClient
        .get<
          z.infer<typeof reserveAlternativeTransportSchema>
        >(`${getBackendUrl()}/alternativeTransports/reserve/${vehicleUuid}`, { signal })
        .then((res) => reserveAlternativeTransportSchema.parse(res.data)),
  });

  const getContent = () => {
    switch (status) {
      case "pending":
        return <DetailsLoader />;
      case "success":
        return reserveVehicle ? (
          <ContentContainer>
            <ReserveVehicleInfo reserveAlternativeTransport={reserveVehicle} />
            <VStack style={{ width: "100%", padding: 0 }}>
              {reserveVehicle.notes.length > 0 && (
                <div style={{ width: "100%", padding: "0.75rem" }}>
                  <ExpandableInfoMessage severity="info" title="Info fra Brudd">
                    {reserveVehicle.notes.map((note, i) => (
                      <Text key={`note-${i}`}>{note}</Text>
                    ))}
                  </ExpandableInfoMessage>
                </div>
              )}
              <OperationalLog
                vehicleId={reserveVehicle.id}
                logs={reserveVehicle.logs ?? []}
              />
            </VStack>
          </ContentContainer>
        ) : (
          <ErrorContainer>
            <NoDataMessage reason="Fant ingen detaljer om bussen" />
          </ErrorContainer>
        );
      case "error":
      default:
        return (
          <ErrorContainer>
            <FailureMessage />
          </ErrorContainer>
        );
    }
  };

  return (
    <>
      <Tabs />
      {getContent()}
    </>
  );
};

export default withErrorBoundary(ReserveAlternativeTransportDetails);
