import { Box, VStack } from "@vygruppen/spor-react";
import { FC, useEffect, useState } from "react";
import { Filter } from "features/History/Filter";
import { FilterData } from "features/History/Filter/FilterData";
import { OperationalEvents } from "features/History/OperationalEvents";
import { HistoryWrapper } from "features/History/utils/HistoryCustomTableStyle";
import { HistoryDetail } from "features/History/HistoryDetail/HistoryDetail";

const setSessionStorageHistoryFilterData = (historyFilterData: FilterData) =>
  sessionStorage.setItem("historyFilterData", historyFilterData.stringify());

const getSessionStorageHistoryFilterData = (): FilterData => {
  const filterDataFromSessionStorage: FilterData | null = FilterData.fromString(
    sessionStorage.getItem("historyFilterData"),
  );

  return filterDataFromSessionStorage ?? new FilterData();
};

export const HistoryOverview: FC = () => {
  const [filter, setFilter] = useState<FilterData>(
    getSessionStorageHistoryFilterData,
  );

  useEffect(() => {
    setSessionStorageHistoryFilterData(filter);
  }, [filter]);

  const [trainDetailUUID, setTrainDetailUUID] = useState<string | null>(null);

  return (
    <HistoryWrapper>
      <VStack>
        <Box maxWidth="1600px" width="100%">
          {trainDetailUUID ? (
            <HistoryDetail
              eventUuid={trainDetailUUID}
              setTrainDetailUUID={setTrainDetailUUID}
            />
          ) : (
            <>
              <Filter filter={filter} setFilter={setFilter} />
              <OperationalEvents
                countryCode="NO"
                filter={filter}
                setFilter={setFilter}
                setTrainDetailUUID={setTrainDetailUUID}
              />
            </>
          )}
        </Box>
      </VStack>
    </HistoryWrapper>
  );
};
