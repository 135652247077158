import { usePuzzelExtendedStateInformation } from "../../../../provider/contexts/ListenForCallStatusContext";
import CallerNumberInfo from "../../../CallerNumberInfo";
import { Text } from "../../../../../../../shared/components/typography/TitleStyles";
import { CallIconHoldStyled } from "../../../../PuzzelStyle";
import {
  IqSessionRequestType,
  IqSessionStateTypes,
  SessionCommands,
  Status,
} from "../../../types";
import SessionCommand from "../../../SessionCommand";

type Props = {
  req: IqSessionRequestType;
};

const StatusInService = (props: Props) => {
  const { req } = props;
  const { status, remoteAddress } = req;

  const { iqSessionState }: any = usePuzzelExtendedStateInformation();

  const ConsultationCall =
    iqSessionState === IqSessionStateTypes.ConsultationCall;

  if (status === Status.InService) {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text bold>På vent</Text>
          <CallerNumberInfo status={status} remoteAddress={remoteAddress} />
        </div>
        <CallIconHoldStyled />

        <SessionCommand
          command={SessionCommands.Hangup}
          requestId={req.id}
          disabled
        />

        {!ConsultationCall ? (
          <SessionCommand
            command={SessionCommands.MuteOff}
            requestId={req.id}
          />
        ) : null}
      </div>
    );
  }
  return null;
};

export default StatusInService;
