import { useQuery } from "@tanstack/react-query";
import { Text, VStack } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import {
  SharedContentGridLayout,
  SharedGridStyle,
} from "features/CenterContent/shared/MainContentGrid";
import Tabs from "features/CenterContent/shared/Tabs/Tabs";
import { useParams } from "react-router-dom";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { ExpandableInfoMessage } from "shared/components/feedback/InfoMessage/ExpandableInfoMessage/ExpandableInfoMessage";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import {
  AlternativeTransportItinerary,
  alternativeTransportResponseSchema,
} from "shared/types/alternativeTransport";
import styled from "styled-components";
import * as z from "zod";
import OperationalLog from "../shared/OperationalLog";
import { BusGps } from "./BusGps/BusGps";
import { Itinerary } from "./Itinerary/Itinerary";
import { ShiftSchedule } from "./ShiftSchedule";

const ContentContainer = styled.div`
  ${SharedContentGridLayout};
  grid-template-columns: repeat(3, minmax(15rem, 40rem));
  grid-template-rows: auto 1fr;

  & > * {
    ${SharedGridStyle};
  }
`;

const ErrorContainer = styled.div`
  ${SharedContentGridLayout};
  ${SharedGridStyle};
  box-sizing: border-box;
`;

const BusGpsArea = styled.div`
  aspect-ratio: 1/1;
  max-height: 100%;
  min-width: 100%;
  ${SharedGridStyle};
  padding: 0;
`;

const DetailsLoader = () => (
  <ContentContainer>
    <div style={{ gridColumn: "1 / -1", alignItems: "center" }}>
      <SkeletonLoader skeletonType="trainStops" />
    </div>
    <div>
      <SkeletonLoader skeletonType="list" />
    </div>
    <div>
      <SkeletonLoader skeletonType="list" />
    </div>
    <div>
      <SkeletonLoader skeletonType="list" />
    </div>
  </ContentContainer>
);

const AlternativeTransportDetails = () => {
  const { vehicleUuid } = useParams();

  const { status, data } = useQuery({
    queryKey: ["altTransDetails", vehicleUuid],
    refetchInterval: 30000,
    queryFn: async ({ signal }) =>
      axiosClient
        .get<
          z.infer<typeof alternativeTransportResponseSchema>
        >(`${getBackendUrl()}/alternativeTransports/${vehicleUuid}`, { signal })
        .then((res) => alternativeTransportResponseSchema.parse(res.data)),
  });

  const getDistributionStopPlace = (
    itinerary: AlternativeTransportItinerary | null,
  ) =>
    itinerary
      ? [...itinerary.stops, ...(itinerary.returnStops ?? [])]
          .filter((stop) => !!stop.stopReference.nsrStopPlace)
          .map((stop) => stop.stopReference.nsrStopPlace)
          .join(",")
      : "";

  const getContent = () => {
    switch (status) {
      case "pending":
        return <DetailsLoader />;
      case "success": {
        const vehicle = data.alternativeTransportResponseVehicle;
        return vehicle ? (
          <ContentContainer>
            <Itinerary alternativeTransport={vehicle} />
            <BusGpsArea>
              <BusGps vehicle={vehicle} />
            </BusGpsArea>
            <VStack style={{ width: "100%", padding: 0 }}>
              {vehicle.notes.length > 0 && (
                <div style={{ width: "100%", padding: "0.75rem" }}>
                  <ExpandableInfoMessage severity="info" title="Info fra Brudd">
                    {vehicle.notes.map((note, i) => (
                      <Text key={`note-${i}`}>{note}</Text>
                    ))}
                  </ExpandableInfoMessage>
                </div>
              )}
              <OperationalLog
                vehicleId={vehicle.id}
                logs={vehicle.logs}
                distributionStopPlaces={getDistributionStopPlace(
                  vehicle.itinerary,
                )}
              />
            </VStack>

            <ShiftSchedule vehicle={vehicle} />
          </ContentContainer>
        ) : (
          <ErrorContainer>
            <NoDataMessage reason="Fant ingen detaljer om bussen" />
          </ErrorContainer>
        );
      }
      case "error":
      default:
        return (
          <ErrorContainer>
            <FailureMessage />
          </ErrorContainer>
        );
    }
  };

  return (
    <>
      <Tabs />
      {getContent()}
    </>
  );
};

export default withErrorBoundary(AlternativeTransportDetails);
