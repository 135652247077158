import { useQuery } from "@tanstack/react-query";
import { queryFnPOST } from "api/tanStackQuery/helpers";
import { PreviewResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { getBackendUrl } from "api/common";
import {
  TrainFormSchema,
  trainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { useEffect } from "react";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { TrainInfoRequest } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";
import { singleTrainFormToRequestBody } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";

type PreviewProps = {
  formState: TrainFormSchema;
  train: TrainIdentifier;
};

export const useTrainInfoPreview = ({ formState, train }: PreviewProps) => {
  const parsedForm = trainFormSchema.safeParse(formState);

  const {
    status: previewStatus,
    data: previewData,
    refetch,
  } = useQuery({
    queryKey: [formState, train],
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: ({ signal }) => {
      const { identifier, countryCode, nominalDate } = train;

      const body: TrainInfoRequest | null = parsedForm.success
        ? singleTrainFormToRequestBody(parsedForm.data, train)
        : null;

      return queryFnPOST<PreviewResponse>({
        url: `${getBackendUrl()}/trainInformation/preview/${countryCode ?? "NO"}/${identifier}/${nominalDate}`,
        body,
        signal,
      });
    },
  });

  useEffect(() => {
    if (parsedForm.success) {
      refetch();
    }
  }, [parsedForm]);

  return { previewData, previewStatus };
};
