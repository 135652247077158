import { useQuery } from "@tanstack/react-query";
import {
  Box,
  FormLabel,
  HStack,
  NumericStepper,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { TrainCompositionResponse } from "features/CenterContent/VehicleDetails/TrainDetails/TrainComposition/types";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { CommonSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { EitherTrainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { AffectedStopsFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/AffectedStopsFields";
import {
  calcTrainCompMetaData,
  getDefaultCapacity,
  getNumPassengerCarsFromLineId,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/changedCapacity";
import { TrainContext } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";
import { FC, useEffect } from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { useBatchUseTrainRoute } from "../useTrainInfoContext";

const MIN_CARS_OLD = 1;
const MIN_CARS_NEW = 2;
const MAX_CARS = 15;

export const TrainCapacityIncreased: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps & TrainContext
> = (props) => {
  const { mode, trainId, formContext, trainFormPrefix } = props;

  // The union from the prop type isn't strong enough so we need to re-cast
  const control = formContext.control as Control<EitherTrainFormSchema>;
  const setValue =
    formContext.setValue as UseFormSetValue<EitherTrainFormSchema>;
  const getValues =
    formContext.getValues as UseFormGetValues<EitherTrainFormSchema>;

  // Set capacity unit once on first load
  useEffect(() => {
    setValue(`${trainFormPrefix}.oldCapacityUnit`, "CARS", {
      shouldValidate: true,
    });
    setValue(`${trainFormPrefix}.newCapacityUnit`, "CARS", {
      shouldValidate: true,
    });
  }, []);

  const oldCapacity =
    getValues(`${trainFormPrefix}.oldCapacity`) ?? MIN_CARS_OLD;
  const newCapacity =
    getValues(`${trainFormPrefix}.newCapacity`) ?? MIN_CARS_NEW;

  const { status: compositionStatus, data: compositionData } = useQuery({
    queryKey: ["trainComposition", trainId.nominalDate, trainId.identifier],
    refetchOnWindowFocus: true,
    queryFn: ({ signal }) =>
      queryFnGET<TrainCompositionResponse>({
        signal,
        url: `${getBackendUrl()}/trainComposition/NO/${trainId.nominalDate}/${trainId.identifier}`,
      }),
  });

  const batchUseTrainRoute = useBatchUseTrainRoute();
  const { data: trainRoute } = batchUseTrainRoute(trainId);

  // Autofill based on train composition
  useEffect(() => {
    if (
      mode === "create" ||
      (!getValues(`${trainFormPrefix}.oldCapacity`) &&
        !getValues(`${trainFormPrefix}.newCapacity`))
    ) {
      if (
        compositionStatus === "success" &&
        compositionData.trainComposition.length > 0
      ) {
        const { numPassengerCars, type } = calcTrainCompMetaData(
          compositionData.trainComposition,
        );
        const capacity = getDefaultCapacity(
          numPassengerCars,
          type,
          "increased",
        );
        setValue(`${trainFormPrefix}.oldCapacity`, capacity.old, {
          shouldValidate: true,
        });
        setValue(`${trainFormPrefix}.newCapacity`, capacity.new, {
          shouldValidate: true,
        });
      } else {
        setValue(`${trainFormPrefix}.oldCapacity`, MIN_CARS_OLD, {
          shouldValidate: true,
        });
        setValue(`${trainFormPrefix}.newCapacity`, MIN_CARS_NEW, {
          shouldValidate: true,
        });
      }
    } else {
      const numPassengerCars = trainRoute?.lineId
        ? getNumPassengerCarsFromLineId(trainRoute.lineId)
        : 5;
      const capacity = getDefaultCapacity(
        numPassengerCars,
        "MULTIPLE_UNIT",
        "increased",
      );
      if (!getValues(`${trainFormPrefix}.oldCapacity`)) {
        setValue(`${trainFormPrefix}.oldCapacity`, capacity.old, {
          shouldValidate: true,
        });
      }
      if (!getValues(`${trainFormPrefix}.newCapacity`)) {
        setValue(`${trainFormPrefix}.newCapacity`, capacity.new, {
          shouldValidate: true,
        });
      }
    }
  }, [compositionStatus, mode]);

  return (
    <VStack width="100%" alignItems="flex-start" gap={3}>
      <AffectedStopsFields {...props} />
      <VStack paddingTop="1rem">
        <Box>
          <FormLabel marginBottom="0">Gammel kapasitet</FormLabel>
          <HStack gap="1rem" alignContent="center" display="flex">
            <Controller
              control={control}
              name={`${trainFormPrefix}.oldCapacity`}
              render={({ field }) => (
                <NumericStepper
                  name={`${trainFormPrefix}.oldCapacity`}
                  minValue={MIN_CARS_OLD}
                  maxValue={MAX_CARS - 1}
                  defaultValue={oldCapacity}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);

                    // Make sure 'newCapacity' is always larger than 'oldCapacity'
                    if (value >= newCapacity) {
                      setValue(
                        `${trainFormPrefix}.newCapacity`,
                        Math.min(value + 1, MAX_CARS),
                        {
                          shouldValidate: true,
                        },
                      );
                    }
                  }}
                />
              )}
            />
            <Text>Vogner</Text>
          </HStack>
        </Box>
        <Box>
          <FormLabel marginBottom="0">Ny kapasitet</FormLabel>
          <HStack gap="1rem">
            <Controller
              control={control}
              name={`${trainFormPrefix}.newCapacity`}
              render={({ field }) => (
                <NumericStepper
                  name={`${trainFormPrefix}.newCapacity`}
                  minValue={oldCapacity + 1}
                  maxValue={MAX_CARS}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
            <Text>Vogner</Text>
          </HStack>
        </Box>
      </VStack>
    </VStack>
  );
};
