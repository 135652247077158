import { format } from "date-fns";
import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";

export const formatIncidentArrivalTime = (
  now: Date,
  incidentArrivalTime: Date,
) => {
  const timeToEventInMinutes = Math.floor(
    (incidentArrivalTime.getTime() - now.getTime()) / (1000 * 60),
  );
  if (Math.abs(timeToEventInMinutes) >= 60) {
    return format(incidentArrivalTime, "HH:mm");
  }
  return `${timeToEventInMinutes.toString()} min`;
};

export const makeTimeDisplay = (train: AffectedTrain, currentTime: Date) => {
  const pastEvent =
    (train.incidentExitTime !== null && train.incidentExitTime < currentTime) ||
    (!train.incidentArrivalTime && !train.incidentExitTime);

  const inEvent =
    train.incidentArrivalTime !== null &&
    train.incidentArrivalTime < currentTime &&
    !pastEvent;

  const timeToEvent = train.incidentArrivalTime
    ? formatIncidentArrivalTime(currentTime, train.incidentArrivalTime)
    : null;

  const timeInEvent = train.incidentExitTime
    ? formatIncidentArrivalTime(currentTime, train.incidentExitTime)
    : null;

  if (timeToEvent !== null && !inEvent && !pastEvent) {
    return timeToEvent;
  }
  if (timeToEvent !== null && inEvent) {
    return `Ankom brudd ${timeToEvent}`;
  }
  if (timeInEvent !== null && pastEvent) {
    return `Passerte brudd ${timeInEvent}`;
  }
  return null;
};
