import { useQuery } from "@tanstack/react-query";
import { useToast } from "@vygruppen/spor-react";
import { StationInformation, StationResponse } from "@vygruppen/vy-train-map";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import { useEffect } from "react";
import { StationInformationMessage } from "websocket/drops/dropsWebsocketMessages";

const STATION_INFO_QUERY_KEY = "stationInfo";
export const useStationInformation = () => {
  const toast = useToast();

  const {
    data: stationInfo,
    isSuccess: isStationInfoSuccess,
    status,
  } = useQuery({
    queryKey: [STATION_INFO_QUERY_KEY],
    retry: 1,
    queryFn: ({ signal }) =>
      queryFnGET<StationResponse>({
        signal,
        url: `${getBackendUrl()}/station-information/trainmap-station-events?countryCode=NO`,
      }),
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (status === "error") {
      toast({
        variant: "error",
        text: "Kunne ikke hente stasjonshendelser",
        isClosable: true,
      });
    }
  }, [status]);

  const updateStationInfo = (
    message: StationInformation,
    prev: StationInformation[],
  ): StationInformation[] => {
    if (!message.event.active) {
      return prev.filter((info) => info.uuid !== message.uuid);
    }

    return [message, ...prev.filter((info) => info.uuid !== message.uuid)];
  };

  const handleStationWebsocketMessage = (
    websocketMessage: StationInformationMessage,
  ) => {
    const { message } = websocketMessage;
    queryClient.setQueryData<StationResponse>(
      [STATION_INFO_QUERY_KEY],
      (prev) =>
        prev
          ? {
              station_information: updateStationInfo(
                message,
                prev.station_information,
              ),
            }
          : {
              station_information: message.event.active ? [message] : [],
            },
    );
  };

  return {
    stationInfo,
    isStationInfoSuccess,
    handleStationWebsocketMessage,
  };
};
