import { VehicleSet } from "features/CenterContent/VehicleDetails/TrainDetails/TrainComposition/types";

export const calcTrainCompMetaData = (
  trainComposition: VehicleSet[],
): {
  type: "CARS" | "MULTIPLE_UNIT";
  numPassengerCars: number;
  minCapacity: number;
} => {
  if (trainComposition.length < 1) {
    return { type: "CARS", numPassengerCars: 1, minCapacity: 1 };
  }
  // kun tog med EL-18 lok som kjører med vogner i dag
  const hasCars = trainComposition.some((vehicleSet) =>
    (vehicleSet.setTypeId ?? "").startsWith("18"),
  );
  const numPassengerCars = trainComposition.reduce(
    (partialSum, vehicle) =>
      partialSum +
      ((vehicle.setTypeId ?? "").startsWith("18")
        ? 0
        : vehicle.compositionPositions.length),
    0,
  );
  const minCapacity = trainComposition.reduce(
    (currentMin, vehicleSet) =>
      Math.min(
        currentMin,
        (vehicleSet.setTypeId ?? "").startsWith("18")
          ? Infinity
          : vehicleSet.compositionPositions.length,
      ),
    Infinity,
  );
  return {
    type: hasCars ? "CARS" : "MULTIPLE_UNIT",
    numPassengerCars,
    minCapacity,
  };
};

// Motorvognsett som Vy kjører i dag kan kun bestå av 3, 4 eller 5 vogner.
// Settene kjøres alene eller to sammenkoblet.
// Vi vet ikke sikkert om dataen i trainComposition representerer ny eller gammel sammensetning når hendelsen opprettes,
// og vi må derfor ta hensyn til begge deler når vi setter default-verdier for gammel og ny kapasitet
// For lok og vogn har vi ikke noe bedre enn å bruke trainComposition direkte
const getMultipleUnitCapacity = (numPassengerCars: number) => {
  switch (numPassengerCars) {
    case 6:
    case 3:
      return { min: 3, max: 6 };
    case 8:
    case 4:
      return { min: 4, max: 8 };
    case 10:
    case 5:
    default:
      return { min: 5, max: 10 };
  }
};

export const getDefaultCapacity = (
  numPassengerCars: number,
  type: "CARS" | "MULTIPLE_UNIT",
  increasedOrReduced: "increased" | "reduced",
): { old: number; new: number } => {
  if (type === "CARS") {
    return {
      old: numPassengerCars,
      new:
        increasedOrReduced === "reduced"
          ? numPassengerCars - 1
          : numPassengerCars + 1,
    };
  }
  const multipleUnitCapacity = getMultipleUnitCapacity(numPassengerCars);
  return increasedOrReduced === "reduced"
    ? { old: multipleUnitCapacity.max, new: multipleUnitCapacity.min }
    : { old: multipleUnitCapacity.min, new: multipleUnitCapacity.max };
};

// Linjenummer brukes som fallback for å finne antall vogner per sett når vi ikke får gode nok data fra trainComposition
// Dette er ikke en sikker metode for å finne materielltypen, fordi i noen tilfeller brukes flere materielltyper
// på samme linje. Det er likevel bedre enn ingenting.
// For R24 gjelder følgende:
// Alle region (R) og regionekspress (RE), bortsett fra R55 kjøres med 74 og 75
// R55 kjøres med 69
// L1 kjøres med 72, men også med 69
// L2 kjøres med 69, men også 72
// L4 kjøres med 75
export const getNumPassengerCarsFromLineId = (lineId: string) => {
  if (lineId === "R55") return 3;
  if (lineId.startsWith("R")) return 5;
  switch (lineId) {
    case "L1":
      return 4;
    case "L2":
      return 3;
    case "L4":
    default:
      return 5;
  }
};
