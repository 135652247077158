import { compareAsc } from "date-fns";
import {
  GetReserveAlternativeTransport,
  ReserveAlternativeTransport,
  VehicleType,
} from "shared/types/alternativeTransport";

const mapVehicleTypeToPrintable = new Map<VehicleType, string>([
  ["BUS", "buss"],
  ["MINIBUS", "minibuss"],
  ["TAXI", "taxi"],
  ["MAXITAXI", "maxitaxi"],
  ["UNKNOWN", "ukjent"],
]);

export const getPrintableFromVehicleType = (type: VehicleType): string =>
  mapVehicleTypeToPrintable.get(type)!;

/**
 * Sorts ReserveAlternativeTransport based on baseStopName and shift times.
 * @param a first ReserveAlternativeTransport to compare
 * @param b second ReserveAlternativeTransport to compare
 * @returns number -1, 0 or 1 to used in sorting
 */
export const sortReserveTransport = (
  a: ReserveAlternativeTransport | GetReserveAlternativeTransport,
  b: ReserveAlternativeTransport | GetReserveAlternativeTransport,
): number => {
  const nameComparison = a.baseStopName.localeCompare(b.baseStopName);

  if (nameComparison === 0) {
    const aShiftStartTime = a.shiftStartTime && new Date(a.shiftStartTime);
    const bShiftStartTime = b.shiftStartTime && new Date(b.shiftStartTime);

    const shiftStartTimeComparison =
      aShiftStartTime &&
      bShiftStartTime &&
      compareAsc(aShiftStartTime, bShiftStartTime);

    // if shift start times are equal or null
    if (shiftStartTimeComparison === 0 || shiftStartTimeComparison === null) {
      const aShiftEndTime = a.shiftEndTime && new Date(a.shiftEndTime);
      const bShiftEndTime = b.shiftEndTime && new Date(b.shiftEndTime);

      const shiftEndTimeComparison =
        aShiftEndTime &&
        bShiftEndTime &&
        compareAsc(aShiftEndTime, bShiftEndTime);

      return shiftEndTimeComparison ? shiftEndTimeComparison : 0;
    }

    return shiftStartTimeComparison ? shiftStartTimeComparison : 0;
  }

  return nameComparison;
};
