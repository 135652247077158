import { AlternativeTransport } from "shared/types/alternativeTransport";
import { matchPhoneNumber } from "../utils";

type Filter = (
  alternatviveTransport: AlternativeTransport,
  searchQuery: string,
) => boolean;

const matchesWorkShiftId = (workShiftId: string | null, query: string) =>
  workShiftId?.includes(query) ?? false;

const matchesDriverPhoneNumber = (
  driverPhoneNumber: string | null,
  query: string,
) => (driverPhoneNumber ? matchPhoneNumber(query, driverPhoneNumber) : false);

const matchesTrainIds = (trainIds: string[], query: string) =>
  trainIds.filter((trainId) => trainId.toLowerCase().startsWith(query)).length >
  0;

const matchesOriginStopName = (
  originStopName: string | undefined,
  query: string,
) => originStopName?.toLowerCase().startsWith(query) ?? false;

const matchesDestinationStopName = (
  destinationStopName: string | null,
  query: string,
) => destinationStopName?.toLowerCase().startsWith(query) ?? false;

export const filterAlternativeTransport: Filter = (
  alternativeTransport,
  searchQuery,
) => {
  const {
    workShiftId,
    driverPhoneNumber,
    trainIds,
    originStopName,
    destinationStopName,
  } = alternativeTransport;
  const lowerCaseQuery = searchQuery.toLowerCase();

  if (lowerCaseQuery.length >= 2) {
    return (
      matchesWorkShiftId(workShiftId, lowerCaseQuery) ||
      matchesDriverPhoneNumber(driverPhoneNumber, lowerCaseQuery) ||
      matchesTrainIds(trainIds, lowerCaseQuery) ||
      matchesOriginStopName(originStopName, lowerCaseQuery) ||
      matchesDestinationStopName(destinationStopName, lowerCaseQuery)
    );
  }

  return true;
};
