import React, { useEffect, useRef } from "react";
import {
  NameColumn,
  PhoneNumberColumn,
  SearchResultList,
  SearchResultRow,
} from "./CatalogSearchResultsStyle";
import { useInfiniteScroll } from "../../../../../shared/hooks/useInfiniteScroll";

export type ContactInfo = {
  readonly phoneNumber: string | undefined;
  readonly name: string;
};

type Props = {
  searchResults: ContactInfo[];
  selectedIndex: number;
  onPhoneNumberClick: (phoneNumber: string) => void;
  isCalling: boolean;
};

export const CatalogSearchResults = ({
  isCalling,
  onPhoneNumberClick,
  searchResults,
  selectedIndex,
}: Props) => {
  const searchResultsContainerRef = useRef<HTMLUListElement>(null);
  const { numberOfItemsToRender } = useInfiniteScroll({
    containerRef: searchResultsContainerRef,
    initialItemsToRender: 25,
    maxItemsToRender: searchResults.length,
  });

  useEffect(() => {
    const selectedElement = document.querySelector(
      `#catalog-search-result-${selectedIndex}`,
    );
    selectedElement?.scrollIntoView({ block: "nearest" });
  }, [selectedIndex]);

  return (
    <SearchResultList ref={searchResultsContainerRef}>
      {searchResults
        .slice(0, numberOfItemsToRender)
        .map((contact: ContactInfo, index) => (
          <SearchResultRow
            key={`catalog-search-result-${index}`}
            id={`catalog-search-result-${index}`}
            $shouldHighlight={index === selectedIndex}
            $disabled={!contact.phoneNumber || isCalling}
            onClick={(event) => {
              event.stopPropagation();
              if (contact.phoneNumber) {
                onPhoneNumberClick(contact.phoneNumber);
              }
            }}
          >
            <NameColumn>{contact.name}</NameColumn>
            <PhoneNumberColumn>{contact.phoneNumber}</PhoneNumberColumn>
          </SearchResultRow>
        ))}
    </SearchResultList>
  );
};
