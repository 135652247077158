export const copyToClipboard = async (text: string) => {
  try {
    if (!navigator.clipboard) {
      throw new Error("Browser don't have support for native clipboard.");
    }
    const copyValue = text;

    await navigator.clipboard.writeText(copyValue);
  } catch (error: any | unknown) {
    console.log(error.toString());
  }
};
