import {
  CalendarDateTime,
  toCalendarDate,
  toTime,
} from "@internationalized/date";
import {
  Box,
  Button,
  DateRangePicker,
  FormControl,
  HStack,
  InfoSelect,
  Item,
  Stack,
  Text,
  TimePicker,
} from "@vygruppen/spor-react";
import { Rule } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { DayChoice } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/DayChoice";
import { WeekRepeat } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/PeriodicDuration";
import { getInitialTimedDuration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/initialDurationValues";
import { getDatesFromWeekdays } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/periodicDurationUtils";
import { FC, useState } from "react";
import { useTheme } from "styled-components";

type AddTimeIntervalRuleProps = {
  savedRule?: Rule;
  onSaveClick: (rule: Rule) => void;
  onClose: () => void;
};

export const AddTimeIntervalRule: FC<AddTimeIntervalRuleProps> = ({
  savedRule,
  onSaveClick,
  onClose,
}) => {
  const theme = useTheme();

  const [startDateTime, setStartDateTime] = useState<CalendarDateTime>(
    savedRule?.startDateTime || getInitialTimedDuration().from_time,
  );
  const [endDateTime, setEndDateTime] = useState<CalendarDateTime>(
    savedRule?.endDateTime || getInitialTimedDuration().to_time,
  );
  const [days, setDays] = useState<number[]>(savedRule?.days || []);
  const [repeat, setRepeat] = useState<WeekRepeat>(
    savedRule?.repeat || WeekRepeat.WEEKLY,
  );

  const isInvalidRule =
    getDatesFromWeekdays(
      toCalendarDate(startDateTime),
      toCalendarDate(endDateTime),
      days,
      repeat,
    ).length === 0;

  return (
    <Box
      width="min-content"
      padding={4}
      boxShadow={`inset 0 0 0 1px ${theme.colorSeparationLine}`}
      borderRadius="sm"
    >
      <FormControl>
        <Text variant="xs">Hendelsen er aktiv mellom</Text>
        <DateRangePicker
          variant="base"
          startLabel="Startdato"
          endLabel="Sluttdato"
          aria-label="Dato"
          value={{
            start: toCalendarDate(startDateTime),
            end: toCalendarDate(endDateTime),
          }}
          onChange={(date) => {
            if (date) {
              setStartDateTime((prev) =>
                prev.set({
                  day: date.start?.day,
                  month: date.start?.month,
                  year: date.start?.year,
                }),
              );
              setEndDateTime((prev) =>
                prev.set({
                  day: date.end?.day,
                  month: date.end?.month,
                  year: date.end?.year,
                }),
              );
            }
          }}
        />
      </FormControl>
      <Text variant="xs" paddingTop={1}>
        I tidsrommet
      </Text>
      <HStack spacing={1}>
        <TimePicker
          label="Fra"
          minuteInterval={5}
          value={toTime(startDateTime)}
          onChange={(time) => {
            setStartDateTime((prev) =>
              prev.set({
                hour: time?.hour,
                minute: time?.minute,
              }),
            );
          }}
        />
        <TimePicker
          label="Til"
          minuteInterval={5}
          value={toTime(endDateTime)}
          onChange={(time) => {
            setEndDateTime((prev) =>
              prev.set({
                hour: time?.hour,
                minute: time?.minute,
              }),
            );
          }}
        />
      </HStack>
      <DayChoice selectedDays={days} setSelectedDays={setDays} />
      <InfoSelect
        items={[
          { label: "Hver uke", value: WeekRepeat.WEEKLY },
          { label: "Hver 2. uke", value: WeekRepeat.BIWEEKLY },
        ]}
        label="Gjentas"
        value={repeat}
        onChange={(value) => {
          if (value === WeekRepeat.WEEKLY || value === WeekRepeat.BIWEEKLY)
            setRepeat(value);
        }}
      >
        {(item) => <Item key={item.value}>{item.label}</Item>}
      </InfoSelect>
      <Stack flexDirection="row" justifyContent="end" paddingTop={3}>
        <Button variant="tertiary" size="sm" onClick={onClose}>
          Avbryt
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={() =>
            onSaveClick({
              id: savedRule?.id || Math.random().toString(),
              startDateTime,
              endDateTime,
              days,
              repeat,
            })
          }
          isDisabled={isInvalidRule}
        >
          Lagre regel
        </Button>
      </Stack>
    </Box>
  );
};
