import { Information } from "features/History/HistoryDetail/fetchHistoryDetail";
import { FC } from "react";
import { Flex, Text } from "@vygruppen/spor-react";
import { theme } from "shared/theme/theme";
import { format } from "date-fns";

type HeaderProps = {
  information: Information;
};

export const DetailsHeader: FC<HeaderProps> = ({ information }) => (
  <Flex gap="8px" alignItems="center">
    <Text fontSize="lg" fontWeight="bold">
      {information.type.label ?? "-"}
    </Text>
    <Text fontSize="md" color={theme.colorTextSecondary}>
      / {format(information.createdAt, "dd.MM.yyyy")}
    </Text>
    {information.stretchName && (
      <Text fontSize="md" color={theme.colorTextSecondary}>
        / {information.stretchName}
      </Text>
    )}
  </Flex>
);
