import { Button } from "@vygruppen/spor-react";
import { TrainOutline24Icon } from "@vygruppen/spor-icon-react";
import { fetchTrainStaffCaller, TrainStaffCaller } from "api/drops/drops";
import { useApiAction } from "api/drops/useApiAction";
import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Text } from "shared/components/typography/TitleStyles";
import { usePuzzelAuthenticationContext } from "features/Header/PuzzelTelephone/provider/contexts/PuzzelAuthenticationContext";
import {
  NO_COUNTRY_CODE_PREFIX,
  removePhoneNumberPrefixes,
} from "features/Header/PuzzelTelephone/utils";
import { Status } from "features/Header/PuzzelTelephone/components/types";
import { TRAIN } from "shared/utils/routes";

type Props = {
  status: Status;
  remoteAddress: string;
};
const CallerNumberInfo = (props: Props) => {
  const { status, remoteAddress } = props;
  const [callerDataState, performGetCallerData] = useApiAction(
    fetchTrainStaffCaller,
    false,
  );
  const navigate = useNavigate();

  const { phoneNumberPrefix, functionalNumberPrefix } =
    usePuzzelAuthenticationContext();

  const phoneNumber = removePhoneNumberPrefixes(remoteAddress, [
    phoneNumberPrefix,
    NO_COUNTRY_CODE_PREFIX,
    functionalNumberPrefix,
  ]);

  useEffect(() => {
    if (status) {
      performGetCallerData(phoneNumber);
    }
  }, [status]);

  const trainStaffCaller: TrainStaffCaller = callerDataState.data?.callerData;

  return trainStaffCaller ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Text>{`GSMR ${trainStaffCaller.gsmrNumber}`}</Text>
        <Text>{`Tog nr. ${trainStaffCaller.trainNumber}`}</Text>
        <Text>{`${trainStaffCaller.functionRole}`}</Text>
      </div>
      <Button
        variant="tertiary"
        size="sm"
        leftIcon={<TrainOutline24Icon />}
        ml={1}
        onClick={() => {
          navigate(
            generatePath(TRAIN, {
              countryCode: "NO",
              trainNumber: trainStaffCaller.trainNumber,
              trainDate: trainStaffCaller.trainDate,
            }),
          );
        }}
      >
        Åpne togfane
      </Button>
    </div>
  ) : (
    <Text>{phoneNumber}</Text>
  );
};

export default CallerNumberInfo;
