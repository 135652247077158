import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { AlternativeTransportResponse } from "shared/types/alternativeTransport";
import { format } from "date-fns";

export const useAlternativeTransportData = (day: string) => {
  const { status, data } = useQuery({
    queryKey: ["altTransport", day],
    refetchInterval: 30000,
    queryFn: async ({ signal }) =>
      queryFnGET<AlternativeTransportResponse>({
        signal,
        url: `${getBackendUrl()}/alternativeTransports/cached?countryCode=NO&date=${format(day, "yyyy-MM-dd")}`,
      }),
  });
  return { status, data };
};
