import { CopyOutline18Icon } from "@vygruppen/spor-icon-react";
import { Flex, Text, useToast } from "@vygruppen/spor-react";
import LeafletDottedLine from "features/CenterContent/shared/Map/LeafletDottedLine";
import LeafletMarker from "features/CenterContent/shared/Map/LeafletMarker";
import LeafletTooltip from "features/CenterContent/shared/Map/LeafletTooltip";
import { BaseGpsData } from "features/CenterContent/shared/types";
import { useState } from "react";
import { interpolateColor } from "shared/utils/colorUtils";
import { formatDayDateString } from "shared/utils/datetime";
import { getDirection, getOffset } from "./utils";

export const VehicleMapMarkers = ({ gpsData }: { gpsData: BaseGpsData[] }) => {
  const [activeId, setActiveId] = useState<string | null>(null);

  const toast = useToast();
  const handleCopyId = async (id: string) => {
    await navigator.clipboard
      .writeText(id)
      .then(() => {
        toast({
          text: `Kopierte ${id} til utklippstavlen.`,
          variant: "success",
          duration: 2000,
        });
      })
      .catch(() => {
        toast({
          text: "Kunne ikke kopiere til utklippstavlen. Vennligst prøv igjen.",
          variant: "error",
          duration: 3000,
        });
      });
  };

  const markers = gpsData.map((marker, index) => {
    const timestamp = formatDayDateString(marker.timestamp);
    const isActive = activeId === marker.id;
    return (
      <LeafletMarker
        key={`${marker.id}_${index}`}
        position={[marker.gps.latitude, marker.gps.longitude]}
        eventHandlers={{
          click: () => {
            setActiveId((prev) => (prev === marker.id ? null : marker.id));
          },
        }}
      >
        <LeafletTooltip
          permanent
          interactive
          direction={getDirection(marker, marker.closestNeighbor)}
          offset={getOffset(marker, marker.closestNeighbor)}
          eventHandlers={{
            mouseover: () => {
              setActiveId(marker.id);
            },
            mouseout: () => {
              setActiveId(null);
            },
            click: () => {
              setActiveId((prev) => (prev === marker.id ? null : marker.id));
            },
            contextmenu: () => {
              handleCopyId(marker.id);
            },
          }}
        >
          <Flex justify="center" alignItems="center" direction="row" gap={1}>
            <Text variant="xs" fontWeight="bold">
              {marker.id}
            </Text>

            {isActive && (
              <CopyOutline18Icon
                onClick={() => {
                  handleCopyId(marker.id);
                }}
                aria-label="Kopier ID"
              />
            )}
          </Flex>

          {isActive && (
            <>
              {marker.phoneNumber && (
                <Text variant="xs" textAlign="center">
                  {marker.phoneNumber}
                </Text>
              )}
              <Text variant="xs" textAlign="center">
                {`${timestamp}` || `Ukjent tidspunkt`}
              </Text>
            </>
          )}
        </LeafletTooltip>
      </LeafletMarker>
    );
  });

  // we only look at the first gpsData since path
  // is only used for mini-gps view (single bus view)
  const path = gpsData[0]?.path ?? [];

  const dottedLines = path.map((location, index) => {
    const timestamp = formatDayDateString(location.timestamp);
    return (
      <LeafletDottedLine
        key={index}
        pathOptions={{
          color: interpolateColor(
            "#00453E", // Spor - DarkTeal
            "#00957A", // Spor - Green Haze
            index / path.length,
          ),
        }}
        center={[location.gps.latitude, location.gps.longitude]}
        radius={3}
      >
        <LeafletTooltip direction="top" offset={[0, -10]}>
          <Text variant="xs" textAlign="center">
            {timestamp || `Ukjent tidspunkt`}
          </Text>
        </LeafletTooltip>
      </LeafletDottedLine>
    );
  });

  return [...markers, ...dottedLines];
};
