import {
  LoginContainer,
  TrainLoaderDark,
  TrainLoaderLight,
} from "app/Auth/Login/LoginStyle";
import React from "react";
import { useColorMode } from "@vygruppen/spor-react";

export const TrainLoader = () => {
  const { colorMode } = useColorMode();
  return (
    <LoginContainer>
      {colorMode === "dark" ? <TrainLoaderDark /> : <TrainLoaderLight />}
    </LoginContainer>
  );
};
