import {
  CalendarDateTime,
  fromDate,
  getLocalTimeZone,
} from "@internationalized/date";
import { CountryCode } from "shared/types/countryCode";
import { z } from "zod";

const openEndedVisibilitySchema = z.object({
  type: z.literal("OPEN_ENDED"),
  fromTime: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
});

const timedVisibilitySchema = z.object({
  type: z.literal("TIMED"),
  fromTime: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
  toTime: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
});

const constantVisibilitySchema = z.object({
  type: z.literal("CONSTANT"),
  visible: z.boolean(),
});

export const visibilitySchema = z.discriminatedUnion("type", [
  openEndedVisibilitySchema,
  timedVisibilitySchema,
  constantVisibilitySchema,
]);

export type Visibility = z.infer<typeof visibilitySchema>;

const textSchema = z.object({
  title: z.string(),
  description: z.string(),
});

const textMapSchema = z.object({
  NOB: textSchema.superRefine((val, ctx) => {
    const titleLength = val.title.length;
    const descriptionLength = val.description.length;
    if (titleLength === 0) {
      ctx.addIssue({
        type: "string",
        code: "too_small",
        path: ["title"],
        message: "Du må skrive en tittel for å kunne opprette hendelsen",
        minimum: 1,
        inclusive: true,
      });
    }
    if (descriptionLength === 0) {
      ctx.addIssue({
        type: "string",
        code: "too_small",
        path: ["description"],
        message: "Du må skrive en melding for å kunne opprette hendelsen",
        minimum: 1,
        inclusive: true,
      });
    }
    return titleLength > 0 && descriptionLength > 0;
  }),
  ENG: textSchema.optional().superRefine((val, ctx) => {
    const titleLength = val?.title.length ?? 0;
    const descriptionLength = val?.description.length ?? 0;
    if (
      (titleLength > 0 && descriptionLength > 0) ||
      (titleLength === 0 && descriptionLength === 0)
    ) {
      return true;
    }
    if (titleLength > 0) {
      ctx.addIssue({
        type: "string",
        code: "too_small",
        path: ["description"],
        message: "Du må skrive en melding for å kunne opprette hendelsen",
        minimum: 1,
        inclusive: true,
      });
    }
    if (descriptionLength > 0) {
      ctx.addIssue({
        type: "string",
        code: "too_small",
        path: ["title"],
        message: "Du må skrive en tittel for å kunne opprette hendelsen",
        minimum: 1,
        inclusive: true,
      });
    }
    return false;
  }),
});

export type TextMap = z.infer<typeof textMapSchema>;

const customTextSchema = z.object({
  contexts: z.array(z.string()),
  texts: textMapSchema,
});

export type CustomTexts = z.infer<typeof customTextSchema>;

const globalEventSchema = z.object({
  type: z.string(),
  uuid: z.string(),
  updatedAt: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
  visibility: visibilitySchema,
  referencedIncidentIds: z.array(z.string()),
  customTexts: customTextSchema.array(),
});

export type GlobalEvent = z.infer<typeof globalEventSchema>;

export const globalEventsResponseSchema = z.array(globalEventSchema);

const openEndedVisibilityFormSchema = z.object({
  type: z.literal("OPEN_ENDED"),
  fromTime: z.custom<CalendarDateTime>(),
});

const timedVisibilityFormSchema = z.object({
  type: z.literal("TIMED"),
  fromTime: z.custom<CalendarDateTime>(),
  toTime: z.custom<CalendarDateTime>(),
});

const visibilityFormSchema = z
  .discriminatedUnion("type", [
    openEndedVisibilityFormSchema,
    timedVisibilityFormSchema,
  ])
  .refine(
    (data) => {
      if (data.type === "TIMED") {
        return data.fromTime <= data.toTime;
      }
      return true;
    },
    {
      message: "Synlighet må settes fram i tid",
    },
  );

export type VisibilityFormSchema = z.infer<typeof visibilityFormSchema>;

export const globalEventFormSchema = z.object({
  customTexts: customTextSchema,
  visibility: visibilityFormSchema,
  incidentIds: z.array(z.string()),
});

export const globalFormSchema = z.object({
  globalForm: globalEventFormSchema,
});

export type GlobalFormSchema = z.infer<typeof globalFormSchema>;

const openEndedVisibilityRequest = z.object({
  type: z.literal("OPEN_ENDED"),
  fromTime: z.string(),
});

const timedVisibilityRequest = z.object({
  type: z.literal("TIMED"),
  fromTime: z.string(),
  toTime: z.string(),
});

const visibilityRequest = z.discriminatedUnion("type", [
  openEndedVisibilityRequest,
  timedVisibilityRequest,
]);

export type VisibilityRequest = z.infer<typeof visibilityRequest>;

export type GlobalEventRequest = {
  globalInformationType: "GLOBAL_GENERAL_WARNING";
  visibility: VisibilityRequest;
  incidentIds: string[];
  countryCode: CountryCode;
  customTexts: CustomTexts[];
};
