import styled from "styled-components";
import { usePuzzelExtendedStateInformation } from "../../../../provider/contexts/ListenForCallStatusContext";
import { useIsTransfered } from "./CallTransfer/TransferProvider";
import { IqSessionRequestType, Status } from "../../../types";
import TransferredSuccessMsg from "./CallTransfer/TransferredSuccessMsg";
import StatusConnected from "./StatusConnected";
import StatusInService from "./StatusInService";
import StatusSetup from "./StatusInSetup";

const CallInSessionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:nth-child(1) {
    margin-left: 1rem;
  }
`;

const CallInSession = () => {
  const isTransfered = useIsTransfered();

  const { iqSessionStateRequests }: any = usePuzzelExtendedStateInformation();

  if (isTransfered) return <TransferredSuccessMsg />;

  if (iqSessionStateRequests?.length > 0) {
    return (
      <CallInSessionWrapper>
        {iqSessionStateRequests.map(
          (req: IqSessionRequestType, index: number) => (
            <>
              {req.status === Status.Connected && (
                <StatusConnected key={`call-in-session-${index}`} req={req} />
              )}
              {req.status === Status.InService && (
                <StatusInService key={`call-in-session-${index}`} req={req} />
              )}
              {req.status === Status.Setup && (
                <StatusSetup key={`call-in-session-${index}`} req={req} />
              )}
            </>
          ),
        )}
      </CallInSessionWrapper>
    );
  }
  return null;
};

export default CallInSession;
