import { FC, ReactElement } from "react";
import { Text } from "shared/components/typography/TitleStyles";
import styled from "styled-components";

type VehicleSummaryProps = {
  vehicleIcon: ReactElement;
  vehicleInfo?: string | null;
  title: string;
  subTitle?: string | null;
  route: string;
  interval: string;
  date?: string | null;
};

const VehicleSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;

  svg {
    margin-right: 0.5rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

export const VehicleSummary: FC<VehicleSummaryProps> = ({
  vehicleIcon,
  vehicleInfo,
  title,
  subTitle,
  route,
  interval,
  date,
}) => (
  <VehicleSummaryWrapper>
    <Header>
      {vehicleIcon}
      {vehicleInfo && <Text secondary>{vehicleInfo}</Text>}
    </Header>
    <Row>
      <Text
        bold
        style={{
          display: "block",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxWidth: "10rem",
        }}
      >
        {title}
      </Text>

      {subTitle && (
        <Text title="Rutedetaljer" secondary>
          {subTitle}
        </Text>
      )}
    </Row>
    <Text title="Planlagt rute">{route}</Text>

    <Row>
      <Text title="Tidsintervall">{interval}</Text>
      {date && (
        <Text title="Dato for avgang" secondary>
          {date}
        </Text>
      )}
    </Row>
  </VehicleSummaryWrapper>
);
