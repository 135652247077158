export enum TrainEventTypeEnum {
  TRAIN_STOPPED = "TRAIN_STOPPED",
  TRAIN_CANCELLED = "TRAIN_CANCELLED",
  TRAIN_CAPACITY_REDUCED = "TRAIN_CAPACITY_REDUCED",
  TRAIN_MISSING_PRODUCT = "TRAIN_MISSING_PRODUCT",
  TRAIN_DELAY_EXPECTED = "TRAIN_DELAY_EXPECTED",
  TRAIN_CAPACITY_INCREASED = "TRAIN_CAPACITY_INCREASED",
  TRAIN_DELAYED = "TRAIN_DELAYED",
  TRAIN_LATE_TO_TRACK = "TRAIN_LATE_TO_TRACK",
  TRAIN_NOT_STOPPING_AT_STATION = "TRAIN_NOT_STOPPING_AT_STATION",
  TRAIN_STOPPING_EXTRA_AT_STATION = "TRAIN_STOPPING_EXTRA_AT_STATION",
  TRAIN_CLOSED_SET = "TRAIN_CLOSED_SET",
  TRAIN_CHANGED_ROUTE = "TRAIN_CHANGED_ROUTE",
  TRAIN_CUSTOM = "TRAIN_GENERAL",
}
