import { InfrastructureEventAction } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/InfrastructureEventAction";
import { InfrastructureEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureEvent";
import { StationEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/StationEvent";
import { StationEventAction } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/StationEventAction";
import { FC } from "react";
import { useStretchBuilder } from "stores/useStretchBuilder";

export const StretchBuilderModalState: FC = () => {
  const stretchBuilderModalState = useStretchBuilder(
    (state) => state.stretchBuilderModalState,
  );

  const getContent = () => {
    switch (stretchBuilderModalState.type) {
      case "infrastructure":
        return <InfrastructureEvent />;
      case "station":
        return <StationEvent />;
      case "closeOrEdit": {
        if (stretchBuilderModalState.uuids.station.length > 0) {
          return (
            <StationEventAction
              uuids={stretchBuilderModalState.uuids.station}
            />
          );
        }
        return stretchBuilderModalState.uuids.infrastructure?.length > 0 ? (
          <InfrastructureEventAction
            uuids={stretchBuilderModalState.uuids.infrastructure}
          />
        ) : null;
      }
      case "none":
      default:
        return null;
    }
  };

  return getContent();
};
