import styled, { keyframes } from "styled-components";

type SkeletonProps = {
  $borderBottom?: boolean;
};

const skeletonAnimation = keyframes`
      from {
          left: -300px;
      }
      to {
          left: 100%;
      }
  `;

export const SkeletonWrapper = styled.div<SkeletonProps>`
  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  display: block;
  min-width: 100%;
  border-bottom: ${(props) =>
    props.$borderBottom
      ? ({ theme }) => `1px solid ${theme.skeletonElementColor}`
      : "none"};

  ::before {
    content: "";
    height: 100%;
    width: 300px;
    position: absolute;
    left: -300px;
    background: ${({ theme }) => `linear-gradient(
      90deg,
      ${theme.colorBackgroundSecondary}00,
      ${theme.colorBackgroundSecondary}e6,
      ${theme.colorBackgroundSecondary}00
    )`};
    animation: ${skeletonAnimation} 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
`;

export const SkeletonElementWrapper = styled.div`
  width: 372px;
  margin: 15px auto;
`;

export const SkeletonElement = styled.div`
  border-radius: 6px;
  height: 18px;
  width: 100%;
  margin: 15px 0;
  background-color: ${({ theme }) => theme.skeletonElementColor};
`;

export const SkeletonButton = styled.div`
  background-color: ${({ theme }) => theme.skeletonElementColor};
  height: 30px;
  border-radius: 6px;
  width: 40px;
  float: right;
  margin: 15px 5px;
`;

export const SkeletonRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 15px;
`;
