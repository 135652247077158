import React, { ReactElement, ReactNode } from "react";
import { ListenForCallStatus } from "../contexts/ListenForCallStatusContext";
import { CallStatusProvider } from "../contexts/CallStatusContext";

type Props = {
  children: ReactNode;
};

export const CallProvider = ({ children }: Props): ReactElement | null => (
  <ListenForCallStatus>
    <CallStatusProvider>{children}</CallStatusProvider>
  </ListenForCallStatus>
);
