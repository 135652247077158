import { Box } from "@vygruppen/spor-react";
import { ReactNode } from "react";

export const ShowIfTrue = ({
  show,
  children,
}: {
  show: boolean;
  children: ReactNode;
}) => {
  if (!show) {
    return null;
  }

  return (
    <Box
      css={`
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 100;
          }
        }

        animation: fadeIn 250ms cubic-bezier(0.4, 0, 0.2, 1);
      `}
    >
      {children}
    </Box>
  );
};
