import { useQuery } from "@tanstack/react-query";
import { log, LogLevel } from "api/cloudWatch";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";

type Attribute = {
  [key: string]: number | string | number[] | string[] | boolean;
};

type FeatureFlag = {
  name: string;
  enabled: boolean;
  attributes: Attribute[];
};

export function useFeatureFlag(name: string): FeatureFlag {
  const { data, error } = useQuery({
    queryKey: ["featureFlags"],
    queryFn: ({ signal }) =>
      queryFnGET<FeatureFlag[]>({ signal, url: `${getBackendUrl()}/features` }),
    staleTime: Infinity,
    gcTime: Infinity,
  });

  if (error) {
    log(
      LogLevel.error,
      "useFeatureFlag",
      `GET /features call failed with error: ${error}`,
    );
  }

  return (
    (data && data.length ? data.find((flag) => flag.name === name) : null) ?? {
      name,
      enabled: false,
      attributes: [],
    }
  );
}

/*
// Use at your own risk, breaks all tests
export const getFeatureFlag = async (name: string) => {
  const data = await queryFnGET<FeatureFlag[]>({
    signal: undefined,
    url: `${getBackendUrl()}/features`,
  });

  return (
    (data && data.length ? data.find((flag) => flag.name === name) : null) ?? {
      name,
      enabled: false,
      attributes: [],
    }
  );
};
*/
