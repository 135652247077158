import { Button, Tooltip } from "@chakra-ui/react";

import split from "shared/icons/split.svg?react";
import styled from "styled-components";
import { Tab } from "./TabsWindows";

const Split = styled(split)`
  // This changes color for dark mode
  & path {
    fill: ${({ theme }) => theme.colorTextMain};
  }
`;

export const SplitButton = ({
  tabs,
  setSelectedTabIds,
}: {
  tabs: Tab[];
  setSelectedTabIds: (tabIds: string[]) => void;
}) => (
  <Tooltip label="Del opp visning" hasArrow>
    <Button
      paddingX={1.5}
      size="xs"
      variant="tertiary"
      onClick={() => {
        setSelectedTabIds(tabs.map((it) => it.id));
      }}
    >
      <Split />
    </Button>
  </Tooltip>
);
