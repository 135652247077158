import React from "react";
import { logout } from "app/Auth/auth";
import { Button, Heading } from "@vygruppen/spor-react";
import { LoginContainer } from "app/Auth/Login/LoginStyle";

const UnauthorizedUser = () => (
  <LoginContainer style={{ flexDirection: "column", textAlign: "center" }}>
    <Heading as="h1">Du har ikke tilgang til Drops Dashboard!</Heading>
    <Heading as="h2" variant="md">
      Ta kontakt med din nærmeste leder dersom du har behov for tilgang.
    </Heading>
    <Button onClick={logout} mt={5} size="lg" width="180px">
      Logg ut
    </Button>
  </LoginContainer>
);

export default UnauthorizedUser;
