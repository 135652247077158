import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
} from "@vygruppen/spor-react";
import { SingleTrainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import {
  EventType,
  GenericType,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { isCustomInputType } from "features/CenterContent/shared/operationalInformation/utils";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import { Select } from "shared/components/forms/Select";
import { customInputInfo } from "shared/types/form";
import { useTheme } from "styled-components";

type CustomInputProps = {
  items: GenericType[] | EventType[];
  formFieldType: "event" | "reason" | "action";
  isDisabled?: boolean;
  optionDisabledPredicate?: (event: EventType | GenericType) => boolean;
};

export const TrainFormFieldWithCustomInput: FC<CustomInputProps> = ({
  items,
  formFieldType,
  isDisabled = false,
  optionDisabledPredicate = () => false,
}) => {
  const theme = useTheme();

  const { formField, label, customFormField } = customInputInfo[formFieldType];

  const {
    control,
    register,
    getValues,
    formState: { errors },
  } = useFormContext<SingleTrainFormSchema>();
  const type = getValues(formField);
  const isCustomInput = isCustomInputType(type);

  const select = (
    <Select
      label={label}
      placeholder={`Velg en ${label.toLowerCase().replace("*", "")}`}
      {...register(formField)}
      disabled={isDisabled}
    >
      {items.map((item) => (
        <option
          key={item.type}
          value={item.type}
          disabled={optionDisabledPredicate(item)}
        >
          {item.label}
        </option>
      ))}
    </Select>
  );

  if (!isCustomInput) {
    return select;
  }

  return (
    <Stack
      gap={2}
      padding={4}
      boxShadow={`inset 0 0 0 1px ${theme.colorSeparationLine}`}
      borderRadius="sm"
    >
      {select}
      <Stack gap={2}>
        {formFieldType === "event" && (
          <>
            <FormControl as="fieldset">
              <FormLabel as="legend">Alvorlighetsgrad</FormLabel>
              <Controller
                control={control}
                name="trainForm.severity"
                render={({ field }) => (
                  <RadioGroup
                    name="severity"
                    value={field.value ?? ""}
                    onChange={field.onChange}
                  >
                    <Radio value="INFO" name="INFO">
                      Informasjon
                    </Radio>
                    <Radio value="WARNING" name="WARNING">
                      Advarsel
                    </Radio>
                  </RadioGroup>
                )}
              />
            </FormControl>
            <RenderErrorInPath errors={errors} errorPath="trainForm.severity" />
          </>
        )}
        <FormControl>
          <Textarea
            label="Norsk tekst"
            {...register(`${customFormField}.NOB`, {
              value: "",
            })}
          />
        </FormControl>
        <RenderErrorInPath
          errors={errors}
          errorPath={`${customFormField}.NOB`}
        />
        <FormControl>
          <Textarea
            label="Engelsk tekst"
            {...register(`${customFormField}.ENG`, {
              value: "",
            })}
          />
        </FormControl>
        <RenderErrorInPath
          errors={errors}
          errorPath={`${customFormField}.ENG`}
        />
      </Stack>
    </Stack>
  );
};
