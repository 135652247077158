import { log, LogLevel } from "api/cloudWatch";
import { useRef, useState } from "react";
import { Topics, WebsocketMessage, WebsocketTopics } from "./websocketTypes";
import { useWebsocketInternalClient } from "./useWebsocketInternalClient";

type WebsocketClientReturnType<T> = {
  sendMessage: (messagePayload: string) => void;
  message: WebsocketMessage<T>;
  register: (name: string, topics: WebsocketTopics[]) => void;
  deregister: (name: string, topics: WebsocketTopics[]) => void;
};

export type WebsocketClientProps = {
  url: string;
  pingInterval: number;
  pongTimeout: number;
};

const logIdentifier = (url: string): string =>
  `useWebsocketClient (...${url.slice(url.lastIndexOf("/"))})`;

export const useWebsocketClient = <T>({
  url,
  pingInterval,
  pongTimeout,
}: WebsocketClientProps): WebsocketClientReturnType<T> => {
  /**
   * Topics can be registered at any time, and we can not be sure that a websocket connection is open when the topic
   * is registered. Therefore, we need to do two things:
   * 1) try to register the topic immediately if the websocket
   *    connection is open.
   * 2) send the active topics each time a new websocket connection is opened. This happens inside
   *    `useWebsocketInternalClient`, in the `onopen` event handler.
   */
  const topicMap = useRef(new Map<string, WebsocketTopics[]>());

  const [message, setMessage] = useState<WebsocketMessage<T>>({
    status: "notReceived",
  });

  const { client } = useWebsocketInternalClient({
    url,
    onMessage: setMessage,
    topicMap,
    pingInterval,
    pongTimeout,
  });

  function waitForOpenSocket(socket: WebSocket) {
    return new Promise((resolve) => {
      if (socket.readyState !== socket.OPEN) {
        socket.addEventListener("open", () => resolve("open"));
      } else {
        resolve("open");
      }
    });
  }

  const sendMessage = async (messagePayload: string) => {
    if (client.current === undefined) {
      log(
        LogLevel.error,
        logIdentifier(url),
        `Could not send Websocket message: no active Websocket client`,
      );
      throw new Error(
        "Could not send Websocket message: no active Websocket client",
      );
    } else if (client.current.readyState === WebSocket.OPEN) {
      client.current.send(messagePayload);
    } else {
      await waitForOpenSocket(client.current);
      client.current.send(messagePayload);
    }
  };

  const sendRegisterTopicsMessage = (topics: Topics) => {
    /**
     * Sending a `setTopics` message with topics that should be added or removed.
     * If the current client is not ready, we do nothing at this point.
     * But a `setTopics` message, containing all registered topics,
     * will be sent once the websocket connects, in the `onopen` handler in `useWebsocketInternalClient`.
     */
    if (client.current) {
      sendMessage(
        JSON.stringify({
          action: "setTopics",
          topics,
        }),
      );
    }
  };

  const register = (name: string, topics: WebsocketTopics[]) => {
    topicMap.current.set(name, topics);
    sendRegisterTopicsMessage({ add: topics, remove: [] });
  };

  const deregister = (name: string, topics: WebsocketTopics[]) => {
    topicMap.current.delete(name);
    sendRegisterTopicsMessage({ add: [], remove: topics });
  };

  return { message, sendMessage, register, deregister };
};
