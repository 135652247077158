import { Flex, Grid, HStack, Text } from "@vygruppen/spor-react";
import { isToday } from "date-fns";
import { format } from "date-fns/format";
import { nb } from "date-fns/locale/nb";
import { DashedLine } from "features/CenterContent/VehicleDetails/shared/DashedLine";
import {
  IndicatorWrapper,
  VerticalLine,
} from "features/TodoOplogg/Oplogg/OploggElement/styles";
import { CircleIndicator } from "shared/components/dataDisplay/CircleIndicator";
import styled, { useTheme } from "styled-components";

const MessageListContainer = styled(Grid)`
  grid-template-columns: 15px 1fr;
  grid-template-rows: 1fr "24px";
  gap: 6px;
`;

// The same as an oplogg element, but without alarm/warning/highlight
export const MessageListElement = ({
  text,
  author,
  reportedDateTime,
  isLastEntry,
}: {
  text: string;
  author: string;
  reportedDateTime: Date;
  isLastEntry: boolean;
}) => {
  const theme = useTheme();
  const isFromToday = isToday(reportedDateTime);
  return (
    <>
      <MessageListContainer mt="12px">
        <IndicatorWrapper>
          <CircleIndicator
            $color={theme.colorSeparationLine}
            style={{ margin: "5px auto 0 auto" }}
          />
          <VerticalLine
            style={{ marginTop: "-49px", height: "calc(100px + 49px)" }} // Go through the circle instead of under it
            color={theme.colorSeparationLine}
          />
        </IndicatorWrapper>
        <Flex flexDir="column" gridColumn={2}>
          <Text variant="xs" fontWeight="bold">
            {format(reportedDateTime, "HH:mm", { locale: nb })}
          </Text>
          <Text variant="sm" fontWeight="normal">
            {text}
          </Text>
        </Flex>
        <HStack
          gridColumn={2}
          justifyContent="flex-end"
          gap="6px"
          alignSelf="stretch"
        >
          <Text
            variant="xxs"
            style={{
              color: theme.colorTextSecondary,
              textAlign: "end",
              fontSize: "14px", // XXS doesn't actually exist in spor-react
              lineHeight: "18px", // XXS doesn't actually exist in spor-react
            }}
          >
            {author}
          </Text>
          {!isFromToday && (
            <Text
              variant="xxs"
              style={{
                color: theme.colorTextSecondary,
                textAlign: "end",
                fontSize: "14px", // XXS doesn't actually exist in spor-react
                lineHeight: "18px", // XXS doesn't actually exist in spor-react
              }}
            >
              {format(reportedDateTime, "dd.MM", { locale: nb })}
            </Text>
          )}
        </HStack>
      </MessageListContainer>
      {!isLastEntry && <DashedLine mt="12px" ml="18px" mr="6px" />}
    </>
  );
};
