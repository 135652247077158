import { log, LogLevel } from "api/cloudWatch";
import { refreshCognitoTokens } from "api/cognito/cognito";
import { getRefreshToken, setDropsToken } from "api/dropsLocalStorage";
import { AxiosError } from "axios";
import OploggMessages, {
  OploggList,
} from "features/TodoOplogg/Oplogg/OploggMessages";
import { OploggProvider } from "features/TodoOplogg/Oplogg/useOploggContext";
import { useEffect } from "react";
import styled, { css } from "styled-components";

const zoomIn = (amount: number) => css`
  transform: scale(${amount});
  transform-origin: 0 0;
  max-width: ${100 / amount}vw;
  max-height: ${100 / amount}vh;
  grid-column: 1 / -1;
`;

const OploggPageWrapper = styled.div<{ $scale: number }>`
  ${({ $scale }) => zoomIn($scale)}

  & ${OploggList} {
    height: calc(100% - 75px);
  }
`;

export const OploggPage = () => {
  const refreshTokens = () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      log(
        LogLevel.warn,
        "OploggPage",
        "Could not refresh Cognito token: refresh token missing",
      );
      return;
    }
    refreshCognitoTokens(refreshToken)
      .then((response) => setDropsToken(response.data.access_token))
      .catch((e: AxiosError) =>
        log(
          LogLevel.warn,
          "OploggPage",
          `Could not refresh Cognito token: refresh token rejected or fetch otherwise rejected with code ${e.code} (${e.status})`,
        ),
      );
  };

  useEffect(() => {
    // Every 10 minutes
    const interval = setInterval(refreshTokens, 600000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <OploggPageWrapper $scale={2}>
      <OploggProvider>
        <OploggMessages showHeader readonly />
      </OploggProvider>
    </OploggPageWrapper>
  );
};
