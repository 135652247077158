import { HStack } from "@vygruppen/spor-react";
import { format } from "date-fns/format";
import { nb } from "date-fns/locale/nb";
import { ItineraryDetails } from "features/CenterContent/VehicleDetails/AlternativeTransportDetails/Itinerary/ItineraryDetails";
import { VehicleCancellation } from "features/CenterContent/VehicleDetails/AlternativeTransportDetails/Itinerary/VehicleCancellation/VehicleCancellation";
import { VehicleUndoCancellation } from "features/CenterContent/VehicleDetails/AlternativeTransportDetails/Itinerary/VehicleCancellation/VehicleUndoCancellation";
import { VehicleReplacement } from "features/CenterContent/VehicleDetails/AlternativeTransportDetails/Itinerary/VehicleReplacement/VehicleReplacement";
import { isShuttleVehicle } from "features/CenterContent/VehicleDetails/AlternativeTransportDetails/utils";
import { VehiclePhoneNumber } from "features/CenterContent/VehicleDetails/shared/VehiclePhoneNumber";
import { VehicleSummary } from "features/CenterContent/VehicleDetails/shared/VehicleSummary";
import { vehicleTypeToIcon } from "features/CenterContent/VehicleDetails/shared/utils";
import { FC } from "react";
import {
  AlternativeTransportItineraryStop,
  AlternativeTransportVehicle,
  StopReference,
} from "shared/types/alternativeTransport";
import styled from "styled-components";

const Grid = styled.div`
  display: inline-grid;
  grid-column: 1 / -1;
  grid-template-columns: 18rem auto;
  column-gap: 24px;
`;

type Props = {
  alternativeTransport: AlternativeTransportVehicle;
};

export const Itinerary: FC<Props> = ({ alternativeTransport }) => {
  const {
    id,
    lineNames,
    tripPatternId,
    trainIds,
    itinerary,
    type,
    vehicleStatus,
    driverPhoneNumber,
    isPartOfShuttlePlan,
    hastusShuttlePlanId,
    originStopName,
    destinationStopName,
    firstStopName,
    scheduledDepartureTimeOrigin,
    scheduledArrivalTimeDestination,
    workShiftPlan,
  } = alternativeTransport;

  const [Icon, iconTitle] = vehicleTypeToIcon.get(type)!;

  const isShuttle = isShuttleVehicle(alternativeTransport);
  const stops = itinerary?.stops ?? [];
  const returnStops = itinerary?.returnStops ?? [];

  const distributionStopReferences: Array<StopReference> = [
    ...stops,
    ...returnStops,
  ].map((value: AlternativeTransportItineraryStop) => value.stopReference);

  const date = scheduledDepartureTimeOrigin
    ? format(scheduledDepartureTimeOrigin, "(dd.MM.yy)", { locale: nb })
    : undefined;

  const turnaroundStopName =
    returnStops.length > 0 ? returnStops[returnStops.length - 1].name : null;

  const replacesTrainIds = trainIds.length > 0 ? trainIds.join(", ") : null;

  const tripDetails = [
    lineNames,
    ...[hastusShuttlePlanId ? hastusShuttlePlanId : tripPatternId],
  ]
    .filter((stop): stop is string => stop !== null && stop !== "")
    .join(" / ");

  const stations = [originStopName, destinationStopName, turnaroundStopName]
    .filter((stop): stop is string => stop !== null && stop !== "")
    .join(" - ");

  const timeInterval = [
    scheduledDepartureTimeOrigin,
    scheduledArrivalTimeDestination,
  ]
    .filter((dateTime): dateTime is Date => dateTime !== null)
    .map((dateTime) => format(dateTime, "HH:mm", { locale: nb }))
    .join(" - ");

  return (
    <Grid>
      <VehicleSummary
        vehicleIcon={<Icon title={iconTitle} />}
        vehicleInfo={isPartOfShuttlePlan ? "Pendel" : null}
        title={replacesTrainIds ?? iconTitle}
        subTitle={tripDetails}
        route={stations.concat(
          isShuttle && firstStopName && firstStopName !== originStopName
            ? ` (oppstart ${firstStopName})`
            : "",
        )}
        interval={timeInterval}
        date={date}
      />

      <ItineraryDetails
        id={id}
        travelDate={scheduledDepartureTimeOrigin}
        itinerary={itinerary}
        vehicleStatus={vehicleStatus}
        distributionStopReferences={distributionStopReferences}
      />

      <HStack role="group" width="max-content" mt={2}>
        <VehiclePhoneNumber
          phoneNumber={driverPhoneNumber}
          workShift={workShiftPlan}
          id={id}
        />
        <VehicleCancellation vehicle={alternativeTransport} />
        <VehicleUndoCancellation vehicle={alternativeTransport} />
        <VehicleReplacement
          vehicle={alternativeTransport}
          distributionStopReferences={distributionStopReferences}
        />
      </HStack>
    </Grid>
  );
};
