import { StaticAlert } from "@vygruppen/spor-react";
import { FC } from "react";

type MapAlertProps = {
  isError: boolean;
  hasNoResults: boolean;
  query: string;
};

const MapAlert: FC<MapAlertProps> = ({ isError, hasNoResults, query }) => {
  if (hasNoResults) {
    return (
      <StaticAlert variant="error">
        Fant ingen materiell med ID &quot;{query}&quot;
      </StaticAlert>
    );
  }
  if (isError) {
    return (
      <StaticAlert variant="error">
        Noe gikk galt ved innlasting av data
      </StaticAlert>
    );
  }

  return null;
};

export default MapAlert;
