import { useState } from "react";
import { axiosClient } from "./config";
import { httpRequest, notAsked, RequestStatus } from "./httpRequest";

/**
 * Hook for making and keeping track of HTTP PATCH requests.
 * @typeParam ResponseType - Response data type. BodyType - Request body type. If no types are defined, the data will confirm to any.
 * @param url - Request url.
 * @returns A http put request method, and the request state.
 */
export const usePatchRequest = <ResponseType, BodyType>(url: string) => {
  const [patchRequestStatus, setPatchRequestStatus] =
    useState<RequestStatus<ResponseType>>(notAsked());
  const abortController = new AbortController();

  const patch = async (body?: BodyType): Promise<ResponseType> => {
    const response = await axiosClient.patch<ResponseType>(url, body, {
      signal: abortController.signal,
    });
    return response.data;
  };

  const patchRequest = (body?: BodyType) => {
    httpRequest<ResponseType>(patch(body), "GET", url, setPatchRequestStatus);
  };

  const resetStatus = () => {
    setPatchRequestStatus(notAsked());
  };

  return {
    patchRequest,
    patchRequestStatus,
    abortController,
    resetStatus,
  };
};
