import { Expandable, SkeletonText, Text } from "@vygruppen/spor-react";
import { FC, useMemo } from "react";
import { PreviewStatus } from "../../../../../RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/Preview";

type BatchPreviewProps = {
  previewStatus: PreviewStatus;
  previewTexts?: string[];
  isFormValid: boolean;
};

export const BatchPreview: FC<BatchPreviewProps> = ({
  previewStatus,
  previewTexts,
  isFormValid,
}) => {
  const title = useMemo(() => {
    if (!isFormValid) {
      return "En oppsummering av hendelsen";
    }
    switch (previewStatus) {
      case "idle":
      case "pending":
        return "En oppsummering av hendelsen";
      case "success":
        return `Se oppsummering (${previewTexts?.length ?? 0})`;
      case "error":
      default:
        return "Feil: Kunne ikke hente oppsummering";
    }
  }, [previewStatus, isFormValid, previewTexts]);

  const texts = useMemo(() => {
    if (!isFormValid) {
      return [];
    }

    switch (previewStatus) {
      case "idle":
      case "pending":
        return (
          <SkeletonText noOfLines={previewTexts?.length ?? 1} width="100%" />
        );
      case "success":
        return previewTexts?.map((text, i) => (
          <Text key={i} mt={2} variant="xs">
            {text}
          </Text>
        ));
      case "error":
      default:
        return [];
    }
  }, [previewStatus, isFormValid, previewTexts]);

  return (
    <Expandable title={title} allowToggle>
      {texts}
    </Expandable>
  );
};
