import {
  ChoiceChip,
  FormControl,
  FormLabel,
  Skeleton,
  Stack,
} from "@vygruppen/spor-react";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { CommonSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { EitherTrainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { AffectedStopsFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/AffectedStopsFields";
import { TrainContext } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";
import {
  OperationalTextContextType,
  useOperationalTextContexts,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { FailureMessageWithRetryButton } from "shared/components/feedback/FailureMessageWithRetryButton";
import { DROPS_CONTEXT_TYPE } from "shared/types/types";
import { filterAndSortContexts } from "shared/utils/contextUtils";

export const TrainCustom: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps & TrainContext
> = ({ mode, ...props }) => {
  const { formContext, trainFormPrefix } = props;

  // Cast context fields, the default from react-hook-form isn't good enough
  const getValues =
    formContext.getValues as UseFormGetValues<EitherTrainFormSchema>;
  const setValue =
    formContext.setValue as UseFormSetValue<EitherTrainFormSchema>;

  const contextTypesFieldPath =
    `${trainFormPrefix}.distributions.contextTypes` as const;

  const {
    data: allContexts,
    isFetching,
    isPending,
    isSuccess,
    isError,
    refetch,
  } = useOperationalTextContexts();

  // TODO(afriestad): Maybe pull this out from subfields?

  const [contexts, setContexts] = useState(allContexts ?? []);

  // The original `isLoading` from tanstack query is only true on first request, not on retries
  const isLoading = isFetching || isPending;

  const contextIsChecked = useCallback(
    (context: OperationalTextContextType["type"]) =>
      getValues(contextTypesFieldPath)?.some((v) => v === context) ?? false,
    [getValues, contextTypesFieldPath],
  );

  const onContextClick = useCallback(
    (
      context: OperationalTextContextType["type"],
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      const oldValues = getValues(contextTypesFieldPath) ?? [];
      if (event.target.checked && !contextIsChecked(context)) {
        setValue(contextTypesFieldPath, [...oldValues, context], {
          shouldValidate: true,
        });
      } else {
        setValue(
          contextTypesFieldPath,
          oldValues.filter((v) => v !== context),
          { shouldValidate: true },
        );
      }
    },
    [getValues, setValue, contextIsChecked],
  );

  // When creating a new custom event, automatically add DROPS as a recipient
  useEffect(() => {
    if (mode === "create") {
      setValue(contextTypesFieldPath, [DROPS_CONTEXT_TYPE], {
        shouldValidate: true,
      });
    }
  }, []);

  useEffect(() => {
    setContexts(filterAndSortContexts(allContexts));
  }, [allContexts]);

  return (
    <>
      <AffectedStopsFields mode={mode} {...props} />

      <FormControl>
        <FormLabel as="legend">Mottagere</FormLabel>

        {isLoading && (
          <Stack flexDirection="row" width="100%">
            <Skeleton height={6} borderRadius={300} flexGrow={2} />
            <Skeleton height={6} borderRadius={300} flexGrow={3} />
            <Skeleton height={6} borderRadius={300} flexGrow={1} />
          </Stack>
        )}

        {isError && !isLoading && (
          <FailureMessageWithRetryButton
            customMessage="Kunne ikke hente mottagere fra Operational Text-tjenesten"
            retryFunction={() => refetch()}
          />
        )}

        {isSuccess && !isLoading && (
          <Stack flexDirection="row" flexWrap="wrap">
            {contexts?.map((context) => (
              <ChoiceChip
                disabled={context.type === DROPS_CONTEXT_TYPE}
                key={context.type}
                onChange={(e) => onContextClick(context.type, e)}
                isChecked={contextIsChecked(context.type)}
              >
                {context.name}
              </ChoiceChip>
            ))}
          </Stack>
        )}
      </FormControl>
    </>
  );
};
