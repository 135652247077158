import React, { useState } from "react";
import { UserRole } from "shared/types/roles";
import TodoFooter from "./TodoFooter";
import TodoEdit from "./TodoEdit";
import TodoBanner from "./TodoBanner";
import { TodoElementWrapper } from "../../TodoStyles";
import { Text } from "../../../../../shared/components/typography/TitleStyles";
import { TodoStatus } from "../../TodoComponent";

export interface Todo {
  id: string;
  userId: string;
  title: string | null;
  description: string;
  dateCreated: string;
  role: UserRole;
  status: TodoStatus | null;
  priority: boolean | null;
  markedAsRead: boolean | null;
  trainNumber: string;
  vehicleSetTypes: string | null;
  transferOnShiftEnd: null;
  alarmTime: string | null;
}

type Props = {
  todo: Todo;
};
const TodoElement = ({ todo }: Props) => {
  const [selectedTodoId, setSelectedTodoId] = useState<string>("");

  const isShowingEdit = selectedTodoId === todo.id;

  return (
    <TodoElementWrapper>
      <TodoBanner todo={todo} />
      {isShowingEdit ? (
        <TodoEdit
          todo={todo}
          selectedTodoId={selectedTodoId}
          setSelectedTodoId={setSelectedTodoId}
        />
      ) : (
        <div>
          <Text>{todo.description}</Text>
          <TodoFooter
            todo={todo}
            onClick={() => {
              setSelectedTodoId(todo.id);
            }}
          />
        </div>
      )}
    </TodoElementWrapper>
  );
};

export default TodoElement;
