import { FormControl, FormErrorMessage } from "@vygruppen/spor-react";
import {
  FormErrorWithDiscriminatedField,
  FormSchema,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/formSchema";
import { useFormContext } from "react-hook-form";
import { Select } from "shared/components/forms/Select";

export const StationThreatLevel = () => {
  const { register, formState } = useFormContext<FormSchema>();
  const fieldErrors: FormErrorWithDiscriminatedField<"threatLevel"> =
    formState.errors.stationForm;

  return (
    <FormControl isInvalid={!!fieldErrors?.threatLevel}>
      <Select
        label="Trusselnivå"
        {...register("stationForm.threatLevel")}
        placeholder="Velg trusselnivå"
        isInvalid={!!fieldErrors?.threatLevel}
      >
        <option value="ALPHA">Alpha</option>
        <option value="BRAVO">Bravo</option>
        <option value="CHARLIE">Charlie</option>
        <option value="DELTA">Delta</option>
      </Select>

      <FormErrorMessage>{fieldErrors?.threatLevel?.message}</FormErrorMessage>
    </FormControl>
  );
};
