import { FC } from "react";
import { CommonSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { AffectedStopsFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/AffectedStopsFields";
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { Input } from "@vygruppen/spor-react";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { clamp } from "features/CenterContent/shared/utils";
import { EitherTrainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { TrainContext } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";

const MIN_VALUE = 0;
const MAX_VALUE = 60 * 24;

type DelayTypeFieldProps = {
  label: string;
  delayFieldKey: "delayInMinutes" | "minutesLate";
};

export const TrainDelayTypeFields: FC<
  CommonTrainInfoFormProps &
    CommonSubTypeProps &
    TrainContext &
    DelayTypeFieldProps
> = (props) => {
  const { formContext, trainFormPrefix, label, delayFieldKey } = props;
  const { errors } = formContext.formState;

  // Cast context fields, the default from react-hook-form isn't good enough
  const register =
    formContext.register as UseFormRegister<EitherTrainFormSchema>;
  const setValue =
    formContext.setValue as UseFormSetValue<EitherTrainFormSchema>;
  const getValues =
    formContext.getValues as UseFormGetValues<EitherTrainFormSchema>;

  const delayFieldPath = `${trainFormPrefix}.${delayFieldKey}` as const;

  return (
    <>
      <AffectedStopsFields {...props} />
      <Input
        type="number"
        label={label}
        step={1}
        {...register(delayFieldPath)}
        value={
          getValues(delayFieldPath) ||
          "" /* Show the field as empty if the value is 0 or missing */
        }
        onChange={(e) => {
          if (e.target.value) {
            setValue(
              delayFieldPath,
              clamp(MAX_VALUE, MIN_VALUE, parseInt(e.target.value, 10)),
            );
          } else {
            setValue(delayFieldPath, 0);
          }
        }}
        width={`${label.length}ch`}
      />
      <RenderErrorInPath errors={errors} errorPath={delayFieldPath} />
    </>
  );
};

export const TrainDelayed: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps & TrainContext
> = (props) => (
  <TrainDelayTypeFields
    label="Forsinkelse (min)"
    delayFieldKey="delayInMinutes"
    {...props}
  />
);
