import React, { FC } from "react";
import styled from "styled-components";
import { Radio } from "@vygruppen/spor-react";

const Label = styled.label`
  color: ${({ theme }) => theme.colorTextMain};
  font-family: VYSans, sans-serif;
  font-size: 1rem;
  line-height: 1.33rem;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  gap: 6px;
  cursor: pointer;
`;

interface RadioButtonProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  name: string;
  value: string;
  checked: boolean;
}

export const RadioButton: FC<RadioButtonProps> = ({
  name,
  value,
  checked,
  children,
  ...props
}) => (
  <Label {...props}>
    <Radio type="radio" value={value} name={name} defaultChecked={checked} />
    {children}
  </Label>
);
