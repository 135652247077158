import {
  DayPlanList,
  DayPlanListItem,
} from "features/CenterContent/shared/DayPlanList";
import { FC } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Text } from "shared/components/typography/TitleStyles";
import { AlternativeTransportVehicle } from "shared/types/alternativeTransport";
import { BUS } from "shared/utils/routes";
import styled from "styled-components";

const ShiftScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 3px;
`;

type ShiftScheduleProps = {
  vehicle: AlternativeTransportVehicle;
};

export const ShiftSchedule: FC<ShiftScheduleProps> = ({ vehicle }) => {
  const { workShiftPlan, operatorName } = vehicle;
  const navigate = useNavigate();

  return (
    <ShiftScheduleWrapper>
      <Text bold style={{ marginBottom: "12px" }}>
        Skiftplan
      </Text>
      <Text>{operatorName}</Text>
      {workShiftPlan?.shiftId && (
        <Text secondary>Skift: {workShiftPlan.shiftId}</Text>
      )}

      {workShiftPlan?.workShiftTrips &&
        workShiftPlan.workShiftTrips.length > 0 && (
          <DayPlanList style={{ marginTop: "18px" }}>
            {workShiftPlan.workShiftTrips.map((trip) => {
              const isCurrentTrip = trip.vehicleUuid === vehicle.id;
              return (
                <DayPlanListItem
                  isClickable
                  isSelected={isCurrentTrip}
                  onClick={
                    !isCurrentTrip
                      ? () =>
                          navigate(
                            generatePath(BUS, {
                              vehicleUuid: trip.vehicleUuid,
                            }),
                          )
                      : undefined
                  }
                  key={trip.vehicleUuid}
                  title={trip.trainIds.join(", ") ?? "Avgang"}
                  subTitle={trip.tripPatternId}
                  fromLocation={trip.originStopName}
                  toLocation={trip.destinationStopName}
                  startTime={trip.scheduledDepartureTimeOrigin}
                  endTime={trip.scheduledArrivalTimeDestination}
                />
              );
            })}
          </DayPlanList>
        )}
    </ShiftScheduleWrapper>
  );
};
