import { useQuery } from "@tanstack/react-query";
import { CountryCode } from "@vygruppen/vy-train-map";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { getBackendUrl } from "api/common";
import { CombinedHistoryResponse } from "shared/types/operationalInformation";

type InformationResponse = {
  information: CombinedHistoryResponse[];
};

export const useOperationalInformation = (
  countryCode: CountryCode,
  date: string,
) => {
  const { data, status } = useQuery({
    queryKey: ["operationalInformation", date],
    refetchOnWindowFocus: false,
    retry: 1,
    refetchInterval: 60000,
    queryFn: async ({ signal }: { signal: AbortSignal }) => {
      const res = await queryFnGET<InformationResponse>({
        signal,
        url: `${getBackendUrl()}/operational-information/history/${countryCode}/${date}`,
      });
      return res.information;
    },

    gcTime: 0,
  });
  return { status, data };
};
