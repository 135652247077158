import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import TrainMap from "features/CenterContent/RoleContent/TrainMap/TrainMap";
import { SharedContentGridLayout } from "features/CenterContent/shared/MainContentGrid";
import styled from "styled-components";

const Container = styled.div`
  ${SharedContentGridLayout};
  background-color: ${({ theme }) => theme.colorBackgroundMain};
  grid-auto-flow: column;
  grid-auto-columns: 2fr minmax(350px, 1fr);
`;

const TrainMapContainer = styled.div`
  position: relative;
`;

const Vaktleder = () => (
  <Container>
    <TrainMapContainer>
      <TrainMap />
    </TrainMapContainer>
  </Container>
);

export default withErrorBoundary(Vaktleder);
