import styled from "styled-components";
import { NativeSelect } from "@vygruppen/spor-react";

// Styled NativeSelect with increased specificity of the option selector to override chakra global option styles
// https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity#increasing_specificity_by_duplicating_selector
export const Select = styled(NativeSelect)`
  &&& option {
    background: inherit;
    color: inherit;
  }
  &&& optgroup {
    background: inherit;
    color: inherit;
  }
`;
