import type { PropsWithChildren } from "react";
import React, { createContext, useContext, useMemo } from "react";

import { awsRumConfig, ignoreError } from "logging/utils";

import { AwsRum } from "aws-rum-web";
import {
  getLocalStorageRole,
  getLocalStorageUsername,
} from "api/dropsLocalStorage";
import { cloudwatchRumConfig } from "logging/config";
import { Environment, environment } from "environment";

type AwsRumContextType = Pick<
  AwsRum,
  "recordError" | "recordEvent" | "recordPageView" | "registerDomEvents"
>;

const AwsRumContext = createContext<AwsRumContextType | null>(null);

/**
 * Can be used to record errors to CloudWatch RUM throughout the app.
 */
export function useAwsRum() {
  const context = useContext(AwsRumContext);
  if (!context) {
    throw new Error("useAwsRum must be used within an AwsRumProvider");
  }
  return context;
}

export const AwsRumProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const config = cloudwatchRumConfig();
  const userName = getLocalStorageUsername();
  const role = getLocalStorageRole();

  const identityPoolId = config?.identityPoolId;
  const rumAppId = config?.rumAppId;
  const appVersion = config?.appVersion;
  const endpoint = config?.endpoint;
  const sessionEventLimit = config?.sessionEventLimit;

  const awsRum = useMemo(() => {
    if (
      !rumAppId ||
      !identityPoolId ||
      !endpoint ||
      environment() === Environment.Development
    ) {
      return null;
    }

    try {
      return new AwsRum(
        rumAppId,
        appVersion ?? "unknown",
        "eu-west-1",
        awsRumConfig({
          identityPoolId,
          endpoint,
          sessionAttributes: {
            userName: userName ?? "anonymous",
            role,
          },
          sessionEventLimit: sessionEventLimit ?? 1000,
        }),
      );
    } catch (error) {
      console.error(rumAppId);
      return null;
    }
  }, [rumAppId, identityPoolId, appVersion, sessionEventLimit, userName, role]);

  const value = useMemo<AwsRumContextType>(
    () => ({
      recordError(error) {
        if (!ignoreError(error)) {
          const logger = awsRum?.recordError.bind(awsRum) ?? console.error;
          logger(error);
        }
      },
      recordEvent: awsRum?.recordEvent.bind(awsRum) ?? console.debug,
      recordPageView: awsRum?.recordPageView.bind(awsRum) ?? console.debug,
      registerDomEvents:
        awsRum?.registerDomEvents.bind(awsRum) ?? console.debug,
    }),
    [awsRum],
  );

  return (
    <AwsRumContext.Provider value={value}>{children}</AwsRumContext.Provider>
  );
};
