export function mailLink() {
  const feedbackEmail = "itservicesenter@service-now.com";
  const ccEmail = "team-brukerflater-drops@vy.no";
  const subject = encodeURIComponent("Tilbakemelding fra Drops Dashboard");

  const body = encodeURIComponent(`
      1. Hva har skjedd, og hva gjorde du da det skjedde?
          
      2. Når skjedde det? Har det skjedd flere ganger?
          
      3. Vedlegg gjerne et skjermbilde av hele skjermen (Trykk på windows knapp, shift og S)

      4. Hvis aktuelt:
      - Steg for å reprodusere feilen 
      - Har du prøvd noen steg for å løse problemet?
  `);

  return `mailto:${feedbackEmail}?cc=${ccEmail}&subject=${subject}&body=${body}`;
}
