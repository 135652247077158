import { useState } from "react";

/**
 * A hook for holding a loading state with a preconfigured timeout.
 *
 * @param timeoutMs time to wait before hasTimedOut becomes true
 */
export const useLoadingWithTimeout = (timeoutMs: number) => {
  const [timeoutRef, setTimeoutRef] = useState<number>();
  const [timedOut, setTimedOut] = useState(false);

  return {
    setIsLoading: (isLoading: boolean) => {
      if (isLoading) {
        window.clearTimeout(timeoutRef);
        setTimeoutRef(
          window.setTimeout(() => {
            setTimedOut(true);
            setTimeoutRef(undefined);
          }, timeoutMs),
        );
      } else {
        window.clearTimeout(timeoutRef);
        setTimeoutRef(undefined);
        setTimedOut(false);
      }
    },
    isLoading: timeoutRef !== undefined,
    hasTimedOut: timedOut,
  };
};
