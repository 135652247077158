import styled from "styled-components";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { OpLoggInput } from "./OploggInput";
import OploggMessages from "./OploggMessages";

const OploggWrapper = styled.div`
  display: grid;
  grid-template-areas: "input" "messages";
  grid-template-rows: auto 1fr;

  min-height: 0;
  height: 100%;

  width: 420px;
  overflow-y: auto;
`;

const Oplogg = () => (
  <OploggWrapper>
    <OpLoggInput />
    <OploggMessages />
  </OploggWrapper>
);

export default withErrorBoundary(Oplogg);
