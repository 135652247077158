import { Tooltip } from "@chakra-ui/react";
import { SpeechBubbleFill24Icon } from "@vygruppen/spor-icon-react";
import { HStack } from "@vygruppen/spor-react";
import { FC } from "react";

type CodeAndCommentProps = {
  code: string | null;
  comment: string | null;
};

const CodeAndComment: FC<CodeAndCommentProps> = ({ code, comment }) => (
  <HStack>
    <p>{code}</p>
    {comment?.replace(/^\s*$/, "")?.length && (
      <Tooltip hasArrow label={comment} placement="top">
        <SpeechBubbleFill24Icon aria-label="Kommentar" />
      </Tooltip>
    )}
  </HStack>
);

export default CodeAndComment;
