import { Tooltip } from "@chakra-ui/react";
import { Flex, VStack } from "@vygruppen/spor-react";
import { CallButton } from "shared/components/buttons/CallButton";
import { useTheme } from "styled-components";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { TrainData } from "./types";
import { ButtonGroup, ButtonStyled } from "./styles";

const getSemanticsForEvent = (priority: number) => {
  if (priority <= 4) {
    return "alarm";
  }
  if (priority <= 5) {
    return "warning";
  }
  return "info";
};

type trainEventTitlesSchema = {
  type: string;
  priority: number;
};

const trimType = (type: string) => {
  const trimmed = type.split(" (")[0];
  return trimmed.length > 15 ? `${trimmed.substring(0, 12)}...` : trimmed;
};
export const RightContent = ({
  trainStaffOnDuty,
  eventNames: [firstEvent, ...otherEvents],
}: {
  trainStaffOnDuty: TrainData["trainStaffOnDuty"];
  eventNames: trainEventTitlesSchema[];
}) => {
  const theme = useTheme();

  return (
    <ButtonGroup role="group">
      <VStack spacing={2} alignItems="flex-end">
        <Flex wrap="wrap" justifyContent="flex-end" gap="3px">
          {firstEvent && (
            <DropsBadge semantics={getSemanticsForEvent(firstEvent.priority)}>
              {trimType(firstEvent.type)}
            </DropsBadge>
          )}

          {otherEvents.length > 0 && (
            <Tooltip
              hasArrow
              placement="right"
              label={
                <VStack>
                  {otherEvents.map((event) => (
                    <DropsBadge
                      semantics={getSemanticsForEvent(event.priority)}
                    >
                      {trimType(event.type)}
                    </DropsBadge>
                  ))}
                </VStack>
              }
              padding="8px"
              bg={theme.colorBackgroundMain}
              border={`1px solid ${theme.borderColor}`}
            >
              <ButtonStyled>
                <DropsBadge
                  semantics={getSemanticsForEvent(
                    Math.min(...otherEvents.map((it) => it.priority)),
                  )}
                >
                  +{otherEvents.length}
                </DropsBadge>
              </ButtonStyled>
            </Tooltip>
          )}
        </Flex>
        <Flex wrap="wrap" justifyContent="flex-end" gap="3px">
          {trainStaffOnDuty?.map((staffOnDuty) => (
            <CallButton
              key={staffOnDuty.gsmrNumber}
              label={staffOnDuty.function}
              phoneNumber={staffOnDuty.functionalTelephoneNumber}
              size="xs"
            />
          ))}
        </Flex>
      </VStack>
    </ButtonGroup>
  );
};
