import { useEffect, useState } from "react";
import { usePuzzelExtendedStateInformation } from "../../../provider/contexts/ListenForCallStatusContext";
import CallerNumberInfo from "../../CallerNumberInfo";
import { CallIconStyled, CallStatusStyled } from "../../../PuzzelStyle";
import {
  assembleFullName,
  NO_COUNTRY_CODE_PREFIX,
  removePhoneNumberPrefixes,
} from "../../../utils";
import { IqSessionRequestType, Status } from "../../types";
import { usePuzzelAuthenticationContext } from "../../../provider/contexts/PuzzelAuthenticationContext";
import { useUserValidationState } from "../../../provider/contexts/UserValidationContext";
import { performPuzzelCatalogSearch } from "../../../../../../api/puzzel/puzzel";

const CallAllocated = () => {
  const { iqSessionStateRequests }: any = usePuzzelExtendedStateInformation();
  const puzzelContext = usePuzzelAuthenticationContext();
  const { phoneNumberPrefix, functionalNumberPrefix } = puzzelContext;
  const puzzelUserId = useUserValidationState().userId;
  const [callerName, setCallerName] = useState("");
  const [callerNumber, setCallerNumber] = useState<string>();

  const resetCaller = () => {
    setCallerName("");
    setCallerNumber("");
  };

  useEffect(() => {
    if (callerNumber) {
      performPuzzelCatalogSearch({
        searchString: callerNumber,
        userId: puzzelUserId,
      }).then((response) => {
        if (response.data?.result.length > 0) {
          const fullName = assembleFullName(response.data?.result);
          setCallerName(fullName);
        }
      });
    }
  }, [callerNumber]);

  useEffect(() => {
    if (iqSessionStateRequests?.length > 0) {
      const incomingPhoneNumber = removePhoneNumberPrefixes(
        iqSessionStateRequests[0].remoteAddress,
        [phoneNumberPrefix, NO_COUNTRY_CODE_PREFIX, functionalNumberPrefix],
      );
      setCallerNumber(incomingPhoneNumber);
    } else {
      resetCaller();
    }
  }, [iqSessionStateRequests, phoneNumberPrefix, functionalNumberPrefix]);

  if (callerNumber) {
    return iqSessionStateRequests
      .filter((req: IqSessionRequestType) => req.status === Status.Allocated)
      .map((req: IqSessionRequestType, index: number) => (
        <CallStatusStyled $showBigAnimation key={`call-allocated-${index}`}>
          <div style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
            <CallIconStyled />
            <CallerNumberInfo
              status={req.status}
              remoteAddress={`${callerName} ${callerNumber}`}
            />
          </div>
        </CallStatusStyled>
      ));
  }
  return null;
};

export default CallAllocated;
