import { FC, useEffect, useState } from "react";
import { CommonSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { useExistingTrainCancelledEvents } from "features/CenterContent/VehicleDetails/TrainDetails/useExistingTrainCancelledEvents";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { AffectedStopsFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/AffectedStopsFields";
import { TrainContext } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";

function isNotUndefined<T>(element: T | undefined): element is T {
  return element !== undefined;
}

export const TrainCancelled: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps & TrainContext
> = (props) => {
  const {
    mode,
    trainId: { identifier, nominalDate },
    uuid,
  } = props;
  const { data: existingEvents } = useExistingTrainCancelledEvents({
    trainId: identifier,
    nominalDate,
    uuid,
  });

  const [alreadySelectedStops, setAlreadySelectedStops] = useState<string[]>(
    [],
  );

  useEffect(() => {
    let idsOrUndefined;
    if (existingEvents) {
      if (uuid && mode === "edit") {
        idsOrUndefined = existingEvents
          .filter((e) => e.uuid !== uuid)
          .flatMap((e) =>
            e.stops.flatMap((event) => event.ids.flatMap((ev) => ev.id)),
          );
      } else {
        idsOrUndefined = existingEvents.flatMap((event) =>
          event.stops.map(
            (stop) => stop.ids.find((id) => id.context === "JBV")?.id,
          ),
        );
      }

      setAlreadySelectedStops(
        idsOrUndefined.filter(isNotUndefined).map((id) => id as string),
      );
    }
  }, [existingEvents]);

  return (
    <AffectedStopsFields
      {...props}
      alreadySelectedStops={alreadySelectedStops}
    />
  );
};
