import { MutableRefObject, useEffect, useRef } from "react";
import { getDropsToken, getRefreshToken } from "api/dropsLocalStorage";
import { log, LogLevel } from "api/cloudWatch";
import { isExpired } from "app/Auth/auth";
import { refreshCognitoTokensSynchronized } from "app/Auth/axiosAuthInterceptor";
import { withBearerToken } from "./utils";
import { WebsocketMessage, WebsocketTopics } from "./websocketTypes";
import { WebsocketClientProps } from "./useWebsocketClient";

type WebsocketInternalClientReturnType = {
  client: MutableRefObject<WebSocket | undefined>;
};

type WebsocketInternalClientProps<T> = {
  url: string;
  topicMap?: MutableRefObject<Map<String, WebsocketTopics[]>>;
  onMessage: (message: WebsocketMessage<T>) => void;
};

const logIdentifier = (url: string): string =>
  `useWebsocketInternalClient (...${url.slice(url.lastIndexOf("/"))})`;

const getDropsTokenOrThrow = (url: string): string => {
  const token = getDropsToken();

  if (!token) {
    log(
      LogLevel.error,
      logIdentifier(url),
      `Failed to establish WebSocket connection: access token was missing.`,
    );
    throw Error(
      "Failed to establish WebSocket connection: access token was missing.",
    );
  } else if (isExpired(token)) {
    /**
     * refreshCognitoTokensSynchronized is async, so the new token might not be ready by the time
     * we try to use it. But the next time we try to connect it should be ready, and we'll be able to
     * establish a connection. Ideally we should make this function async and wait for a new token,
     * but this is still better than doing nothing.
     */
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      refreshCognitoTokensSynchronized(refreshToken);
    }
  }

  return token;
};

const createClient = <T>(
  url: string,
  pongTimoutId: MutableRefObject<number | undefined>,
  onMessage: (message: WebsocketMessage<T>) => void,
  topicMap?: MutableRefObject<Map<String, WebsocketTopics[]>>,
): WebSocket => {
  const token = getDropsTokenOrThrow(url);
  const ws = new WebSocket(withBearerToken(url, token));

  ws.onmessage = (event) => {
    if (event.data === "pong") {
      if (pongTimoutId) {
        clearTimeout(pongTimoutId.current);
        pongTimoutId.current = undefined;
      }
    } else {
      const parsedMessage = JSON.parse(event.data);
      onMessage({ data: parsedMessage, status: "received" });
    }
  };

  ws.onopen = () => {
    /**
     * topicMap contains all topics the client should currently be listening to. Because topics can be registered
     * before the websocket connection is ready, we need to send all registered topics `onopen`
     */
    if (topicMap?.current) {
      ws.send(
        JSON.stringify({
          action: "setTopics",
          topics: {
            add: Array.from(topicMap.current.values()).flat(),
            remove: [],
          },
        }),
      );
    }
    log(
      LogLevel.info,
      logIdentifier(url),
      "WebSocket connection opened successfully",
    );
  };

  ws.onerror = (event) => {
    log(
      LogLevel.error,
      logIdentifier(url),
      `WebSocket error occurred: ${JSON.stringify(event)}`,
    );
  };

  ws.onclose = (closeEvent) => {
    log(
      closeEvent.wasClean ? LogLevel.info : LogLevel.error,
      logIdentifier(url),
      `WebSocket connection closed ${
        closeEvent.wasClean ? "cleanly" : "uncleanly"
      } with code: [${closeEvent.code}] - ${closeEvent.reason}`,
    );
  };

  return ws;
};

/**
 * Hook responsible for maintaining an active Websocket connection. This is achieved by sending a ping and creating a
 * new Websocket if a pong is not received within reasonable time.
 * @param url the url to connect to
 * @param topicMap the current topics we are subscribing to. A ´setTopics` message will be sent upon
 *                 connection, based on the contents of this map.
 * @param onMessage callback for new messages
 * @param pingInterval how often (in milliseconds) the pings should be sent
 * @param pongTimeout how many milliseconds to wait for a pong before trying to reconnect
 */
export const useWebsocketInternalClient = <T>({
  url,
  topicMap,
  onMessage,
  pingInterval,
  pongTimeout,
}: WebsocketInternalClientProps<T> &
  WebsocketClientProps): WebsocketInternalClientReturnType => {
  const pongTimeoutId = useRef<number>();
  const pingIntervalId = useRef<number>();
  const client = useRef<WebSocket>();

  /**
   * Create a new Websocket or reuse an existing Websocket if one already
   * is connected
   */
  const getOrCreateClient = () => {
    // we already have a connected client
    if (
      client.current &&
      (client.current.readyState === WebSocket.CONNECTING ||
        client.current.readyState === WebSocket.OPEN)
    ) {
      return client.current;
    }
    return createClient(url, pongTimeoutId, onMessage, topicMap);
  };

  // setup ping pong
  useEffect(() => {
    pingIntervalId.current = window.setInterval(() => {
      if (client.current) {
        client.current.send('{"action": "ping"}');

        // wait for pong
        pongTimeoutId.current = window.setTimeout(() => {
          log(
            LogLevel.warn,
            logIdentifier(url),
            "Did not receive pong before timeout, creating new WebSocket client",
          );
          client.current = getOrCreateClient();
        }, pongTimeout);
      }
    }, pingInterval);
  }, []);

  // initialise and cleanup
  useEffect(() => {
    // Initialise client
    client.current = getOrCreateClient();

    // Clean up on unmount
    return () => {
      client.current?.close();
      window.clearTimeout(pongTimeoutId.current);
      window.clearInterval(pingIntervalId.current);
    };
  }, []);

  return { client };
};
