import axios from "axios";
import React from "react";
import { log, LogLevel } from "../cloudWatch";

interface NotAsked {
  status: "notAsked";
}

interface Loading {
  status: "loading";
}

interface Success<ResponseType> {
  status: "success";
  data: ResponseType;
}

interface Refreshing<ResponseType> {
  status: "refreshing";
  data: ResponseType;
}

interface Failure {
  status: "failure";
}

export const notAsked = (): NotAsked => ({
  status: "notAsked",
});

export const loading = (): Loading => ({
  status: "loading",
});

export const failure = (): Failure => ({
  status: "failure",
});

export const success = <ResponseDataType,>(
  data: ResponseDataType,
): Success<ResponseDataType> => ({
  status: "success",
  data,
});

export const refreshing = <ResponseDataType,>(
  data: ResponseDataType,
): Refreshing<ResponseDataType> => ({
  status: "refreshing",
  data,
});

export type RequestStatus<ResponseDataType> =
  | NotAsked
  | Loading
  | Success<ResponseDataType>
  | Refreshing<ResponseDataType>
  | Failure;

export const httpRequest = <ResponseType,>(
  request: Promise<ResponseType>,
  method: "GET" | "PUT" | "POST" | "DELETE",
  url: string,
  setState: React.Dispatch<React.SetStateAction<RequestStatus<ResponseType>>>,
) => {
  setState((prevState) => {
    if (prevState.status === "success") {
      return refreshing(prevState.data);
    }
    return loading();
  });

  request
    .then((response) => setState(success(response)))
    .catch((error) => {
      if (!axios.isCancel(error)) {
        log(
          LogLevel.error,
          `Api${method}Response`,
          `${method} request to ${url} responded with an error: ${error}`,
        );
        setState(failure());
      } else {
        setState(notAsked());
      }
    });
};

export function isSuccessfulRequest<T>(
  request: RequestStatus<T>,
): request is Success<T> {
  return request.status === "success";
}

export function isRefreshingRequest<T>(
  request: RequestStatus<T>,
): request is Refreshing<T> {
  return request.status === "refreshing";
}

export function isFailureRequest<T>(
  request: RequestStatus<T>,
): request is Failure {
  return request.status === "failure";
}

export function isSucessOrFailure<T>(
  request: RequestStatus<T>,
): request is Success<T> | Failure {
  return request.status === "success" || request.status === "failure";
}
